import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_SESSION } from "graphQL/queries";
import { useHistory, useLocation } from "react-router-dom";
import { useToastMessage } from "components/useToastMessage";

export const useSession = () => {
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const { showToastMessage } = useToastMessage();

  const [verifySessionToken, { data, loading, error, client }] = useLazyQuery(
    GET_USER_SESSION,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    }
  );

  const [isLoading, setIsLoading] = useState(true);
  const [session, setSession] = useState(false);

  const checkUserSession = () => {
    if (data?.client_user_session?.length > 0) {
      setSession(true);
    } else {
      showToastMessage({
        color: "danger",
        message: error?.message || "Session expired!",
      });
      client.resetStore().then(() => {
        localStorage.clear();
        setSession(false);
        history.push("/login");
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!loading && data?.client_user_session) {
      checkUserSession();
    }
  }, [data, error]);

  useEffect(async () => {
    if (token) {
      await verifySessionToken({
        variables: { token, expiredAt: new Date().toISOString() },
      });
    } else {
      setSession(false);
      setIsLoading(false);
    }
  }, [token, location]);

  return [session, isLoading];
};
