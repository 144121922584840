import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "antd";
import Icon from "@ant-design/icons";

import SearchIcon from "../../assests/icons/SearchIcon";

const InputWrapper = styled.div`
  position: relative;
  min-width: 28px;
  height: 44px;
  max-width: 332px;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const SearchField = styled(Input)`
  position: absolute;
  background: ${({ isExpand }) => (isExpand ? "#f5f5f5" : "transparent")};
  border-radius: 100px;
  min-width: ${({ isExpand }) => (isExpand ? "205px" : "60px")};
  right: -20px;
  width: ${({ isExpand }) => (isExpand ? "42vw" : "60px")};
  height: 44px;
  border: ${({ isExpand }) => (isExpand ? " 1px solid #f5f5f5" : "none")};

  input {
    background: #f5f5f5;
    font-size: 0.89rem;
  }
  .assets-search-icon {
    font-size: 15.2px;
    margin-right: 8px;
    margin-left: 6px;
    @media screen and (max-width: 768px) {
      font-size: 22px;
    }
  }
  @media screen and (max-width: 360px) {
    min-width: ${({ isExpand }) => (isExpand ? "183px" : "40px")};
  }
`;

const ExpandableSearch = ({ onSearch = null }) => {
  const [isSearch, setIsSearch] = useState(false);
  return (
    <InputWrapper>
      <SearchField
        onChange={onSearch}
        isExpand={isSearch}
        onBlur={() => setIsSearch(false)}
        placeholder="Search"
        prefix={
          <Icon
            component={SearchIcon}
            className="assets-search-icon"
            onClick={() => setIsSearch(true)}
          />
        }
      />
    </InputWrapper>
  );
};

export default ExpandableSearch;
