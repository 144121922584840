import React from "react";

import styled from "styled-components";
import { IonCol, IonRow, IonText } from "@ionic/react";

import ArrowLeftCirlce from "../../assests/icons/arrowLeftCircle";
import DownArrow from "../../assests/icons/downArrow";
import NewNotification from "../../assests/icons/newNotificationIcon";
import Notification from "../../assests/icons/notificationIcon";
import { useHistory } from "react-router";
import useWindowDimensions from "../useWindowDimensions";

const TextColumn = styled(IonCol)`
  flex: 0;
`;
const Column = styled(IonCol)`
  display: flex;
  justify-content: flex-end;
  font-size: 0.938rem;
`;
const WelcomeText = styled(IonText)`
  font-size: 1.5rem;
  font-weight: 300;
  cursor: pointer;
  caret-color: rgba(0, 0, 0, 0);
  user-select: none;
`;
const ProjectSelectorContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;
const TitleProject = styled.span`
  font-weight: 700;
`;
const PointerCursor = styled.span`
  cursor: pointer;
`;
const PointerCursorSpan = styled.span`
  cursor: pointer;
`;
const ProjectName = styled.span`
  font-weight: 400;
`;
const HeaderContainer = styled(IonRow)`
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: var(--text-primary);
  border-bottom: 0.5px solid #c4c4c4;
  padding: 20px 0px;
  @media screen and (max-width: 768px) {
    padding: 20px 10px;
    ${WelcomeText} {
      font-weight: 400;
    }
    ${PointerCursorSpan} {
      // display: none;
    }
  }
`;
const HeaderComponent = ({
  arrowCircle = false,
  projectName = "Name of the project",
  newNotification,
  handlerProjectDrawer,
  handleNotificationDrawer,
  onClickBack = undefined,
}) => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  return (
    <HeaderContainer>
      <TextColumn>
        {arrowCircle ? (
          <PointerCursorSpan
            onClick={() => (onClickBack ? onClickBack() : history.goBack())}
          >
            <ArrowLeftCirlce />
          </PointerCursorSpan>
        ) : (
          <WelcomeText
            onClick={() =>
              !history.location.pathname.includes("/home") &&
              history.push("/home")
            }
          >
            VIZDOM
          </WelcomeText>
        )}
      </TextColumn>
      <Column>
        <ProjectSelectorContainer>
          <TitleProject>Project&nbsp;:&nbsp;</TitleProject>
          <PointerCursor onClick={() => handlerProjectDrawer(true)}>
            <ProjectName title={projectName}>
              {projectName
                ? projectName.length <= (width <= 768 ? 12 : 25)
                  ? projectName
                  : projectName?.substr(0, width <= 768 ? 10 : 20) + "..."
                : ""}
            </ProjectName>
            &nbsp;
            <DownArrow />
          </PointerCursor>
        </ProjectSelectorContainer>
        <PointerCursor onClick={() => handleNotificationDrawer(true)}>
          {newNotification ? <NewNotification /> : <Notification />}
        </PointerCursor>
      </Column>
    </HeaderContainer>
  );
};

export default HeaderComponent;
