import { IonCol, IonRow, IonText } from "@ionic/react";
import styled from "styled-components";
import { RightOutlined } from "@ant-design/icons";
import LinkPlaceholder from "../../assests/images/linkPlaceholder.png";
import { Image as AntdImage } from "antd";

const MetaTagContent = styled.div`
  display: flex;
  padding: 12px;
`;
const MetaImage = styled(IonCol)`
  flex: 0.1;
  min-width: 4rem;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c4c4c4;
  border-radius: 4px;
  padding: 0px;
  .ant-image {
    height: 100%;
    width: 100%;
  }
`;
const MetaDescription = styled.div`
  padding: 0px 12px 0px 16px;
  flex: 1;
`;
const MetaLink = styled(IonText)`
  font-size: 0.9rem;
  color: var(--text-primary);
  font-weight: 600;
`;
const MetaDomain = styled(IonText)`
  font-size: 0.8rem;
  color: #1da1f2;
  font-weight: 500;
`;
const Image = styled(AntdImage)`
  width: 100%;
  height: 100%;
  min-width: 44px;
  .ant-image {
    height: 100%;
  }
`;
const Content = styled.div`
  font-family: Montserrat;
  width: 100%;
  background: #f5f5f5;
  cursor: pointer;
  margin-bottom: 10px;
  &:hover {
    background: #efeeee;
  }
  @media screen and (max-width: 768px) {
    ${MetaImage} {
      min-width: 4.5rem;
    }
  }
`;

const LinksItem = (props) => {
  const handleLink = (url) => {
    window.open(url);
  };

  return (
    <Content onClick={() => handleLink(props.url)} key={props.id}>
      <MetaTagContent>
        <MetaImage>
          <Image
            preview={false}
            src={props.image || "error"}
            fallback={LinkPlaceholder}
          />
        </MetaImage>
        <MetaDescription>
          <IonRow>
            <MetaLink title={props.url ? props.url : ""}>
              {props.sentByPersonName}
            </MetaLink>
          </IonRow>
          <IonRow>
            <MetaDomain>{props.url}</MetaDomain>
          </IonRow>
        </MetaDescription>
        <div style={{ alignSelf: "center" }}>
          <RightOutlined style={{ fontSize: 14, color: "#969697" }} />
        </div>
      </MetaTagContent>
    </Content>
  );
};

export default LinksItem;
