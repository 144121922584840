import TaskCommentItem from "./taskCommentItem";
import CommentInput from "./commentInputComponent";
import { IonRow, IonText, useIonToast, IonLoading } from "@ionic/react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  GET_FILE_COMMENTS,
  GET_COMMENTS_LIST,
  GET_REPLIES_LIST,
  GET_PINNED_COMMENTS,
  GET_RESOLVED_COMMENTS,
} from "../../graphQL/queries";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  ADD_ASSET_COMMENT_THREAD,
  REPLY_TO_COMMENT,
  RESOLVE_COMMENT,
  UPLOAD_FILE,
  LINK_ATTACHMENT_AND_COMMENT,
  READ_COMMENTS,
  PIN_COMMENT,
  UNPIN_COMMENT,
  PIN_FILE_COMMENT,
  READ_FILE_COMMENTS,
} from "../../graphQL/mutations";
import { useParams } from "react-router-dom";
import { useRouterQuery } from "../../lib/useRouterQuery";
import { useHistory } from "react-router-dom";

const Comments = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const Comment = styled.div`
  margin-bottom: 20px;
  width: 100%;
  padding: 4px;
`;

const ViewHideText = styled(IonText)`
  color: #575757;
  font-size: 0.875rem;
  margin: 10px 0px;
`;

const CommentsContainer = styled(IonRow)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ViewMoreText = styled(IonText)`
  margin-left: 64px;
  margin-bottom: 20px;
  font-size: 0.875rem;
  cursor: pointer;
  display: ${({ display }) => (display ? "block" : "none")};
`;

const NoDataText = styled(IonText)`
  font-size: 0.75rem;
  font-weight: 700;
  color: #575757;
  padding-top: 20px;
`;

const NoDataContent = styled(IonRow)`
  height: 300px;
`;

const PinnedCommentsContent = styled.div`
  width: 100%;
  background: ${({ status, highlight }) =>
    highlight ? "#d4efff" : status ? "#E7E7E7" : "rgb(245, 245, 245)"};
  color: rgb(87, 87, 87);
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0px;
  cursor: pointer;
`;

const CommentsContent = styled(IonRow)`
  margin-top: 10px;
  overflow-y: auto;
  min-height: 300px;
  max-height: ${({ height }) => `calc(${height + "px"} - 390px)`};
  width: 100%;
`;

const TaskComments = ({
  menu,
  height = "",
  path = "individual-asset",
  fileCommentList,
  fillCommentTotalCount = 0,
  getFileOffset = undefined,
}) => {
  const [switchComment, setSwitchComment] = useState("Hide");
  const [commentMessage, setCommentMessage] = useState("");
  const [commentAttachedFile, setCommentAttachedFile] = useState();
  const [commentsList, setCommentsList] = useState([]);
  const [relatedComments, setRelatedComments] = useState([]);
  const [replyId, setReplyId] = useState("");

  const [thread, setThread] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [resolvingLoading, setResolving] = useState(false);
  const [commentsLimit, setCommentLimit] = useState(10);

  const [commentsCount, setCommentsCount] = useState(0);

  const [repliesLimit] = useState(10);
  const [repliesCount, setRepliesCount] = useState(0);
  const [viewMoreComments, setViewMoreComments] = useState(false);
  const [viewMoreReplies, setViewMoreReplies] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [signedUrl, setSignedUrl] = useState("");
  const [resourceId, setResourceId] = useState("");
  const [pinnedCommentsCount, setPinnedCommentsCount] = useState(0);
  const [pinnedCommentIds, setPinnedCommentId] = useState([]);
  const [onFocusInput, setOnFocusInput] = useState(false);
  const [replyToPersonName, setReplyToPersonName] = useState("");
  const [scroll, setScroll] = useState(false);
  const [viewMoreCommentsState, setViewMoreCommentsState] = useState("");
  const [viewMoreRepliesState, setViewMoreRepliesState] = useState("");
  const [displayUploadedImage, setDisplayUploadedImage] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [fileFormat, setFileFormat] = useState();
  const [isPreviewReply, setIsPreviewReply] = useState(false);
  const [highlightCommentId, setHighlightCommentId] = useState("");
  const [highlightPinSection, setHighlightPinSection] = useState(false);

  const { task_id } = useParams();
  const taskId = task_id;
  const query = useRouterQuery();
  const status = query.get("status");
  const id = query.get("id");
  const commentReplyId = query.get("commentReplyId");

  const [commentsOffset, setCommentsOffset] = useState(
    query.get("commentOffset") ? Number(query.get("commentOffset")) : 0
  );
  const [commentsLessOffset, setCommentsLessOffset] = useState(
    query.get("commentOffset") ? Number(query.get("commentOffset")) : 0
  );
  const [repliesOffset, setRepliesOffset] = useState(
    query.get("replyOffset") ? Number(query.get("replyOffset")) : 0
  );
  const [repliesLessOffset, setRepliesLessOffset] = useState(
    query.get("replyOffset") ? Number(query.get("replyOffset")) : 0
  );
  const [threadId, setThreadId] = useState(
    commentReplyId ? commentReplyId : ""
  );

  const userId = localStorage.getItem("userId");
  const deliverableVersionId = localStorage.getItem("currentVersionId");

  const history = useHistory();

  const [present] = useIonToast();

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  const [addComment] = useMutation(ADD_ASSET_COMMENT_THREAD);
  const [replyToComment] = useMutation(REPLY_TO_COMMENT);
  const [resolveComment] = useMutation(RESOLVE_COMMENT);
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [linkAttachmentToComment] = useMutation(LINK_ATTACHMENT_AND_COMMENT);
  const [readComments] = useMutation(READ_COMMENTS);
  const [readFileComments] = useMutation(READ_FILE_COMMENTS);
  const [pinComment] = useMutation(PIN_COMMENT);
  const [unPinComment] = useMutation(UNPIN_COMMENT);
  const [pinFileComment] = useMutation(PIN_FILE_COMMENT);

  const [
    getAllComments,
    {
      data: commentsData,
      loading: commentsLoading,
      fetchMore: fetchMoreAllComments,
    },
  ] = useLazyQuery(menu === "File" ? GET_FILE_COMMENTS : GET_COMMENTS_LIST, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onError: (err) => {
      console.log(err.message);
    },
  });

  const [
    getAllResolvedComments,
    {
      data: resolvedCommentsData,
      loading: resolvedCommentsLoading,
      fetchMore: fetchMoreAllResolvedComments,
    },
  ] = useLazyQuery(GET_RESOLVED_COMMENTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onError: (err) => {
      console.log(err.message);
    },
  });

  useEffect(() => {
    if (menu === "File") {
      setCommentLimit(100);
      if (fileCommentList?.length > 0) {
        if (viewMoreComments) {
          setCommentsList([...fileCommentList]);
        } else {
          setCommentsList([...fileCommentList]);
          setCommentsCount(fillCommentTotalCount || 0);

          handleReadFileComments(
            fileCommentList[0]?.comments[0].created_at || ""
          );
        }

        setViewMoreComments(false);
      } else {
        setViewMoreComments(false);
        setCommentsList([]);
        setCommentsCount(0);
      }
    }
  }, [fileCommentList, fillCommentTotalCount, status]);

  useEffect(() => {
    if (status === "comment") {
      if (menu === "Resolved") {
        getAllResolvedComments({
          fetchPolicy: "network-only",
          nextFetchPolicy: "cache-first",
          variables: {
            taskId: taskId, //task id needs to send from route/...
            offset: commentsOffset,
            limit: commentsLimit,
            versionId: localStorage.getItem("currentVersionId"),
            status: "RESOLVED",
            order_by: menu === "Task" ? "desc" : "desc",
            userId: localStorage.getItem("userId"),
          },
        });
      } else {
        if (menu !== "File") {
          getAllComments({
            fetchPolicy: "network-only",
            nextFetchPolicy: "cache-first",
            variables: {
              taskId: taskId, //task id needs to send from route/...
              offset: commentsOffset,
              limit: commentsLimit,
              versionId: localStorage.getItem("currentVersionId"),
              order_by: menu === "Task" ? "desc" : "desc",
              userId: localStorage.getItem("userId"),
            },
          });
        }

        getAllPinnedComments({
          fetchPolicy: "network-only",
          nextFetchPolicy: "cache-first",
          variables: {
            where: {
              task_id: { _eq: taskId },
              deliverable_version_id:
                menu === "File"
                  ? {
                      _eq: deliverableVersionId,
                      _is_null: false,
                    }
                  : {
                      _is_null: true,
                    },
              user_id: {
                _eq: localStorage.getItem("userId"),
              },
            },
            offset: commentsOffset,
            limit: commentsLimit,
          },
        });
      }
    } else {
      getAllPinnedComments({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {
          where: {
            task_id: { _eq: taskId },
            user_id: { _eq: localStorage.getItem("userId") },
            deliverable_version_id:
              menu === "File"
                ? {
                    _eq: deliverableVersionId,
                    _is_null: false,
                  }
                : {
                    _is_null: true,
                  },
          },
          offset: commentsOffset,
          limit: commentsLimit,
        },
      });
    }
  }, [status]);

  useEffect(() => {
    if (status === "comment" && menu !== "File") {
      if (commentsData?.thread?.length > 0) {
        if (viewMoreComments) {
          setCommentsList(
            viewMoreCommentsState === "more"
              ? [...commentsList, ...commentsData.thread]
              : [...commentsData.thread, ...commentsList]
          );
        } else {
          handleScrollIntoView("comment_container");
          setCommentsList(commentsData.thread);
          setCommentsCount(
            commentsData?.thread_aggregate?.aggregate?.count || 0
          );
          handleReadComments(commentsData?.thread[0]?.comments[0].id || "");
        }
        setViewMoreComments(false);
      } else {
        setCommentsList([]);
        setCommentsCount(0);
      }
      setPageLoading(commentsLoading);
    }
  }, [commentsData, commentsLoading, status]);

  useEffect(() => {
    if (status === "comment" && menu !== "File") {
      if (resolvedCommentsData?.thread?.length > 0) {
        if (viewMoreComments) {
          setCommentsList(
            viewMoreCommentsState === "more"
              ? [...commentsList, ...resolvedCommentsData.thread]
              : [...resolvedCommentsData.thread, ...commentsList]
          );
        } else {
          handleScrollIntoView("comment_container");
          setCommentsList(resolvedCommentsData.thread);
          setCommentsCount(
            resolvedCommentsData?.thread_aggregate?.aggregate?.count || 0
          );
        }
        setViewMoreComments(false);
      } else {
        setCommentsList([]);
        setCommentsCount(0);
      }
      setPageLoading(resolvedCommentsLoading);
    }
  }, [resolvedCommentsData, resolvedCommentsLoading, status]);

  useEffect(() => {
    handleScrollIntoView("comment_container");
  }, []);

  const [
    getAllPinnedComments,
    {
      data: pinnedCommentsData,
      loading: pinnedCommentsLoading,
      fetchMore: fetchMoreAllPinnedComments,
    },
  ] = useLazyQuery(GET_PINNED_COMMENTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onError: (err) => {
      console.log(err.message);
    },
  });

  useEffect(() => {
    if (pinnedCommentsData?.pinned_comment_aggregate?.aggregate?.count) {
      setPinnedCommentId(
        pinnedCommentsData?.pinned_comment?.map((each) => each.comment.id)
      );
      setPinnedCommentsCount(
        pinnedCommentsData.pinned_comment_aggregate.aggregate.count || 0
      );
    } else {
      setPinnedCommentsCount(0);
      setPinnedCommentId([]);
    }
    if (status === "pinned_comments") {
      if (pinnedCommentsData?.pinned_comment?.length > 0) {
        if (viewMoreComments) {
          setCommentsList([
            ...commentsList,
            ...pinnedCommentsData.pinned_comment,
          ]);
        } else {
          handleScrollIntoView("comment_container");
          setCommentsList(pinnedCommentsData.pinned_comment);
          setCommentsCount(
            pinnedCommentsData?.pinned_comment_aggregate?.aggregate?.count || 0
          );
        }
        setViewMoreComments(false);
      } else {
        setCommentsList([]);
        setCommentsCount(0);
      }
      setPageLoading(pinnedCommentsLoading);
    }
  }, [pinnedCommentsData, pinnedCommentsLoading, status]);

  const [
    getReplies,
    {
      data: repliesData,
      loading: repliesLoading,
      fetchMore: fetchMoreRepliesList,
    },
  ] = useLazyQuery(GET_REPLIES_LIST, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      comment_thread: threadId,
      offset: repliesOffset,
      limit: repliesLimit,
      userId: localStorage.getItem("userId"),
    },
    onError: (err) => {
      console.log(err.message);
    },
  });

  useEffect(() => {
    if (threadId) {
      getReplies({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {
          comment_thread: threadId,
          offset: repliesOffset,
          limit: repliesLimit,
          userId: localStorage.getItem("userId"),
        },
      });
    }
  }, []);

  useEffect(() => {
    if (repliesData?.comment?.length > 0) {
      if (viewMoreReplies) {
        setRelatedComments(
          viewMoreRepliesState === "more"
            ? [...relatedComments, ...repliesData.comment]
            : [...repliesData.comment, ...relatedComments]
        );
        setViewMoreReplies(false);
      } else {
        setRelatedComments(repliesData.comment);
        setViewMoreReplies(false);
      }
      setRepliesCount(repliesData.comment_aggregate.aggregate.count);
      if (menu === "File") {
        handleReadFileComments(repliesData?.comment[0].created_at || "");
      } else {
        handleReadComments(repliesData?.comment[0]?.id || "");
      }
    }
    setPageLoading(repliesLoading);
  }, [repliesData, repliesLoading]);

  const handleSwitchComments = (value) => {
    setSwitchComment(value === "View" ? "Hide" : "View");
  };

  const handlePinAction = (field) => {
    if (field === "reply_pin") {
      if (menu !== "File") {
        fetchMoreRepliesList({
          query: GET_REPLIES_LIST,
          variables: {
            comment_thread: threadId,
            offset: 0,
            limit: repliesLimit + repliesOffset,
            userId: localStorage.getItem("userId"),
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return fetchMoreResult;
          },
        });
      }

      fetchMoreAllPinnedComments({
        query: GET_PINNED_COMMENTS,
        variables: {
          offset: 0,
          limit: commentsOffset + commentsLimit,
          where: {
            task_id: { _eq: taskId },
            deliverable_version_id:
              menu === "File"
                ? {
                    _eq: deliverableVersionId,
                    _is_null: false,
                  }
                : {
                    _is_null: true,
                  },
            user_id: {
              _eq: localStorage.getItem("userId"),
            },
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult;
        },
      });
    } else {
      if (menu !== "File") {
        fetchMoreAllComments({
          query: menu === "File" ? GET_FILE_COMMENTS : GET_COMMENTS_LIST,
          variables: {
            taskId: taskId, //task id needs to send from route/...
            offset: 0,
            limit: commentsOffset + commentsLimit,
            versionId: localStorage.getItem("currentVersionId"),
            order_by: menu === "Task" ? "desc" : "desc",
            userId: localStorage.getItem("userId"),
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return fetchMoreResult;
          },
        });
      }

      fetchMoreAllPinnedComments({
        query: GET_PINNED_COMMENTS,
        variables: {
          where: {
            task_id: { _eq: taskId },
            deliverable_version_id:
              menu === "File"
                ? {
                    _eq: deliverableVersionId,
                    _is_null: false,
                  }
                : {
                    _is_null: true,
                  },
            user_id: {
              _eq: localStorage.getItem("userId"),
            },
          },
          offset: 0,
          limit: commentsOffset + commentsLimit,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult;
        },
      });
    }
  };
  const handleHighlihtPinSection = () => {
    const highlightPinSection = setTimeout(() => {
      setHighlightPinSection(false);
    }, 2000);
    return () => clearTimeout(highlightPinSection);
  };
  const handleAction = async (
    action,
    id,
    thread_id,
    field,
    pinStatus,
    replyToPersonName
  ) => {
    if (action === "reply") {
      setReplyId(id);
      setOnFocusInput(!onFocusInput);
      setThread(thread_id);
      setReplyToPersonName(replyToPersonName);
    }
    if (action === "peview_reply") {
      setReplyId(id);
      setThread(thread_id);
      setReplyToPersonName(replyToPersonName);
      setIsPreviewReply(true);
    }
    if (action === "resolved") {
      try {
        setResolving(true);
        let userId = localStorage.getItem("userId");
        const { data } = await resolveComment({
          fetchPolicy: "network-only",
          nextFetchPolicy: "cache-first",
          variables: {
            thread_id: thread_id,
            status: field,
            resolved_by: userId,
          },
        });
        if (data?.update_thread?.returning) {
          let commentsData = JSON.parse(JSON.stringify(commentsList));
          const index = commentsData.findIndex((ele) => ele.id === thread_id);
          commentsData[index].status = field;
          setCommentsList(commentsData);
        }
      } catch (error) {
        showToastMessage({
          color: "danger",
          message: error.message || "Something went wrong please try again!",
        });
      } finally {
        setResolving(false);
      }
    }
    if (action === "pin") {
      const userId = localStorage.getItem("userId");
      if (menu === "File") {
        try {
          if (pinStatus === "pin") {
            const { data } = await pinFileComment({
              fetchPolicy: "network-only",
              nextFetchPolicy: "cache-first",
              variables: {
                commentId: thread_id,
                taskId,
                deliverableVersionId,
                userId,
              },
            });
            if (data?.insert_pinned_comment_one) {
              setHighlightPinSection(true);
              handlePinAction(field);
              handleHighlihtPinSection();
            }
          } else {
            const { data: pinData } = await unPinComment({
              fetchPolicy: "network-only",
              nextFetchPolicy: "cache-first",
              variables: {
                where: {
                  user_id: { _eq: localStorage.getItem("userId") },
                  comment_id: { _eq: thread_id },
                  task_id: { _eq: taskId },
                  deliverable_version_id: { _eq: deliverableVersionId },
                },
              },
            });
            if (pinData.delete_pinned_comment.affected_rows) {
              handlePinAction(field);
            }
          }
        } catch (error) {
          showToastMessage({
            color: "danger",
            message: error.message || "Something went wrong please try again!",
          });
        } finally {
          setLoading(false);
        }
      } else {
        try {
          if (pinStatus === "pin") {
            const { data: pinData } = await pinComment({
              fetchPolicy: "network-only",
              nextFetchPolicy: "cache-first",
              variables: {
                commentId: thread_id,
                taskId: taskId,
              },
            });
            if (pinData.pinComment.status) {
              setHighlightPinSection(true);
              handlePinAction(field);
              handleHighlihtPinSection();
            }
          } else {
            const { data: pinData } = await unPinComment({
              fetchPolicy: "network-only",
              nextFetchPolicy: "cache-first",
              variables: {
                where: {
                  user_id: { _eq: localStorage.getItem("userId") },
                  comment_id: { _eq: thread_id },
                  task_id: { _eq: taskId },
                },
              },
            });
            if (pinData.delete_pinned_comment.affected_rows) {
              handlePinAction(field);
            }
          }
        } catch (error) {
          showToastMessage({
            color: "danger",
            message: error.message || "Something went wrong please try again!",
          });
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleOnchangeComment = (e) => {
    setCommentMessage(e.target.value);
  };

  const handleInsertComment = (insertData, imageResourceObject, type, id) => {
    if (type === "comment") {
      if (imageResourceObject) {
        let obj = {};
        let insertedObj = insertData.comments[0];
        insertedObj.resources = [imageResourceObject];
        obj.comments = [insertedObj];
        obj.created_at = insertData.created_at;
        obj.id = insertData.id;
        obj.status = insertData.status;
        obj.comments_aggregate = {
          aggregate: {
            count: 1,
          },
        };
        setCommentsList([...[obj], ...commentsList]);
        setCommentsCount(commentsCount + 1);
      } else {
        let obj = {};
        obj = insertData;
        obj.comments_aggregate = {
          aggregate: {
            count: 1,
          },
        };
        setCommentsList([...[obj], ...commentsList]);
        setCommentsCount(commentsCount + 1);
      }
      handleScrollIntoView(id);
      setHighlightCommentId(id);
    } else {
      if (thread === threadId) {
        if (imageResourceObject) {
          let obj = {};
          obj = insertData;
          obj.resources = [imageResourceObject];
          setRelatedComments([...[obj], ...relatedComments]);
        } else setRelatedComments([...[insertData], ...relatedComments]);
        handleScrollIntoView(id);
        setHighlightCommentId(id);
      }
    }
  };

  const handleCommentAttachment = async (file, menuField) => {
    setFileFormat(menuField);
    try {
      setResolving(true);
      const { data } = await uploadFile({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {
          fileName: file.file.name,
        },
      });
      if (data?.fileUpload?.signedUrl && data?.fileUpload?.resourceId) {
        setDisplayUploadedImage(URL.createObjectURL(file.file));
        setCommentAttachedFile(file);
        setSignedUrl(data.fileUpload.signedUrl);
        setResourceId(data.fileUpload.resourceId);
        setFileName(file.file.name);
        setImageUrl(data.fileUpload.url);
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    } finally {
      setResolving(false);
    }
  };

  const handleLinkAttachmentToComment = async (id, insertedData, type) => {
    try {
      setLoading(true);
      const { data: attachmentData } = await linkAttachmentToComment({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {
          resource_id: resourceId,
          comment_id: id,
        },
      });
      if (attachmentData?.update_resource?.affected_rows) {
        handleInsertComment(
          insertedData,
          {
            id: resourceId,
            name: fileName,
            url: imageUrl,
            type: "FILE",
          },
          type,
          id
        );
        setCommentMessage("");
        setResourceId("");
        setSignedUrl("");
        setCommentAttachedFile();
        setFileName("");
        setDisplayUploadedImage();
        setImageUrl("");
        setIsPreviewReply(false);
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddComment = async (signedUrl) => {
    try {
      setLoading(true);
      let userId = localStorage.getItem("userId");
      const { data } = await addComment({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {
          status: "UNRESOLVED",
          visibility: "PUBLIC",
          comment_text: commentMessage,
          comment_user: userId,
          task: task_id,
        },
      });
      if (data?.insert_thread_one) {
        if (signedUrl) {
          handleLinkAttachmentToComment(
            data.insert_thread_one?.comments[0].id || "",
            data.insert_thread_one,
            "comment"
          );
        } else {
          handleInsertComment(
            data.insert_thread_one,
            null,
            "comment",
            data.insert_thread_one?.comments[0].id || ""
          );
          setCommentMessage("");
          setResourceId("");
          setCommentAttachedFile();
          setFileName("");
          setDisplayUploadedImage();
          setImageUrl("");
          setIsPreviewReply(false);
        }
        setSignedUrl("");
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendComment = async () => {
    if (signedUrl) {
      setLoading(true);
      await fetch(signedUrl, {
        method: "PUT",
        body: commentAttachedFile.file,
      }).then((response) => {
        console.log("fileUpload", response);
      });
      setLoading(false);
      if (replyId) handleSendReply("signedUrl");
      else handleAddComment("signedUrl");
    } else {
      if (commentMessage) {
        if (replyId) handleSendReply("");
        else handleAddComment("");
      }
    }
  };

  const handleSendReply = async (signedUrl) => {
    try {
      setLoading(true);
      let userId = localStorage.getItem("userId");
      const { data } = await replyToComment({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {
          comment_text: commentMessage,
          comment_user: userId,
          comment_thread: thread,
        },
      });
      if (data?.insert_comment_one) {
        let commentsData = JSON.parse(JSON.stringify(commentsList)); //thread
        for (let i = 0; i < commentsData.length; i++) {
          if (commentsData[i].id === thread) {
            let count = commentsData[i].comments_aggregate.aggregate.count + 1;
            commentsData[i].comments_aggregate.aggregate.count = count;
            break;
          }
        }
        setCommentsList(commentsData);
        if (signedUrl) {
          handleLinkAttachmentToComment(
            data.insert_comment_one?.id || "",
            data.insert_comment_one,
            "reply"
          );
        } else {
          if (thread === threadId) {
            handleInsertComment(
              data.insert_comment_one,
              null,
              "reply",
              data.insert_comment_one?.id || ""
            );
          }
        }
        setCommentMessage("");
        setResourceId("");
        setSignedUrl("");
        setCommentAttachedFile();
        setFileName("");
        setReplyId("");
        setReplyToPersonName("");
        setDisplayUploadedImage();
        setImageUrl("");
        setIsPreviewReply(false);
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseReply = () => {
    setCommentMessage("");
    setReplyId("");
    setReplyToPersonName("");
    setCommentAttachedFile();
    setDisplayUploadedImage();
    setImageUrl("");
    setFileName("");
    setResourceId("");
    setSignedUrl("");
    setIsPreviewReply(false);
  };

  const getRelatedComments = (comment_thread, viewOrHide, repliesCount) => {
    if (threadId !== comment_thread) {
      setRepliesOffset(0);
      setRepliesLessOffset(0);
    }
    setThreadId(viewOrHide === "Hide" ? "" : comment_thread);
    setRepliesCount(repliesCount);
    getReplies({
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        comment_thread: comment_thread,
        offset: 0,
        limit: repliesLimit,
        userId: localStorage.getItem("userId"),
      },
    });
  };

  const handleCommentsViewMore = (state) => {
    if (status === "comment") {
      if (menu === "File") {
        if (getFileOffset) getFileOffset(commentsOffset + commentsLimit);
      } else {
        if (menu === "Resolved") {
          fetchMoreAllResolvedComments({
            query: GET_RESOLVED_COMMENTS,
            variables: {
              taskId: taskId, //task id needs to send from route/...
              offset:
                menu === "File"
                  ? commentsOffset + commentsLimit
                  : state === "more"
                  ? commentsOffset + commentsLimit
                  : commentsLessOffset - commentsLimit,
              limit: commentsLimit,
              versionId: localStorage.getItem("currentVersionId"),
              status: "RESOLVED",
              order_by: menu === "Task" ? "desc" : "desc",
              userId: localStorage.getItem("userId"),
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              return fetchMoreResult;
            },
          });
        } else {
          fetchMoreAllComments({
            query: menu === "File" ? GET_FILE_COMMENTS : GET_COMMENTS_LIST,
            variables: {
              taskId: taskId, //task id needs to send from route/...
              offset:
                menu === "File"
                  ? commentsOffset + commentsLimit
                  : state === "more"
                  ? commentsOffset + commentsLimit
                  : commentsLessOffset - commentsLimit,
              limit: commentsLimit,
              versionId: localStorage.getItem("currentVersionId"),
              order_by: menu === "Task" ? "desc" : "desc",
              userId: localStorage.getItem("userId"),
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              return fetchMoreResult;
            },
          });
        }
      }
    } else {
      fetchMoreAllPinnedComments({
        query: GET_PINNED_COMMENTS,
        variables: {
          where: {
            task_id: { _eq: taskId },
            deliverable_version_id:
              menu === "File"
                ? {
                    _eq: deliverableVersionId,
                    _is_null: false,
                  }
                : {
                    _is_null: true,
                  },
            user_id: {
              _eq: localStorage.getItem("userId"),
            },
          },
          offset: commentsOffset + commentsLimit,
          limit: commentsLimit,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult;
        },
      });
    }
    if (state === "more") {
      setCommentsOffset(commentsOffset + commentsLimit);
    } else {
      setCommentsLessOffset(commentsLessOffset - commentsLimit);
    }

    setViewMoreCommentsState(state);
    setViewMoreComments(true);
  };

  const handleRepliesViewMore = (state) => {
    fetchMoreRepliesList({
      query: GET_REPLIES_LIST,
      variables: {
        comment_thread: threadId,
        offset:
          state === "more"
            ? repliesOffset + repliesLimit
            : repliesLessOffset - repliesLimit,
        limit: repliesLimit,
        userId: localStorage.getItem("userId"),
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult;
      },
    });
    if (state === "more") {
      setRepliesOffset(repliesOffset + repliesLimit);
    } else {
      setRepliesLessOffset(repliesLessOffset - repliesLimit);
    }
    setViewMoreRepliesState(state);
    setViewMoreReplies(true);
  };

  const handleCloseFileAttachment = () => {
    setCommentAttachedFile();
    setSignedUrl("");
    setFileName("");
    setResourceId("");
    setDisplayUploadedImage();
    setImageUrl("");
  };

  const color = ["#7C9EC9", "#FD9393", "#FFC542", "#8BDDB3"];

  const handleReadFileComments = async (commentCreatedAt) => {
    if (!commentCreatedAt) {
      return;
    }
    try {
      setLoading(true);
      await readFileComments({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {
          commentCreatedAt: commentCreatedAt,
          deliverableId: deliverableVersionId,
          taskId: taskId,
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleReadComments = async (commentId) => {
    if (commentId) {
      try {
        setLoading(true);
        const { data: readComment } = await readComments({
          fetchPolicy: "network-only",
          nextFetchPolicy: "cache-first",
          variables: {
            commentId: commentId,
            taskId: taskId,
          },
        });
        if (readComment?.readComments?.status) {
        }
      } catch (error) {
        // showToastMessage({
        //   color: "danger",
        //   message: error.message || "Something went wrong please try again!",
        // });
      } finally {
        setLoading(false);
      }
    }
  };

  const loadPinnedComments = () => {
    if (status !== "pinned_comments")
      history.push(
        `/assets/${taskId}/${path}?status=pinned_comments&tab=${menu}`
      );
  };

  const handleHighlightComment = () => {
    const highlightTimer = setTimeout(() => {
      setHighlightCommentId("");
    }, 5000);
    return () => clearTimeout(highlightTimer);
  };

  const handleScrollIntoView = (id) => {
    if (id) {
      const scrollTimer = setTimeout(() => {
        let target = document.getElementById(id);
        if (target) {
          target.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }
        handleHighlightComment();
      }, 100);
      return () => clearTimeout(scrollTimer);
    }
  };

  const handleScroll = (eachId) => {
    if (id === eachId && scroll === false && !commentReplyId) {
      setScroll(true);
      handleScrollIntoView(id);
      setHighlightCommentId(id);
    }
  };

  const handleReplyScroll = (replyId) => {
    if (id === replyId && scroll === false) {
      setScroll(true);
      handleScrollIntoView(id);
      setHighlightCommentId(id);
    }
  };

  return (
    <CommentsContainer id="comment_container">
      {(menu === "Task" || menu === "File") && (
        <PinnedCommentsContent
          highlight={highlightPinSection}
          status={status === "pinned_comments" ? true : false}
          onClick={() => loadPinnedComments()}
        >
          <span>
            {pinnedCommentsCount.toString().length === 1
              ? "0" + pinnedCommentsCount
              : pinnedCommentsCount}
            {" " + "Comments Pinned"}
          </span>
        </PinnedCommentsContent>
      )}
      <Comments>
        {commentsList.length > 0 ? (
          <div>
            <ViewHideText>
              <u
                style={{ cursor: "pointer" }}
                onClick={() => handleSwitchComments(switchComment)}
              >
                {switchComment} all Comments
              </u>
            </ViewHideText>
            <IonLoading
              cssClass="my-custom-class"
              isOpen={pageLoading || resolvingLoading}
              message={"Please wait..."}
              // duration={10000}
            />
            {switchComment === "Hide" && (
              <CommentsContent height={window.innerHeight}>
                <ViewMoreText
                  display={id && commentsLessOffset > 0 ? true : false}
                  onClick={() => handleCommentsViewMore("less")}
                >
                  <u>View recent comments</u>
                </ViewMoreText>
                <>
                  {commentsList?.map((element, index) => (
                    <Comment
                      id={
                        status === "comment"
                          ? element?.comments[0]?.id || element.id
                          : index
                      }
                      key={element.id || index}
                    >
                      {status === "comment" &&
                        id === element?.comments[0]?.id &&
                        !commentReplyId &&
                        handleScroll(element.comments[0]?.id)}
                      <TaskCommentItem
                        index={index}
                        new={true}
                        handleAction={handleAction}
                        menu={menu}
                        comment={
                          status === "pinned_comments"
                            ? element?.comment
                              ? element.comment
                              : {}
                            : element?.comments[0]
                            ? element?.comments[0]
                            : {}
                        }
                        read={
                          status === "comment"
                            ? element?.comments[0]?.user?.id === userId
                              ? ""
                              : !element?.comments[0]?.user_activity_trackings
                                  ?.length
                            : ""
                        }
                        count={
                          element?.comments_aggregate?.aggregate?.count - 1 || 0
                        }
                        pinnedCommentIds={pinnedCommentIds}
                        pin={
                          status === "pinned_comments"
                            ? true
                            : pinnedCommentIds?.length > 0 &&
                              pinnedCommentIds.includes(element?.comments[0].id)
                        }
                        status={
                          status === "comment"
                            ? element.status
                            : element.comment?.thread?.status || ""
                        }
                        hideMarkAsResolved={
                          status === "pinned_comments" ? true : false
                        }
                        getRelatedComments={getRelatedComments}
                        relatedComments={relatedComments || []}
                        replyId={replyId}
                        threadId={threadId}
                        profileBackground={color[index % color.length]}
                        repliesCount={repliesCount}
                        repliesOffset={repliesOffset}
                        repliesLimit={repliesLimit}
                        viewMoreReplies={viewMoreReplies}
                        handleRepliesViewMore={handleRepliesViewMore}
                        commentsCount={commentsCount}
                        handleReplyScroll={handleReplyScroll}
                        repliesLessOffset={repliesLessOffset}
                        message={commentMessage}
                        handleOnchangeComment={handleOnchangeComment}
                        handleSendComment={handleSendComment}
                        handleAttachment={handleCommentAttachment}
                        handleCloseReply={handleCloseReply}
                        isLoading={isLoading}
                        isAttchment={menu !== "File"}
                        commentAttachedFile={commentAttachedFile}
                        replyToPersonName={replyToPersonName}
                        path={path}
                        signedUrl={signedUrl}
                        handleCloseFileAttachment={handleCloseFileAttachment}
                        displayUploadedImage={displayUploadedImage}
                        fileFormat={fileFormat}
                        fileName={fileName}
                        isPreviewReply={isPreviewReply}
                        highlightCommentId={highlightCommentId}
                      />
                    </Comment>
                  ))}
                </>
                <ViewMoreText
                  display={
                    commentsCount > commentsOffset + commentsLimit
                      ? true
                      : false
                  }
                  onClick={() => handleCommentsViewMore("more")}
                >
                  <u>View more</u>
                </ViewMoreText>
              </CommentsContent>
            )}
          </div>
        ) : (
          <NoDataContent>
            <NoDataText>No {menu} Comments</NoDataText>
          </NoDataContent>
        )}
        <div
          style={{
            margin: "10px 0px 10px",
            display:
              status === "pinned_comments" && !replyId
                ? "none"
                : menu === "Task" || (menu === "File" && replyId)
                ? "block"
                : "none",
          }}
        >
          <CommentInput
            message={isPreviewReply ? "" : commentMessage}
            handleOnchangeComment={handleOnchangeComment}
            handleSendComment={handleSendComment}
            handleAttachment={handleCommentAttachment}
            handleCloseReply={handleCloseReply}
            isLoading={isLoading}
            isAttchment={menu !== "File"}
            isReply={isPreviewReply ? false : replyId ? true : false}
            onFocusInput={onFocusInput}
            commentAttachedFile={commentAttachedFile}
            replyToPersonName={isPreviewReply ? "" : replyToPersonName}
            path={path}
            signedUrl={signedUrl}
            handleCloseFileAttachment={handleCloseFileAttachment}
            displayUploadedImage={displayUploadedImage}
            fileFormat={fileFormat}
            fileName={fileName}
          />
        </div>
      </Comments>
    </CommentsContainer>
  );
};

export default TaskComments;
