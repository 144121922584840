import { IonRow, IonText } from "@ionic/react";
import CloseIcon from "../../assests/icons/close";
import styled from "styled-components";
import CommentInput from "./commentInputComponent";
import { Modal } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { fileDownloader } from "../../lib/fileDownloader";
import { DocumentViewer } from "react-documents";
import useWindowDimensions from "../useWindowDimensions";
import { useRouterQuery } from "../../lib/useRouterQuery";

const Confirmation = styled(Modal)`
  .ant-modal {
    width: 40%;
  }
  .ant-modal-content {
    border-radius: 6px;
    background-color: transparent;
  }
  .ant-modal-body {
    padding: 0px;
  }
`;
const DownloadIcon = styled.div`
  position: absolute;
  right: ${({ type }) => (type === "doc" ? "12%" : "4%")};
  top: ${({ type }) => (type === "doc" ? "4%" : "4%")};
  background: white;
  padding: ${({ type }) => (type === "doc" ? "8px 12px" : "2px 8px")};
  display: flex;
  border-radius: 4px;
  display: none;
  z-index: 10;
  cursor: pointer;
  border: 1px solid gray;
`;

const Close = styled.span`
  cursor: pointer;
`;

const CloseContent = styled(IonRow)`
  justify-content: flex-end;
`;

const Content = styled(IonRow)`
  height: ${({ type }) => (type === "img" ? "300px" : "400px")};
  width: 100%;
  margin: 36px 0px 10px;
  position: relative;
  border: ${({ type }) => (type === "img" ? "" : "1px solid #9f9f9f")};
  &:hover {
    ${DownloadIcon} {
      display: block;
    }
  }
  @media screen and (max-width: 768px) {
    ${DownloadIcon} {
      display: block;
      right: ${({ type }) => (type === "doc" ? "16%" : "4%")};
    }
  }
`;

const HeaderText = styled(IonText)`
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  color: var(--text-primary);
`;

const ModalContent = styled.div`
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  font-size: 0.75rem;
`;
const ImageContent = styled.div`
  background: #ffffff;
  border-radius: inherit;
  padding: 20px;
`;
const InputContent = styled.div`
  margin-top: 16px;
  border-radius: inherit;
`;
const Input = styled.div`
  margin: 10px 0px 10px;
`;

const PreviewAttachment = (props) => {
  const { width } = useWindowDimensions();
  const query = useRouterQuery();
  const tab = query.get("tab");

  const onDownload = (url, fileName) => {
    if (url) {
      fileDownloader({
        url: url,
        fileName: fileName,
      });
    }
  };
  return (
    <div>
      <Confirmation
        style={{ top: "20%" }}
        visible={props.isModalVisible}
        footer={null}
        closable={false}
        width={width <= 768 ? "100%" : "40%"}
      >
        <ModalContent>
          <ImageContent>
            <CloseContent>
              <Close onClick={() => props.handleModal(false)}>
                <CloseIcon stroke="#130F26" height="10" width="10" />
              </Close>
            </CloseContent>
            <HeaderText fontWeight={700}>
              {props.replyToPersonName} sent the{" "}
              {props.imageUrl ? "image" : "document"}
            </HeaderText>
            <Content type={props.docUrl ? "doc" : "img"}>
              <DownloadIcon
                title="Download"
                onClick={() =>
                  onDownload(
                    props.imageUrl ? props.imageUrl : props.docUrl,
                    props.fileName
                  )
                }
                type={props.docUrl ? "doc" : "img"}
              >
                <DownloadOutlined style={{ fontSize: 18 }} />
              </DownloadIcon>
              {props.docUrl && (
                <DocumentViewer
                  url={props.docUrl}
                  style={{ width: "100%", height: "100%" }}
                  viewerUrl="url"
                />
              )}
              {props.imageUrl && (
                <img
                  src={props.imageUrl}
                  alt="img"
                  height={"100%"}
                  width={"100%"}
                />
              )}
            </Content>
            {!props.onlyDoc && (
              <HeaderText fontWeight={400}>{props.commentText}</HeaderText>
            )}
          </ImageContent>
          {!props.onlyDoc && tab !== "Resolved" && (
            <InputContent>
              <Input>
                <CommentInput {...props} isPreviewReply={true} />
              </Input>
            </InputContent>
          )}
        </ModalContent>
      </Confirmation>
    </div>
  );
};
export default PreviewAttachment;
