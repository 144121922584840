import React, { useState } from "react";
import styled from "styled-components";
import { IonCol, IonRow, IonContent } from "@ionic/react";

import Header from "../header/index";
import WebSidebarMenu from "../webSidebarMenu";
import MobBottomMenu from "../mobBottomMenu";
import useWindowDimensions from "../useWindowDimensions";

const MenuWrapper = styled(IonCol)`
  max-width: 140px;
  padding: 0px;
`;

const SubContent = styled(IonCol)`
  display: flex;
  flex-direction: column;
`;

const HeaderContent = styled(IonRow)`
  padding: 0 6% 0 6%;
`;

const ChildrenContent = styled(IonContent)`
  flex: 1;
  overflow-y: auto;
  font-family: Montserrat, sans-serif;
  // padding: 0 6% 0 6%;
`;
const PageContent = styled(IonRow)`
  height: 100%;
  width: 100%;
  @media screen and (max-width: 768px) {
    ${SubContent} {
      padding: 0px;
    }
    ${HeaderContent} {
      padding: 0px;
    }
  }
`;

const Row = styled(IonRow)`
  padding: 0 6%;
  ${({ disablePaddingMd }) =>
    disablePaddingMd &&
    ` 
@media screen and (max-width:768px){
  padding:0px;
}
`}
`;

const PageLayout = (props) => {
  const { width } = useWindowDimensions();
  return (
    <PageContent>
      {width > 768 && (
        <MenuWrapper>
          <WebSidebarMenu title={props.title} />
        </MenuWrapper>
      )}
      <SubContent>
        <HeaderContent>
          <Header arrowCircle={props.arrowCircle} />
        </HeaderContent>
        <ChildrenContent scrollEvents>
          <Row disablePaddingMd={props.disablePaddingMd}>{props.children}</Row>
        </ChildrenContent>
        {width <= 768 && <MobBottomMenu title={props.title} />}
      </SubContent>
    </PageContent>
  );
};

export default PageLayout;
