import { IonRow, IonCol, IonText } from "@ionic/react";
import styled from "styled-components";
import CommentsComponent from "./commentsComponent";
import AssetsComponent from "./assetsComponent";
import { useEffect, useState } from "react";
import AnalyticsHeader from "./analyticsHeader";
import AnalyticsResults from "./analyticsResults";

const CommentAssetContent = styled(IonCol)`
  margin-right: 20px;
  padding: 0px;
  min-width: 450px;
  display: flex;
  flex-direction: column;
`;
const AnalyticsContainer = styled(IonCol)`
  background: #f5f5f5;
  border-radius: 5px;
  min-width: 510;
`;

const AnalyticsContent = styled.div`
  padding: 50px 30px 30px 30px;
  display: flex;
  flex-direction: column;
`;

const CommentsContent = styled(IonRow)`
  padding: 30px 24px;
  margin-bottom: 20px;
  background: #f5f5f5;
  border-radius: 5px;
  flex: 1;
`;

const AssetsContent = styled(IonRow)`
  padding: 30px 24px;
  background: #f5f5f5;
  border-radius: 5px;
  flex: 1;
`;

const AnalyticsText = styled(IonText)`
  font-size: 1.125rem;
  font-weight: 300;
`;

const ProjectText = styled(IonText)`
  font-size: 0.75rem;
  font-weight: 700;
  margin-top: 6px;
  margin-bottom: 40px;
`;

// const BarContainer = styled(IonCol)``;

// const CardContainer = styled(IonCol)``;

const Content = styled(IonRow)`
  margin: 40px 0px 40px;
  color: var(--text-primary);
  @media screen and (max-width: 768px) {
    display: none;
    ${CommentAssetContent} {
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 1274px) {
    ${AnalyticsContainer} {
      margin-top: 30px;
    }
  }
`;
const WebViewComponents = (props) => {
  const [todaysDate, setTodaysDate] = useState("");

  useEffect(() => {
    let date = new Date();
    let todaysDate =
      date.toLocaleString("default", { month: "long" }) +
      " " +
      date.getFullYear();
    setTodaysDate(todaysDate);
  }, []);
  return (
    <Content>
      <CommentAssetContent>
        <CommentsContent>
          <CommentsComponent
            new={true}
            count={props.commentsCount}
            commentsData={props.commentsData}
          />
        </CommentsContent>
        <AssetsContent>
          <AssetsComponent
            count={props.assetsCount}
            assetsData={props.assetsData}
          />
        </AssetsContent>
      </CommentAssetContent>
      <AnalyticsContainer>
        <AnalyticsContent>
          <AnalyticsText>Analytics</AnalyticsText>
          <ProjectText>
            {localStorage.getItem("project")} | {todaysDate}
          </ProjectText>
          <AnalyticsHeader
            assets={
              props.analyticsDataObj.countAssets
                ? props.analyticsDataObj.countAssets
                : 0
            }
            approved={
              props.analyticsDataObj.percentApproved
                ? props.analyticsDataObj.percentApproved
                : 0
            }
            spent={
              props.analyticsDataObj.days ? props.analyticsDataObj.days : 0
            }
          />
          <div style={{ width: "100%", marginTop: 40, marginBottom: 30 }}>
            <AnalyticsResults
              countAssets={
                props.analyticsDataObj.countAssets
                  ? props.analyticsDataObj.countAssets
                  : 0
              }
              feedbackSatisfied={props.analyticsDataObj.feedbackSatisfied}
              feedbackLiked={props.analyticsDataObj.feedbackLiked}
              feedbackLoved={props.analyticsDataObj.feedbackLoved}
              approvedTasksCount={props.analyticsDataObj.statusApproved}
              pendingTasksCount={props.analyticsDataObj.statusPending}
              disapprovedTasksCount={props.analyticsDataObj.statusDisapproved}
            />
          </div>
        </AnalyticsContent>
      </AnalyticsContainer>
    </Content>
  );
};

export default WebViewComponents;
