import React, { useState } from "react";
import styled from "styled-components";
import { Image } from "antd";
import { IonIcon } from "@ionic/react";
import PlaceholderImage from "../../../assests/images/placeholder.jpg";

import Flex from "../../flex";
import Chip from "../../chipStatus";
import CheckCircle from "../../../assests/icons/svg/checkCircle.svg";
import CheckCircleFilled from "../../../assests/icons/svg/checkFilled.svg";
import { getDate } from "../../../lib/getDate";

const Card = styled(Flex)`
  width:${({ width }) => width || " 100%"}};
  min-height: 99px;
  background: ${({ backgroundColor }) => backgroundColor || "#fff"};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 40px;
  position: relative;
  cursor: pointer;
  transition: 0.3s box-shadow;
  &:hover {
    box-shadow: 0px 2px 15px #ccc;
  }
  @media screen and (max-width:768px){
    &:hover {
      box-shadow:none;
    }
  }
`;
const Title = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 11px;
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 35%;
  @media screen and (max-width: 768px) {
    max-width: 33vw;
  }
`;

const SubTitle = styled.div`
  font-family: Montserrat;
  font-weight: normal;
  font-size: 10px;
  color: var(--text-primary);
`;

const ImageWrapper = styled.div`
  .ant-image,
  img {
    object-fit: cover;
    background: #e7e2e2;
    width: 140px !important;
    height: 78px !important;
    border-radius: 5px !important;
    margin-right: 10px !important;
  }
`;

const AssetStatusChip = styled(Chip)`
  position: absolute;
  left: 4px;
  padding: 3px 10px;
  top: -20px;
  font-size: 11.5px;
`;

const Icon = styled(IonIcon)`
  font-size: 22px;
  margin-right: 18px;
  position: absolute;
  right: 2%;
  top: 32%;
  color: #3dd598;
  @media screen and (max-width: 768px) {
    margin-right: 7px;
  }
`;

const CompareItems = ({
  name = "--",
  version = 0,
  status = "--",
  isSelected = false,
  onClick = undefined,
  filePath = "",
  backgroundColor = "",
  width = "",
  createdDate = "",
  hideStatusChip = false,
}) => {
  const [fileSize, setFileSize] = useState(0);

  const getImageSize = (bytes) => {
    var sizes = ["b", "kb", "mb", "gb", "tb"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  const loadFile = async () => {
    if (filePath) {
      try {
        const resp = await fetch(filePath);
        const size = +resp.headers.get("Content-Length");
        const formattedSize = getImageSize(size);
        setFileSize(formattedSize);
      } catch (err) {
        console.log(err);
        setFileSize(0);
      }
    }
  };

  return (
    <Card
      alignCenter
      onClick={onClick}
      backgroundColor={backgroundColor}
      width={width}
    >
      <ImageWrapper>
        <Image
          preview={false}
          onLoad={loadFile}
          src={filePath || "error"}
          fallback={PlaceholderImage}
        />
      </ImageWrapper>

      <div>
        <Title>{name}</Title>
        <SubTitle>{getDate(createdDate)}</SubTitle>
        <SubTitle>File Size : {fileSize}</SubTitle>
        <SubTitle>version {version}</SubTitle>
      </div>
      {!hideStatusChip && status && (
        <AssetStatusChip status={status?.toLowerCase()}>
          {status?.toLowerCase()}
        </AssetStatusChip>
      )}

      <Icon icon={isSelected ? CheckCircleFilled : CheckCircle} />
    </Card>
  );
};

export default CompareItems;
