import React from "react";
import styled from "styled-components";
import { Upload } from "antd";

import CloseIcon from "../../assests/icons/close";

import { IonCol, IonRow, IonText, IonButton } from "@ionic/react";
import { RightOutlined } from "@ant-design/icons";

const Content = styled(IonCol)`
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  letter-spacing: 0.2px;
`;

const TitleContent = styled(IonRow)`
  justify-content: space-between;
  padding: 0 24px 10px 8px;
  margin-bottom: 20px;
`;

const Title = styled(IonText)`
  color: var(--text-primary);
  font-weight: 700;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
`;

const Close = styled.span`
  cursor: pointer;
`;

const SelectorContent = styled(IonCol)`
flex: 1 1 0%;
overflow-y: auto;
}
`;
const ButtonContent = styled(IonRow)`
  flex: 0.2;
  align-items: center;
  justify-content: center;
`;

const Button = styled(IonButton)`
  text-transform: capitalize;
  min-width: 100px;
  padding: 0rem 3.125rem;
  font-family: Montserrat, sans-serif;
`;

const MenuItem = styled(IonRow)`
  width: 100%;
  padding: 20px 10px 20px 20px;
  font-weight: 600;
  font-size: 0.75rem;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const AttachmentModal = styled(IonRow)`
  font-family: Montserrat, sans-serif;
  width: 100%;
  height: inherit;
  outline: none;
  padding: 20px 0 20px 0;
  text-align: center;
  border-radius: 10px;
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
    background-color: inherit;
    border: none;
  }
`;

const AttachmentDrawer = (props) => {
  const Menu = ({ menu, handleAttachment }) => {
    return (
      <Upload
        name="logo"
        listType="picture-card"
        className="avatar-uploader"
        multiple={false}
        accept={menu === "Photo" ? "image/*" : ".pdf,.doc,.docs"}
        beforeUpload={() => false}
        onChange={(file) => handleAttachment(file, menu)}
        showUploadList={false}
      >
        <MenuItem>
          <div>{menu === "Photo" ? "Media" : menu}</div>
          <div style={{ alignSelf: "center" }}>
            <RightOutlined style={{ fontSize: 13, color: "#969697" }} />
          </div>
        </MenuItem>
      </Upload>
    );
  };

  return (
    <Content>
      <TitleContent>
        <Title>Attach</Title>
        <Close onClick={() => props.handleAttachmentDrawer(false)}>
          <CloseIcon stroke="#130F26" height="10" width="10" />
        </Close>
      </TitleContent>
      <SelectorContent>
        <AttachmentModal>
          <Menu menu="Photo" handleAttachment={props.handleAttachment} />
          <Menu menu="Document" handleAttachment={props.handleAttachment} />
        </AttachmentModal>
      </SelectorContent>
      <ButtonContent>
        <div>
          <Button
            onClick={() => props.handleAttachmentDrawer(false)}
            shape="round"
            color="dark"
          >
            Cancel
          </Button>
        </div>
      </ButtonContent>
    </Content>
  );
};

export default AttachmentDrawer;
