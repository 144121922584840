import { IonRow } from "@ionic/react";
import { useState } from "react";
import styled from "styled-components";
import CommentsSelectorHeader from "../assetsComponents/commentsSelectorHeader";
import DocsComponent from "./docsComponent";
import LinksComponent from "./linksComponent";
import Media from "../review/media";

const Content = styled(IonRow)`
  flex-direction: column;
  font-family: Montserrat, sans-serif;
`;

const Attachments = () => {
  const [menu, setMenu] = useState("Media");

  const handleMenuSelector = (menu) => {
    setMenu(menu);
  };

  return (
    <Content style={{ marginTop: 40 }}>
      <CommentsSelectorHeader
        menuOption1="Media"
        menuOption2="Docs"
        menuOption3="Links"
        handleMenuSelector={handleMenuSelector}
        menu={menu}
        menu1Length="0"
        menu2Length="0"
        menu3Length="0"
        newMenu1={false}
        newMenu2={false}
        newMenu3={false}
        // background="FFFFFF"  //if want to change background
      />
      {menu === "Media" && (
        <div style={{ marginTop: 10 }}>
          <Media />
        </div>
      )}
      {menu === "Docs" && (
        <div style={{ marginTop: 10 }}>
          <DocsComponent menu={menu} />
        </div>
      )}
      {menu === "Links" && (
        <div style={{ marginTop: 10 }}>
          <LinksComponent menu={menu} />
        </div>
      )}
    </Content>
  );
};

export default Attachments;
