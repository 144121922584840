import React from "react";

const RightArrow = ({
  width = "6",
  height = "9",
  viewBox = "0 0 6 9",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.60138 8C1.4473 8 1.29241 7.93947 1.17525 7.81674C0.940917 7.57296 0.941719 7.17991 1.17685 6.93778L3.54586 4.4999L1.17685 2.06284C0.941719 1.82071 0.940917 1.42684 1.17525 1.18305C1.40958 0.938431 1.78997 0.940089 2.02591 1.18139L4.82345 4.05959C4.9366 4.1765 5 4.33488 5 4.4999C5 4.66574 4.9366 4.82412 4.82345 4.94104L2.02591 7.8184C1.90874 7.93947 1.75466 8 1.60138 8Z"
          fill="#130F26"
        />
        <path
          d="M1.60138 8C1.4473 8 1.29241 7.93947 1.17525 7.81674C0.940917 7.57296 0.941719 7.17991 1.17685 6.93778L3.54586 4.4999L1.17685 2.06284C0.941719 1.82071 0.940917 1.42684 1.17525 1.18305C1.40958 0.938431 1.78997 0.940089 2.02591 1.18139L4.82345 4.05958C4.9366 4.1765 5 4.33488 5 4.4999C5 4.66574 4.9366 4.82412 4.82345 4.94104L2.02591 7.8184C1.90874 7.93947 1.75466 8 1.60138 8"
          stroke="#130F26"
          strokeWidth="0.35"
        />
      </g>
    </svg>
  );
};
export default RightArrow;
