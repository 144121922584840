import React from "react";

const MobHomeSelectedIcon = ({
  width = "16",
  height = "16",
  viewBox = "0 0 16 16",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.05176 10.7569H9.92843"
        stroke="#130F26"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.59961 9.14201C1.59961 5.38801 2.00894 5.65001 4.21228 3.60668C5.17628 2.83068 6.67628 1.33334 7.97161 1.33334C9.26627 1.33334 10.7963 2.82334 11.7689 3.60668C13.9723 5.65001 14.3809 5.38801 14.3809 9.14201C14.3809 14.6667 13.0749 14.6667 7.99028 14.6667C2.90561 14.6667 1.59961 14.6667 1.59961 9.14201Z"
        stroke="#130F26"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default MobHomeSelectedIcon;
