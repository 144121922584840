import React from "react";

const MobAssetsSelectedIcon = ({
  width = "16",
  height = "16",
  viewBox = "0 0 16 16",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.87047 9.71617H11.2658"
        stroke="#130F26"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66667 5.1989C1.66667 3.57096 2.50001 2.17287 4.08134 1.84842C5.662 1.52334 6.86334 1.63572 7.86134 2.17414C8.86 2.71255 8.574 3.50747 9.6 4.09096C10.6267 4.67509 12.278 3.79763 13.3567 4.96144C14.486 6.17985 14.48 8.05033 14.48 9.24271C14.48 13.7735 11.942 14.1329 8.07334 14.1329C4.20467 14.1329 1.66667 13.8192 1.66667 9.24271V5.1989Z"
        stroke="#130F26"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default MobAssetsSelectedIcon;
