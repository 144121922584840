import React from "react";

const ArrowLeftCircle = ({
  width = "28",
  height = "28",
  viewBox = "0 0 20 20",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 10C0 4.48 4.49 0 10 0L10.2798 0.00384421C15.6706 0.152163 20 4.57356 20 10C20 15.51 15.52 20 10 20C4.49 20 0 15.51 0 10ZM11.98 14C12.27 13.7 12.27 13.23 11.97 12.94L9.02 10L11.97 7.06C12.27 6.77 12.27 6.29 11.98 6C11.68 5.7 11.21 5.7 10.92 6L7.43 9.47C7.29 9.61 7.21 9.8 7.21 10C7.21 10.2 7.29 10.39 7.43 10.53L10.92 14C11.06 14.15 11.25 14.22 11.44 14.22C11.64 14.22 11.83 14.15 11.98 14Z"
        fill="#130F26"
      />
    </svg>
  );
};
export default ArrowLeftCircle;
