import React from "react";
import { IonFooter, IonButton, IonSpinner } from "@ionic/react";
import styled from "styled-components";
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";

import Flex from "../flex";
import Chip from "../chipStatus";
import { getDate } from "../../lib/getDate";
import Close from "../../assests/icons/close";

const { confirm } = Modal;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 28px;
  color: var(--text-primary);
  margin-right: 10px;
`;

const Footer = styled(IonFooter)`
  font-family: Montserrat;
  padding: 1.4rem 3% 2rem 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled(IonButton)`
  text-transform: capitalize;
  width: ${({ fullWidth }) => (fullWidth ? "399px" : "190px")};
  font-size: 0.738rem;
  font-family: "Montserrat";
  --background: ${({ inverse }) => (inverse ? "#FF6F6F" : "#8BDDB3")};
  height: 2.875rem;
  color: #ffffff;
  font-weight: bold;
  --box-shadow: none;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media screen and (max-width: 768px) {
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "190px")};
  }
`;

const IconWrapper = styled.span`
  margin-right: 10px;
  font-size: 0.638rem;
`;

const ButtonText = styled.span`
  margin-bottom: 4px;
`;

const SubTitle = styled.div`
  color: #929292;
  font-weight: 600;
  font-size: 0.688rem;
  margin-bottom: 10px;
`;
const IconButton = styled.button`
  color: var(--text-primary);
  background: none;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const AssetsTitileWrapper = styled(Flex)`
  margin-right: 80px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const ActionWrapper = styled(Flex)`
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;
const CloseIconWrapper = styled.div`
  margin-bottom: 3px;
  font-size: 0.638rem;
`;

const TruncatedText = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.89rem;
  color: var(--text-primary);
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 24px;
  margin-top: 6px;
`;

const ArrowIconWrappper = styled.button`
  position: absolute;
  top: -3px;
  left: 0;
  margin: 0 auto;
  right: 0;
  width: fit-content;sss
  color: #767676;
  transform: ${({ inverse }) => (inverse ? "rotate(180deg)" : "rotate(0deg)")};
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  visibility: hidden;
  @media screen and (max-width: 768px) {
    visibility: ${({ inverse }) => (inverse ? "visible" : "hidden")};
  }
`;

const ReviewFooter = ({
  isLoading = false,
  status = "",
  onApproveOrReject = undefined,
  approveOrRejectStatus = "",
  name = "",
  createdAt = "",
  onSubmitApproveOrReject = undefined,
  selectedApproveOption = {},
  rejectFeedbackMessage = "",
  deliverableName = "",
  handleErrorMessage = undefined,
  onReviewToggle = undefined,
  isReview = false,
}) => {
  const showRejectConfirm = () => {
    if (!rejectFeedbackMessage) {
      handleErrorMessage("reject", "Please state the reason for rejecting");
      return;
    }
    if (rejectFeedbackMessage?.length < 2) {
      handleErrorMessage(
        "reject",
        "Please enter the valid reason for rejecting"
      );
      return;
    }
    confirm({
      title: `Are you sure reject this ${name || ""} asset?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onSubmitApproveOrReject(approveOrRejectStatus);
      },
    });
  };

  return (
    <Footer>
      <Flex spaceBetween alignCenter>
        <ArrowIconWrappper onClick={onReviewToggle} inverse={isReview}>
          <DownOutlined />
        </ArrowIconWrappper>
        <AssetsTitileWrapper column>
          <Flex alignCenter>
            <Title>{name}</Title>
            <div>
              {status && <Chip status={status.toLowerCase()}>{status}</Chip>}
            </div>
          </Flex>
          <SubTitle>
            Created on: {createdAt ? getDate(createdAt) : "--"}
          </SubTitle>
          {/* <TruncatedText>{deliverableName}</TruncatedText> */}
        </AssetsTitileWrapper>
        <ActionWrapper center alignCenter>
          {approveOrRejectStatus === "" ? (
            <>
              <Button
                shape="round"
                style={{ marginRight: "10px" }}
                inverse
                onClick={() => onApproveOrReject && onApproveOrReject("reject")}
              >
                <IconWrapper slot="start">
                  <CloseIconWrapper>
                    <Close height="10" width="10" />
                  </CloseIconWrapper>
                </IconWrapper>
                <ButtonText>Reject All</ButtonText>
              </Button>
              <Button
                shape="round"
                onClick={() =>
                  onApproveOrReject && onApproveOrReject("approve")
                }
              >
                <IconWrapper slot="start">
                  <CheckOutlined />
                </IconWrapper>
                <ButtonText>Approve All</ButtonText>
              </Button>
            </>
          ) : (
            <ActionWrapper alignCenter>
              <Button
                fullWidth
                shape="round"
                inverse={approveOrRejectStatus === "reject"}
                onClick={() =>
                  approveOrRejectStatus === "reject"
                    ? showRejectConfirm()
                    : onSubmitApproveOrReject(approveOrRejectStatus)
                }
              >
                {isLoading ? (
                  <IonSpinner name="crescent" />
                ) : (
                  <>
                    <IconWrapper slot="start">
                      {approveOrRejectStatus === "approve" ? (
                        <CheckOutlined />
                      ) : (
                        <CloseIconWrapper>
                          <Close height="10" width="10" />
                        </CloseIconWrapper>
                      )}
                    </IconWrapper>
                    <ButtonText>{approveOrRejectStatus} All</ButtonText>
                  </>
                )}
              </Button>
              <IconButton
                onClick={() => onApproveOrReject && onApproveOrReject("")}
              >
                <Close stroke="#130F26" height="10" width="10" />
              </IconButton>
            </ActionWrapper>
          )}
        </ActionWrapper>
      </Flex>
    </Footer>
  );
};

export default ReviewFooter;
