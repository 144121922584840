import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { IonButton, IonCard, IonRow, IonSpinner } from "@ionic/react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Input from "../input";
import { useMutation } from "@apollo/client";
import {
  CHANGE_PASSWORD,
  LOGGED_IN_USER_CHANGE_PASSWORD,
} from "../../graphQL/mutations";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useToastMessage } from "../useToastMessage";

const SubContent = styled(IonRow)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Button = styled(IonButton)`
  margin: 5vh 0;
  text-transform: capitalize;
  width: 148px;
  height: 41px;
  font-size: 0.688rem;
  font-weight: bold;
  font-family: Montserrat, sans-serif;
  --background: var(--button-primary);
  --box-shadow: none;
`;

const Text = styled.h5`
  cursor: pointer;
  margin-bottom: 40px;
  font-size: 1.5rem;
  font-weight: 500;
`;

const StyledLink = styled(Link)`
  color: ${({ linkColor }) => linkColor || "#130f26"};
  font-weight: 600;
  font-size: 1.25rem;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

const Card = styled(IonCard)`
  font-family: Montserrat, sans-serif;
  box-shadow: none;
  flex: 1.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${({ isLoggedInUser }) => (isLoggedInUser ? "6%" : "10%")};
  justify-content: space-around;
  max-width: 530px;
  width: 100%;
  @media screen and (max-width: 768px) {
    ${Text} {
      display: ${({ isLoggedInUser }) => (isLoggedInUser ? "block" : "none")};
    }
    ${StyledLink} {
      font-size: 0.938rem;
    }
  }
`;

const ChangePasswordComponent = ({
  resetToken = "",
  isLoggedInUser = false,
}) => {
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  const [loggedInUserChangePassword] = useMutation(
    LOGGED_IN_USER_CHANGE_PASSWORD
  );
  const { showToastMessage } = useToastMessage();
  const history = useHistory();
  const [newPassword, setNewPassword] = useState({ value: null, error: null });
  const [reNewPassword, setReNewPassword] = useState({
    value: null,
    error: null,
  });
  const [currentPassword, setCurrentPassword] = useState({
    value: null,
    error: null,
  });
  const [isLoading, setLoading] = useState(false);

  const updateInputFields = (value, setValue) => {
    setValue({ value, error: null });
  };

  const changePasswordCredentials = async () => {
    if (isLoggedInUser) {
      try {
        setLoading(true);
        const { data } = await loggedInUserChangePassword({
          variables: {
            currentPassword: currentPassword.value,
            newPassword: newPassword.value,
            resetToken: localStorage.getItem("token"),
          },
        });
        if (data?.changePassword?.code == 200) {
          showToastMessage({
            message:
              data?.changePassword?.message || "Password changed successfully!",
          });
          history.goBack();
        }
      } catch (error) {
        showToastMessage({
          color: "danger",
          message: error.message || "Something went wrong!",
        });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const { data } = await changePassword({
          variables: {
            resetToken,
            newPassword: newPassword.value,
          },
        });
        if (data?.changePassword?.message) {
          showToastMessage({
            message:
              data?.changePassword?.message || "Password changed successfully!",
          });
          history.push("/login");
        }
      } catch (error) {
        showToastMessage({
          color: "danger",
          message: "Invalid resetToken!",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = () => {
    let allValidated = true;
    if (isLoggedInUser && !currentPassword.value) {
      allValidated = false;
      setCurrentPassword({
        ...currentPassword,
        error: "Please enter the current password",
      });
    }
    if (!newPassword.value) {
      allValidated = false;
      setNewPassword({
        ...newPassword,
        error: "Please enter the new password",
      });
    }
    if (reNewPassword.value !== newPassword.value) {
      allValidated = false;
      setReNewPassword({
        ...reNewPassword,
        error: "The passwords don’t match.",
      });
    }
    if (allValidated) {
      changePasswordCredentials();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Card isLoggedInUser={isLoggedInUser}>
      <div>
        <Text onClick={() => history.goBack()}>
          <ArrowLeftOutlined style={{ fontSize: 20 }} /> Change Password
        </Text>
        {isLoggedInUser && (
          <Input
            label="Current Password"
            placeholder="Enter Current Password"
            type="password"
            margin="0 0 0.5rem 0"
            onChange={(event) =>
              updateInputFields(event.target.value, setCurrentPassword)
            }
            error={currentPassword.error}
            showError
            onKeyPress={handleKeyPress}
          />
        )}
        <Input
          label="New Password"
          placeholder="Enter Password"
          type="password"
          margin="0 0 0.5rem 0"
          onChange={(event) =>
            updateInputFields(event.target.value, setNewPassword)
          }
          error={newPassword.error}
          showError
          onKeyPress={handleKeyPress}
        />
        <Input
          label="Confirm New Password"
          placeholder="Re-Enter Password"
          type="password"
          margin="0 0 0.5rem 0"
          onChange={(event) =>
            updateInputFields(event.target.value, setReNewPassword)
          }
          error={reNewPassword.error}
          showError
          onKeyPress={handleKeyPress}
        />
      </div>
      <SubContent>
        <Button shape="round" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? <IonSpinner name="crescent" /> : " Confirm "}
        </Button>
      </SubContent>
    </Card>
  );
};

export default ChangePasswordComponent;
