import React, { useEffect, useRef } from "react";
import { IonApp, IonPage } from "@ionic/react";
import "@ionic/core/css/ionic.bundle.css";
import { Route, Switch, Redirect } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import { GET_USER_ID } from "graphQL/queries";
import Routes from "./routes";
import { useSession } from "../../components/sessionValidator";
import useWindowDimensions from "../../components/useWindowDimensions";
import { useHistory, useLocation } from "react-router-dom";
import { App } from "@capacitor/app";

const RoutesPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [session, isLoading] = useSession();
  const { width } = useWindowDimensions();
  const token = localStorage.getItem("token");

  const mounted = useRef();
  const exitapp = useRef();

  const [getUser, { data: userData }] = useLazyQuery(GET_USER_ID);

  useEffect(async () => {
    if (token) {
      await getUser({
        variables: {
          gte: new Date().toISOString(),
          token,
        },
      });
    }
  }, [token]);

  useEffect(() => {
    const user =
      userData?.client_user_session?.length > 0
        ? userData?.client_user_session[0]?.user
        : "";

    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userName", user?.name || "");
    }
  }, [userData]);

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      App.addListener("backButton", () => {
        if (!exitapp.current) {
          exitapp.current = true;
          setTimeout(() => {
            exitapp.current = false;
          }, 600);
          history.goBack();
        } else {
          if (window.confirm("Are you sure you want to exit?")) {
            exitapp.current = false;
            App.exitApp();
          } else {
            exitapp.current = false;
          }
        }
      });
    }
    return () => {
      App.removeAllListeners();
    };
  }, [location, history]);

  return (
    <IonApp>
      <IonPage id="main">
        {!session && !isLoading && (
          <Redirect to={width <= 768 ? "/" : "/login"} />
        )}
        <Switch>
          {Routes.map((routeObj, routeObjIndex) => {
            return (
              <Route
                path={routeObj.path}
                key={routeObjIndex}
                exact={routeObj.isExact}
                render={(routerProps) => (
                  <routeObj.page
                    key={
                      routerProps.match.params[routeObj.key] || routeObjIndex
                    }
                  />
                )}
              />
            );
          })}
        </Switch>
      </IonPage>
    </IonApp>
  );
};
export default RoutesPage;
