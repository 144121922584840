import React from "react";

const MobCommentsSelectedIcon = ({
  width = "16",
  height = "16",
  viewBox = "0 0 16 16",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6262 8.27535H10.6322"
        stroke="#130F26"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.95387 8.27535H7.95987"
        stroke="#130F26"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.28102 8.27535H5.28702"
        stroke="#130F26"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7142 12.7132C10.6775 14.7509 7.65989 15.1911 5.19103 14.0494C4.82657 13.9026 2.46759 14.5559 1.95572 14.0447C1.44386 13.5327 2.09776 11.1734 1.95103 10.8089C0.808726 8.34039 1.24957 5.32174 3.28696 3.28474C5.88781 0.682877 10.1133 0.682877 12.7142 3.28474C15.3204 5.89063 15.315 10.112 12.7142 12.7132Z"
        stroke="#130F26"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default MobCommentsSelectedIcon;
