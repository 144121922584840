import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import HomeIcon from "../assests/icons/homeIcon";
import CommentsIcon from "../assests/icons/commentsIcon";
import AssetsIcon from "../assests/icons/assetsIcon";
// import MessageIcon from "../assests/icons/messageIcon";
import WebHomeSelectedIcon from "../assests/icons/webHomeSelectedIcon";
import WebCommentsSelectedIcon from "../assests/icons/webCommentsSelectedIcon";
import WebAssetsSelectedIcon from "../assests/icons/webAssetsSelectedIcon";
// import WebMessageSelectedIcon from "../assests/icons/webMessageSelectedIcon";
import ChatIcon from "assests/icons/chatIcon";

import UserAvatar from "./userAvatar";
import Flex from "./flex";
import Logout from "./logout";

const SideBarWrapper = styled(Flex)`
  height: 100vh;
  width: 140px;
  background: #f5f5f5;
  user-select: none;
  padding: 30% 0;
`;
const MenuItemList = styled(Flex)`
  width: 100%;
`;
const Item = styled.div`
  margin-bottom: ${({ itemKey }) => itemKey !== 4 && "60px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.div`
  margin-top: 5px;
  font-size: 0.688rem;
  color: ${({ selected }) => (selected ? "#130F26" : "#929292")};
  font-weight: ${({ selected }) => (selected ? 700 : 500)};
  letter-spacing: 0.2px;
`;

const MenuItem = ({ title, value, Icon, itemKey, onClick }) => {
  return (
    <Item key={itemKey} itemKey={itemKey} onClick={() => onClick(value)}>
      {Icon}
      <Title selected={value.toLowerCase() === title.toLowerCase()}>
        {value}
      </Title>
    </Item>
  );
};

const WebSidebarMenu = ({ title = "Home" }) => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {};

  const history = useHistory();

  const handdleClick = (itemValue) => {
    history.push("/" + itemValue);
  };

  return (
    <SideBarWrapper column alignCenter spaceBetween>
      <UserAvatar
        bordered
        name={user?.name}
        avatar={user?.avatar}
        onClick={() => history.push("/user/profile")}
      />
      <MenuItemList column>
        <MenuItem
          title={title}
          value="Home"
          itemKey={1}
          Icon={
            title.toLowerCase() === "home" ? (
              <WebHomeSelectedIcon />
            ) : (
              <HomeIcon />
            )
          }
          onClick={() => handdleClick("home")}
        />
        <MenuItem
          title={title}
          value="Comments"
          itemKey={2}
          Icon={
            title.toLowerCase() === "comments" ? (
              <WebCommentsSelectedIcon />
            ) : (
              <CommentsIcon />
            )
          }
          onClick={() => handdleClick("comments")}
        />
        <MenuItem
          title={title}
          value="Assets"
          itemKey={3}
          Icon={
            title.toLowerCase() === "assets" ? (
              <WebAssetsSelectedIcon />
            ) : (
              <AssetsIcon />
            )
          }
          onClick={() => handdleClick("assets")}
        />
        <MenuItem
          title={title}
          value="Chat"
          itemKey={4}
          Icon={<ChatIcon filled={title.toLowerCase() === "chat"} />}
          onClick={() => handdleClick("chat")}
        />
      </MenuItemList>
      <Logout />
    </SideBarWrapper>
  );
};
export default WebSidebarMenu;
