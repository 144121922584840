import React, { useState } from "react";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { IonButton, IonCard, IonCol, IonRow, IonSpinner } from "@ionic/react";

import Input from "./input";

const SubContent = styled(IonRow)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Button = styled(IonButton)`
  margin-bottom: 4%;
  text-transform: capitalize;
  height: 41px;
  min-width: ${({ buttonText }) => (buttonText === "Done" ? "131px" : "148px")};
  font-size: 0.688rem;
  font-weight: bold;
  font-family: Montserrat;
  --background: var(--button-primary);
  --box-shadow: none;
`;

const LoginText = styled.h5`
  margin-bottom: 30px;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  color: ${({ linkColor }) => linkColor || "#130f26"};
  font-weight: 600;
  font-size: 1.25rem;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

const Text = styled.div`
  color: var(--text-primary);
  font-size: 0.75rem;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
  visibility: ${({ seconds }) => (seconds ? "hidden" : "visible")};
  opacity: ${({ seconds }) => (seconds ? 0 : 1)};
  transition: 0.5s;
  &:hover {
    opacity: 0.8;
  }
`;

const TimerText = styled.div`
  font-size: 0.75rem;
  color: #1da1f2;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-align: center;
`;

const ResendOtpWrapper = styled.div``;

const Card = styled(IonCard)`
  font-family: Montserrat, sans-serif;
  box-shadow: none;
  flex: 1.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10% auto 0 auto;
  justify-content: space-around;
  max-width: 530px;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-bottom: 4.4rem;
    ${LoginText} {
      display: none;
    }
    ${StyledLink} {
      font-size: 9px;
    }
    ${ResendOtpWrapper} {
      background: #f5f5f5;
      width: 100%;
      bottom: 0px;
      left: 0px;
      padding: 0.3rem 0.75rem;
      position: fixed;
      height: 10vh;
      max-height: 140px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

const ErrorText = styled.div`
  font-size: 0.813rem;
  color: #ff5959;
  transform: ${({ error }) => (error ? "scaleY(1)" : "scaleY(0)")};
  transform-origin: top;
  transition: 0.25s all;
  min-height: 1.375rem;
  margin-left: 0.188rem;
  margin-bottom: 0.125rem;
  user-select: none;
`;

const EmailSentTitle = styled.h3`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.375rem;
  color: #5961f9;
`;

const EmailSentDescription = styled.p`
  font-family: Montserrat;
  font-size: 0.938rem;
  line-height: 1.375rem;
  color: var(--text-primary);
`;

const LoginWithPhoneNumber = ({
  title = "Login using OTP",
  isPhone = true,
  inputLabel,
  placeholder = "",
  value,
  error = undefined,
  onInputPhoneNumber = "",
  showOTP,
  onSubmit = undefined,
  onInputClick = undefined,
  seconds = undefined,
  onPhoneCodeSelect = undefined,
  phoneCodeValue = "",
  otpInputs = [],
  onOtpEnter = undefined,
  loading = false,
  otpError = "",
  buttonText = "Done",
  resendOTP = undefined,
  onChangeField = undefined,
  isEmailSent = false,
}) => {
  const history = useHistory();
  const [isPhoneType, setIsPhoneType] = useState(isPhone);

  const inputPhoneNumber = (event) => {
    const phoneRegex = /^(?:[1-9]\d*|\d)$/;
    const inputValue = event.target.value;
    if (inputValue && inputValue.match(phoneRegex)) {
      if (onInputPhoneNumber) {
        onInputPhoneNumber(inputValue);
      }
      if (onChangeField) {
        onChangeField("phone", inputValue);
        setIsPhoneType(true);
      }
    } else {
      if (!inputValue && onInputPhoneNumber) {
        onInputPhoneNumber(inputValue);
      }
      if (onChangeField) {
        onChangeField("email", inputValue);
        setIsPhoneType(false);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  const getSecondsToMinutes = (time) => {
    let m =
        Math.floor((time % 3600) / 60)
          .toString()
          .padStart(2, "0") || "00",
      s =
        Math.floor(time % 60)
          .toString()
          .padStart(2, "0") || "00";

    return m + ":" + s;
  };

  return (
    <Card>
      <div>
        <LoginText
          as="h5"
          fw="500"
          size="1.5rem"
          onClick={() => history.goBack()}
        >
          <ArrowLeftOutlined /> {title}
        </LoginText>
        {isEmailSent ? (
          <div>
            <EmailSentTitle>Mail Sent!</EmailSentTitle>
            <EmailSentDescription>
              A Link has been sent to your <b>Email Id: {value} </b>
              to help you Recover Password.
            </EmailSentDescription>
          </div>
        ) : (
          <div onClick={onInputClick}>
            <Input
              placeholder={
                placeholder
                  ? placeholder
                  : showOTP
                  ? "Phone Number"
                  : "Enter Phone Number"
              }
              label={inputLabel || "An OTP has been shared on your Number "}
              onChange={inputPhoneNumber}
              value={value}
              phoneCodeValue={phoneCodeValue}
              error={error || ""}
              showError
              readOnly={showOTP}
              onPhoneCodeSelect={onPhoneCodeSelect}
              showPhoneCode={isPhoneType}
              onKeyPress={handleKeyPress}
            />
          </div>
        )}

        {showOTP && (
          <>
            <IonRow>
              {otpInputs?.map(({ code }, otpInputIndex) => (
                <IonCol key={`${otpInputIndex}-otp-input-field`}>
                  <Input
                    align="center"
                    id={`otp-input-${otpInputIndex}`}
                    value={code}
                    onChange={(event) =>
                      onOtpEnter
                        ? onOtpEnter(event.target.value, otpInputIndex)
                        : ""
                    }
                    showErrorBorder={(!code && otpError) || ""}
                    type="number"
                  />
                </IonCol>
              ))}
            </IonRow>
            <ErrorText error={otpError}>{otpError}</ErrorText>
          </>
        )}
      </div>
      <SubContent>
        <Button
          shape="round"
          onClick={onSubmit}
          disabled={loading}
          buttonText={buttonText}
        >
          {loading ? <IonSpinner name="crescent" /> : buttonText}
        </Button>
        {showOTP && (
          <TimerText>{getSecondsToMinutes(Number(seconds))}</TimerText>
        )}
      </SubContent>
      {showOTP && (
        <ResendOtpWrapper>
          <Text seconds={seconds} onClick={resendOTP}>
            Resend OTP
          </Text>
        </ResendOtpWrapper>
      )}
    </Card>
  );
};

export default LoginWithPhoneNumber;
