import { IonText } from "@ionic/react";
import styled from "styled-components";

const SubContent = styled.div`
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Circle = styled.div`
  height: 3.7rem;
  width: 3.7rem;
  border-radius: 50%;
  margin-bottom: 4px;
  position: absolute;
  background: #f5f5f5;
`;

const BarContent = styled.div`
  height: 100%;
  position: relative;
`;

const BarFlex = styled.div`
  width: 3rem;
  bottom: 0px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
`;

const Bar = styled.div`
  width: 0.6rem;
  border-radius: 100px 100px 0px 0px;
  height: ${({ height }) => height};
  background: ${({ background }) => background};
`;

const Result = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResNumber = styled(IonText)`
  font-size: 1rem;
  font-weight: 700;
`;
const ResName = styled(IonText)`
  font-size: 0.75rem;
  font-weight: 300;
  color: var(--text-primary);
  text-transform: uppercase;
`;

const Content = styled.div`
  height: 4.5rem;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 768px) {
    background: #f5f5f5;
    ${Circle} {
      background: #ffffff;
    }
    ${ResName} {
      text-transform: capitalize;
    }
  }
`;

const AnalyticsHeader = (props) => {
  return (
    <Content>
      <SubContent>
        <Circle></Circle>
        <BarContent>
          <BarFlex>
            <Bar height={"55%"} background={"#8BDDB3"}></Bar>
            <Bar height={"70%"} background={"#FFDC3E"}></Bar>
            <Bar height={"40%"} background={"#FF6F6F"}></Bar>
          </BarFlex>
        </BarContent>
      </SubContent>
      <SubContent>
        <Result>
          <ResNumber>{props.assets}</ResNumber>
          <ResName>Assets</ResName>
        </Result>
      </SubContent>
      <SubContent>
        <Result>
          <ResNumber>{Math.round(props.approved)}%</ResNumber>
          <ResName>Approved</ResName>
        </Result>
      </SubContent>
      <SubContent>
        <Result>
          <ResNumber>{props.spent}</ResNumber>
          <ResName>Days</ResName>
        </Result>
      </SubContent>
    </Content>
  );
};

export default AnalyticsHeader;
