import React, { useState } from "react";
import { withRouter, useHistory } from "react-router";
import LoginLayout from "../../../components/loginLayout";
import ResetPassword from "../../../components/loginWithPhoneComponent";
import { emailRules } from "../../../constants/rules";
import { validator } from "../../../lib/validator";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD, USER_PHONE_LOGIN } from "../../../graphQL/mutations";
import { useToastMessage } from "../../../components/useToastMessage";
import OTPInput from "../../../components/otpInput";

const ForgotPassword = () => {
  const [isOTP, setIsOTP] = useState(false);
  const history = useHistory();
  const [inputDetails, setInputDetails] = useState({
    type: "email",
    value: "",
    error: "",
  });
  const [isEmailSent, setEmailSent] = useState(false);
  const [buttonText, setButtonText] = useState("Confirm");
  const [phoneCodeValue, setPhoneCodeValue] = useState("+91");
  const [sendOtp] = useMutation(USER_PHONE_LOGIN);

  const [forgotPassword, { loading: forgotPasswordLoading }] =
    useMutation(FORGOT_PASSWORD);
  const { showToastMessage } = useToastMessage();

  const submitEmail = async () => {
    try {
      const { data } = await forgotPassword({
        variables: { emailId: inputDetails.value },
      });
      if (data?.forgotPassword?.message) {
        setEmailSent(true);
        setButtonText("Back to login");
      }
    } catch (error) {
      console.log(error);
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    }
  };

  const sendOTPToNumber = async () => {
    try {
      const userPhoneNumber = (
        phoneCodeValue +
        " " +
        inputDetails?.value
      ).replace(/\+/g, "");
      const { data } = await sendOtp({
        variables: {
          phoneNumber: userPhoneNumber,
        },
      });
      if (data) {
        showToastMessage({
          message: "OTP sent to the phone number",
        });
        setIsOTP(true);
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    }
  };

  const emailReset = () => {
    const validDetails = validator({
      rules: emailRules,
      inputValue: inputDetails.value || "",
    });
    if (validDetails.status) {
      submitEmail();
    } else {
      setInputDetails({
        ...inputDetails,
        error: validDetails.errorMessage,
      });
    }
  };

  const phoneReset = () => {
    if (!inputDetails.value) {
      setInputDetails({
        ...inputDetails,
        error: "Please enter a valid mobile number",
      });
      return;
    } else if (
      !(inputDetails.value.length >= 4 && inputDetails.value.length <= 12)
    ) {
      setInputDetails({
        ...inputDetails,
        error: "Please enter a valid mobile number",
      });
      return;
    }
    sendOTPToNumber();
  };

  const onSubmitDetails = () => {
    if (buttonText === "Confirm") {
      if (inputDetails.type === "email") {
        emailReset();
      } else if (inputDetails.type === "phone") {
        phoneReset();
      }
    } else if (buttonText === "Back to login") {
      history.push("/login");
    }
  };

  const onChangeField = (type, value) => {
    setInputDetails({ type, value, error: "" });
  };

  const onPhoneCodeSelect = (phoneCode) => {
    setPhoneCodeValue(phoneCode);
  };

  return isOTP ? (
    <OTPInput
      title="Forgot Password"
      redirect="/forgot-password"
      layoutTitle="Forgot Password"
      type="forgot-password"
      phoneCodeValue={phoneCodeValue}
      number={inputDetails?.value}
    />
  ) : (
    <LoginLayout showBackButton title="Forgot Password">
      <ResetPassword
        title="Forgot Password"
        inputLabel="Kindly share the follwing information to help us reset 
      your password"
        placeholder="Email / Phone Number"
        value={inputDetails?.value}
        isPhone={false}
        error={inputDetails?.error}
        isEmailSent={isEmailSent}
        loading={forgotPasswordLoading}
        onSubmit={onSubmitDetails}
        onPhoneCodeSelect={onPhoneCodeSelect}
        phoneCodeValue={phoneCodeValue}
        onChangeField={onChangeField}
        buttonText={buttonText}
      />
    </LoginLayout>
  );
};
export default withRouter(ForgotPassword);
