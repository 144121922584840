import { IonText, IonAvatar } from "@ionic/react";
import styled from "styled-components";

const SidebarWrapper = styled.div`
  display: flex;
  flex: 0.5;
  background: #f5f5f5;
  border-radius: 10px 0px 0px 10px;
  max-width: 380px;
  min-width: 300px;
  @media (max-width: 785px) {
    flex: 1;
    max-width: 100%;
    border-radius: inherit;
    min-width: auto;
  }
`;

const SidebarContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;
`;

const MsgContacts = styled.div`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 8px;
  padding: 0px 20px 0px 20px;
  &:hover {
    background: #f1efef;
  }
`;

const MsgContactContainer = styled.div`
  display: flex;
  min-height: 100px;
  flex-direction: row;
  flex: 1 1;
  align-items: center;
  border-bottom: 0.75px solid #e1e1e1;
`;

const MsgContainerAvatar = styled.div`
  display: flex;
  flex: 0.3;
`;

const MsgContactDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const MsgContactLatest = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.2px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 450px) {
    width: 100px;
  }
`;

const MsgContactNot = styled.div`
  display: flex;
  flex: 0.3;
  flex-direction: column;
  align-items: flex-end;
`;

const MsgLatestTime = styled.div`
  color: var(--text-primary);
  font-weight: 700;
  font-style: normal;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.2px;
  height: 20px;
`;

const MsgContactNotification = styled.div`
  display: flex;
  background: #3dd598;
  border-radius: 50%;
  padding: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #ffffff;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
`;

const StyledIonAvatar = styled(IonAvatar)`
  display: flex;
  width: 34px !important;
  height: 34px !important;
  background: #7c9ec9;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1em;
  font-weight: 600;
`;

const ContactName = styled.h4`
  color: var(--text-primary);
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.2px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 7px;
  text-overflow: ellipsis;
  @media (max-width: 450px) {
    width: 100px;
  }
`;

const SidebarMain = () => {
  const userData = [
    {
      name: "Alice Smith",
      latestText: "Great. I will have a look",
      textTime: "4:24 PM",
      newNotis: 3,
    },
    {
      name: "Chetan Bashetti",
      latestText: "Hope everything is good",
      textTime: "11:04 AM",
      newNotis: 2,
    },
    {
      name: "Prashant Halaki",
      latestText: "Lets See, What we can do",
      textTime: "3:44 PM",
    },
    {
      name: "Ankit Joshi",
      latestText: "Yee!! it went well",
      textTime: "09:17 AM",
      newNotis: 5,
    },
  ];

  return (
    <SidebarWrapper>
      <SidebarContent>
        {userData?.map((eachMessage, id) => (
          <MsgContacts key={id}>
            <MsgContactContainer>
              <MsgContainerAvatar>
                <StyledIonAvatar slot="start">
                  {eachMessage.name[0]}
                </StyledIonAvatar>
              </MsgContainerAvatar>
              <MsgContactDetails>
                <IonText color="#130f26">
                  <ContactName>{eachMessage.name}</ContactName>
                </IonText>
                <MsgContactLatest>{eachMessage.latestText}</MsgContactLatest>
              </MsgContactDetails>
              <MsgContactNot>
                <MsgLatestTime>{eachMessage.textTime}</MsgLatestTime>
                {eachMessage.newNotis ? (
                  <MsgContactNotification>
                    <div>{eachMessage.newNotis}</div>
                  </MsgContactNotification>
                ) : (
                  ""
                )}
              </MsgContactNot>
            </MsgContactContainer>
          </MsgContacts>
        ))}
      </SidebarContent>
    </SidebarWrapper>
  );
};

export default SidebarMain;
