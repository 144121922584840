import React from "react";

const Compare = ({
  height = "15",
  width = "19",
  viewBox = "0 0 15 19",
  color = "none",
  stroke = "white",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_0:2494" fill="white">
        <rect y="4" width="6" height="12" rx="1" />
      </mask>
      <rect
        y="4"
        width="6"
        height="12"
        rx="1"
        stroke="black"
        strokeWidth="2.4"
        mask="url(#path-1-inside-1_0:2494)"
      />
      <mask id="path-2-inside-2_0:2494" fill="white">
        <rect x="9" width="6" height="19" rx="1" />
      </mask>
      <rect
        x="9"
        width="6"
        height="19"
        rx="1"
        stroke="black"
        strokeWidth="2.4"
        mask="url(#path-2-inside-2_0:2494)"
      />
    </svg>
  );
};
export default Compare;
