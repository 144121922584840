import React from "react";
import ArrowLeftCircle from "assests/icons/arrowLeftCircle";
import { FileTextOutlined } from "@ant-design/icons";
import styled from "styled-components";
import CommentInput from "./commentInputComponent";
import { stringToHslColor } from "../../lib/stringToHslColor";
import { Avatar } from "antd";
import { DocumentViewer } from "react-documents";

const FileClose = styled.div`
  display: flex;
  align-items: center;
  background: #f7f6f6;
  max-width: max-content;
  margin-top: 10px;
  padding: 6px 20px;
  border-radius: 8px;
`;

const FileName = styled.div`
  font-size: 1.2rem;
  color: #130f26;
  padding: 10px 10px;
`;
const InputContent = styled.div`
  padding: 0px 20px;
  border-radius: inherit;
`;
const Input = styled.div`
  margin: 10px 0px 10px;
`;
const FileContent = styled.div`
  height: 100%;
  background: #f7f6f6;
  display: flex;
  justify-content: center;
`;
const ButtonContent = styled.div`
  padding: 0px 20px;
`;
const BackButoon = styled.div`
  width: max-content;
`;
const ImageContent = styled.div`
  flex: 1 1 0%;
  display: flex;
  align-items: center;
`;
const ImageFIle = styled.div`
  width: 100%;
  height: 50%;
`;
const ProfileIconContent = styled.div`
  flex: 0.01;
  font-weight: 600;
  margin-right: 12px;
`;
const ProfileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.8rem;
  width: 2.8rem;
  border-radius: 50%;
  background-color: ${({ background }) =>
    background ? background : "#7C9EC9"};
  color: rgb(255, 255, 255);
`;
const ProfileAvatar = styled(Avatar)`
  background-color: ${({ background }) =>
    background ? background : "#3f99a2"};
  height: 100%;
  width: 100%;
  .ant-avatar-string {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const ProfileText = styled.span`
  font-size: 0.875rem;
`;
const ProfileDateTime = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const ProfileContent = styled.div`
  display: flex;
  padding: 0px 20px;
  margin: 15px 0px 25px;
`;
const NameContent = styled.div`
  font-weight: 700;
  color: var(--text-primary);
  font-size: 0.85rem;
`;
const DateContent = styled.div`
  font-weight: 500;
  font-size: 0.85rem;
  color: #5e5e5e;
`;
const Document = styled.div`
  border: 2px solid #c6c6c6;
  flex: 1;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: ${({ padding }) => (padding ? "20px 0px 20px" : "20px 0px 0px")};
  display: flex;
  flex-direction: column;
`;

const MobPreviewAttachment = (props) => {
  return (
    <Content padding={props.isAttchmentUpload ? true : false}>
      <ButtonContent>
        <BackButoon onClick={() => props.handleModal(false)}>
          <ArrowLeftCircle />
        </BackButoon>
      </ButtonContent>
      {props.isAttchmentUpload && (
        <ImageContent>
          <ImageFIle>
            {props.fileFormat === "Photo" ? (
              <img
                src={props.image || ""}
                alt="image"
                height="100%"
                width="100%"
              />
            ) : (
              <FileContent>
                <FileClose>
                  <FileTextOutlined
                    style={{ fontSize: 50, color: "#7C9EC9" }}
                  />
                  <FileName title={props.fileName}>
                    {props.fileName?.toString().length >= 40
                      ? props.fileName?.toString().substr(0, 20) + "..."
                      : props.fileName?.toString()}
                  </FileName>
                </FileClose>
              </FileContent>
            )}
          </ImageFIle>
        </ImageContent>
      )}
      {props.isAttchmentUpload && (
        <div>
          <InputContent>
            <Input>
              <CommentInput {...props} />
            </Input>
          </InputContent>
        </div>
      )}
      {!props.isAttchmentUpload && (
        <ProfileContent>
          <ProfileIconContent>
            <ProfileIcon>
              <ProfileAvatar
                src={props.avatar}
                background={stringToHslColor(props.sentByPersonName || "A")}
              >
                <ProfileText>
                  {(props.sentByPersonName &&
                    props.sentByPersonName.charAt(0)) ||
                    "A"}
                </ProfileText>
              </ProfileAvatar>
            </ProfileIcon>
          </ProfileIconContent>
          <ProfileDateTime>
            <NameContent>
              {"Sent By" + " " + props.sentByPersonName}
            </NameContent>
            <DateContent>{props.dateTime}</DateContent>
          </ProfileDateTime>
        </ProfileContent>
      )}
      {!props.isAttchmentUpload && (
        <Document>
          {props.docUrl && (
            <DocumentViewer
              url={props.docUrl}
              style={{ width: "100%", height: "100%" }}
              viewerUrl="url"
            />
          )}
        </Document>
      )}
    </Content>
  );
};
export default MobPreviewAttachment;
