import { IonCol, IonRow, IonText, useIonToast } from "@ionic/react";
import styled from "styled-components";
import CommentsItem from "./commentsItem";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { GET_COMMENT_POSITION } from "../../graphQL/queries";
import { useLazyQuery } from "@apollo/client";

const Content = styled(IonCol)``;

const Header = styled.div`
  color: var(--text-primary);
  font-size: 1.125rem;
  font-weight: 300;
  display: flex;
  align-items: center;
`;

const Dot = styled.div`
  height: 10px;
  width: 10px;
  background: #ff4f4f;
  border-radius: 50%;
`;

const CommentsContent = styled(IonRow)`
  margin-top: 30px;
  min-height: 10px;
  max-height: 200px;
  overflow-y: auto;
`;

const CommentsContainer = styled(IonRow)`
  width: 100%;
`;
const NoDataText = styled(IonText)`
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--text-primary);
`;
const CommentsComponent = (props) => {
  const color = ["#7C9EC9", "#FD9393", "#FFC542", "#8BDDB3"];
  const history = useHistory();
  const [id, setId] = useState("");
  const [commentThread, setCommentThread] = useState("");
  const [taskId, setTaskId] = useState("");

  const [present] = useIonToast();

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  const [getCommentPosition, { data: commentPosition }] = useLazyQuery(
    GET_COMMENT_POSITION,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        commentId: "",
        limit: 10,
        nestedLimit: 10,
        taskId: "",
      },
      onError: (err) => {
        showToastMessage({
          color: "danger",
          message: err.message || "Something went wrong please try again!",
        });
      },
    }
  );

  useEffect(() => {
    if (commentPosition?.getCommentPosition) {
      if (commentPosition.getCommentPosition?.nestedOffset === null) {
        if (taskId) {
          history.push(
            `/assets/${taskId}/individual-asset?status=comment&tab=Task&id=${id}&commentOffset=${commentPosition.getCommentPosition?.offset}`
          );
        }
      } else {
        if (taskId) {
          history.push(
            `/assets/${taskId}/individual-asset?status=comment&tab=Task&id=${id}&commentReplyId=${commentThread}&commentOffset=${commentPosition.getCommentPosition?.offset}&replyOffset=${commentPosition.getCommentPosition?.nestedOffset}`
          );
        }
      }
    }
  }, [commentPosition]);

  const handleOnClickComment = (taskId, id, comment_thread) => {
    setTaskId(taskId);
    setId(id);
    setCommentThread(comment_thread);
    getCommentPosition({
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        commentId: id,
        limit: 10,
        nestedLimit: 10,
        taskId: taskId,
      },
    });
  };
  return (
    <Content>
      <Header>
        <span style={{ fontWeight: 700 }}>
          {props.count.toString().length === 1
            ? "0" + props.count
            : props.count}
        </span>
        &nbsp;
        <span>New Comments</span>&nbsp;&nbsp;
        <Dot />
      </Header>
      {props.commentsData.length > 0 ? (
        <CommentsContent>
          {props.commentsData.map((ele, id) => (
            <CommentsContainer key={id}>
              <CommentsItem
                user={ele.user}
                imageUrl={
                  ele.thread?.task_threads[0]?.related_task?.deliverables[0]
                    ?.versions[0]?.resource?.url || ""
                }
                comment={ele.comment_text}
                createdAt={ele.created_at}
                task_id={ele.thread?.task_threads[0]?.related_task?.id || ""}
                handleOnClickComment={(taskId) =>
                  handleOnClickComment(
                    taskId,
                    ele.id,
                    ele.id !== ele.thread?.comments[0]?.id
                      ? ele.comment_thread
                      : ""
                  )
                }
                profileBackground={color[id % color.length]}
                type={
                  ele.id === ele.thread?.comments[0]?.id
                    ? "Commented"
                    : ele.user?.id === localStorage.getItem("userId")
                    ? "Replied to your comment"
                    : "Replied"
                }
              />
            </CommentsContainer>
          ))}
        </CommentsContent>
      ) : (
        <div style={{ height: 200, padding: "10px 0px 0px" }}>
          <NoDataText>You have no new comments today.</NoDataText>
        </div>
      )}
    </Content>
  );
};

export default CommentsComponent;
