import React from "react";

const HomeIcon = ({
  width = "20",
  height = "20",
  viewBox = "0 0 20 20",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.56543 13.4462H12.4113"
        stroke="#929292"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 11.4275C2 6.73501 2.51167 7.06251 5.26583 4.50834C6.47083 3.53834 8.34583 1.66667 9.965 1.66667C11.5833 1.66667 13.4958 3.52917 14.7117 4.50834C17.4658 7.06251 17.9767 6.73501 17.9767 11.4275C17.9767 18.3333 16.3442 18.3333 9.98833 18.3333C3.6325 18.3333 2 18.3333 2 11.4275Z"
        stroke="#929292"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default HomeIcon;
