import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { IonButton, IonSpinner, IonIcon } from "@ionic/react";
import styled from "styled-components";

import { useToastMessage } from "./useToastMessage";
import { USER_LOGOUT } from "../graphQL/mutations";
import { power } from "ionicons/icons";
import { SmallText } from "./text";
import { MediumText } from "components/text";

const Button = styled(IonButton)`
  min-width: 108px;
  ${SmallText} {
    color: #fff;
  }
  ${({ primary }) =>
    !primary &&
    `  --background: none;
      --color: var(--text-primary);
      ${SmallText}{
        text-decoration: underline;
        color: var(--text-primary);
      }
      `}

  --box-shadow: none;
  --ripple-color: transparent;
  font-size: 0.75rem;
  ${({ text }) => text && "--padding-start:3px;"}
`;

const Logout = ({ text = false, primary = false, profilePage = false }) => {
  const { showToastMessage } = useToastMessage();
  const history = useHistory();
  const [update_client_user_session, { loading, client }] =
    useMutation(USER_LOGOUT);

  const logoutUser = async () => {
    try {
      const { data } = await update_client_user_session({
        variables: {
          token: localStorage.getItem("token"),
        },
      });
      if (data) {
        localStorage.clear();
        client.resetStore().then((data) => {
          history.push("/login");
        });
      }
    } catch (err) {
      showToastMessage({
        color: "danger",
        message: err.message || "Something went wrong please try again!",
      });
    }
  };

  return (
    <>
      {profilePage ? (
        <>
          <MediumText>
            {loading ? (
              <IonSpinner name="crescent" />
            ) : (
              <>
                Do you wish to&nbsp;
                <MediumText
                  as="u"
                  color="#1DA1F2"
                  fontWeight="600"
                  pointer
                  onClick={logoutUser}
                >
                  Logout&nbsp;
                </MediumText>
                ?
              </>
            )}
          </MediumText>
        </>
      ) : (
        <Button onClick={logoutUser} text={text} primary={primary} size="large">
          {loading ? (
            <IonSpinner name="crescent" />
          ) : (
            <>
              {!text && <IonIcon slot="start" icon={power} />}
              <SmallText fontWeight={500} capitalize>
                Logout
              </SmallText>
            </>
          )}
        </Button>
      )}
    </>
  );
};

export default Logout;
