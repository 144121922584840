import React from "react";

const CommentsIcon = ({
  width = "20",
  height = "21",
  viewBox = "0 0 20 21",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2821 10.6452H13.2896"
        stroke="#929292"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.94221 10.6452H9.94971"
        stroke="#929292"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.60042 10.6452H6.60792"
        stroke="#929292"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8919 16.1926C13.346 18.7397 9.57401 19.29 6.48794 17.8627C6.03236 17.6793 3.08363 18.4959 2.4438 17.8569C1.80397 17.217 2.62134 14.2678 2.43794 13.8122C1.01005 10.7265 1.56111 6.95322 4.10785 4.40697C7.35891 1.15464 12.6408 1.15464 15.8919 4.40697C19.1496 7.66433 19.1429 12.9411 15.8919 16.1926Z"
        stroke="#929292"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default CommentsIcon;
