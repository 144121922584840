import React from "react";

const WebHomeSelectedIcon = ({
  width = "18",
  height = "18",
  viewBox = "0 0 18 18",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.62995 0C7.60364 0 6.58503 0.579169 5.74754 1.1796C5.17818 1.5878 4.60794 2.07264 4.12739 2.4812L4.12738 2.48121C3.90005 2.6745 3.69279 2.85071 3.5152 2.99367C3.50379 3.00285 3.4927 3.01243 3.48196 3.02239C3.14204 3.33763 2.83614 3.60895 2.55834 3.8538L2.44575 3.95298L2.44571 3.95301C2.21335 4.1576 1.99743 4.34773 1.80305 4.52715C1.35151 4.94397 0.971416 5.34263 0.686012 5.84066C0.116082 6.83518 0 8.10086 0 10.4341C0 12.1818 0.101982 13.5477 0.37622 14.6031C0.654727 15.6748 1.12312 16.4717 1.87998 17.0149C2.6168 17.5438 3.55115 17.7759 4.64119 17.8883C5.72507 18 7.0514 18 8.62218 18H8.62229H8.6845H8.68461C10.2554 18 11.5817 18 12.6656 17.8883C13.7556 17.7759 14.69 17.5438 15.4268 17.0149C16.1837 16.4717 16.6521 15.6748 16.9306 14.6031C17.2048 13.5477 17.3068 12.1818 17.3068 10.4341C17.3068 8.10093 17.1909 6.83526 16.6213 5.84076C16.336 5.34271 15.956 4.94403 15.5045 4.52719C15.3099 4.34755 15.0938 4.1572 14.8611 3.95235L14.8611 3.95232L14.7493 3.85382C14.4715 3.60896 14.1656 3.33764 13.8257 3.02239C13.815 3.01246 13.8039 3.00291 13.7925 2.99376C13.6229 2.85718 13.4288 2.69355 13.217 2.51511C12.7207 2.09678 12.1277 1.59705 11.5283 1.1736C10.6803 0.574598 9.65345 0 8.62995 0ZM5.59116 12.4622C5.59116 12.1154 5.87229 11.8343 6.21907 11.8343H11.0874C11.4342 11.8343 11.7153 12.1154 11.7153 12.4622C11.7153 12.809 11.4342 13.0901 11.0874 13.0901H6.21907C5.87229 13.0901 5.59116 12.809 5.59116 12.4622Z"
        fill="#130F26"
      />
    </svg>
  );
};
export default WebHomeSelectedIcon;
