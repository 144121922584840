import React from "react";

const FilterIcon = ({ width = "14", height = "14", viewBox = "0 0 14 14" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Iconly/Light-Outline/Filter">
        <g id="Group 10">
          <path
            id="Fill 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.051 10.792H0.75C0.336 10.792 0 10.456 0 10.042C0 9.62799 0.336 9.29199 0.75 9.29199H7.051C7.465 9.29199 7.801 9.62799 7.801 10.042C7.801 10.456 7.465 10.792 7.051 10.792Z"
            fill="black"
          />
          <path
            id="Fill 3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.05 3.5H5.75C5.336 3.5 5 3.164 5 2.75C5 2.336 5.336 2 5.75 2H12.05C12.464 2 12.8 2.336 12.8 2.75C12.8 3.164 12.464 3.5 12.05 3.5Z"
            fill="black"
          />
          <g id="Group 7">
            <mask
              id="mask0_0:3554"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="7"
              height="7"
            >
              <path
                id="Clip 6"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0.000427246H6.2258V6.19203H0V0.000427246Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_0:3554)">
              <path
                id="Fill 5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.11276 1.5C2.22376 1.5 1.49976 2.216 1.49976 3.097C1.49976 3.977 2.22376 4.692 3.11276 4.692C4.00276 4.692 4.72576 3.977 4.72576 3.097C4.72576 2.216 4.00276 1.5 3.11276 1.5ZM3.11276 6.192C1.39676 6.192 -0.000244141 4.804 -0.000244141 3.097C-0.000244141 1.39 1.39676 0 3.11276 0C4.82976 0 6.22576 1.39 6.22576 3.097C6.22576 4.804 4.82976 6.192 3.11276 6.192Z"
                fill="black"
              />
            </g>
          </g>
          <path
            id="Fill 8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3877 8.5C9.49768 8.5 8.77368 9.216 8.77368 10.096C8.77368 10.977 9.49768 11.692 10.3877 11.692C11.2767 11.692 11.9997 10.977 11.9997 10.096C11.9997 9.216 11.2767 8.5 10.3877 8.5ZM10.3877 13.192C8.67068 13.192 7.27368 11.803 7.27368 10.096C7.27368 8.389 8.67068 7 10.3877 7C12.1037 7 13.4997 8.389 13.4997 10.096C13.4997 11.803 12.1037 13.192 10.3877 13.192Z"
            fill="black"
          />
        </g>
      </g>
    </svg>
  );
};
export default FilterIcon;
