import {
  IonRow,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonContent,
  IonLoading,
  IonText,
  IonModal,
} from "@ionic/react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import DocsItem from "./docsItem";
import { useQuery } from "@apollo/client";
import { GET_ATTACHMENT_DOCS } from "../../graphQL/queries";
import { useParams } from "react-router-dom";
import dateTimeToTimestamp from "../../lib/DateTimeToTimeStamp";
import timeStampToTimeElapsed from "../../lib/TimeStampToTimeElapsed";
import PreviewAttachment from "../assetsComponents/previewAttachment";
import useWindowDimensions from "../useWindowDimensions";
import MobPreviewAttachment from "../assetsComponents/mobPreviewAttachment";

const TimeStampHolder = styled(IonText)`
  display: ${({ display }) => display};
  // margin-top: 20px;
  width: 100%;
  padding-top: 12px;
  font-weight: 500;
  font-size: 0.8rem;
  color: #969697;
  margin-left: 16px;
  font-family: Montserrat, sans-serif;
`;

const NoDataText = styled(IonText)`
  font-family: Montserrat, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  color: #575757;
  padding-top: 20px;
`;
const NoDataContent = styled(IonRow)``;
const TotalCount = styled(IonText)`
  font-family: var(--font-family);
  display: none;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 16px 0px 0px 16px;
`;
const Content = styled(IonRow)`
  font-family: var(--font-family);
  @media screen and (max-width: 768px) {
    ${TotalCount} {
      display: block;
    }
  }
`;

const DocsComponent = ({ menu }) => {
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const { task_id } = useParams();
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [docsData, setDocsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [getMoreDocs, setGetMoreDocs] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [userName, setUserName] = useState("");
  const { width } = useWindowDimensions();
  const [dateTime, setDateTime] = useState("");
  const [avatar, setAvatar] = useState("");

  const { data, loading: docsLoading } = useQuery(GET_ATTACHMENT_DOCS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      offset: offset,
      limit: limit,
      taskId: task_id, //task id needs to send from route/...
    },
    onError: (err) => {
      console.log(err.message);
    },
  });

  useEffect(() => {
    if (data?.resource?.length > 0) {
      if (getMoreDocs) {
        setDocsData([...docsData, ...data.resource]);
        setTotalCount(data?.resource_aggregate?.aggregate?.count);
        setGetMoreDocs(false);
      } else {
        setDocsData(data.resource);
        setTotalCount(data?.resource_aggregate?.aggregate?.count);
      }
      setGetMoreDocs(false);
    }
  }, [data]);

  const loadMoreDocs = ($event) => {
    if (docsData.length < totalCount) {
      setGetMoreDocs(true);
      setOffset(offset + limit);
      $event.target.complete();
    } else {
      setDisableInfiniteScroll(true);
    }
  };

  const getTimeElapsedFormat = (dateTime) => {
    let dataDateTime = new Date(dateTime).toLocaleString();
    let previousTimeStamp = dateTimeToTimestamp({
      date: dataDateTime.substring(0, 10),
      time: dataDateTime.substring(12),
    });
    let currentDateTime = new Date().toLocaleString();
    let currentDate = currentDateTime.substring(0, 10);
    let currentTime = currentDateTime.substring(12);

    let currentTimeStamp = dateTimeToTimestamp({
      date: currentDate,
      time: currentTime,
    });
    let timeElapse = timeStampToTimeElapsed(
      currentTimeStamp,
      previousTimeStamp
    );
    if (timeElapse?.substring(0, 3).includes("h")) {
      return "Today";
    } else {
      return dataDateTime.substring(0, 10);
    }
  };

  const handlePreviewDoc = (fileUrl, fileName, userName, dateTime, avatar) => {
    if (fileName && fileUrl) {
      setFileUrl(fileUrl);
      setFileName(fileName);
      setUserName(userName);
      setIsModalVisible(true);
      setDateTime(dateTime);
      setAvatar(avatar);
    }
  };
  const handleModal = (state) => {
    setIsModalVisible(state);
    // props.handleCloseReply();
  };

  return (
    <Content>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={docsLoading}
        message={"Please wait..."}
      />

      <IonContent
        style={{
          width: "100%",
          overflowY: "auto",
          height: window.innerHeight - 200,
        }}
        scrollEvents
      >
        {docsData?.length > 0 ? (
          <>
            <TotalCount>{totalCount + " " + "Documents"}</TotalCount>
            {docsData?.map((ele, id) => (
              <>
                <TimeStampHolder
                  display={
                    id === 0
                      ? "block"
                      : docsData[id - 1] &&
                        new Date(docsData[id - 1]?.created_at)
                          .toLocaleString()
                          .substring(0, 2) >
                          new Date(docsData[id]?.created_at)
                            .toLocaleString()
                            .substring(0, 2)
                      ? "block"
                      : "none"
                  }
                  border_top={id !== 0 ? "1px solid #C4C4C4" : ""}
                >
                  {getTimeElapsedFormat(ele.created_at)}
                </TimeStampHolder>
                <DocsItem
                  id={id}
                  userName={ele.creator?.name || ""}
                  docsName={ele.name || ""}
                  fileUrl={ele.url ? ele.url : ""}
                  handlePreviewDoc={(fileUrl, fileName, userName) =>
                    handlePreviewDoc(
                      fileUrl,
                      fileName,
                      userName,
                      getTimeElapsedFormat(ele.created_at),
                      ele.creator?.avatar || ""
                    )
                  }
                />
              </>
            ))}
          </>
        ) : (
          <NoDataContent>
            <NoDataText>{`No ${menu} Found`}</NoDataText>
          </NoDataContent>
        )}
        <IonInfiniteScroll
          threshold="100px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e) => loadMoreDocs(e)}
        >
          <IonInfiniteScrollContent loadingText="Loading docs..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
      {isModalVisible && width > 768 && (
        <PreviewAttachment
          isModalVisible={isModalVisible}
          handleModal={handleModal}
          fileName={fileName}
          docUrl={fileUrl}
          onlyDoc={true}
          replyToPersonName={userName}
        />
      )}
      {width <= 768 && isModalVisible && (
        <IonModal isOpen={isModalVisible}>
          <MobPreviewAttachment
            isAttchmentUpload={false}
            dateTime={dateTime}
            docUrl={fileUrl}
            sentByPersonName={userName}
            avatar={avatar}
            handleModal={handleModal}
          />
        </IonModal>
      )}
    </Content>
  );
};

export default DocsComponent;
