import { withRouter } from "react-router-dom";
import PageLayout from "../../components/pageLayout";
import styled from "styled-components";
import SidebarMain from "../../components/message/sidebarMain";
import MessageSection from "../../components/message/messageSection";

const Wrapper = styled.div`
  display: flex;
  height: -webkit-fill-available;
  margin: 2em 0 2em 0;
  border: 1px solid #aaaaaa;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  @media (max-width: 785px) {
    margin: 1em;
  }
`;
const Messages = () => {
  return (
    <PageLayout title="Messages">
      <Wrapper>
        <SidebarMain />
        <MessageSection />
      </Wrapper>
    </PageLayout>
  );
};
export default withRouter(Messages);
