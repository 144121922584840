import { gql } from "@apollo/client";

export const USER_PASSWORD_LOGIN = gql`
  mutation login($email: String!, $password: String!, $rememberMe: Boolean) {
    login(email: $email, password: $password, rememberMe: $rememberMe) {
      token
      message
      user_id
    }
  }
`;

export const USER_PHONE_LOGIN = gql`
  mutation sendOtp($phoneNumber: String!) {
    sendOtp(phoneNumber: $phoneNumber) {
      validUpto
    }
  }
`;

export const VERIFY_USER_OTP = gql`
  mutation verifyOtp($otp: String!, $phoneNumber: String!) {
    verifyOtp(otp: $otp, phoneNumber: $phoneNumber) {
      token
      user_id
    }
  }
`;

export const USER_LOGOUT = gql`
  mutation update_client_user_session($token: String!) {
    update_client_user_session(
      _set: { expired_at: "now()" }
      where: { token: { _eq: $token } }
    ) {
      affected_rows
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $newPassword: String! = ""
    $resetToken: String! = ""
  ) {
    changePassword(newPassword: $newPassword, resetToken: $resetToken) {
      code
      message
    }
  }
`;

export const LOGGED_IN_USER_CHANGE_PASSWORD = gql`
  mutation changePassword(
    $currentPassword: String! = ""
    $newPassword: String! = ""
    $resetToken: String! = ""
  ) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
      resetToken: $resetToken
    ) {
      code
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userId: uuid!, $userSetInput: user_set_input) {
    update_user_by_pk(pk_columns: { id: $userId }, _set: $userSetInput) {
      email
      name
      phone
      id
      avatar
    }
  }
`;

export const UPLOAD_PROFILE_PIC = gql`
  mutation uploadProfilePic($fileName: String!, $isProfilePic: Boolean = true) {
    fileUpload(fileName: $fileName, isProfilePic: $isProfilePic) {
      signedUrl
      resourceId
      url
      fileName
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($userId: uuid!, $deletedAt: timestamptz) {
    update_client_user_map(
      where: { user_id: { _eq: $userId } }
      _set: { deleted_at: $deletedAt }
    ) {
      affected_rows
    }
  }
`;

export const ADD_ASSET_COMMENT_THREAD = gql`
  mutation addThread(
    $status: String!
    $visibility: String!
    $comment_text: String!
    $comment_user: uuid!
    $task: uuid!
  ) {
    insert_thread_one(
      object: {
        status: $status
        visibility: $visibility
        comments: {
          data: { comment_text: $comment_text, comment_user: $comment_user }
        }
        task_threads: { data: { task: $task } }
        visible_to_client: true
      }
    ) {
      created_at
      id
      resolved_by
      status
      visibility
      comments {
        comment_text
        comment_user
        comment_thread
        created_at
        id
        user {
          avatar
          id
          name
        }
      }
    }
  }
`;

export const REPLY_TO_COMMENT = gql`
  mutation addReplyToThread(
    $comment_text: String!
    $comment_user: uuid!
    $comment_thread: uuid!
  ) {
    insert_comment_one(
      object: {
        comment_text: $comment_text
        comment_user: $comment_user
        comment_thread: $comment_thread
      }
    ) {
      comment_text
      comment_thread
      comment_user
      created_at
      id
      user {
        avatar
        id
        name
      }
    }
  }
`;

export const RESOLVE_COMMENT = gql`
  mutation resolvedThread(
    $thread_id: uuid!
    $status: String!
    $resolved_by: uuid
  ) {
    update_thread(
      where: { id: { _eq: $thread_id } }
      _set: { status: $status, resolved_by: $resolved_by }
    ) {
      affected_rows
      returning {
        id
        resolved_by
        status
        visibility
        created_at
      }
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($fileName: String!) {
    fileUpload(fileName: $fileName) {
      signedUrl
      fileName
      resourceId
      url
    }
  }
`;
export const APPROVE_ASSET = gql`
  mutation approveAsset(
    $taskId: uuid!
    $status: String
    $clientStatus: String
    $feedbackMessage: String
    $userId: uuid!
  ) {
    update_task(
      where: { id: { _eq: $taskId } }
      _set: {
        approver_id: $userId
        last_updator_id: $userId
        status: $status
        client_status: $clientStatus
      }
    ) {
      affected_rows
      returning {
        creator_id
        created_at
        approver_id
        delete_status
        deadline
        description
        id
        name
        phase
        last_updator_id
        project_id
        rank
        stage
        status
        subtype
      }
    }
    insert_task_feedback(
      objects: {
        user_id: $userId
        task_id: $taskId
        feedback: $feedbackMessage
        feedback_type: "APPROVE"
      }
    ) {
      affected_rows
      returning {
        created_at
        feedback
        id
        task_id
        user_id
      }
    }
  }
`;

export const REJECT_ASSET = gql`
  mutation rejectAsset(
    $taskId: uuid!
    $approverId: uuid
    $status: String
    $clientStatus: String
    $feedbackMessage: String
    $userId: uuid!
  ) {
    update_task(
      where: { id: { _eq: $taskId } }
      _set: {
        approver_id: $approverId
        last_updator_id: $userId
        status: $status
        client_status: $clientStatus
      }
    ) {
      returning {
        creator_id
        created_at
        approver_id
        delete_status
        deadline
        description
        id
        name
        phase
        last_updator_id
        project_id
        rank
        stage
        status
        subtype
      }
      affected_rows
    }
    insert_task_feedback(
      objects: {
        user_id: $userId
        task_id: $taskId
        feedback: $feedbackMessage
        feedback_type: "REJECT"
      }
    ) {
      affected_rows
      returning {
        created_at
        feedback
        id
        task_id
        user_id
      }
    }
  }
`;

export const UPDATE_ASSET_STATUS = gql`
  mutation updateAssetStatus($taskId: uuid!, $status: String, $userId: uuid!) {
    update_task(
      where: { id: { _eq: $taskId } }
      _set: { approver_id: $userId, last_updator_id: $userId, status: $status }
    ) {
      affected_rows
    }
  }
`;

export const LINK_ATTACHMENT_AND_COMMENT = gql`
  mutation linkAttachmentAndComment($resource_id: uuid!, $comment_id: uuid!) {
    update_resource(
      where: { id: { _eq: $resource_id } }
      _set: { comment_id: $comment_id }
    ) {
      affected_rows
    }
  }
`;

export const READ_COMMENTS = gql`
  mutation readComment($commentId: String!, $taskId: String!) {
    readComments(commentId: $commentId, taskId: $taskId) {
      status
    }
  }
`;

export const READ_FILE_COMMENTS = gql`
  mutation readFileComments(
    $commentCreatedAt: String! = ""
    $deliverableId: String! = ""
    $taskId: String! = ""
  ) {
    readFileComments(
      taskId: $taskId
      deliverableId: $deliverableId
      commentCreatedAt: $commentCreatedAt
    ) {
      message
    }
  }
`;

export const PIN_COMMENT = gql`
  mutation pinComment($commentId: String!, $taskId: String!) {
    pinComment(commentId: $commentId, taskId: $taskId) {
      status
    }
  }
`;

export const PIN_FILE_COMMENT = gql`
  mutation pinFileComment(
    $commentId: uuid
    $deliverableVersionId: uuid
    $taskId: uuid
    $userId: uuid
  ) {
    insert_pinned_comment_one(
      object: {
        comment_id: $commentId
        deliverable_version_id: $deliverableVersionId
        task_id: $taskId
        user_id: $userId
      }
      on_conflict: {
        constraint: pinned_comment_comment_id_user_id_task_id_deliverable_version_i
        update_columns: []
      }
    ) {
      id
      comment_id
      deliverable_version_id
      task_id
    }
  }
`;

export const UNPIN_COMMENT = gql`
  mutation unPinComment($where: pinned_comment_bool_exp!) {
    delete_pinned_comment(where: $where) {
      affected_rows
    }
  }
`;
export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($emailId: String!) {
    forgotPassword(emailId: $emailId) {
      message
    }
  }
`;

export const ADD_FILE_COMMENT = gql`
  mutation addFileComment(
    $commentText: String
    $commentUser: uuid
    $coordinates: jsonb
    $file: uuid
  ) {
    insert_file_thread_one(
      object: {
        coordinates: $coordinates
        file: $file
        file_thread: {
          data: {
            comments: {
              data: { comment_text: $commentText, comment_user: $commentUser }
            }
            visible_to_client: true
            visibility: "PUBLIC"
          }
        }
      }
    ) {
      id
      file_thread {
        id
        visibility
        visible_to_client
        comments {
          comment_text
          comment_thread
          comment_user
          created_at
          id
          thread {
            file_threads {
              coordinates
              file
              id
              thread
            }
          }
          user {
            avatar
            email
            id
            name
            phone
          }
        }
        comments_aggregate {
          aggregate {
            count
          }
        }
      }
      coordinates
      file
      created_at
    }
  }
`;

export const SAVE_USER_PUSH_NOTIFICATION_TOKEN = gql`
  mutation upsertUserPushNotificationToken(
    $platform: String
    $token: String
    $userId: uuid!
  ) {
    insert_user_push_notification_token_one(
      object: { platform: $platform, token: $token, user_id: $userId }
      on_conflict: {
        constraint: user_push_notification_token_user_id_token_platform_key
        update_columns: token
        where: { platform: { _eq: $platform }, user_id: { _eq: $userId } }
      }
    ) {
      platform
      token
      user_id
      id
    }
  }
`;

export const READ_ASSETS = gql`
  mutation readAssets($taskId: String!) {
    readAssets(taskId: $taskId) {
      status
    }
  }
`;

export const APPROVE_OR_REJECT_VERSION = gql`
  mutation approveOrRejectVersion(
    $userId: uuid
    $clientStatus: String
    $deliverableVersionId: uuid!
  ) {
    update_deliverable_version_by_pk(
      pk_columns: { id: $deliverableVersionId }
      _set: {
        client_status: $clientStatus
        approved_by: $userId
        last_updator_id: $userId
      }
    ) {
      client_status
      deliverable_id
      id
      last_updator_id
      resource_id
      status
      version
      approved_by
    }
  }
`;

export const APPROVE_OR_REJECT_ALL = gql`
  mutation approveOrRejectAll(
    $feedbackMessage: String!
    $type: String!
    $taskId: String!
    $userId: String!
  ) {
    approveOrRejectAll(
      feedbackMessage: $feedbackMessage
      feedbackType: $type
      operation: $type
      taskId: $taskId
      userId: $userId
    ) {
      data
      message
    }
  }
`;
