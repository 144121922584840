import React, { useState, useEffect, useLayoutEffect } from "react";

import { Drawer, Modal } from "antd";
import { IonCol } from "@ionic/react";
import { useHistory } from "react-router";

import HeaderComponent from "./headerComponent";
import Projects from "./ProjectDrawer";
import Notifications from "./notificationDrawer";
import SwitchProjectConfirmation from "./switchProjectConfirmation";
import { useIonToast, IonLoading } from "@ionic/react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_PROJECTS_LIST, GET_NOTIFICATIONS } from "../../graphQL/queries";

import styled from "styled-components";

const NotificationsDrawer = styled(Drawer)`
  .ant-drawer-body {
    // padding: 60px 20px 40px 30px;
    padding: ${({ padding }) => padding || ""};
  }
`;
const ProjectsDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: ${({ padding }) => padding || ""};
  }
`;

const Confirmation = styled(Modal)`
  .ant-modal-content {
    border-radius: 6px;
  }
`;

const HeaderContent = styled(IonCol)`
  padding: 0px;
  @media screen and (max-width: 768px) {
  }
`;

const HeaderMain = (props) => {
  const [present] = useIonToast();
  const history = useHistory();

  const [visibleProject, setVisibleProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState(
    localStorage.getItem("project")
  );
  const [projectDrawerPlacement, setProjectDrawerPlacement] =
    useState("bottom");

  const [projects, setProjects] = useState([]);
  const [visibleNotification, setVisibleNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notifDrawerWidth, setNotifDrawerWidth] = useState(500);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(20);
  const [count, setCount] = useState(0);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [getMoreNotifications, setGetMoreNotifications] = useState(false);

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  const handlerProjectDrawer = (visible) => {
    setVisibleProject(visible);
    setSelectedProject(localStorage.getItem("project"));
  };

  const handleSwitchProject = (project) => {
    setVisibleProject(false);
    handleModal(true);
  };

  const handleSelector = (project) => {
    setSelectedProject(project);
  };

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 768) {
        setProjectDrawerPlacement("right");
        setNotifDrawerWidth(500);
      } else {
        setProjectDrawerPlacement("bottom");
        setNotifDrawerWidth(380);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const clientId = localStorage.getItem("clientId");

  const { data: projectData, error } = useQuery(GET_PROJECTS_LIST, {
    variables: {
      client_id: clientId,
      delete_status: "DELETED",
    },
    onError: (err) => {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    },
  });

  useEffect(() => {
    setProjects(projectData?.project || []);
  }, [projectData, error]);

  const [
    getNotifications,
    {
      data: notificationData,
      loading: notificationLoading,
      fetchMore: fetchMoreNotifications,
    },
  ] = useLazyQuery(GET_NOTIFICATIONS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onError: (err) => {
      showToastMessage({
        color: "danger",
        message: err.message || "Something went wrong please try again!",
      });
    },
  });

  useEffect(() => {
    if (notificationData?.user_notification?.length > 0) {
      if (getMoreNotifications) {
        setNotifications([
          ...notifications,
          ...notificationData.user_notification,
        ]);
      } else {
        setNotifications(notificationData.user_notification);
      }
      setCount(notificationData?.user_notification_aggregate?.aggregate?.count);
      setGetMoreNotifications(false);
    } else {
    }
  }, [notificationData || visibleNotification]);

  const loadMoreNotifications = () => {
    if (notifications.length < count) {
      setGetMoreNotifications(true);
      fetchMoreNotifications({
        query: GET_NOTIFICATIONS,
        variables: {
          offset: offset + limit,
          limit: limit,
          userId: localStorage.getItem("userId"),
          projectId: localStorage.getItem("projectId"),
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult;
        },
      });
      setOffset(offset + limit);
    } else {
      setDisableInfiniteScroll(true);
    }
  };

  const handleNotificationDrawer = (visible) => {
    if (visible) {
      getNotifications({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {
          offset: offset,
          limit: limit,
          userId: localStorage.getItem("userId"),
          projectId: localStorage.getItem("projectId"),
        },
      });
    }
    if (!visible) {
      setOffset(0);
      setDisableInfiniteScroll(false);
      setCount(0);
      setGetMoreNotifications(false);
    }
    setVisibleNotification(visible);
  };

  const handleModal = (visible) => {
    setIsModalVisible(visible);
  };

  const handleSwitchProjectConfirmation = (project) => {
    let selectedProjectData = projects.find(
      (eachProject) => eachProject.name === project
    );
    localStorage.setItem("project", project);
    localStorage.setItem("projectId", selectedProjectData?.id);
    setIsModalVisible(false);
    !history.location.pathname.includes("/home") && history.push("/home");
    window.location.reload();
  };
  return (
    <HeaderContent>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={notificationLoading}
        message={"Please wait..."}
      />
      <HeaderComponent
        projectName={localStorage.getItem("project")}
        handlerProjectDrawer={handlerProjectDrawer}
        handleNotificationDrawer={handleNotificationDrawer}
        newNotification={true}
        arrowCircle={props.arrowCircle}
        onClickBack={props.onClickBack}
      />
      <ProjectsDrawer
        placement={projectDrawerPlacement}
        closable={false}
        onClose={() => handlerProjectDrawer(false)}
        visible={visibleProject}
        key={projectDrawerPlacement}
        height="70%"
        width="500"
        padding={
          projectDrawerPlacement === "bottom"
            ? "30px 0px 24px 0px"
            : "50px 50px 40px 50px"
        }
      >
        <Projects
          projects={projects}
          selectedProject={selectedProject}
          handlerProjectDrawer={handlerProjectDrawer}
          handleSelector={handleSelector}
          handleSwitchProject={handleSwitchProject}
        />
      </ProjectsDrawer>
      <Confirmation
        style={{ top: "35%" }}
        visible={isModalVisible}
        footer={null}
        closable={false}
      >
        <SwitchProjectConfirmation
          handleModal={handleModal}
          handleSwitchProjectConfirmation={handleSwitchProjectConfirmation}
          selectedProject={selectedProject}
          existingProject={localStorage.getItem("project")}
        />
      </Confirmation>
      {visibleNotification && (
        <NotificationsDrawer
          placement={"right"}
          closable={false}
          onClose={() => handleNotificationDrawer(false)}
          visible={visibleNotification}
          width={notifDrawerWidth}
          padding={
            window.innerWidth <= 768
              ? "30px 0px 24px 0px"
              : "50px 50px 40px 50px"
          }
        >
          <Notifications
            notifications={notifications}
            selectedProject={selectedProject}
            handleNotificationDrawer={handleNotificationDrawer}
            handleSelector={handleSelector}
            handleSwitchProject={handleSwitchProject}
            loadMoreNotifications={loadMoreNotifications}
            disableInfiniteScroll={disableInfiniteScroll}
            notificationLoading={getMoreNotifications}
          />
        </NotificationsDrawer>
      )}
    </HeaderContent>
  );
};
export default HeaderMain;
