import React from "react";

const CompareIconFilled = ({
  height = "18",
  width = "18",
  viewBox = "0 0 18 18",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" rx="2" fill="#130F26" />
      <rect
        x="4.05078"
        y="5.2085"
        width="3.57212"
        height="7.52331"
        rx="1"
        fill="white"
      />
      <rect
        x="9.93115"
        y="2.70068"
        width="3.57212"
        height="11.7029"
        rx="1"
        fill="white"
      />
    </svg>
  );
};
export default CompareIconFilled;
