import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { IonPage, IonLoading } from "@ionic/react";

import WelcomePageComponent from "components/welcomePageComponent";
import { useSession } from "components/sessionValidator";
import useWindowDimensions from "components/useWindowDimensions";
const WelcomePage = ({ history }) => {
  const [session, isLoading] = useSession();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (session && !isLoading) {
      history.push("/home");
    }
  }, [session, isLoading]);

  useEffect(() => {
    if (width > 768) {
      history.push("/login");
    }
  }, [width]);
  return (
    <IonPage>
      <IonLoading
        isOpen={isLoading || session}
        spinner="bubbles"
        message="Please wait..."
      />
      <WelcomePageComponent
        onClick={() => history.push("/login")}
        hideLoginText={isLoading || session}
      />
    </IonPage>
  );
};
export default withRouter(WelcomePage);
