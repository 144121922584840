import styled from "styled-components";
import { MessageOutlined, MessageFilled } from "@ant-design/icons";
import { Popover, Badge } from "antd";
import Send from "../../assests/icons/send";
import Clip from "../../assests/icons/clip";
import Flex from "../flex";
import Download from "../../assests/icons/download";
import Compare from "../../assests/icons/compare";
import CloseCircle from "../../assests/icons/closeCircle";
import { Link, useHistory } from "react-router-dom";
import { useRouterQuery } from "../../lib/useRouterQuery";

const SubWrapper = styled.div`
  flex: 1;
`;

const CloseIconWrapper = styled.div`
  padding: 2vh 0px;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  color: var(--text-primary);
`;

const SideBarActionsWrapper = styled(Flex)`
  background: #f9f9f9;
  width: 134px;
  border-right: ${({ isVisible }) =>
    isVisible ? "1.5px solid #e6e6e6" : "1.5px solid #F9F9F9"};
  padding: 16.5px 0;

  @media screen and (max-width: 768px) {
    padding: 16.5px 30px;
  }
`;

const SubTitle = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  color: var(--text-primary);
  font-style: normal;
  font-size: 11px;
  margin: ${({ margin }) => margin || 0};
`;

const IconWrapper = styled(Link)`
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  color: var(--text-primary);
  display: block;
  &:hover {
    color: var(--text-primary);
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.7)};
  }

  padding: 2vh 0px;
  ${SubTitle} {
    font-weight: ${({ isSelected }) => (isSelected ? "bold" : "600")};
    font-size: 11px;
    text-decoration: ${({ isSelected }) =>
      isSelected ? "underline" : "unset"};
  }
`;

const ShareItem = styled(Flex)`
  font-family: Montserrat;
  font-size: 0.75rem;
  color: var(--text-primary);
  cursor: pointer;
  padding: 10px 40px 10px 0px;
  &:not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
  }
`;
const ShareIconWrapper = styled(Flex)`
  margin-right: 8px;
  font-size: 20px;
  color: #64b161;
`;

const SideBarActions = ({
  onClick = undefined,
  onCloseSideBar = undefined,
  isVisible = false,
  onDownload = undefined,
}) => {
  const query = useRouterQuery();
  const status = query.get("status");
  const history = useHistory();
  const closeSideBar = () => {
    if (query.has("status")) {
      query.delete("status");
      history.replace();
    }
    if (onCloseSideBar) onCloseSideBar();
  };

  return (
    <SideBarActionsWrapper column alignCenter isVisible={isVisible}>
      <SubWrapper>
        <IconWrapper
          to={`?status=comment&tab=Task`}
          isSelected={status === "comment"}
          onClick={() => onClick("comment")}
        >
          <Badge dot offset={[-2, 4]} style={{ boxShadow: "none" }}>
            {status === "comment" || status === "pinned_comments" ? (
              <MessageFilled style={{ fontSize: "18px", color: "#130F26" }} />
            ) : (
              <MessageOutlined style={{ fontSize: "18px", color: "#130F26" }} />
            )}
          </Badge>

          <SubTitle>Comments</SubTitle>
        </IconWrapper>
        <IconWrapper
          to="?status=attachment"
          isSelected={status === "attachment"}
          onClick={() => onClick("attachment")}
        >
          <Clip />
          <SubTitle>Attachment</SubTitle>
        </IconWrapper>
        <IconWrapper
          to="?status=compare"
          isSelected={status === "compare"}
          onClick={() => onClick("compare")}
        >
          <Compare color={(status === "compare" && "#130F26") || "none"} />
          <SubTitle>Compare</SubTitle>
        </IconWrapper>
      </SubWrapper>

      <Flex column>
        <IconWrapper
          to="?status=share"
          isSelected={status === "share"}
          onClick={() => onClick("share")}
        >
          <Send fill={(status === "share" && "#130F26") || "none"} />
          <SubTitle>Share</SubTitle>
        </IconWrapper>
        <IconWrapper as="div" onClick={onDownload}>
          <Download />
          <SubTitle>Download</SubTitle>
        </IconWrapper>
        {isVisible && (
          <CloseIconWrapper onClick={closeSideBar}>
            <CloseCircle />
          </CloseIconWrapper>
        )}
      </Flex>
    </SideBarActionsWrapper>
  );
};

export default SideBarActions;
