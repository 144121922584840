import React from "react";

const Close = ({
  height = "12",
  width = "12",
  viewBox = "0 0 12 12",
  color = "none",
  stroke = "white",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4386 1.5032L1.45361 10.4882"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.45 10.5L1.44995 1.5"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default Close;
