import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useIonToast } from "@ionic/react";

import LoginLayout from "../../components/loginLayout";
import LoginWithPhoneComponent from "../../components/loginWithPhoneComponent";
import { USER_PHONE_LOGIN } from "../../graphQL/mutations";

const LoginWithPhoneNumber = () => {
  const [sendOtp, { loading }] = useMutation(USER_PHONE_LOGIN);

  const [present] = useIonToast();
  const [phone, setPhone] = useState({
    value: localStorage.getItem("phoneNumber") || null,
    error: null,
  });
  const [phoneCodeValue, setPhoneCodeValue] = useState(
    localStorage.getItem("phoneCode") || "+91"
  );
  const history = useHistory();

  const updatePhoneInput = (value) => {
    setPhone({ value, error: null });
  };

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  const savePhoneDetails = async () => {
    try {
      const userPhoneNumber = (phoneCodeValue + " " + phone.value).replace(
        /\+/g,
        ""
      );
      const { data } = await sendOtp({
        variables: {
          phoneNumber: userPhoneNumber,
        },
      });
      if (data.error) {
        showToastMessage({
          color: "danger",
          message: "Please request for new otp after 2hrs!",
        });
      } else if (data) {
        history.push("/login/otp");
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    }
  };

  const onSubmitPhoneDetails = () => {
    if (!phone.value) {
      setPhone({ ...phone, error: "Please enter a valid mobile number" });
      return;
    } else if (!(phone.value.length >= 4 && phone.value.length <= 12)) {
      setPhone({ ...phone, error: "Please enter a valid mobile number" });
      return;
    }
    savePhoneDetails();
    localStorage.setItem("phoneNumber", phone.value);
    localStorage.setItem("phoneCode", phoneCodeValue);
  };

  const onPhoneCodeSelect = (phoneCode) => {
    setPhoneCodeValue(phoneCode);
  };

  return (
    <LoginLayout showBackButton>
      <LoginWithPhoneComponent
        inputLabel="Kindly share your phone number to get OTP"
        onInputPhoneNumber={updatePhoneInput}
        value={phone.value}
        error={phone.error}
        loading={loading}
        onSubmit={onSubmitPhoneDetails}
        onPhoneCodeSelect={onPhoneCodeSelect}
        phoneCodeValue={phoneCodeValue}
        buttonText="Get OTP"
      />
    </LoginLayout>
  );
};

export default LoginWithPhoneNumber;
