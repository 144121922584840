import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ImageCarousel from "../imageCarousel";
import AssetsActions from "./assetsActions";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  margin: 0 auto;
  margin-top: 20px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 40px;
  &:last-child {
    border-bottom: none;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 0.938rem;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  color: var(--text-primary);
  margin-bottom: 25px;
  @media screen and (max-width: 768px) {
    padding: 0 4%;
  }
`;

const AssetsCard = ({
  projectDetails,
  hideReview = false,
  path = "individual-asset",
}) => {
  const [currentVersionId, setCurrentVersionId] = useState("");

  useEffect(() => {
    if (projectDetails?.imageURL?.length > 0) {
      setCurrentVersionId(projectDetails?.imageURL[0]?.currentVersionId);
    }
  }, [projectDetails]);
  const onChangeAssetVersion = (versionId) => {
    setCurrentVersionId(versionId);
  };
  return (
    <Wrapper>
      <Title>{projectDetails.name || "--"}</Title>
      <ImageCarousel
        {...projectDetails}
        hideReview={hideReview}
        onChangeAssetVersion={onChangeAssetVersion}
      />
      <AssetsActions
        taskId={projectDetails.id || ""}
        projectDetails={projectDetails}
        currentVersionId={currentVersionId}
        path={path}
      />
    </Wrapper>
  );
};

export default AssetsCard;
