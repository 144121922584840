import React from "react";

const EditIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9985 5.78124L5.92543 14.8994C5.45358 15.3587 4.83343 15.6154 4.18632 15.6154H1.01815C0.842894 15.6154 0.681115 15.5478 0.559781 15.4263C0.438448 15.3047 0.384521 15.1426 0.384521 14.967L0.465411 11.7655C0.478892 11.1306 0.735041 10.5362 1.17993 10.0904L7.61063 3.6469C7.71848 3.53883 7.90722 3.53883 8.01507 3.6469L10.2705 5.89336C10.4188 6.0406 10.6345 6.13651 10.8637 6.13651C11.3625 6.13651 11.7535 5.73125 11.7535 5.24495C11.7535 5.0018 11.6591 4.78566 11.5108 4.62356C11.4704 4.56953 9.32278 2.43114 9.32278 2.43114C9.18797 2.29606 9.18797 2.06641 9.32278 1.93133L10.226 1.01276C11.0619 0.175232 12.4101 0.175232 13.2459 1.01276L14.9985 2.76885C15.8209 3.59287 15.8209 4.94371 14.9985 5.78124Z"
        fill="currentColor	"
      />
    </svg>
  );
};

export default EditIcon;
