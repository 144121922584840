import React from "react";
import { Avatar } from "antd";
import styled from "styled-components";

import { stringToHslColor } from "../lib/stringToHslColor";

const AvatarWrapper = styled.div`
  border-radius: 50%;
  width: fit-content;
  cursor: pointer;
  transition: 0.5s all;
  ${({ bordered }) =>
    bordered &&
    ` 
    border: 3px solid #ffffff;
    box-shadow: 0px 0px 20px 3px rgba(104, 103, 103, 0.15);
    &:hover {
  box-shadow: 0px 0px 14px 0px #848181db;
}`}
`;

const Text = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${({ textSize }) => textSize || "0.875rem"};
`;

const UserAvatar = ({
  onClick = undefined,
  size = 40,
  name = "",
  avatar = "",
  bordered = false,
  textSize = "",
}) => {
  return (
    <AvatarWrapper onClick={onClick} bordered={bordered} for="upload">
      <Avatar
        src={avatar}
        size={size}
        style={{
          backgroundColor: stringToHslColor(name || "A"),
        }}
      >
        <Text textSize={textSize}>{(name && name?.charAt(0)) || "A"}</Text>
      </Avatar>
    </AvatarWrapper>
  );
};

export default UserAvatar;
