import styled from "styled-components";
import { InboxOutlined } from "@ant-design/icons";

const NoData = styled.div`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(19, 15, 38);
  font-weight: 500;
  opacity: 0.7;
  font-size: 14px;
`;
const NoDataMessage = ({ fontSize, message }) => {
  return (
    <NoData>
      <span>
        <InboxOutlined style={{ fontSize: fontSize || "4em" }} />
      </span>
      <span>{message}</span>
    </NoData>
  );
};

export default NoDataMessage;
