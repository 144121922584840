import { useIonToast, IonText, IonCol } from "@ionic/react";
import { withRouter } from "react-router-dom";
import PageLayout from "../../components/pageLayout";
import styled from "styled-components";
import { useEffect, useState } from "react";
import WebViewComponents from "../../components/homePageComponents/webViewComponents";
import MobViewComponents from "../../components/homePageComponents/MobViewComponents";
import {
  GET_NEW_ASSETS,
  GET_NEW_COMMENTS,
  GET_ANALYTICS,
} from "../../graphQL/queries";
import { useQuery } from "@apollo/client";

const DateShower = styled(IonText)`
  background: rgb(19, 15, 38);
  max-width: max-content;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  border-radius: 5px;
  font-weight: 700;
  padding: 0px 8px;
`;
const Wish = styled(IonText)`
  font-weight: 600;
  color: rgb(19, 15, 38);
  font-size: 1.25rem;
  margin-top: 8px;
`;

const PageContainer = styled(IonCol)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  @media screen and (max-width: 768px) {
    ${DateShower} {
      display: none;
    }
  }
`;

const Home = () => {
  const [present] = useIonToast();
  const [assetsData, setAssetsData] = useState([]);
  const [assetsCount, setAssetsCount] = useState(0);
  const [todaysDate, setTodaysDate] = useState("");
  const [assetsOffset] = useState(0);
  const [assetsLimit] = useState(10);
  const [commentsData, setCommentsData] = useState([]);
  const [commentsCount, setCommentsCount] = useState(0);
  const [analyticsDataObj, setAnalyticsDataObj] = useState();

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  const { data: analyticsData } = useQuery(GET_ANALYTICS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      projectId: localStorage.getItem("projectId"),
    },
    onError: (err) => {
      showToastMessage({
        color: "danger",
        message: err.message || "Something went wrong please try again!",
      });
    },
  });

  useEffect(() => {
    if (analyticsData?.getAnalyticsData) {
      setAnalyticsDataObj(analyticsData.getAnalyticsData);
    }
  }, [analyticsData]);

  const { data: newComments } = useQuery(GET_NEW_COMMENTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      limit: 10,
      offset: 0,
      projectId: localStorage.getItem("projectId"),
      userId: localStorage.getItem("userId"),
    },
    onError: (err) => {
      showToastMessage({
        color: "danger",
        message: err.message || "Something went wrong please try again!",
      });
    },
  });

  useEffect(() => {
    if (newComments?.comment?.length > 0) {
      setCommentsData(newComments?.comment);
      setCommentsCount(newComments?.comment_aggregate?.aggregate?.count);
    }
  }, [newComments]);

  const { data: newAssets, loading: loadingNewAssets } = useQuery(
    GET_NEW_ASSETS,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        limit: assetsLimit,
        offset: assetsOffset,
        projectId: localStorage.getItem("projectId"),
        clientId: localStorage.getItem("clientId"),
        userId: localStorage.getItem("userId"),
      },
      onError: (err) => {
        showToastMessage({
          color: "danger",
          message: err.message || "Something went wrong please try again!",
        });
      },
    }
  );

  useEffect(() => {
    if (newAssets?.task?.length > 0) {
      setAssetsData(newAssets.task);
      setAssetsCount(newAssets.task_aggregate?.aggregate?.count);
    }
  }, [newAssets]);

  useEffect(() => {
    let date = new Date();
    let todaysDate =
      date.getDate() +
      " " +
      date.toLocaleString("default", { month: "long" }).substring(0, 3) +
      " " +
      date.getFullYear();
    setTodaysDate(todaysDate);
  }, []);

  const getGreeting = () => {
    const currentTime = new Date().getHours();
    if (currentTime < 12) {
      return "Good Morning";
    } else if (currentTime < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  return (
    <PageLayout title="Home">
      <PageContainer>
        <DateShower>{todaysDate}</DateShower>
        <Wish>
          {getGreeting()} {localStorage.getItem("userName")}!
        </Wish>
        <WebViewComponents
          commentsData={commentsData}
          assetsData={assetsData}
          assetsCount={assetsCount >= 10 ? 10 : assetsCount}
          commentsCount={commentsCount >= 10 ? 10 : commentsCount}
          analyticsDataObj={analyticsDataObj ? analyticsDataObj : {}}
        />
        <MobViewComponents
          commentsCount={commentsCount >= 10 ? 10 : commentsCount}
          assetsCount={assetsCount}
          todaysDate={todaysDate}
          analyticsDataObj={analyticsDataObj ? analyticsDataObj : {}}
        />
      </PageContainer>
    </PageLayout>
  );
};
export default withRouter(Home);
