const Clip = ({ fill = "#130F26", strokeWidth = "0" }) => (
  <svg
    width="12"
    height="18"
    viewBox="0 0 12 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.06407 17.2653C3.09773 17.2653 0.685059 14.8526 0.685059 11.8863V4.3557C0.685059 3.9591 1.00641 3.63854 1.40222 3.63854C1.79816 3.63854 2.1195 3.9591 2.1195 4.3557V11.8863C2.1195 14.0609 3.88883 15.8309 6.06407 15.8309C8.23931 15.8309 10.0086 14.0609 10.0086 11.8863V3.99705C10.0086 2.6129 8.88266 1.48694 7.49851 1.48694C6.11423 1.48694 4.98827 2.6129 4.98827 3.99705V11.1692C4.98827 11.7622 5.47088 12.245 6.06407 12.245C6.65725 12.245 7.13987 11.7622 7.13987 11.1692V4.3557C7.13987 3.9591 7.46122 3.63854 7.85703 3.63854C8.25297 3.63854 8.57431 3.9591 8.57431 4.3557V11.1692C8.57431 12.5533 7.44822 13.6793 6.06407 13.6793C4.67992 13.6793 3.55382 12.5533 3.55382 11.1692V3.99705C3.55382 1.8226 5.32314 0.0524902 7.49851 0.0524902C9.67375 0.0524902 11.4431 1.8226 11.4431 3.99705V11.8863C11.4431 14.8526 9.0304 17.2653 6.06407 17.2653Z"
      fill={fill}
      stroke="black"
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default Clip;
