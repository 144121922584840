import React from "react";

const ChatIcon = ({ filled = false }) => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4336 1.02333L7.79432 1C6.67954 1 5.77273 1.88667 5.77273 2.97667V4.68467L3.02159 4.69033C1.90682 4.69033 1 5.577 1 6.667V13.714C1 14.804 1.90682 15.6903 3.02159 15.6903H5.09091V19.0237C5.09091 19.1613 5.1775 19.285 5.30909 19.3347C5.34898 19.3497 5.39057 19.357 5.43182 19.357C5.52591 19.357 5.6183 19.3187 5.68409 19.248L8.99227 15.6897L14.6602 15.667C15.775 15.667 16.6818 14.7803 16.6818 13.6907V13.653L18.475 15.5817C18.5408 15.6523 18.6332 15.6907 18.7273 15.6907C18.7685 15.6907 18.8101 15.6833 18.85 15.6683C18.9816 15.6187 19.0682 15.495 19.0682 15.3573V12.024H19.433C20.5477 12.024 21.4545 11.1373 21.4545 10.0477V3C21.4545 1.91033 20.5481 1.02367 19.4336 1.02333ZM20.7727 10.047C20.7727 10.7693 20.1717 11.3567 19.433 11.3567H18.7273C18.5387 11.3567 18.3864 11.506 18.3864 11.69V14.4947L16.6818 12.6613V6.64333C16.6818 5.55333 15.775 4.66667 14.6595 4.66667L6.45455 4.683V2.97633C6.45455 2.254 7.05557 1.66633 7.79364 1.66633L19.4326 1.68967C20.1714 1.68967 20.7731 2.27733 20.7731 2.99967L20.7727 10.047Z"
        fill={filled ? "black" : "none"}
      />
      <path
        d="M19.4571 0.845599L7.81776 0.822266C6.70298 0.822266 5.79616 1.70893 5.79616 2.79893V4.50693L3.04503 4.5126C1.93026 4.5126 1.02344 5.39927 1.02344 6.48926V13.5363C1.02344 14.6263 1.93026 15.5126 3.04503 15.5126H5.11435V18.8459C5.11435 18.9836 5.20094 19.1073 5.33253 19.1569C5.37241 19.1719 5.41401 19.1793 5.45526 19.1793C5.54935 19.1793 5.64173 19.1409 5.70753 19.0703L9.01571 15.5119L14.6837 15.4893C15.7984 15.4893 16.7053 14.6026 16.7053 13.5129V13.4753L18.4984 15.4039C18.5642 15.4746 18.6566 15.5129 18.7507 15.5129C18.792 15.5129 18.8335 15.5056 18.8734 15.4906C19.005 15.4409 19.0916 15.3173 19.0916 15.1796V11.8463H19.4564C20.5712 11.8463 21.478 10.9596 21.478 9.86993V2.82227C21.478 1.7326 20.5715 0.845932 19.4571 0.845599ZM16.0234 13.5126C16.0234 14.2349 15.4224 14.8223 14.6823 14.8223L8.86298 14.8456C8.76753 14.8459 8.67616 14.8856 8.61207 14.9546L5.79616 17.9836V15.1789C5.79616 14.9949 5.64378 14.8456 5.45526 14.8456H3.04503C2.30628 14.8456 1.70526 14.2579 1.70526 13.5359V6.48893C1.70526 5.7666 2.30628 5.17893 3.04571 5.17893L6.13707 5.1726C6.13741 5.1726 6.13741 5.1726 6.13776 5.1726L14.6837 5.15527C15.4224 5.15527 16.0234 5.74293 16.0234 6.46526V12.6123V13.5126ZM20.7962 9.86927C20.7962 10.5916 20.1951 11.1789 19.4564 11.1789H18.7507C18.5622 11.1789 18.4098 11.3283 18.4098 11.5123V14.3169L16.7053 12.4836V6.4656C16.7053 5.3756 15.7984 4.48893 14.683 4.48893L6.47798 4.50527V2.7986C6.47798 2.07627 7.07901 1.4886 7.81707 1.4886L19.456 1.51193C19.4564 1.51193 19.4564 1.51193 19.4567 1.51193C20.1955 1.51193 20.7965 2.0996 20.7965 2.82193V9.86927H20.7962Z"
        fill={filled ? "black" : "#929292"}
        stroke={filled ? "black" : "#929292"}
        strokeWidth="0.55"
      />
      <path
        d="M5.11328 11.5H12.2724"
        stroke={filled ? "white" : "#929292"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.11328 8.5H12.2724"
        stroke={filled ? "white" : "#929292"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatIcon;
