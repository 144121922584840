import { withRouter } from "react-router-dom";
import PageLayout from "../../components/pageLayout";
import CommentsComponent from "../../components/commentsComponents";

const Comments = () => {
  return (
    <PageLayout title="Comments">
      <div style={{ width: "100%" }}>
        <CommentsComponent />
      </div>
    </PageLayout>
  );
};
export default withRouter(Comments);
