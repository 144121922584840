import React from "react";

const MobHomeIcon = ({
  width = "16",
  height = "16",
  viewBox = "0 0 16 16",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.05273 10.7569H9.9294"
        stroke="#929292"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.6001 9.14201C1.6001 5.38801 2.00943 5.65001 4.21276 3.60668C5.17676 2.83068 6.67676 1.33334 7.9721 1.33334C9.26676 1.33334 10.7968 2.82334 11.7694 3.60668C13.9728 5.65001 14.3814 5.38801 14.3814 9.14201C14.3814 14.6667 13.0754 14.6667 7.99076 14.6667C2.9061 14.6667 1.6001 14.6667 1.6001 9.14201Z"
        stroke="#929292"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default MobHomeIcon;
