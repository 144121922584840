export const validator = ({ rules = [], inputValue = "" }) => {
  let errorMessage = "";
  rules.some((eachFunction) => {
    if (typeof eachFunction(inputValue) === "boolean" && inputValue) {
      errorMessage = "";
    } else {
      errorMessage = eachFunction(inputValue);
      return true;
    }
  });
  return { status: !errorMessage, errorMessage };
};
