import { IonCol, IonRow } from "@ionic/react";
import styled from "styled-components";
import DocsIcon from "../../assests/icons/docs";
import { DownloadOutlined } from "@ant-design/icons";
import { fileDownloader } from "../../lib/fileDownloader";

const WhiteBackground = styled.div`
  padding: 6px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ImageContent = styled(IonCol)`
  flex: 0.1;
  padding: 0px;
  margin-right: 12px;
  display: flex;
  min-width: 80px;
`;
const DescriptionContent = styled(IonCol)`
  flex: 1;
  padding: 0px 10px 0px 0px;
`;

const SenderName = styled(IonRow)`
  font-size: 0.87rem;
  font-weight: 700;
`;

const DocName = styled(IonRow)`
  font-size: 0.87rem;
  font-weight: 400;
  white-space: pre-wrap;
  word-break: break-word;
`;

const DownloadIcon = styled.div`
  position: absolute;
  right: 4%;
  top: 30%;
  background: white;
  padding: 2px 8px;
  display: flex;
  border-radius: 4px;
  display: none;
  z-index: 10;
  cursor: pointer;
  border: 1px solid gray;
`;

const AntDownloadIcon = styled(DownloadOutlined)`
  font-size: 16px;
`;

const ItemContent = styled(IonRow)`
  padding: 16px 0px;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  display: flex;
  color: var(--text-primary);
  font-family: Montserrat, sans-serif;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #e7e7e7;
    ${DownloadIcon} {
      display: block;
    }
  }
  @media screen and (max-width: 768px) {
    ${DownloadIcon} {
      display: block;
      padding: 0px 8px;
      top: 6%;
    }
    ${AntDownloadIcon} {
      font-size: 12px;
    }
  }
`;
const DocsItem = (props) => {
  const onDownload = (url, fileName) => {
    console.log(url, fileName);
    if (url) {
      fileDownloader({
        url: url,
        fileName: fileName,
      });
    }
  };
  return (
    <ItemContent
      id={props.id}
      onClick={() =>
        props.handlePreviewDoc(props.fileUrl, props.docsName, props.userName)
      }
    >
      <DownloadIcon
        title="Download"
        onClick={() => onDownload(props.fileUrl, props.docsName)}
      >
        <AntDownloadIcon />
      </DownloadIcon>
      <ImageContent>
        <WhiteBackground>
          <DocsIcon />
        </WhiteBackground>
      </ImageContent>
      <DescriptionContent>
        <SenderName>{props.userName}</SenderName>
        <DocName>{props.docsName}</DocName>
      </DescriptionContent>
    </ItemContent>
  );
};

export default DocsItem;
