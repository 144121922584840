import React from "react";
import styled from "styled-components";
import {
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonItem,
} from "@ionic/react";

const Item = styled(IonItem)`
  --padding-start: 10px !important;
  --inner-padding-end: 10px !important;
  --ripple-color: transparent;
`;

const ItemLabel = styled(IonLabel)`
  font-size: 0.85rem !important;
  color: var(--text-primary) !important;
  --ion-font-family: "Montserrat", sans-serif !important;
`;

const FiterListItem = ({
  selectedItem = undefined,
  items = [],
  panelValue = "",
  value = "",
}) => {
  return (
    <IonList lines="none">
      <IonRadioGroup
        value={value}
        onIonChange={(event) =>
          selectedItem && selectedItem(panelValue, event.detail.value)
        }
      >
        {items?.map(({ label = "", value = "" }, itemIndex) => (
          <Item key={`filter-item-${itemIndex}`}>
            <ItemLabel>{label}</ItemLabel>
            <IonRadio value={value} color="dark" />
          </Item>
        ))}
      </IonRadioGroup>
    </IonList>
  );
};

export default FiterListItem;
