const Send = ({ stroke = "#130F26", fill = "none" }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4209 5.78356L7.58509 10.6517L1.92351 7.16704C1.18164 6.71034 1.33201 5.58365 2.16775 5.34108L15.5346 1.4516C16.2947 1.23162 16.9975 1.94427 16.7699 2.70795L12.8039 16.061C12.5555 16.8966 11.4415 17.0413 10.9912 16.2952L7.58509 10.6517"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Send;
