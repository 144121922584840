import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PageLayout from "../../components/pageLayout";
import Compare from "../../components/review/compare";
import { IonCol } from "@ionic/react";
import { useParams } from "react-router-dom";
import { GET_ASSET } from "../../graphQL/queries";
import { useQuery } from "@apollo/client";
import useWindowDimensions from "../../components/useWindowDimensions";

const Wrapper = styled(IonCol)`
  padding: 10% 0;
`;

const CompareAsset = () => {
  const { task_id } = useParams();
  const [asset, setAsset] = useState({});
  const [selectedAsset, setSelectedAsset] = useState({});
  const { width } = useWindowDimensions();

  const { data, loading } = useQuery(GET_ASSET, {
    variables: {
      taskId: task_id,
    },
  });

  const getStatus = (status) => {
    const currentStatus = status.toLowerCase();
    if (
      currentStatus === "todo" ||
      currentStatus === "inprogress" ||
      currentStatus === "client_review"
    ) {
      return "Pending";
    }
    if (currentStatus === "closed") {
      return "Approved";
    }
    if (currentStatus === "internal_review") {
      return "Rejected";
    }
    return status;
  };

  const formatResponse = (responseData = {}) => {
    let lists = [];
    const listData =
      responseData?.task?.length > 0 ? [...responseData?.task] : [];
    if (listData.length > 0) {
      lists = listData.map((eachList) => ({
        ...eachList,
        delhiveryStatus: `Deliverable ${eachList.deliverables.length}`,
        status: getStatus(eachList.status),
        imageURL: eachList?.deliverables.map((eachRefrences) => ({
          currentVersionId: eachRefrences?.current_version_id,
          version:
            (eachRefrences?.versions?.length > 0 &&
              eachRefrences?.versions[0]?.version) ||
            "",
          status:
            (eachRefrences?.versions?.length > 0 &&
              eachRefrences?.versions[0]?.status) ||
            "",
          ...((eachRefrences?.versions?.length > 0 &&
            eachRefrences?.versions[0]?.resource) ||
            {}),
        })),
      }));
      return lists;
    } else {
      return lists;
    }
  };

  useEffect(() => {
    const formattedResponse = formatResponse(data);
    setAsset(formattedResponse[0] || {});
    const versionId = localStorage.getItem("currentVersionId");
    const currentVersion =
      formattedResponse?.length > 0 &&
      formattedResponse[0]?.imageURL?.length > 0
        ? formattedResponse[0]?.imageURL.find(
            (each) => each?.currentVersionId === versionId
          )
        : {};

    setSelectedAsset(currentVersion);
  }, [data]);
  return (
    <PageLayout title="Assets" arrowCircle={width <= 768 ? true : false}>
      <Wrapper>
        <Compare
          currentVersionId={localStorage.getItem("currentVersionId")}
          assetsList={asset}
          selectedAsset={selectedAsset}
        />
      </Wrapper>
    </PageLayout>
  );
};

export default CompareAsset;
