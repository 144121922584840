import React from "react";

const ExpandIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 6.75862V12.5517C14 12.9358 13.8474 13.3042 13.5758 13.5758C13.3042 13.8474 12.9358 14 12.5517 14H1.44828C1.06417 14 0.695795 13.8474 0.42419 13.5758C0.152586 13.3042 0 12.9358 0 12.5517V1.44828C0 1.06417 0.152586 0.695795 0.42419 0.42419C0.695795 0.152586 1.06417 0 1.44828 0H7.24138C7.43343 0 7.61762 0.0762929 7.75342 0.212095C7.88922 0.347897 7.96552 0.532085 7.96552 0.724138C7.96552 0.916191 7.88922 1.10038 7.75342 1.23618C7.61762 1.37198 7.43343 1.44828 7.24138 1.44828H1.93103C1.80312 1.44866 1.68055 1.49964 1.59009 1.59009C1.49964 1.68055 1.44866 1.80312 1.44828 1.93103V12.069C1.44866 12.1969 1.49964 12.3195 1.59009 12.4099C1.68055 12.5004 1.80312 12.5513 1.93103 12.5517H12.069C12.1969 12.5513 12.3195 12.5004 12.4099 12.4099C12.5004 12.3195 12.5513 12.1969 12.5517 12.069V6.75862C12.5517 6.56657 12.628 6.38238 12.7638 6.24658C12.8996 6.11078 13.0838 6.03448 13.2759 6.03448C13.4679 6.03448 13.6521 6.11078 13.7879 6.24658C13.9237 6.38238 14 6.56657 14 6.75862V6.75862Z"
        fill="white"
      />
      <path
        d="M14 0.481345V3.85076C14 4.04225 13.9239 4.2259 13.7885 4.3613C13.6531 4.49671 13.4695 4.57278 13.278 4.57278C13.0865 4.57278 12.9028 4.49671 12.7674 4.3613C12.632 4.2259 12.556 4.04225 12.556 3.85076V2.46449L8.21423 6.80622C8.07736 6.93376 7.89633 7.00319 7.70928 6.99989C7.52223 6.99659 7.34376 6.92081 7.21147 6.78853C7.07919 6.65624 7.00341 6.47777 7.00011 6.29072C6.99681 6.10367 7.06624 5.92264 7.19378 5.78577L11.5355 1.44403H10.1492C9.95775 1.44403 9.7741 1.36797 9.6387 1.23256C9.50329 1.09716 9.42722 0.913508 9.42722 0.722017C9.42722 0.530527 9.50329 0.346879 9.6387 0.211474C9.7741 0.0760695 9.95775 0 10.1492 0H13.5187C13.6462 0.000380919 13.7684 0.0512162 13.8586 0.141403C13.9488 0.231591 13.9996 0.353801 14 0.481345V0.481345Z"
        fill="white"
      />
    </svg>
  );
};

export default ExpandIcon;
