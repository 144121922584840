export const emailRules = [
  (v) => !!v || "E-mail is required",
  (v) =>
    /^\w+([.-/+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
    "E-mail must be valid",
];

export const nameRules = [
  (v) => !!v || "Name is required",
  (v) => /^[A-Za-z ]{3,}$/.test(v) || "Name must be valid",
];

export const phoneNumberRules = [
  (v) => !!v || "Phone is required",
  (v) => /^[0-9 ]{3,13}$/.test(v) || "Invalid phone number",
];
