import TaskCommentItem from "./taskCommentItem";
import CommentInput from "./commentInputComponent";
import { IonRow, IonText, useIonToast, IonLoading } from "@ionic/react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  GET_FILE_COMMENTS,
  GET_REPLIES_LIST,
  USER_COMMENTS_ACTIVITY_TRACK,
} from "../../graphQL/queries";
import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_ASSET_COMMENT_THREAD,
  REPLY_TO_COMMENT,
  RESOLVE_COMMENT,
  UPLOAD_FILE,
  LINK_ATTACHMENT_AND_COMMENT,
  READ_COMMENTS,
} from "../../graphQL/mutations";
import { FileTextOutlined } from "@ant-design/icons";
import Close from "../../assests/icons/close";
import { useParams } from "react-router-dom";

const CommentsContent = styled(IonRow)`
  margin-top: 10px;
  overflow-y: auto;
  min-height: 100px;
  max-height: ${({ height }) => height || "500px"};
`;

const ViewHideText = styled(IonText)`
  color: #575757;
  font-size: 0.875rem;
  margin: 10px 0px;
`;

const CommentsContainer = styled(IonRow)`
  display: flex;
  flex-direction: column;
`;

const ViewMoreText = styled(IonText)`
  margin-left: 64px;
  margin-bottom: 20px;
  font-size: 0.875rem;
  cursor: pointer;
  display: ${({ display }) => (display ? "block" : "none")};
`;

const CloseIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const FileClose = styled.div`
  display: flex;
  align-items: center;
  background: #f7f6f6;
  max-width: max-content;
  margin-top: 10px;
  padding: 6px 20px;
  border-radius: 8px;
`;
const FileName = styled.div`
  font-size: 0.8rem;
  color: var(--text-primary);
  padding: 10px 20px;
`;

const NoDataText = styled(IonText)`
  font-size: 0.75rem;
  font-weight: 700;
  color: #575757;
  padding-top: 20px;
`;

const NoDataContent = styled(IonRow)`
  height: 300px;
`;

const TaskComments = ({ menu, height = "", fileCommentList = [] }) => {
  const [switchComment, setSwitchComment] = useState("Hide");
  const [commentMessage, setCommentMessage] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [commentAttachedFile, setCommentAttachedFile] = useState();
  const [replyAttachedFile, setReplyAttachedFile] = useState();
  const [commentsList, setCommentsList] = useState([]);
  const [relatedComments, setRelatedComments] = useState([]);
  const [replyId, setReplyId] = useState("");
  const [threadId, setThreadId] = useState("");
  const [thread, setThread] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [replyLoading, setReplyLoading] = useState(false);
  const [resolvingLoading, setResolving] = useState(false);
  const [commentsOffset, setCommentsOffset] = useState(0);
  const [commentsLimit] = useState(4);
  const [commentsCount, setCommentsCount] = useState(0);
  const [repliesOffset, setRepliesOffset] = useState(0);
  const [repliesLimit] = useState(4);
  const [repliesCount, setRepliesCount] = useState(0);
  const [viewMoreComments, setViewMoreComments] = useState(false);
  const [viewMoreReplies, setViewMoreReplies] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [commentsReadList, setCommentsReadList] = useState([]);
  const [commentsReadList1, setCommentsReadList1] = useState([]);
  const [signedUrl, setSignedUrl] = useState("");
  const [resourceId, setResourceId] = useState("");

  const { task_id } = useParams();
  const taskId = task_id;

  const [present] = useIonToast();

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  const [addComment] = useMutation(ADD_ASSET_COMMENT_THREAD);
  const [replyToComment] = useMutation(REPLY_TO_COMMENT);
  const [resolveComment] = useMutation(RESOLVE_COMMENT);
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [linkAttachmentToComment] = useMutation(LINK_ATTACHMENT_AND_COMMENT);
  const [readComments] = useMutation(READ_COMMENTS);

  const { data: userCommentsaActivityData, loading: activityCommentsLoading } =
    useQuery(USER_COMMENTS_ACTIVITY_TRACK, {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        user_id: localStorage.getItem("userId"),
      },
      onError: (err) => {
        console.log(err.message);
      },
    });

  useEffect(() => {
    if (userCommentsaActivityData?.user_activity_tracking?.length > 0) {
      setCommentsReadList(userCommentsaActivityData.user_activity_tracking);
      let array = userCommentsaActivityData.user_activity_tracking.map(
        (each) => {
          return each.comment_id;
        }
      );
      setCommentsReadList1(array);
    }
  }, [userCommentsaActivityData, activityCommentsLoading]);

  // useEffect(() => {
  //   setCommentsList([...fileCommentList]);
  // }, [fileCommentList]);

  const { data: commentsData, loading: commentsLoading } = useQuery(
    GET_FILE_COMMENTS,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        taskId: taskId, //task id needs to send from route/...
        offset: commentsOffset,
        limit: commentsLimit,
        status: menu === "Task" ? "UNRESOLVED" : "RESOLVED",
        order_by: menu === "Task" ? "desc" : "desc",
      },
      onError: (err) => {
        console.log(err.message);
      },
    }
  );

  useEffect(() => {
    if (commentsData?.thread?.length > 0) {
      console.log("commentsData.thread", commentsData.thread);
      if (viewMoreComments) {
        setCommentsList([...commentsList, ...commentsData.thread]);
      } else {
        let target = document.getElementById("comment_container");
        if (target) {
          target.scrollIntoView({
            top: 0,
            behavior: "smooth",
          });
        }
        setCommentsList(commentsData.thread);
        setCommentsCount(commentsData?.thread_aggregate?.aggregate?.count || 0);
      }
      setViewMoreComments(false);
    }
    setPageLoading(commentsLoading);
  }, [commentsData, commentsLoading]);

  useEffect(() => {
    let target = document.getElementById("comment_container");
    if (target) {
      target.scrollIntoView({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [commentsData?.thread?.length === 0]);

  const {
    data: repliesData,
    loading: repliesLoading,
    fetchMore: fetchMoreRepliesList,
  } = useQuery(GET_REPLIES_LIST, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      comment_thread: threadId,
      offset: repliesOffset,
      limit: repliesLimit,
      userId: localStorage.getItem("userId"),
    },
    onError: (err) => {
      console.log(err.message);
    },
  });

  useEffect(() => {
    if (repliesData?.comment?.length > 0) {
      if (viewMoreReplies) {
        setRelatedComments([...relatedComments, ...repliesData.comment]);
        setViewMoreReplies(false);
      } else {
        setRelatedComments(repliesData.comment);
        setViewMoreReplies(false);
      }
    }
    setPageLoading(repliesLoading);
  }, [repliesData, repliesLoading]);

  const handleSwitchComments = (value) => {
    setSwitchComment(value === "View" ? "Hide" : "View");
  };

  const handleAction = async (action, id, threadId, field) => {
    if (action === "reply") {
      setReplyId(id);
      setThread(threadId);
      setReplyMessage("");
    }
    if (action === "resolved") {
      try {
        setResolving(true);
        let userId = localStorage.getItem("userId");
        const { data } = await resolveComment({
          fetchPolicy: "network-only",
          nextFetchPolicy: "cache-first",
          variables: {
            thread_id: threadId,
            status: "RESOLVED",
            resolved_by: userId,
          },
        });
        if (data?.update_thread?.returning) {
          let commentsData = JSON.parse(JSON.stringify(commentsList));
          const index = commentsData.findIndex((ele) => ele.id === threadId);
          commentsData.splice(index, 1);
          setCommentsList(commentsData);
          setCommentsCount(commentsCount - 1);
        }
      } catch (error) {
        showToastMessage({
          color: "danger",
          message: error.message || "Something went wrong please try again!",
        });
      } finally {
        setResolving(false);
      }
    }
  };

  const handleOnchangeComment = (e) => {
    setCommentMessage(e.target.value);
  };

  const handleCommentAttachment = async (file, menuField) => {
    try {
      setResolving(true);
      const { data } = await uploadFile({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {
          fileName: file.file.name,
        },
      });
      if (data?.fileUpload?.signedUrl && data?.fileUpload?.resourceId) {
        setCommentAttachedFile(file);
        setSignedUrl(data.fileUpload.signedUrl);
        setResourceId(data.fileUpload.resourceId);
        setFileName(file.file.name);
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    } finally {
      setResolving(false);
    }
  };

  const handleSendComment = async () => {
    if (signedUrl) {
      setLoading(true);
      await fetch(signedUrl, {
        method: "PUT",
        body: commentAttachedFile.file,
      }).then((response) => {
        console.log("fileUpload", response);
      });
      setLoading(false);
      try {
        setLoading(true);
        let userId = localStorage.getItem("userId");
        const { data } = await addComment({
          fetchPolicy: "network-only",
          nextFetchPolicy: "cache-first",
          variables: {
            status: "UNRESOLVED",
            visibility: "PUBLIC",
            comment_text: commentMessage,
            comment_user: userId,
            task: task_id,
          },
        });
        if (data?.insert_thread_one) {
          let obj = {};
          obj = data.insert_thread_one;
          obj.comments_aggregate = {
            aggregate: {
              count: 1,
            },
          };
          setCommentsList([...[obj], ...commentsList]);
          setCommentsCount(commentsCount + 1);

          try {
            setLoading(true);
            const { data: attachmentData } = await linkAttachmentToComment({
              fetchPolicy: "network-only",
              nextFetchPolicy: "cache-first",
              variables: {
                resource_id: resourceId,
                comment_id: data.insert_thread_one?.comments[0].id,
              },
            });
            if (attachmentData?.update_resource?.affected_rows) {
              setCommentMessage("");
              setResourceId("");
              setSignedUrl("");
              setCommentAttachedFile();
              setFileName("");
            }
          } catch (error) {
            showToastMessage({
              color: "danger",
              message:
                error.message || "Something went wrong please try again!",
            });
          } finally {
            setLoading(false);
          }
        }
      } catch (error) {
        showToastMessage({
          color: "danger",
          message: error.message || "Something went wrong please try again!",
        });
      } finally {
        setLoading(false);
      }
    } else {
      if (commentMessage) {
        try {
          setLoading(true);
          let userId = localStorage.getItem("userId");
          const { data } = await addComment({
            fetchPolicy: "network-only",
            nextFetchPolicy: "cache-first",
            variables: {
              status: "UNRESOLVED",
              visibility: "PUBLIC",
              comment_text: commentMessage,
              comment_user: userId,
              task: task_id,
            },
          });
          if (data?.insert_thread_one) {
            let obj = {};
            obj = data.insert_thread_one;
            obj.comments_aggregate = {
              aggregate: {
                count: 1,
              },
            };
            setCommentsList([...[obj], ...commentsList]);
            setCommentsCount(commentsCount + 1);
            setCommentMessage("");
            setResourceId("");
            setSignedUrl("");
            setCommentAttachedFile();
            setFileName("");
          }
        } catch (error) {
          showToastMessage({
            color: "danger",
            message: error.message || "Something went wrong please try again!",
          });
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleOnchangeReply = (e, thread) => {
    setReplyMessage(e.target.value);
  };

  const handleSendReply = async () => {
    try {
      setReplyLoading(true);
      let userId = localStorage.getItem("userId");
      const { data } = await replyToComment({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {
          comment_text: replyMessage,
          comment_user: userId,
          comment_thread: thread,
        },
      });
      if (data?.insert_comment_one) {
        let commentsData = JSON.parse(JSON.stringify(commentsList)); //thread
        for (let i = 0; i < commentsData.length; i++) {
          if (commentsData[i].id === thread) {
            let count = commentsData[i].comments_aggregate.aggregate.count + 1;
            commentsData[i].comments_aggregate.aggregate.count = count;
            break;
          }
        }
        setCommentsList(commentsData);
        let obj = {};
        obj = data.insert_comment_one;
        if (thread === threadId) {
          setRelatedComments([...[obj], ...relatedComments]);
        }
        setReplyMessage("");
        setReplyId("");
        setReplyAttachedFile();
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    } finally {
      setReplyLoading(false);
    }
  };

  const handleReplyAttachment = (file, menuField) => {
    setReplyAttachedFile(file);
  };

  const handleCloseReply = () => {
    setReplyMessage("");
    setReplyId("");
    setReplyAttachedFile();
  };

  const getRelatedComments = (comment_thread, viewOrHide, repliesCount) => {
    console.log("==>", comment_thread, viewOrHide, repliesCount);
    if (threadId !== comment_thread) {
      setRepliesOffset(0);
    }
    setThreadId(viewOrHide === "Hide" ? "" : comment_thread);
    setRepliesCount(repliesCount);
    fetchMoreRepliesList({
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        comment_thread: comment_thread,
        offset: 0,
        limit: repliesLimit,
        userId: localStorage.getItem("userId"),
      },
    });
  };

  const handleCommentsViewMore = () => {
    setCommentsOffset(commentsOffset + commentsLimit);
    setViewMoreComments(true);
  };

  const handleRepliesViewMore = () => {
    setRepliesOffset(repliesOffset + repliesLimit);
    setViewMoreReplies(true);
  };

  const handleCloseFileAttachment = () => {
    setCommentAttachedFile();
    setSignedUrl("");
    setFileName("");
    setResourceId("");
  };

  const color = ["#7C9EC9", "#FD9393", "#FFC542", "#8BDDB3"];

  const findIsCommentRead = (id) => {
    if (id) {
      let a = commentsReadList.find((each) => each.comment_id === id);
      return a === undefined;
    } else {
      return true;
    }
  };

  const handleReadComments = async (readArray) => {
    if (readArray.length > 0) {
      try {
        setLoading(true);
        const { data: readComment } = await readComments({
          fetchPolicy: "network-only",
          nextFetchPolicy: "cache-first",
          variables: {
            user_activity_array: readArray,
            taskId: taskId,
            userId: localStorage.getItem("userId"),
          },
        });
        if (readComment?.update_user_activity_tracking?.affected_rows) {
          console.log("affected_rows", readComment);
        }
      } catch (error) {
        showToastMessage({
          color: "danger",
          message: error.message || "Something went wrong please try again!",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    let readArray1 = commentsReadList1;
    let readArray = [];
    for (let i = 0; i < commentsList.length; i++) {
      if (!readArray1.includes(commentsList[i].comments[0].id)) {
        let id = commentsList[i].comments[0].id;
        readArray1.push(id);
        readArray.push(id);
      }
    }
    setCommentsReadList1(readArray1);
    handleReadComments(readArray);
  }, [commentsList.length]);

  return (
    <CommentsContainer id="comment_container">
      {commentsList.length > 0 && (
        <ViewHideText>
          <u
            style={{ cursor: "pointer" }}
            onClick={() => handleSwitchComments(switchComment)}
          >
            {switchComment} all Comments
          </u>
        </ViewHideText>
      )}
      {commentsList.length > 0 ? (
        <>
          <IonLoading
            cssClass="my-custom-class"
            isOpen={pageLoading || resolvingLoading || activityCommentsLoading}
            message={"Please wait..."}
            // duration={10000}
          />
          {switchComment === "Hide" && (
            <CommentsContent height={height}>
              <>
                {commentsList?.map((element, index) => (
                  <div
                    key={element.thread || index}
                    style={{ marginBottom: 20, width: "100%" }}
                  >
                    <TaskCommentItem
                      new={true}
                      handleAction={handleAction}
                      menu={"Task"}
                      comment={element.comments[0] ? element.comments[0] : {}}
                      read={findIsCommentRead(
                        element.comments[0] ? element.comments[0].id : ""
                      )}
                      count={
                        element?.comments_aggregate?.aggregate?.count - 1 || 0
                      }
                      getRelatedComments={getRelatedComments}
                      relatedComments={relatedComments || []}
                      replyId={replyId}
                      threadId={threadId}
                      replyMessage={replyMessage}
                      handleOnchangeReply={handleOnchangeReply}
                      handleSendReply={handleSendReply}
                      handleReplyAttachment={handleReplyAttachment}
                      replyAttachedFile={replyAttachedFile}
                      handleCloseReply={handleCloseReply}
                      profileBackground={color[index % color.length]}
                      replyLoading={replyLoading}
                      repliesCount={repliesCount}
                      repliesOffset={repliesOffset}
                      repliesLimit={repliesLimit}
                      viewMoreReplies={viewMoreReplies}
                      handleRepliesViewMore={handleRepliesViewMore}
                      hideMarkAsResolved
                    />
                  </div>
                ))}
              </>
              <ViewMoreText
                display={
                  commentsCount > commentsOffset + commentsLimit ? true : false
                }
                onClick={handleCommentsViewMore}
              >
                <u>View more</u>
              </ViewMoreText>
            </CommentsContent>
          )}
        </>
      ) : (
        <NoDataContent>
          <NoDataText>No {menu} Comments</NoDataText>
        </NoDataContent>
      )}
      <div
        style={{
          marginBottom: 10,
          display: replyId ? "block" : "none",
        }}
      >
        <CommentInput
          message={commentMessage}
          handleOnchangeComment={handleOnchangeComment}
          handleSendComment={handleSendComment}
          handleAttachment={handleCommentAttachment}
          handleCloseReply={handleCloseReply}
          isLoading={isLoading}
          isAttchment={true}
          commentAttachedFile={commentAttachedFile}
        />
        {commentAttachedFile && (
          <div>
            <FileClose>
              <FileTextOutlined style={{ fontSize: 30, color: "#7C9EC9" }} />
              <FileName title={fileName}>
                {fileName.length >= 40
                  ? fileName.substr(0, 20) + "..."
                  : fileName}
              </FileName>
              <CloseIcon>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={handleCloseFileAttachment}
                >
                  <Close stroke="#130F26" height="10" width="10" />
                </span>
              </CloseIcon>
            </FileClose>
          </div>
        )}
      </div>
    </CommentsContainer>
  );
};

export default TaskComments;
