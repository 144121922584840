import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import { IonButton, IonContent, IonSpinner, IonIcon } from "@ionic/react";
import { GET_VERSIONS } from "../../graphQL/queries";

import ShareList from "./compare/compareList";
import Flex from "../flex";
import CloseIcon from "../../assests/icons/close";
import ShareDrawer from "../assets/shareDrawer";
import SocialShareList from "./socialShareList";

const Title = styled.h2`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  margin: 50px 0 52px 0;
  color: var(--text-primary);
  padding: 0% 10%;
`;

const Button = styled(IonButton)`
  height: 41px;
  --background: ${({ disabled }) => (disabled ? "#e6e6e6" : "#130f26")};
  --box-shadow: none;
  width: 153px;
  color: ${({ disabled }) => (disabled ? " #130f26" : "#fff")};
  text-transform: capitalize;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 11.5px;
  opacity: 1;
  transition: 0.35s all;
`;

const ShareButtonWrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
  height: 90px;
  background: #f5f5f5;
  padding-bottom: 20px;
`;
const Wrapper = styled.div`
  position: relative;
`;

const ShareListWrapper = styled.div`
  padding: 26px 10%;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 26px 0;
  }
`;

const IconButton = styled.button`
  position: absolute;
  right: 12%;
  top: -8px;
  cursor: pointer;
  background: none;
`;

const NoDataMessage = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  color: #575757;
  padding: 30px 0;
`;

const Share = ({
  isShareVisible = false,
  isShareModelType = false,
  currentVersionId = "b7c43cde-4931-11ec-9df2-b756eed1564d",
  onCancel = undefined,
}) => {
  const [assetVersions, setAssetVersions] = useState([]);
  const [isShare, setIsShare] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState([]);
  const [getVersions, { data, loading }] = useLazyQuery(GET_VERSIONS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (data?.task_deliverable && data?.task_deliverable.length > 0) {
      const versions = data?.task_deliverable[0]?.versions?.map(
        (each, index) => ({
          currentVersionId: data?.task_deliverable[0]?.current_version_id,
          index,
          id: each.id,
          version: each?.version,
          status: each?.status,
          ...each.resource,
        })
      );
      setAssetVersions(versions);
    }
  }, [data]);

  useEffect(() => {
    getVersions({
      variables: {
        currentVersionId: currentVersionId,
      },
    });
    setSelectedVersion([]);
  }, [currentVersionId]);

  const onSelectAssetVersion = (multipleVersion) => {
    setSelectedVersion([...multipleVersion]);
  };

  return isShareModelType ? (
    <ShareDrawer
      isModalVisible={isShareVisible}
      onCancel={onCancel}
      imageURL={assetVersions}
    />
  ) : (
    <>
      {isShare ? (
        <Wrapper>
          <IconButton onClick={() => setIsShare(false)}>
            <CloseIcon stroke="#130F26" height="10" width="10" />
          </IconButton>

          <Title>Share the selected versions</Title>
          <SocialShareList selectedAssets={selectedVersion} />
        </Wrapper>
      ) : (
        <Wrapper>
          <Title>Choose the versions you wish to share</Title>
          <IonContent
            style={{
              height: "58vh",
              "--background": "transparent",
            }}
          >
            <ShareListWrapper>
              {loading ? (
                <Flex center>
                  <IonSpinner name="crescent" />
                </Flex>
              ) : assetVersions?.length > 0 ? (
                <ShareList
                  width="301px"
                  defaultSelected={selectedVersion}
                  compareOptions={assetVersions}
                  onSelect={onSelectAssetVersion}
                  currentVersionId={currentVersionId}
                  multipleSelect
                  hideStatusChip
                />
              ) : (
                <NoDataMessage>No versions available to share!</NoDataMessage>
              )}
            </ShareListWrapper>
          </IonContent>
          {
            <ShareButtonWrapper fullWidth center alignCenter>
              <Button
                shape="round"
                onClick={() => setIsShare(true)}
                disabled={selectedVersion?.length < 1}
              >
                Share
              </Button>
            </ShareButtonWrapper>
          }
        </Wrapper>
      )}
    </>
  );
};

export default Share;
