import React, { useState, useEffect } from "react";
import CompareItems from "./CompareItems";
import { Empty } from "antd";

const CompareList = ({
  compareOptions,
  onSelect = undefined,
  backgroundColor = "",
  width = "",
  currentVersionId = "",
  multipleSelect = false,
  hideStatusChip = false,
  defaultSelected = [],
}) => {
  const [selectedVersion, setSelectedVersion] = useState("");
  const [multipleVersion, setMultipleVersion] = useState([...defaultSelected]);

  const onSelectAssetVersion = (version) => {
    if (multipleSelect) {
      let multipleVersionsCopy = [...multipleVersion];
      if (multipleVersionsCopy.some((each) => each.id === version?.id)) {
        multipleVersionsCopy = multipleVersionsCopy.filter(
          (each) => each.id != version?.id
        );
        setMultipleVersion([...multipleVersionsCopy]);
        if (onSelect) onSelect([...multipleVersionsCopy]);
      } else {
        multipleVersionsCopy.push(version);
        setMultipleVersion([...multipleVersionsCopy]);
        if (onSelect) onSelect([...multipleVersionsCopy]);
      }
    } else {
      if (version?.id === selectedVersion?.id) {
        setSelectedVersion("");
        if (onSelect) onSelect("");
      } else {
        setSelectedVersion(version);
        if (onSelect) onSelect(version);
      }
    }
  };
  useEffect(() => {
    setSelectedVersion("");
  }, [currentVersionId]);

  return (
    <>
      {compareOptions?.length > 0 ? (
        compareOptions?.map((eachAsset, assetIndex) => (
          <CompareItems
            isSelected={
              multipleSelect
                ? multipleVersion.some((each) => each.id === eachAsset?.id)
                : eachAsset?.id === selectedVersion?.id
            }
            name={eachAsset?.name}
            filePath={eachAsset?.url}
            version={eachAsset?.version}
            status={eachAsset?.status}
            createdDate={eachAsset?.created_at}
            key={`compare-${assetIndex}`}
            onClick={() => onSelectAssetVersion(eachAsset)}
            backgroundColor={backgroundColor}
            width={width}
            hideStatusChip={hideStatusChip}
          />
        ))
      ) : (
        <Empty description="No versions available !" />
      )}
    </>
  );
};

export default CompareList;
