import React from "react";

const CloseCircle = ({
  height = "27",
  width = "27",
  viewBox = "0 0 27 27",
  color = "none",
  stroke = "white",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="13.5"
        y="0.0649414"
        width="19"
        height="19"
        rx="9.5"
        transform="rotate(45 13.5 0.0649414)"
        fill="#130F26"
      />
      <path
        d="M16.0926 11.1431L10.9121 16.3236"
        stroke="white"
        strokeQidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0951 16.3259L10.9097 11.1405"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default CloseCircle;
