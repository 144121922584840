import { IonCol, IonRow, IonText, IonContent } from "@ionic/react";
import styled from "styled-components";
import AssetItem from "./assetItem";
import { useHistory } from "react-router-dom";

const Content = styled(IonCol)``;

const Header = styled.div`
  color: var(--text-primary);
  font-size: 1.125rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const Dot = styled.div`
  height: 10px;
  width: 10px;
  background: #ff4f4f;
  border-radius: 50%;
`;

const NoDataText = styled(IonText)`
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--text-primary);
`;

const AssetsContainer = styled(IonRow)`
  width: 100%;
`;
const CommentsComponent = (props) => {
  const history = useHistory();

  const handleOnClickAsset = (taskId) => {
    if (taskId) {
      history.push(`/assets/${taskId}/individual-asset`);
    }
  };
  return (
    <Content>
      <Header>
        <span style={{ fontWeight: 700 }}>
          {props.count.toString().length === 1
            ? "0" + props.count
            : props.count}
        </span>
        &nbsp;
        <span> New Assets</span>&nbsp;&nbsp;
        <Dot />
      </Header>
      {props.assetsData.length > 0 ? (
        <IonContent
          style={{
            width: "100%",
            overflowY: "auto",
            height: 200,
            fontFamily: "Montserrat, sans-serif",
          }}
          scrollEvents
          className="new-asset-ion-content"
        >
          {props.assetsData.map((ele, id) => (
            <AssetsContainer key={id}>
              <AssetItem
                userName={ele.creator.name || ""}
                imageUrl={
                  ele.deliverables[0]?.current_version?.resource?.url || ""
                }
                createdAt={ele.deliverables[0]?.created_at}
                task_id={ele.id || ""}
                handleOnClickAsset={handleOnClickAsset}
              />
            </AssetsContainer>
          ))}
        </IonContent>
      ) : (
        <div style={{ height: 200, padding: "10px 0px 0px" }}>
          <NoDataText>No new Assets have been uploaded today.</NoDataText>
        </div>
      )}
    </Content>
  );
};

export default CommentsComponent;
