import React from "react";

const WebAssetsSelectedIcon = ({
  width = "20",
  height = "20",
  viewBox = "0 0 20 20",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.08301 6.49864C2.08301 4.46372 3.12467 2.7161 5.10134 2.31055C7.07717 1.9042 8.57884 2.04467 9.82634 2.71769C11.0747 3.3907 10.7172 4.38435 11.9997 5.11372C13.283 5.84388 15.3472 4.74705 16.6955 6.20182C18.1072 7.72483 18.0997 10.0629 18.0997 11.5534C18.0997 17.2169 14.9272 17.6661 10.0913 17.6661C5.25551 17.6661 2.08301 17.274 2.08301 11.5534V6.49864Z"
        fill="#130F26"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.52959 3.26775C8.45308 2.68698 7.11386 2.53473 5.22724 2.92273C3.62541 3.25138 2.70801 4.66523 2.70801 6.49864V11.5534C2.70801 14.3007 3.46165 15.5595 4.58803 16.2148C5.18667 16.563 5.94511 16.775 6.87991 16.8956C7.8145 17.0162 8.88494 17.0411 10.0913 17.0411C12.54 17.0411 14.3653 16.9148 15.5926 16.1945C16.7225 15.5314 17.4747 14.2694 17.4747 11.5534V11.5483C17.4747 10.7973 17.4747 9.89701 17.312 9.0015C17.1494 8.10629 16.8327 7.26925 16.2371 6.62668C15.7528 6.10412 15.1389 6.00999 14.324 6.01104C14.2074 6.01119 14.08 6.01359 13.9472 6.01608C13.6547 6.02157 13.3361 6.02756 13.0495 6.01118C12.6124 5.98619 12.1321 5.90813 11.6907 5.65701M10.1229 2.16755C8.70454 1.40239 7.04056 1.27369 4.97573 1.6983L10.1229 2.16755ZM10.1229 2.16755C10.912 2.59299 11.2092 3.14781 11.4446 3.58732L11.4511 3.59944C11.6672 4.00276 11.8291 4.2977 12.3086 4.57043C12.5089 4.68435 12.7679 4.74304 13.1209 4.76321C13.3615 4.77697 13.5952 4.77234 13.8572 4.76715C14.0017 4.76429 14.1548 4.76126 14.3224 4.76104C15.1975 4.75991 16.2899 4.84476 17.1539 5.77696C17.97 6.6574 18.3553 7.75091 18.5419 8.77812C18.7248 9.78517 18.7247 10.7797 18.7247 11.5146L18.7247 11.5534C18.7247 14.5009 17.8906 16.2952 16.2253 17.2726C14.6574 18.1928 12.4785 18.2911 10.0913 18.2911C8.87982 18.2911 7.74026 18.267 6.71993 18.1353C5.69981 18.0037 4.75841 17.76 3.95944 17.2952C2.29061 16.3243 1.45801 14.5268 1.45801 11.5534V6.49864C1.45801 4.2623 2.62442 2.18087 4.97573 1.6983"
        fill="#130F26"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.54688 12.1452C5.54688 11.8461 5.78939 11.6036 6.08854 11.6036H14.0827C14.3819 11.6036 14.6244 11.8461 14.6244 12.1452C14.6244 12.4444 14.3819 12.6869 14.0827 12.6869H6.08854C5.78939 12.6869 5.54688 12.4444 5.54688 12.1452Z"
        fill="white"
      />
    </svg>
  );
};
export default WebAssetsSelectedIcon;
