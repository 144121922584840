import { IonCol, IonRow, IonText } from "@ionic/react";
import styled from "styled-components";
import CommentIcon from "../../assests/icons/webCommentsSelectedIcon";
import AssetIcon from "../../assests/icons/webAssetsSelectedIcon";

import AnalyticsHeader from "./analyticsHeader";
import AnalyticsResults from "./analyticsResults";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Text = styled(IonText)`
  font-size: 0.875rem;
  font-weight: 300;
  margin-top: ${({ margin }) => margin};
  display: flex;
  align-items: center;
`;

const DateDataContent = styled.div`
  display: flex;
  margin-top: 8px;
`;

const DateContent = styled.div`
  height: 100px;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #130f26;
  border-radius: 5px;
`;

const ShowDate = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff;
`;

const ShowMonYear = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  color: #ffffff;
`;

const CommentAssetContent = styled(IonCol)`
  margin-left: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px;
`;

const CommentContent = styled(IonRow)`
  flex: 1;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  background: #f5f5f5;
`;
const AssetContent = styled(IonRow)`
  flex: 1;
  border-radius: 5px;
  display: flex;
  background: #f5f5f5;
`;

const DesignColumn = styled.div`
  width: 5px;
  background: #8bddb3;
  border-radius: 5px 0px 0px 5px;
`;

const Comment = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 16px;
  color: var(--text-primary);
`;

const Count = styled.span`
  font-size: 0.875rem;
  font-weight: 700;
  margin-left: 20px;
`;

const Dot = styled.div`
  height: 8px;
  width: 8px;
  background: #ff4f4f;
  border-radius: 50%;
`;

const AnalyticsText = styled(IonText)`
  font-weight: 600;
  font-size: 14px;
`;

const MobContent = styled(IonRow)`
  color: var(--text-primary);
  display: none;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    display: flex;
  }
`;
const MobViewComponents = (props) => {
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const history = useHistory();

  useEffect(() => {
    let date = new Date();

    setDate(date.getDate());
    setMonth(date.toLocaleString("default", { month: "long" }).substring(0, 3));
    setYear(date.getFullYear().toString().substring(2));
  }, []);
  return (
    <MobContent>
      <Text margin="8px">You have recieved</Text>
      <DateDataContent>
        <DateContent>
          <ShowDate>
            {date.toString().length === 1 ? "0" + date : date}
          </ShowDate>
          <ShowMonYear>{month + " " + year}</ShowMonYear>
        </DateContent>
        <CommentAssetContent>
          <CommentContent onClick={() => history.push("/comments")}>
            <DesignColumn />
            <Comment>
              <CommentIcon />
              {props.commentsCount > 0 ? (
                <Count>{props.commentsCount}</Count>
              ) : (
                <>&nbsp;&nbsp;&nbsp;</>
              )}
              &nbsp;
              <Text margin="0px">
                {props.commentsCount > 0
                  ? "New Comments"
                  : "You have no Comments today"}
                &nbsp;&nbsp;
                {props.commentsCount > 0 && <Dot />}
              </Text>
            </Comment>
          </CommentContent>
          <AssetContent onClick={() => history.push("/assets")}>
            <DesignColumn />
            <Comment>
              <AssetIcon />
              {props.assetsCount > 0 ? (
                <Count>{props.assetsCount}</Count>
              ) : (
                <>&nbsp;&nbsp;&nbsp;</>
              )}
              &nbsp;
              <Text margin="0px">
                {props.assetsCount > 0
                  ? "New Assets for approval"
                  : "No new Assets been uploaded today"}
                &nbsp;&nbsp;
                {props.assetsCount > 0 && <Dot />}
              </Text>
            </Comment>
          </AssetContent>
        </CommentAssetContent>
      </DateDataContent>
      <div style={{ marginTop: 40, marginBottom: 30 }}>
        <div style={{ marginBottom: 10 }}>
          <AnalyticsText>Analytics</AnalyticsText>
        </div>
        <AnalyticsHeader
          assets={
            props.analyticsDataObj.countAssets
              ? props.analyticsDataObj.countAssets
              : 0
          }
          approved={
            props.analyticsDataObj.percentApproved
              ? props.analyticsDataObj.percentApproved
              : 0
          }
          spent={props.analyticsDataObj.days ? props.analyticsDataObj.days : 0}
        />
        <AnalyticsResults
          countAssets={
            props.analyticsDataObj.countAssets
              ? props.analyticsDataObj.countAssets
              : 0
          }
          feedbackSatisfied={props.analyticsDataObj.feedbackSatisfied}
          feedbackLiked={props.analyticsDataObj.feedbackLiked}
          feedbackLoved={props.analyticsDataObj.feedbackLoved}
          approvedTasksCount={props.analyticsDataObj.statusApproved}
          pendingTasksCount={props.analyticsDataObj.statusPending}
          disapprovedTasksCount={props.analyticsDataObj.statusDisapproved}
        />
      </div>
    </MobContent>
  );
};

export default MobViewComponents;
