import React from "react";

import PageLayout from "components/pageLayout";
import { Title } from "components/text";
import UserChat from "components/userChat";

const Chat = () => {
  return (
    <PageLayout title="chat">
      <Title as="div" margin="2rem 0 0 0">
        Chat
      </Title>
      <UserChat />
    </PageLayout>
  );
};

export default Chat;
