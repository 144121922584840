import React from "react";

const DownArrow = ({
  width = "10",
  height = "12",
  viewBox = "0 0 5 3",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-1.97154e-08 0.451035C-1.4664e-08 0.335474 0.0432372 0.219311 0.130897 0.131436C0.305031 -0.0443128 0.585779 -0.0437109 0.758729 0.13264L2.50007 1.90939L4.24083 0.13264C4.41378 -0.0437107 4.69512 -0.0443126 4.86925 0.131437C5.04398 0.307186 5.04279 0.592477 4.87044 0.76943L2.81458 2.86759C2.73107 2.95245 2.61794 3 2.50007 3C2.38162 3 2.26849 2.95245 2.18497 2.86759L0.129713 0.76943C0.0432377 0.681555 -2.47404e-08 0.565994 -1.97154e-08 0.451035Z"
        fill="black"
      />
    </svg>
  );
};
export default DownArrow;
