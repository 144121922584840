import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IonPage as ReactIonPage, IonContent, useIonToast } from "@ionic/react";
import { useParams, useHistory } from "react-router-dom";
import { getDate } from "../lib/getDate";
import { IonRow } from "@ionic/react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { DownOutlined } from "@ant-design/icons";

import Header from "components/header";
import Flex from "./flex";
import SideBar from "./review/sideBar";
import ReviewFooter from "./review/reviewFooter";
import ApproveOrReject from "./review/approveOrReject";
import { GET_ASSET, GET_FILE_COMMENTS } from "../graphQL/queries";
import { APPROVE_OR_REJECT_ALL } from "../graphQL/mutations";
import { useRouterQuery } from "../lib/useRouterQuery";

import ReviewImgae from "./review/reviewImage";
import useWindowDimensions from "./useWindowDimensions";
import AssetsActions from "./assets/assetsActions";
import ApproveFeedbackModel from "./review/approveFeedbackModel";
import { getUniqueArrayList } from "lib/getUniqueArrayList";

const ReviewImageWrapper = styled.div`
  flex: 1;
  @media screen and (max-width: 768px) {
    transition: flex 0.18s;
    flex: ${({ isReview }) => (isReview ? 1 : "unset")};
    height: ${({ isReview }) => (isReview ? "100%" : "fit-content")};
    display: flex;
    flex-direction: column;
  }
`;

const Wrapper = styled(Flex)`
  ${({ isReviewPage }) =>
    isReviewPage
      ? `  
    max-width: 792px;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 45px;
    flex-direction:column;
`
      : ` height: 100%;`}

  @media screen and (max-width: 768px) {
    display: block;
    ${({ isReview }) =>
      isReview &&
      `
    display:flex;
    flex: 1;
    flex-direction: column;`}
  }
`;

const SubWrapper = styled.div`
  padding: ${({ padding }) => padding || "20px 6%"};
`;

const AssetsTitileWrapper = styled(Flex)`
  ${({ isReviewPage }) =>
    isReviewPage
      ? ` 
 display: none;
margin-top:20px;
@media screen and (max-width: 768px) {
  display: flex;
  padding: 20px 6% 0 6%;
}`
      : `margin-bottom:25px;`}
  @media screen and (max-width: 768px) {
    padding: 0 4%;
  }
`;
const SubTitle = styled.div`
  color: #929292;
  font-weight: 600;
  font-size: 0.688rem;
  margin-top: 3px;
  margin-bottom: 18px;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1rem;
  line-height: 15px;
  color: var(--text-primary);
  margin-right: 10px;
`;

const SideBarWrapper = styled(Flex)`
  max-width: 850px;
  width: ${({ isVisible }) => (isVisible ? "30vw" : "134px")};
  min-width: ${({ isVisible }) => (isVisible && "522px") || "0px"};
  background: #fff;
  height: auto;
  top: 0;
  right: 0;
  transition: 0.18s all ease-in;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const HeaderContent = styled(IonRow)`
  display: none;
  @media screen and (max-width: 768px) {
    display: ${({ isReview }) => (isReview ? "none" : "block")};
  }
`;

const SideBardContent = styled(IonContent)`
  --background: transparent;
`;
const ArrowIconWrappper = styled.button`
  margin: 12px auto 0 auto;
  color: #767676;
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  display: none;
  @media screen and (max-width: 768px) {
    display: ${({ inverse }) => (inverse ? "none" : "block")};
  }
`;

const ReviewTitleContainer = styled(Flex)`
  flex: 1;
`;

const IonPage = styled(ReactIonPage)`
  padding: ${({ isReviewPage }) => (isReviewPage ? "unset" : "0 6%")};
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;

const IndividualAsset = ({ isReviewPage = false }) => {
  const { task_id } = useParams();
  const [approveOrRejectAll, { loading: approveOrRejectLoading }] = useMutation(
    APPROVE_OR_REJECT_ALL
  );
  const { data, loading } = useQuery(GET_ASSET, {
    variables: {
      taskId: task_id,
    },
  });
  const [getAllComments, { data: fileComments }] = useLazyQuery(
    GET_FILE_COMMENTS,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    }
  );

  const history = useHistory();
  const [present] = useIonToast();
  const { width } = useWindowDimensions();
  const query = useRouterQuery();
  const status = query.get("status");

  const [approveOrRejectStatus, setApproveOrRejectStatus] = useState("");
  const [selectedApproveOption, setSelectedApproveOption] = useState({});
  const [isModalVisible, setModalVisible] = useState(false);
  const [asset, setAsset] = useState({});
  const [rejectFeedbackMessage, setRejectFeedbackMessage] = useState("");
  const [approveOrRejectError, setApproveOrRejectError] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [currentVersionId, setCurrentVersionId] = useState("");
  const [deliverableName, setDeliverableName] = useState("");
  const [fileCommentList, setFileCommentList] = useState([]);
  const [fileCommentInfo, setFileCommentInfo] = useState("");
  const [isReview, setIsReview] = useState(false);
  const [totalFileComment, setTotalFileComment] = useState(0);
  const fileCommentLimit = 100;

  const getStatus = (status) => {
    const currentStatus = status.toLowerCase();
    if (currentStatus === "client_review") {
      return "Pending";
    }
    if (currentStatus === "closed") {
      return "Approved";
    }
    if (currentStatus !== "client_review" || currentStatus !== "closed") {
      return "Rejected";
    }
    return status;
  };

  const formatResponse = (responseData = {}) => {
    let lists = [];
    const listData =
      responseData?.task?.length > 0 ? [...responseData?.task] : [];
    if (listData.length > 0) {
      lists = listData.map((eachList) => ({
        ...eachList,
        delhiveryStatus: `Deliverable ${eachList.deliverables.length}`,
        clientStatus: eachList?.client_status?.toLowerCase(),
        status: eachList.status?.toLowerCase(),
        imageURL: eachList?.deliverables.map((eachRefrences) => ({
          currentVersionId: eachRefrences?.current_version_id,
          clientStatus:
            (eachRefrences?.versions?.length > 0 &&
              eachRefrences?.versions[0]?.client_status?.toLowerCase()) ||
            "",
          version:
            (eachRefrences?.versions?.length > 0 &&
              eachRefrences?.versions[0]?.version) ||
            "",
          status:
            (eachRefrences?.versions?.length > 0 &&
              eachRefrences?.versions[0]?.status) ||
            "",
          ...((eachRefrences?.versions?.length > 0 &&
            eachRefrences?.versions[0]?.resource) ||
            {}),
        })),
      }));
      return lists;
    } else {
      return lists;
    }
  };

  useEffect(() => {
    const formattedResponse = formatResponse(data);
    const versionId = query.get("deliverableId");
    let currentVersion = "";
    if (
      formattedResponse.length > 0 &&
      formattedResponse[0].imageURL.length > 0
    ) {
      if (versionId) {
        currentVersion = formattedResponse[0].imageURL.find(
          (each) => each?.currentVersionId === versionId
        );
      } else {
        currentVersion = formattedResponse[0].imageURL[0];
      }
    }

    setCurrentVersionId(currentVersion?.currentVersionId || "");
    localStorage.setItem(
      "currentVersionId",
      currentVersion?.currentVersionId || ""
    );

    getAllComments({
      variables: {
        limit: fileCommentLimit,
        versionId: currentVersion?.currentVersionId,
        commentLimit: 6,
        userId: localStorage.getItem("userId"),
      },
    });
    setDeliverableName(currentVersion?.name || "");

    setAsset(formattedResponse[0] || {});
  }, [data]);

  useEffect(() => {
    if (status) {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    const commentsData = [...(fileComments?.thread || [])];
    const formattedArray = getUniqueArrayList([
      ...fileCommentList,
      ...commentsData,
    ]);
    setFileCommentList([...formattedArray]);
    setTotalFileComment(fileComments?.thread_aggregate?.aggregate?.count || 0);
    setFileCommentInfo(fileComments);
  }, [fileComments]);

  const onApproveOrReject = (status) => {
    setApproveOrRejectStatus(status);
    setSelectedApproveOption({});
    setRejectFeedbackMessage("");
    setApproveOrRejectError("");

    if (status) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const onSelectApproveFeedback = (value) => {
    setSelectedApproveOption(value);
    setApproveOrRejectError("");
  };

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  const handleErrorMessage = (status, message) => {
    setApproveOrRejectError(message);
  };

  const onSubmitApproveOrReject = async (status) => {
    if (status === "approve" && !selectedApproveOption?.value) {
      setApproveOrRejectError("Please select the feedback of the Design");
      return;
    }

    try {
      let userId = localStorage.getItem("userId");
      const { data } = await approveOrRejectAll({
        variables: {
          feedbackMessage:
            status === "approve"
              ? selectedApproveOption?.label || ""
              : rejectFeedbackMessage || "",
          type: status === "approve" ? "APPROVED" : "REJECTED",
          taskId: task_id,
          userId,
        },
      });
      if (data?.approveOrRejectAll?.data?.affected_rows >= 1) {
        if (status === "approve") {
          setModalVisible(true);
        } else {
          history.push("/assets");
        }
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    }
  };

  const onCloseFeedbackModal = () => {
    setModalVisible(false);
    history.push("/assets");
  };
  const onRejectFeedbackInput = (value) => {
    setApproveOrRejectError("");
    setRejectFeedbackMessage(value);
  };
  const onSelectSidebarStatus = (sidbarValue) => {
    setIsVisible(true);
    setSidebarStatus(sidbarValue);
  };

  const handleImageChange = (versionId, versionName) => {
    setCurrentVersionId(versionId);
    setFileCommentList([]);
    setTotalFileComment(0);
    setFileCommentInfo("");
    localStorage.setItem("currentVersionId", versionId || "");
    getAllComments({
      variables: {
        limit: fileCommentLimit,
        versionId,
        commentLimit: 6,
        userId: localStorage.getItem("userId"),
      },
    });
    setDeliverableName(versionName);
  };

  const onReviewToggle = () => {
    setIsReview(!isReview);
  };

  const getFileOffset = (offsetValue) => {
    getAllComments({
      variables: {
        offset: offsetValue,
        limit: fileCommentLimit,
        versionId: localStorage.getItem("currentVersionId"),
        commentLimit: 6,
        userId: localStorage.getItem("userId"),
      },
    });
  };

  const onPostReplyComment = (threadId, repliedComment) => {
    const fileCommentCopy = [...fileCommentList];
    const updatedFileComment = fileCommentCopy.map((each) =>
      each.id === threadId
        ? {
            ...each,
            comments: [...each.comments, { ...repliedComment }],
            comments_aggregate: {
              ...each.comments_aggregate,
              aggregate: {
                ...each.comments_aggregate.aggregate,
                count: each.comments_aggregate.aggregate.count + 1,
              },
            },
          }
        : each
    );
    setFileCommentList([...updatedFileComment]);
  };

  const onPostNewComment = (commentedText) => {
    const fileCommentCopy = [...fileCommentList];
    fileCommentCopy.unshift(commentedText);
    setFileCommentList([...fileCommentCopy]);
  };

  const onUpdateAsset = (version) => {
    const imageList = [...(asset?.imageURL || [])];
    const assetData = { ...asset };
    assetData.imageURL = imageList.map((each) =>
      each.currentVersionId === version.id
        ? { ...each, clientStatus: version?.client_status }
        : { ...each }
    );
    setAsset({ ...assetData });
  };

  const onBrowserBackClick = () => {
    if (approveOrRejectStatus) {
      setApproveOrRejectStatus("");
    } else {
      history.push("/assets");
    }
  };

  return (
    <IonPage isReviewPage={isReviewPage}>
      {isReviewPage && (
        <HeaderContent isReview={isReview}>
          <Header arrowCircle onClickBack={onBrowserBackClick} />
        </HeaderContent>
      )}

      <IonContent>
        <Wrapper isReviewPage={!isReviewPage} isReview={isReview}>
          <ReviewTitleContainer column fullWidth>
            {(!isReview || !isReviewPage) && (
              <AssetsTitileWrapper column isReviewPage={isReviewPage}>
                <Flex alignCenter>
                  <Title>{asset?.name}</Title>
                </Flex>
                {isReviewPage && (
                  <SubTitle>
                    Created on:&nbsp;
                    {asset?.created_at ? getDate(asset?.created_at) : "--"}
                  </SubTitle>
                )}
              </AssetsTitileWrapper>
            )}
            <ReviewImageWrapper isReview={isReview}>
              <ReviewImgae
                id={asset?.id}
                imageURL={asset?.imageURL || []}
                width={width}
                approveOrRejectStatus={approveOrRejectStatus}
                onApproveOrReject={onApproveOrReject}
                isVisible={isVisible}
                onImageChange={handleImageChange}
                fileCommentList={fileCommentList}
                isReview={isReview}
                totalFileComment={totalFileComment}
                setTotalFileComment={setTotalFileComment}
                onPostReplyComment={onPostReplyComment}
                isReviewPage={isReviewPage}
                deliverableName={deliverableName}
                status={asset?.client_status}
                asset={asset}
                onPostNewComment={onPostNewComment}
                onUpdateAsset={onUpdateAsset}
                onReviewToggle={onReviewToggle}
                selectedItem={currentVersionId}
              />
            </ReviewImageWrapper>
          </ReviewTitleContainer>
          <>
            {width < 768 || !isReviewPage ? (
              !isReview ? (
                approveOrRejectStatus ? (
                  <ApproveOrReject
                    approveOrRejectStatus={approveOrRejectStatus}
                    onSelectApproveFeedback={onSelectApproveFeedback}
                    onTextInput={onRejectFeedbackInput}
                    selectedApproveOption={selectedApproveOption}
                  />
                ) : (
                  <SubWrapper padding={isReviewPage ? "0px 6%" : "0 4%"}>
                    <AssetsActions
                      taskId={task_id || ""}
                      path={isReviewPage ? "review" : "individual-asset"}
                      padding={isReview ? 0 : "20px 0"}
                      compareOption={isReviewPage}
                      fileCommentList={fileCommentList}
                      totalFileComment={totalFileComment}
                      projectDetails={asset}
                      getFileOffset={getFileOffset}
                      currentVersionId={currentVersionId}
                    />
                  </SubWrapper>
                )
              ) : (
                ""
              )
            ) : (
              (isReviewPage && (
                <SideBarWrapper isVisible={isVisible}>
                  <SideBardContent scrollEvents>
                    {approveOrRejectStatus ? (
                      <ApproveOrReject
                        approveOrRejectStatus={approveOrRejectStatus}
                        onSelectApproveFeedback={onSelectApproveFeedback}
                        onTextInput={onRejectFeedbackInput}
                        selectedApproveOption={selectedApproveOption}
                        errorMessage={approveOrRejectError}
                      />
                    ) : (
                      <SideBar
                        isVisible={isVisible}
                        onClick={onSelectSidebarStatus}
                        onCloseSideBar={() => setIsVisible(false)}
                        sidebarStatus={sidebarStatus}
                        currentVersionId={currentVersionId}
                        assetsList={asset}
                        fileCommentList={fileCommentList}
                        fillCommentTotalCount={totalFileComment}
                        fileCommentInfo={fileCommentInfo}
                        getFileOffset={getFileOffset}
                      />
                    )}
                  </SideBardContent>
                </SideBarWrapper>
              )) ||
              ""
            )}
          </>
        </Wrapper>
      </IonContent>
      {isReviewPage && !isReview && (
        <>
          <ReviewFooter
            name={asset?.name}
            status={asset?.clientStatus}
            createdAt={asset?.created_at}
            approveOrRejectStatus={approveOrRejectStatus}
            onApproveOrReject={onApproveOrReject}
            onSubmitApproveOrReject={onSubmitApproveOrReject}
            selectedApproveOption={selectedApproveOption}
            isLoading={approveOrRejectLoading}
            rejectFeedbackMessage={rejectFeedbackMessage}
            deliverableName={deliverableName}
            handleErrorMessage={handleErrorMessage}
            onReviewToggle={onReviewToggle}
            isReview={isReview}
          />
          <ApproveFeedbackModel
            isModalVisible={isModalVisible}
            onClose={onCloseFeedbackModal}
            selectedApproveFeedback={selectedApproveOption}
          />
        </>
      )}
    </IonPage>
  );
};

export default IndividualAsset;
