import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Input } from "antd";
import Icon, { RightOutlined } from "@ant-design/icons";

import Flex from "../flex";
import SearchIcon from "../../assests/icons/SearchIcon";
import FilterIcon from "../../assests/icons/FilterIcon";
import FilterDrawer from "./filterDrawer";
import ExpandableSearch from "./expandableSearch";

const Wrapper = styled(Flex)`
  padding: 20px 0;
  @media screen and (max-width: 768px) {
    padding: 20px 30px;
  }
`;

const HeaderTitle = styled.h1`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.75rem;
  line-height: 34px;
  letter-spacing: 0.2px;
  color: var(--text-primary);
  margin-bottom: 0;
`;

const rectangleShape = css`
  background: #f5f5f5;
  border-radius: 100px;
  max-width: 332px;
  width: 100%;
  height: 44px;
`;

const SearchField = styled(Input)`
  ${rectangleShape}
  border: 1px solid #f5f5f5;

  input {
    background: #f5f5f5;
    font-size: 0.89rem;
  }
  .assets-search-icon {
    font-size: 15.2px;
    margin-right: 8px;
    margin-left: 6px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const Subwrapper = styled(Flex)``;
const FilterButton = styled.button`
  ${rectangleShape}
  max-width:112px;
  min-width: 100px;
  margin-left: 23px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 11px;
  color: var(--text-primary);

  .assets-filter-icon {
    margin: 0 8px;
  }
  &:hover {
    border: 1px solid #40a9ff;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const FilterMobile = styled.button`
  background: #f5f5f5;
  border-radius: 5px;
  font-size: 12px;
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  height: 23px;
  display: none;
  margin-right: 1%;
  min-width: 60px;
  .filter-text {
    text-transform: capitalize;
    font-weight: 700;
  }
  @media screen and (max-width: 360px) {
    max-width: 128px;
    margin-right: 4px;
  }
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const AssetsHeader = ({
  searchAssets = undefined,
  filterAssets = undefined,
  viewAll = undefined,
  filteredResults,
  clearAllFilter = undefined,
}) => {
  const [isFilterDrawer, setFilterDrawer] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    state: "all",
    space: "all",
    type: "all",
  });
  const [filterText, setFilterText] = useState("");
  const onViewAll = () => {
    viewAll();
    setFilterDrawer(false);
  };

  const resetFilter = () => {
    setSelectedFilter({ state: "all", space: "all", type: "all" });
    if (filterAssets) filterAssets({ state: "all", space: "all", type: "all" });
    setFilterText("");
    setFilterDrawer(false);
    if (clearAllFilter) clearAllFilter();
  };

  const selectedItem = (field, value) => {
    if (field === "type") {
      setFilterText(`File Type (${value})`);
    } else {
      setFilterText(`${field} Type (${value})`);
    }

    setSelectedFilter({ ...selectedFilter, [field]: value });
    if (filterAssets) filterAssets({ ...selectedFilter, [field]: value });
  };

  return (
    <Wrapper spaceBetween alignCenter>
      <HeaderTitle>Assets</HeaderTitle>
      <Subwrapper alignCenter>
        <FilterMobile onClick={() => setFilterDrawer(true)}>
          {filterText ? (
            <span>
              Filter By : <span className="filter-text">{filterText}</span>
            </span>
          ) : (
            "Filter"
          )}
          <RightOutlined
            style={{
              fontSize: "10px",
              fontWeight: "bold",
              marginLeft: "4px",
            }}
          />
        </FilterMobile>
        <SearchField
          placeholder="Search"
          onChange={searchAssets}
          prefix={
            <Icon component={SearchIcon} className="assets-search-icon" />
          }
        />
        <ExpandableSearch onSearch={searchAssets} />
        <FilterButton onClick={() => setFilterDrawer(true)}>
          <Icon component={FilterIcon} className="assets-filter-icon" />
          Filter
        </FilterButton>
      </Subwrapper>
      <FilterDrawer
        filterAssets={filterAssets}
        visible={isFilterDrawer}
        onClose={() => setFilterDrawer(false)}
        filteredResults={filteredResults}
        viewAll={onViewAll}
        selectedItem={selectedItem}
        selectedFilter={selectedFilter}
        resetFilter={resetFilter}
        clearAllFilter={clearAllFilter}
      />
    </Wrapper>
  );
};

export default AssetsHeader;
