import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Image as AntdImage, Empty } from "antd";
import { Carousel as ReactCarousel } from "react-responsive-carousel";
import { useLazyQuery } from "@apollo/client";
import { GET_VERSIONS } from "../../../graphQL/queries";
import { IonSpinner } from "@ionic/react";

import PlaceholderImage from "../../../assests/images/placeholder.jpg";
import Flex from "../../flex";
import Chip from "../../chipStatus";

const CompareTitle = styled.h1`
  font-family: Montserrat;
  font-size: 28px;
  color: var(--text-primary);
  font-weight: normal;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const Image = styled(AntdImage)`
  width: 100%;
  max-height: 22vw;
  min-height: 150px;
  height: 100%;
  object-fit: contain;
  background: #ccc;
  @media screen and (max-width: 768px) {
    max-height: 41vw;
    min-height: 166px;
    border-radius: 5px;
  }
`;

const EmptyWrapper = styled(Flex)`
  width: 100%;
  max-height: 22vw;
  min-height: 150px;
  height: 100%;
  @media screen and (max-width: 768px) {
    max-height: 41vw;
    min-height: 166px;
    border-radius: 5px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 22px;
  margin-top: 5%;
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    max-width: 493px;
    margin: 20px auto;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;
const ImageWrapper = styled(Flex)`
  width: 100%;
  .ant-image {
    width: 100%;
    height: 22vw;
    min-height: 150px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    padding-bottom: 30px;
    padding-top: 20px;
    border-bottom: 1px solid #e1e1e1;
    &:first-child {
      padding-top: 0px;
    }
  }
`;
const AssetsTitle = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 0.8rem;
  color: var(--text-primary);
  margin-right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  @media screen and (max-width: 768px) {
    max-width: 160px;
  }
`;

const AssetTitleWrapper = styled(Flex)`
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    margin: 0 0 10px 0;
  }
`;

const ChipStatus = styled(Chip)`
  @media screen and (max-width: 768px) {
    font-size: 9.5px;
    padding: 3px 10px;
    min-height: 20px;
    min-width: 62px;
  }
`;

const Subtitle = styled.div`
  margin-top: 2px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--text-primary);
`;

const Carousel = styled(ReactCarousel)`
  .carousel.carousel-slider {
    height: 100%;
  }
`;

const images = [
  {
    url: "https://images.unsplash.com/photo-1634893448760-0cb6e1af22f8?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDI0fE04alZiTGJUUndzfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
  },
  {
    url: "https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=853&q=80",
  },
  {
    url: "https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=853&q=80",
  },
];

const CompareAssets = ({
  assetsList = [...images],
  selectedAssetVersion = {},
  currentVersion = {
    name: "3D Tasker",
    status: "pending",
    url: "https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=853&q=80",
  },
}) => {
  const [getVersions, { data, loading }] = useLazyQuery(GET_VERSIONS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });
  const [compareVersions, setCompareVersions] = useState([]);
  const [currentVersionId, setCurrentVersionId] = useState("");
  const [selectedCompareVersion, setSelectedCompareVersion] = useState("");
  const [selectedVersionIndex, setSelectedVersionIndex] = useState(0);

  useEffect(() => {
    if (data?.task_deliverable && data?.task_deliverable.length > 0) {
      const deliverableVersion = data?.task_deliverable[0]?.versions.slice(1);
      const versions = deliverableVersion.map((each, index) => ({
        currentVersionId: data?.task_deliverable[0]?.current_version_id,
        id: each.id,
        version: each?.version,
        status: each?.status,
        ...each.resource,
      }));
      setCompareVersions(versions);
      const currentCompareVersion =
        versions.length > 0 ? { ...versions[0] } : {};
      setSelectedCompareVersion(currentCompareVersion);
    }
  }, [data]);

  useEffect(() => {
    getVersions({
      variables: {
        currentVersionId: selectedAssetVersion?.currentVersionId,
      },
    });
    setCurrentVersionId(selectedAssetVersion?.currentVersionId);
  }, [selectedAssetVersion?.currentVersionId]);

  useEffect(() => {
    setSelectedVersionIndex(selectedAssetVersion?.index);
  }, [selectedAssetVersion?.index]);

  const onCurrentVersionChange = (index, value) => {
    setSelectedVersionIndex(0);
    if (value?.props.id) {
      setCurrentVersionId(value?.props.id);
      getVersions({
        variables: {
          currentVersionId: value?.props.id,
        },
      });
    }
  };
  const getCurrentVersion = (id) => {
    return assetsList?.imageURL?.length > 0
      ? assetsList?.imageURL.find((each) => each.currentVersionId === id)
      : {};
  };

  const onCompareVersion = (index, value) => {
    setSelectedCompareVersion({
      name: value?.props?.name,
      id: value?.props?.id,
      status: value?.props?.status,
      version: value?.props?.version,
    });
  };

  return (
    <div>
      <CompareTitle>Compare Versions</CompareTitle>
      <Wrapper>
        <ImageWrapper column>
          <Carousel
            showArrows
            showThumbs={false}
            swipeable
            emulateTouch
            swipeScrollTolerance={5}
            onChange={onCurrentVersionChange}
            selectedItem={
              assetsList?.imageURL?.length > 0
                ? assetsList?.imageURL.findIndex(
                    (each) =>
                      each.currentVersionId ===
                      selectedAssetVersion?.currentVersionId
                  )
                : 0
            }
          >
            {assetsList?.imageURL?.map(
              ({ url, currentVersionId = "", name = "" }, assetIndex) => (
                <div
                  key={`current-version-${assetIndex}`}
                  id={currentVersionId}
                  name={name}
                >
                  <Image
                    preview={false}
                    src={url || "error"}
                    fallback={PlaceholderImage}
                  />
                </div>
              )
            )}
          </Carousel>

          <AssetTitleWrapper alignCenter>
            <div>
              <AssetsTitle>
                {getCurrentVersion(currentVersionId)?.name || "--"}
                rwerwerwerrewerwerrwerwerewrwerwer
              </AssetsTitle>
              <Subtitle>
                Version: {getCurrentVersion(currentVersionId)?.version || "--"}
              </Subtitle>
            </div>
            {getCurrentVersion(currentVersionId)?.status && (
              <ChipStatus
                status={getCurrentVersion(
                  currentVersionId
                )?.status.toLowerCase()}
              >
                {getCurrentVersion(currentVersionId)?.status.toLowerCase()}
              </ChipStatus>
            )}
          </AssetTitleWrapper>
        </ImageWrapper>
        <ImageWrapper column>
          {loading ? (
            <EmptyWrapper
              center
              alignCenter
              style={{ maxHeight: " 22vw", minHeight: "150px" }}
            >
              <IonSpinner name="crescent" />
            </EmptyWrapper>
          ) : compareVersions?.length <= 0 ? (
            <EmptyWrapper center alignCenter>
              <Empty description="No versions available !" />
            </EmptyWrapper>
          ) : (
            <>
              <Carousel
                showArrows
                style={{ maxHeight: " 22vw", minHeight: "150px" }}
                showThumbs={false}
                swipeable
                emulateTouch
                selectedItem={selectedVersionIndex}
                onChange={onCompareVersion}
              >
                {compareVersions?.map(
                  (
                    {
                      name = "",
                      currentVersionId = "",
                      status = "",
                      version = "",
                      url,
                    },
                    versionIndex
                  ) => (
                    <div
                      key={`compare-version-${versionIndex}`}
                      id={currentVersionId}
                      name={name}
                      status={status}
                      version={version}
                    >
                      <Image
                        preview={false}
                        src={url || "error"}
                        fallback={PlaceholderImage}
                      />
                    </div>
                  )
                )}
              </Carousel>

              <AssetTitleWrapper alignCenter>
                <div>
                  <AssetsTitle>
                    {selectedCompareVersion?.name || "--"}
                  </AssetsTitle>
                  <Subtitle>
                    version: {selectedCompareVersion?.version || "--"}
                  </Subtitle>
                </div>

                {selectedCompareVersion?.status && (
                  <ChipStatus
                    status={selectedCompareVersion?.status.toLowerCase()}
                  >
                    {selectedCompareVersion?.status.toLowerCase()}
                  </ChipStatus>
                )}
              </AssetTitleWrapper>
            </>
          )}
        </ImageWrapper>
      </Wrapper>
    </div>
  );
};

export default CompareAssets;
