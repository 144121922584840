import { IonRow, IonCol, IonText } from "@ionic/react";
import styled from "styled-components";

const Content = styled(IonRow)`
  width: 100%;
  align-items: center;
  background-color: ${({ background }) =>
    background ? background : "#f5f5f5"};
  border-radius: 4px;
  color: var(--text-primary);
`;

const MenuItem = styled(IonCol)`
  flex: 1;
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? "#E7E7E7" : "")};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  font-size: 0.875rem;
  &:hover {
    background-color: #e7e7e7;
  }
`;
const Text = styled(IonText)``;

const Dot = styled.div`
  height: 8px;
  width: 8px;
  background: #ff4f4f;
  border-radius: 50%;
`;

const Menu = ({ title, length, menu, newComments, handleMenuSelector }) => {
  return (
    <MenuItem
      selected={title === menu ? true : false}
      onClick={() => handleMenuSelector(title)}
    >
      <Text>
        {title} {length && "(" + length + ")"}
      </Text>
      {newComments && (
        <>
          &nbsp;&nbsp;
          <Dot />
        </>
      )}
    </MenuItem>
  );
};
const CommentsSelectorHeader = ({
  menuOption1,
  menuOption2,
  menuOption3,
  menu,
  menu1Length,
  menu2Length,
  menu3Length,
  newMenu1,
  newMenu2,
  newMenu3,
  handleMenuSelector,
  background,
}) => {
  return (
    <Content background={background}>
      <Menu
        title={menuOption1}
        menu={menu}
        length={menu1Length > 0 ? menu1Length : ""}
        newComments={newMenu1}
        handleMenuSelector={handleMenuSelector}
      />
      <Menu
        title={menuOption2}
        menu={menu}
        length={menu2Length > 0 ? menu2Length : ""}
        newComments={newMenu2}
        handleMenuSelector={handleMenuSelector}
      />
      <Menu
        title={menuOption3}
        menu={menu}
        length={menu3Length > 0 ? menu3Length : ""}
        newComments={newMenu3}
        handleMenuSelector={handleMenuSelector}
      />
    </Content>
  );
};

export default CommentsSelectorHeader;
