import { IonCol, IonRow, IonText, IonIcon } from "@ionic/react";
import styled from "styled-components";
import { Progress } from "antd";
import thumbsUp from "../../assests/icons/svg/thumbsUp.svg";
import heartOutline from "../../assests/icons/svg/heartOutlined.svg";
import checkCircle from "../../assests/icons/svg/checkCircle.svg";
import upArrow from "../../assests/icons/svg/upArrow.svg";
import rectangle from "../../assests/icons/svg/rectangle.svg";
import blankRectangle from "../../assests/icons/svg/blank_rectangle.svg";
import downArrow from "../../assests/icons/svg/downArrow.svg";

const BarContent = styled(IonCol)`
  //   border: 1px solid green;
  padding: 20px 20px 10px 20px;
  margin-right: 1.5rem;
  background: #ffffff;
  border-radius: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContent = styled(IonCol)`
  //   border: 1px solid blue;
  padding: 0px;
  flex: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const HeaderContent = styled(IonRow)`
  display: flex;
  justify-content: center;
  //   padding: 20px 0px;
  width: 100%;
`;
const Header = styled(IonRow)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  background: #f5f5f5;
  border-radius: 8px;
  width: 100%;
`;

const NumberText = styled(IonText)`
  font-size: 0.938rem;
  font-weight: 700;
`;

const HeaderText = styled(IonText)`
  font-size: 0.75rem;
  font-weight: 700;
`;
const Icon = styled(IonIcon)`
  font-size: 1.375rem;
  margin-left: 12px;
  transform: rotate(90deg);
`;

const IconA = styled(IonIcon)`
  font-size: 1.625rem;
`;
const IconB = styled(IonIcon)`
  font-size: 5rem;
`;
const IconC = styled(IonIcon)`
  font-size: 5rem;
`;
const BarsContainer = styled.div`
  margin-top: 16px;
  height: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BarsContent = styled.div`
  transform: rotate(270deg);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BarText = styled(IonText)`
  font-size: 0.875rem;
  font-weight: 300;
`;
const BarContnent = styled.div`
  display: flex;
  align-items: center;
  .ant-progress-inner {
    background-color: #e2e2e2;
  }
`;
const ProgressBarContent = styled.div`
  display: flex;
  align-items: center;
`;
const BarValueContent = styled.div`
    margin-top: 6px;
    font-size: 0.75rem;
    height: 20px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: rgb(245, 245, 245);
    transform: rotate(
90deg
);
    font-weight: 700;
}
`;

const Card = styled(IonCol)`
  padding: 0px 20px 0px 20px;
  max-height: 90px;
  width: 100%;
  background: ${({ background }) => background || "#F5F5F5"};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: unset;
  font-weight: 700;
  color: #ffffff;
  position: relative;
  margin-top: 20px;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    ${CardContent} {
      flex: 0.5;
    }
    ${BarsContainer} {
      width: 100%;
      margin-top: 0px;
      height: 200px;
      padding-right: 6px;
    }
    ${BarsContent} {
      justify-content: space-around;
    }
    ${BarContent} {
      background: #f5f5f5;
      border-radius: 12px;
      padding: 0px;
      margin-right: 1rem;
    }
    ${HeaderContent} {
      margin-bottom: 10px;
      padding: 0px 0px 6px 0px;
    }
    ${Header} {
      border-radius: 12px 12px 0px 0px;
      background: #e2e2e2;
    }
    ${BarValueContent} {
      background: #130f26;
      color: #ffffff;
      width: 36px;
    }
  }
  @media (min-width: 661px) and (max-width: 768px) {
    ${BarsContainer} {
      width: 56%;
    }
  }
  @media (min-width: 551px) and (max-width: 660px) {
    ${BarsContainer} {
      width: 65%;
    }
  }
  @media (min-width: 451px) and (max-width: 550px) {
    ${BarsContainer} {
      width: 80%;
    }
  }
  @media (min-width: 350px) and (max-width: 450px) {
    ${BarsContainer} {
      width: 100%;
    }
  }
`;

const ProgressBar = ({ title, value, icon, strokeColor, count }) => {
  return (
    <ProgressBarContent>
      <BarValueContent>{count}</BarValueContent>
      <div style={{ flex: 1 }}>
        <BarText>{title}</BarText>
        <BarContnent>
          <Progress
            strokeColor={strokeColor}
            strokeWidth={9}
            percent={value}
            showInfo={false}
            // background="red"
            style={{ fontSize: 0, background: "#E2E2E2" }}
          />
          <Icon icon={icon} slot="start" />
        </BarContnent>
      </div>
    </ProgressBarContent>
  );
};

const CardComponent = ({
  value = 0,
  title = "",
  icon1,
  icon2,
  icon3,
  background,
}) => {
  return (
    <Card background={background}>
      <div style={{ fontSize: "1.5rem" }}>{value}</div>
      <div style={{ fontSize: "0.75rem" }}>{title}</div>
      <div style={{ position: "absolute", right: 14 }}>
        <IconA icon={icon1} slot="start" />
      </div>
      <div style={{ position: "absolute", right: 0, top: 10 }}>
        <IconB icon={icon2} slot="start" />
      </div>
      <div style={{ position: "absolute", right: 18, top: -3 }}>
        <IconC icon={icon3} slot="start" />
      </div>
    </Card>
  );
};

const AnalyticsResults = (props) => {
  const handleProgressValue = (feedbackCount, approvedTaskCount) => {
    if (feedbackCount === 0 || approvedTaskCount === 0) {
      return 0;
    } else {
      return Math.round((feedbackCount / approvedTaskCount) * 100);
    }
  };
  return (
    <Content>
      <BarContent>
        <HeaderContent>
          <Header>
            <NumberText>
              {props.countAssets ? props.countAssets : "0"}
            </NumberText>
            &nbsp;
            <HeaderText>Assets Created</HeaderText>
          </Header>
        </HeaderContent>
        <BarsContainer>
          <BarsContent>
            <ProgressBar
              title={"SATISFIED"}
              value={handleProgressValue(
                props.feedbackSatisfied ? props.feedbackSatisfied : 0,
                props.approvedTasksCount ? props.approvedTasksCount : 0
              )}
              icon={checkCircle}
              strokeColor="#5961F9"
              count={props.feedbackSatisfied ? props.feedbackSatisfied : 0}
            />
            <ProgressBar
              title={"LIKED"}
              value={handleProgressValue(
                props.feedbackLiked ? props.feedbackLiked : 0,
                props.approvedTasksCount ? props.approvedTasksCount : 0
              )}
              icon={thumbsUp}
              strokeColor="#8BDDB3"
              count={props.feedbackLiked ? props.feedbackLiked : 0}
            />
            <ProgressBar
              title={"LOVED"}
              value={handleProgressValue(
                props.feedbackLoved ? props.feedbackLoved : 0,
                props.approvedTasksCount ? props.approvedTasksCount : 0
              )}
              icon={heartOutline}
              strokeColor="#FF6F6F"
              count={props.feedbackLoved ? props.feedbackLoved : 0}
            />
          </BarsContent>
        </BarsContainer>
      </BarContent>
      <CardContent>
        <CardComponent
          value={props.approvedTasksCount ? props.approvedTasksCount : 0}
          title="APPROVED"
          icon1={upArrow}
          icon2={rectangle}
          icon3={blankRectangle}
          background={"#8BDDB3"}
        />
        <CardComponent
          value={props.pendingTasksCount ? props.pendingTasksCount : 0}
          title="PENDING"
          icon1={upArrow}
          icon2={rectangle}
          icon3={blankRectangle}
          background={"#FFDC3E"}
        />
        <CardComponent
          value={props.disapprovedTasksCount ? props.disapprovedTasksCount : 0}
          title="DISAPPROVED"
          icon1={downArrow}
          icon2={rectangle}
          icon3={blankRectangle}
          background={"#FF6F6F"}
        />
      </CardContent>
    </Content>
  );
};

export default AnalyticsResults;
