import { useIonToast } from "@ionic/react";

export const useToastMessage = () => {
  const [present] = useIonToast();

  const showToastMessage = ({
    color = "success",
    message = "",
    duration = 3000,
  }) => {
    return present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration,
    });
  };

  return { showToastMessage };
};
