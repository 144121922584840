import React from "react";

const Docs = ({
  height = "30",
  width = "30",
  viewBox = "0 0 30 30",
  // color = "none",
  fill = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0C6.46875 0 5.625 0.84375 5.625 1.875V28.125C5.625 29.1562 6.46875 30 7.5 30H26.25C27.2812 30 28.125 29.1562 28.125 28.125V7.5L20.625 0H7.5Z"
        fill="#E2E5E7"
      />
      <path
        d="M22.5 7.5H28.125L20.625 0V5.625C20.625 6.65625 21.4688 7.5 22.5 7.5Z"
        fill="#B0B7BD"
      />
      <path d="M28.125 13.125L22.5 7.5H28.125V13.125Z" fill="#CAD1D8" />
      <path
        d="M24.375 24.375C24.375 24.8906 23.9531 25.3125 23.4375 25.3125H2.8125C2.29688 25.3125 1.875 24.8906 1.875 24.375V15C1.875 14.4844 2.29688 14.0625 2.8125 14.0625H23.4375C23.9531 14.0625 24.375 14.4844 24.375 15V24.375Z"
        fill="#F15642"
      />
      <path
        d="M5.96094 17.7628C5.96094 17.5153 6.15594 17.2453 6.47 17.2453H8.20156C9.17656 17.2453 10.0541 17.8978 10.0541 19.1484C10.0541 20.3334 9.17656 20.9934 8.20156 20.9934H6.95V21.9834C6.95 22.3134 6.74 22.5 6.47 22.5C6.2225 22.5 5.96094 22.3134 5.96094 21.9834V17.7628ZM6.95 18.1894V20.0569H8.20156C8.70406 20.0569 9.10156 19.6134 9.10156 19.1484C9.10156 18.6244 8.70406 18.1894 8.20156 18.1894H6.95Z"
        fill="white"
      />
      <path
        d="M11.5214 22.5C11.2739 22.5 11.0039 22.365 11.0039 22.0359V17.7778C11.0039 17.5087 11.2739 17.3128 11.5214 17.3128H13.238C16.6636 17.3128 16.5886 22.5 13.3055 22.5H11.5214ZM11.9939 18.2278V21.5859H13.238C15.262 21.5859 15.352 18.2278 13.238 18.2278H11.9939Z"
        fill="white"
      />
      <path
        d="M17.8048 18.2878V19.4794H19.7164C19.9864 19.4794 20.2564 19.7494 20.2564 20.0109C20.2564 20.2584 19.9864 20.4609 19.7164 20.4609H17.8048V22.035C17.8048 22.2975 17.6183 22.499 17.3558 22.499C17.0258 22.499 16.8242 22.2975 16.8242 22.035V17.7769C16.8242 17.5078 17.0267 17.3119 17.3558 17.3119H19.9873C20.3173 17.3119 20.5123 17.5078 20.5123 17.7769C20.5123 18.0169 20.3173 18.2869 19.9873 18.2869H17.8048V18.2878Z"
        fill="white"
      />
      <path
        d="M23.4375 25.3125H5.625V26.25H23.4375C23.9531 26.25 24.375 25.8281 24.375 25.3125V24.375C24.375 24.8906 23.9531 25.3125 23.4375 25.3125Z"
        fill="#CAD1D8"
      />
    </svg>
  );
};
export default Docs;
