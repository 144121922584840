import React, { useState } from "react";
import styled from "styled-components";
import { Button as AntdButton, Input } from "antd";
import Flex from "../flex";

const TextArea = styled(Input.TextArea)`
  border: none !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 18px;
  color: var(--text-primary);
  &:focus {
    box-shadow: none !important;
  }
  margin-bottom: ${({ column }) => (column ? "10px" : "2px")};
  margin-top: ${({ column }) => (column ? " 18px" : "2px")};
`;

const PostButton = styled(AntdButton)`
  align-self: ${({ iscenter }) => (iscenter ? "center" : "flex-end")};
  span {
    text-decoration-line: underline;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    color: #467aff;
    font-size: 0.75rem;
  }
`;

const CommentTextAreaWrapper = styled(Flex)`
  position: relative;
  width: 100%;
  border-top: ${({ borderBottom }) =>
    borderBottom ? "1px solid #C4C4C4" : "unset"};
`;

const CommentTextArea = ({
  column = false,
  commentText = "",
  commentPlaceholder = "Add Comment...",
  autoSizeOptions = { minRows: 2, maxRows: 3 },
  postComment = undefined,
  isLoading = false,
  borderBottom = false,
  onComment = undefined,
}) => {
  return (
    <CommentTextAreaWrapper column={column} borderBottom={borderBottom}>
      <TextArea
        value={commentText}
        autoFocus
        onChange={onComment}
        placeholder={commentPlaceholder}
        autoSize={autoSizeOptions}
      />
      <PostButton
        type="link"
        onClick={postComment}
        loading={isLoading}
        disabled={!commentText}
        iscenter={!column}
      >
        Post
      </PostButton>
    </CommentTextAreaWrapper>
  );
};

export default CommentTextArea;
