import Home from "../../pages/home";
import Welcome from "../../pages/welcome";

import Login from "../../pages/login";
import ForgotPassword from "../../pages/login/forgotPassword";
import ChangePassword from "../../pages/login/changePassword";
import OTPLogin from "../../pages/login/otpLogin";
import LoginWithPhoneNumber from "../../pages/login/loginWithPhoneNumber";

import Comments from "../../pages/comments";
import Assets from "../../pages/assets";
import Messages from "../../pages/messages";
import IndividualAsset from "../../pages/assets/individualAsset";
import ReviewNow from "../../pages/review/index";
import CompareAsset from "../../pages/review/compareAsset";
import Profile from "../../pages/profile";
import Chat from "pages/chat";
import ChangePasswordPage from "../../pages/changePassword";

const Routes = [
  {
    path: "/login",
    page: Login,
    isExact: true,
  },

  {
    path: "/login/phone-number",
    page: LoginWithPhoneNumber,
  },
  {
    path: "/login/otp",
    page: OTPLogin,
  },
  {
    path: "/change-password",
    page: ChangePassword,
  },
  {
    path: "/forgot-password",
    page: ForgotPassword,
  },
  {
    path: "/home",
    page: Home,
    isExact: true,
  },
  {
    path: "/",
    page: Welcome,
    isExact: true,
  },
  {
    path: "/comments",
    page: Comments,
  },
  {
    path: "/assets",
    page: Assets,
    isExact: true,
  },
  {
    path: "/assets/:task_id/individual-asset",
    page: IndividualAsset,
    isExact: true,
  },
  // {
  //   path: "/messages",
  //   page: Messages,
  // },
  {
    path: "/assets/:task_id/review",
    page: ReviewNow,
    isExact: true,
  },
  {
    path: "/assets/:task_id/compare",
    page: CompareAsset,
  },
  {
    path: "/user/profile/change-password",
    page: ChangePasswordPage,
    isExact: true,
  },
  {
    path: "/user/profile",
    page: Profile,
  },
  {
    path: "/chat",
    page: Chat,
  },
];
export default Routes;
