import React from "react";
import styled from "styled-components";
import { IonButton, IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import { useQuery } from "@apollo/client";
import { GET_PROJECT_MANAGER } from "graphQL/queries";
import { IonLoading } from "@ionic/react";

import { MediumText } from "components/text";
import WhatsAppIcon from "assests/icons/svg/whatsapp.svg";
import GmailIcon from "assests/icons/svg/gmail.svg";
import Flex from "components/flex";

const Button = styled(IonButton)`
  margin-top: 1.5rem;
  text-transform: capitalize;
  height: 51px;
  max-width: 335px;
  width: 100%;
  font-size: 0.688rem;
  font-weight: bold;
  font-family: Montserrat;
  --background: var(--button-primary);
  --box-shadow: none;
`;

const Icon = styled(IonIcon)`
  font-size: ${({ fontSize }) => fontSize || "1.438rem"};
  margin-right: ${({ marginRight }) => marginRight || "unset"};
`;

const ChatContainer = styled.div`
  background: #f5f5f5;
  border-radius: 10px;
  padding: 3rem 2rem;
  width: 100%;
  margin-top: 2rem;
  ${({ inverse }) =>
    inverse &&
    ` 
  padding: 1rem 0;
  margin-top: 0px;
  background: #fff;

${Button}{
  --border-radius: 10px;
  --background:#f5f5f5;
  max-width: 480px;
  --color:#130f26;
  font-size:0.75rem;
  font-weight: 500;
}
${MediumText}{
  font-size:0.75rem;
}
.greet-text{
  margin-bottom:10px;
  display:inline-block;
}
  `}
`;

const Connections = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserChat = ({ message = "Hi", inverse = false }) => {
  const isMobile = window.matchMedia("(max-width:768px)");
  const { data, loading } = useQuery(GET_PROJECT_MANAGER, {
    variables: {
      projectId: localStorage.getItem("projectId"),
    },
  });
  const userName = localStorage.getItem("userName") || "user";
  return (
    <ChatContainer inverse={inverse}>
      <IonLoading isOpen={loading} spinner="bubbles" message="Please wait..." />
      <MediumText as="p" fontWeight={500}>
        <span className="greet-text">Hello {userName},</span>
        <br />
        You can connect with our designer for any further support or query.
      </MediumText>
      <Connections>
        {data?.user?.length > 0 &&
          data.user.map((dataObj, index) => (
            <Button
              key={index}
              shape={inverse ? undefined : "round"}
              target="_blank"
              href={
                dataObj.phone
                  ? isMobile?.matches
                    ? `whatsapp://send/?phone=${dataObj.phone}&text=${message}`
                    : `https://web.whatsapp.com/send?phone=${dataObj.phone}&text=${message}`
                  : `mailto:${dataObj.email}`
              }
            >
              <Flex
                alignCenter
                fullWidth
                title={"Connect with " + dataObj.name}
              >
                <Icon
                  slot="start"
                  icon={dataObj.phone ? WhatsAppIcon : GmailIcon}
                  marginRight="0.75rem"
                />
                Connect with{" "}
                {isMobile?.matches
                  ? dataObj.name.length > 10
                    ? dataObj.name.substr(0, 10) + "..."
                    : dataObj.name || "Project Manager"
                  : dataObj.name.length > 14
                  ? dataObj.name.substr(0, 14) + "..."
                  : dataObj.name || "Project Manager"}
              </Flex>
              <Icon icon={chevronForwardOutline} slot="end" fontSize="1.2rem" />
            </Button>
          ))}
      </Connections>
    </ChatContainer>
  );
};

export default UserChat;
