import styled from "styled-components";
import { IonCol, IonRow, IonText } from "@ionic/react";
import { RightOutlined } from "@ant-design/icons";
import dateTimeToTimestamp from "../../lib/DateTimeToTimeStamp";
import timeStampToTimeElapsed from "../../lib/TimeStampToTimeElapsed";

const CommentContent = styled(IonCol)`
  padding: 16px 10px;
  flex: 1;
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Comment = styled(IonCol)`
  flex: 1;
`;

const ProfileName = styled(IonText)`
  color: var(--text-primary);
  font-weight: 400;
  padding-right: 10px;
`;

const Name = styled.span`
  font-weight: 400;
`;
const TimeStamp = styled.span`
  color: #859ee6;
  font-weight: 600;
`;
const ImageContent = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 120px;
`;

const Image = styled.img`
  height: 68px;
  border-radius: 5px;
`;

const Content = styled(IonRow)`
  font-size: 0.75rem;
  width: 100%;
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f5f5f5;
  &:hover {
    background-color: #ffffff;
  }
`;

const CommentsItem = (props) => {
  const getTimeElapsedFormat = (dateTime) => {
    let dataDateTime = new Date(dateTime).toLocaleString();
    let previousTimeStamp = dateTimeToTimestamp({
      date: dataDateTime.substring(0, 10),
      time: dataDateTime.substring(12),
    });
    let currentDateTime = new Date().toLocaleString();
    let currentDate = currentDateTime.substring(0, 10);
    let currentTime = currentDateTime.substring(12);

    let currentTimeStamp = dateTimeToTimestamp({
      date: currentDate,
      time: currentTime,
    });
    let timeElapse = timeStampToTimeElapsed(
      currentTimeStamp,
      previousTimeStamp
    );
    return timeElapse || "";
  };

  return (
    <Content onClick={() => props.handleOnClickAsset(props.task_id || "")}>
      <CommentContent>
        <ImageContent>
          <Image src={props.imageUrl} height="50px" width="100%" />
        </ImageContent>
        <Comment>
          <Header>
            <ProfileName>
              <Name>
                {props.userName}&nbsp; uploaded new asset to{" "}
                {localStorage.getItem("project")} &nbsp;
              </Name>
              &nbsp;
              <TimeStamp>{getTimeElapsedFormat(props.createdAt)}</TimeStamp>
            </ProfileName>
            <div>
              <RightOutlined style={{ color: "#000000" }} />
            </div>
          </Header>
        </Comment>
      </CommentContent>
    </Content>
  );
};

export default CommentsItem;
