const Download = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.112 14.1496L11.112 3.11206"
      stroke="#130F26"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.785 11.4658L11.112 14.1498L8.43904 11.4658"
      stroke="#130F26"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3588 7.45044H16.2141C18.0795 7.45044 19.5911 8.96202 19.5911 10.8284V15.3054C19.5911 17.1662 18.0831 18.6741 16.2223 18.6741H6.01065C4.14523 18.6741 2.63273 17.1616 2.63273 15.2962V10.8183C2.63273 8.95836 4.14157 7.45044 6.00148 7.45044H6.86498"
      stroke="#130F26"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Download;
