import React from "react";
import styled, { css } from "styled-components";
import { Input as AndInput } from "antd";
import PhoneCodeSelector from "./phoneCodeSelector";

const InputWrapper = styled.div`
  margin: ${({ margin }) => margin};
`;

const StyledInput = styled(AndInput)`
  height: ${({ height }) => height};
  border-radius: 10px;
  border-color: ${({ error }) => (error ? "#ff5959" : "#E2E2E2")};
  text-align: ${({ align }) => align};
  border-width: 2px;
  font-size: 1rem;
  &:focus,
  &:hover {
    border-right-width: 2px !important;
  }

  ${({ error }) =>
    error &&
    css`
      &:focus {
        border-width: 2px;
        box-shadow: 0px 0px 4px #ff5959;
      }
      &:hover,
      &:focus {
        border-color: #ff5959;
      }
    `}
`;

const ErrorText = styled.div`
  font-size: 0.813rem;
  color: #ff5959;
  transform: ${({ error }) => (error ? "scaleY(1)" : "scaleY(0)")};
  transform-origin: top;
  transition: 0.25s all;
  min-height: 1.375rem;
  margin-left: 0.188rem;
  margin-bottom: 0.125rem;
  user-select: none;
`;

const Label = styled.label`
  color: var(--text-primary);
  font-weight: bold;
  font-size: 0.938rem;
  padding-bottom: 0.9rem;
  display: inline-block;
`;

const Flex = styled.div`
  display: flex;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Input = ({
  label = "",
  margin = "",
  height = "51px",
  error = "",
  showErrorBorder = false,
  showError = false,
  showPhoneCode = false,
  onPhoneCodeSelect = undefined,
  phoneCodeValue = "",
  align = "start",
  ...rest
}) => {
  return (
    <InputWrapper margin={margin}>
      {label && <Label>{label}</Label>}
      <Flex>
        {showPhoneCode && (
          <PhoneCodeSelector
            onPhoneCodeSelect={onPhoneCodeSelect}
            phoneCodeValue={phoneCodeValue}
          />
        )}
        <StyledInput
          height={height}
          error={error || showErrorBorder || ""}
          align={align}
          {...rest}
        />
      </Flex>

      {showError && <ErrorText error={error}>{error}</ErrorText>}
    </InputWrapper>
  );
};

export default Input;
