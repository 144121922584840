import styled, { css } from "styled-components";

const defaultTextStyles = ({ margin, fontWeight, pointer, capitalize }) => css`
  font-family: Montserrat;
  color: ${({ color }) => color || "#130f26"};
  ${fontWeight && `font-weight:${fontWeight};`}
  ${margin && `margin:${margin};`}
  ${pointer && "cursor:pointer;"}
  ${capitalize && "text-transform: capitalize;"}
`;

export const Title = styled.span`
  font-size: 1.75rem;
  ${defaultTextStyles}
`;

export const LabelText = styled.span`
  font-weight: bold;
  font-size: 0.938rem;
  ${defaultTextStyles}
`;

export const MediumText = styled.span`
  font-size: 0.938rem;
  ${defaultTextStyles}
`;

export const SmallText = styled.span`
  font-size: 0.75rem;
  ${defaultTextStyles}
`;
