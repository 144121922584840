import { useState, useEffect } from "react";
import { IonCol, IonRow, IonText, IonIcon, IonModal } from "@ionic/react";
import styled from "styled-components";
import { Badge } from "antd";
import Reply from "../../assests/icons/reply";
import {
  CheckCircleOutlined,
  DownloadOutlined,
  FileTextOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { Avatar } from "antd";
import pinIcon from "../../assests/icons/svg/pin.svg";
import pinnedIcon from "../../assests/icons/svg/pinned.svg";
import ReplyFilled from "../../assests/icons/replyFilled";
import { stringToHslColor } from "../../lib/stringToHslColor";
import { useRouterQuery } from "../../lib/useRouterQuery";
import { fileDownloader } from "../../lib/fileDownloader";
import PreviewAttachment from "./previewAttachment";
import useWindowDimensions from "../useWindowDimensions";
import MobPreviewAttachment from "./mobPreviewAttachment";
import ResolvedIcon from "assests/icons/svg/resolved.svg";

const FileNumContent = styled(IonCol)`
  flex: 0.01;
  justify-content: center;
  display: flex;
  border-right: 1px solid #e6e6e6;
  padding-top: 2.5rem;
`;
const ProfileIconContent = styled(IonCol)`
  flex: 0.01;
  font-weight: 600;
`;

const ReplyContent = styled.div`
  display: ${({ display }) => display};
  width: 100%;
`;

const CommentContent = styled(IonCol)`
  padding: ${({ padding }) => padding || "16px 10px"};
  flex: 1;
  display: flex;
`;

const Header = styled(IonRow)`
  display: flex;
  justify-content: unset;
`;

const Comment = styled(IonCol)`
  flex: 1;
`;

const ProfileName = styled(IonText)`
  color: #575757;
  font-weight: 600;
  margin-right: 14px;
`;
const DateTime = styled.span`
  color: #929292;
  font-weight: 500;
`;

const ProfileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.375rem;
  width: 2.375rem;
  border-radius: 50%;
  background-color: ${({ background }) =>
    background ? background : "#7C9EC9"};
  color: rgb(255, 255, 255);
  margin-top: 10px;
`;

const CommentText = styled(IonText)`
  color: var(--text-primary);
  font-weight: 400;
  margin-top: 8px;
`;

const Actions = styled(IonRow)`
  margin-top: 10px;
`;

const Action = styled.div`
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

const PinAction = styled.div`
  cursor: ${({ noPointer }) => !noPointer && "pointer"};
  font-size: 0.688rem;
  font-weight: 700;
  color: ${({ color }) => (color ? color : "#969697")};
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

const ViewHideText = styled(IonText)`
  color: ${({ status }) => (status === "Hide" ? "#1da1f2" : "#575757")};
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 5px;
  font-weight: ${({ status }) => (status === "Hide" ? "600" : "400")};
`;
const ViewMoreText = styled(IonText)`
  margin-left: 64px;
  font-size: 0.8rem;
  cursor: pointer;
  display: ${({ display }) => (display ? "block" : "none")};
  margin-top: ${({ margin }) => margin && "20px"};
`;

const PinIcon = styled(IonIcon)`
  font-size: 12px;
`;

const DownloadIcon = styled.div`
  position: absolute;
  right: 6%;
  top: 8%;
  background: white;
  padding: 2px 8px;
  display: flex;
  border-radius: 4px;
  display: none;
  z-index: 10;
`;

const AntDownloadIcon = styled(DownloadOutlined)`
  font-size: 16px;
`;

const ImageContent = styled.div`
  height: max-content;
  width: max-content;
  max-width: ${({ path }) => (path ? "200px" : "300px")};
  cursor: pointer;
  margin: 10px 0px;
  position: relative;
  &:hover {
    ${DownloadIcon} {
      display: block;
    }
  }
`;

const Image = styled.img`
  height: ${({ path }) => (path ? "100px" : "160px")};
  width: ${({ path }) => (path ? "150px" : "250px")};
`;
const FileClose = styled.div`
  display: flex;
  align-items: center;
  background: #f7f6f6;
  max-width: ${({ path }) => (path ? "200px" : "300px")};
  margin-top: 10px;
  padding: 6px 20px;
  border-radius: 8px;
`;
const FileName = styled.div`
  font-size: 0.8rem;
  color: var(--text-primary);
  padding: 10px 10px;
  white-space: pre-wrap;
  word-break: break-word;
`;

const ProfileAvatar = styled(Avatar)`
  background-color: ${({ background }) =>
    background ? background : "#3f99a2"};
  height: 100%;
  width: 100%;
  .ant-avatar-string {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ProfileText = styled.span`
  font-size: ${({ replyText }) => (replyText ? "0.875rem" : "1.125rem")};
`;
const Icon = styled(IonIcon)`
  font-size: 15px;
`;

const Content = styled(IonRow)`
  background-color: ${({ background }) =>
    background ? background : "#FFFFFF"};
  font-size: 0.875rem;
  width: 100%;
  display: flex;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    background-color: ${({ background }) =>
      background ? background : "#f5f5f5"};
    ${Header} {
      justify-content: space-between;
    }
    ${FileClose} {
      background: #ffffff;
      max-width: 100%;
    }
    ${DownloadIcon} {
      display: block;
      padding: 0px 8px;
      border: 1px solid #9e9e9e;
    }
    ${AntDownloadIcon} {
      font-size: 12px;
    }
    ${ImageContent} {
      width: 80%;
      max-width: 100%;
    }
    ${Image} {
      height: 100%;
      width: 100%;
    }
  }
`;

const RepliesRow = styled(IonRow)`
  padding-left: 10px;
`;

const TaskCommentItem = (props) => {
  const query = useRouterQuery();
  const comment_thread = query.get("id");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [commentText, setCommentText] = useState("");
  const [fileName, setFileName] = useState("");
  const [docUrl, setDocUrl] = useState("");

  const { width } = useWindowDimensions();
  const userId = localStorage.getItem("userId");

  const handleSwitchComments = (value, comment_thread, count) => {
    props.getRelatedComments(comment_thread, value, count);
  };

  const onDownload = (url, fileName) => {
    if (url) {
      fileDownloader({
        url: url,
        fileName: fileName,
      });
    }
  };

  const handleUrl = (fileName) => {
    if (fileName) {
      let str = fileName.split(".");
      let fileType = str[str.length - 1].toString();
      return fileType.toLowerCase() === "png" ||
        fileType.toLowerCase() === "jpeg" ||
        fileType.toLowerCase() === "jpg"
        ? true
        : false;
    }
  };

  const handleClickOnImage = (
    url,
    fileName,
    comment_text,
    comment_id,
    comment_thread,
    personName,
    type
  ) => {
    if (type === "image") {
      setImageUrl(url);
      setDocUrl("");
    } else {
      setDocUrl(url);
      setImageUrl("");
    }
    setIsModalVisible(true);
    setFileName(fileName);
    setCommentText(comment_text);
    props.handleAction(
      "peview_reply",
      comment_id,
      comment_thread,
      "",
      "",
      personName
    );
  };

  const handleModal = (state) => {
    setIsModalVisible(state);
    props.handleCloseReply();
  };

  useEffect(() => {
    if (props.isPreviewReply === false) setIsModalVisible(false);
  }, [props.isPreviewReply]);

  useEffect(() => {
    if (props.signedUrl) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [props.signedUrl]);

  const replaceLinkInComment = (text) => {
    return text.replace(
      /(https?:\/\/)([^ ]+)/g,
      '<a title="$2" target="_blank" href="$&">$2</a>'
    );
  };

  const getDate = (time) => {
    if (time.length > 1) {
      return time + "th";
    } else if (time == 1) return time + "st";
    else if (time == 2) return time + "nd";
    else if (time == 3) return time + "3rd";
    else return time + "th";
  };

  const handleDisplayDateTime = (createdAt) => {
    if (createdAt) {
      var createdDateTime = new Date(createdAt).toString();
      let formatedDate =
        createdDateTime.substr(4, 3) +
        " " +
        getDate(createdDateTime.substr(8, 2)) +
        " " +
        "at " +
        (createdDateTime.substr(16, 2) == "12"
          ? createdDateTime.substr(16, 2)
          : (Number(createdDateTime.substr(16, 2)) % 12).toString().length === 1
          ? +"0" + (Number(createdDateTime.substr(16, 2)) % 12)
          : Number(createdDateTime.substr(16, 2)) % 12) +
        ":" +
        createdDateTime.substr(19, 2) +
        (Number(createdDateTime.substr(16, 2)) < 12 ? "am" : "pm");
      return formatedDate;
    }
  };

  return (
    <Content
      background={
        props.highlightCommentId === props.comment.id ? "#d2eeff" : false
      }
    >
      {props.menu === "File" && (
        <FileNumContent>
          <div>{(props.commentsCount || 0) - props.index}.</div>
        </FileNumContent>
      )}
      <IonCol size={props.menu === "File" ? "10" : "12"}>
        <IonRow>
          <ProfileIconContent>
            <ProfileIcon>
              <ProfileAvatar
                src={props.comment?.user?.avatar}
                background={stringToHslColor(props.comment?.user?.name || "A")}
              >
                <ProfileText>
                  {(props.comment?.user?.name &&
                    props.comment.user.name.charAt(0)) ||
                    "A"}
                </ProfileText>
              </ProfileAvatar>
            </ProfileIcon>
          </ProfileIconContent>
          <Comment>
            <Header>
              <ProfileName>
                {props.comment?.user?.name || "Some Person"}&nbsp;&nbsp;
                {props.path !== "review" && width > 768 && (
                  <DateTime>
                    {handleDisplayDateTime(props.comment.created_at || "")}
                  </DateTime>
                )}
              </ProfileName>
              {props.read && props.menu !== "Resolved" && (
                <Badge
                  style={{
                    backgroundColor: "#69B79A",
                    fontWeight: 700,
                  }}
                  count="NEW"
                />
              )}
            </Header>
            {(width <= 768 || props.path === "review") && (
              <DateTime>
                {handleDisplayDateTime(props.comment.created_at || "")}
              </DateTime>
            )}
            <IonRow style={{ display: "contents" }}>
              {props.comment?.resources &&
                props.comment?.resources.length !== 0 && (
                  <ImageContent path={props.path === "review" ? true : false}>
                    <DownloadIcon
                      title="Download"
                      onClick={() =>
                        onDownload(
                          props.comment?.resources[0]?.url,
                          props.comment?.resources[0]?.name
                        )
                      }
                    >
                      <AntDownloadIcon />
                    </DownloadIcon>
                    {handleUrl(props.comment?.resources[0]?.name) ? (
                      <Image
                        onClick={() =>
                          handleClickOnImage(
                            props.comment.resources[0]?.url,
                            props.comment.resources[0]?.name,
                            props.comment?.comment_text
                              ? props.comment?.comment_text
                              : "",
                            props.comment.id,
                            props.comment.comment_thread,
                            props.comment?.user?.name || "unknown",
                            "image"
                          )
                        }
                        src={
                          props.comment?.resources &&
                          props.comment?.resources[0]?.url
                        }
                        path={props.path === "review" ? true : false}
                        alt="resourceImg"
                      />
                    ) : (
                      <div
                        onClick={() =>
                          handleClickOnImage(
                            props.comment.resources[0]?.url,
                            props.comment.resources[0]?.name,
                            props.comment?.comment_text
                              ? props.comment?.comment_text
                              : "",
                            props.comment.id,
                            props.comment.comment_thread,
                            props.comment?.user?.name || "unknown",
                            "document"
                          )
                        }
                      >
                        <FileClose
                          path={props.path === "review" ? true : false}
                        >
                          <FileTextOutlined
                            style={{ fontSize: 24, color: "#7C9EC9" }}
                          />
                          <FileName title={props.comment?.resources[0]?.name}>
                            {props.comment.resources[0]?.name?.toString()
                              .length >= 40
                              ? props.comment.resources[0]?.name
                                  ?.toString()
                                  .substr(0, 40) + "..."
                              : props.comment.resources[0]?.name?.toString()}
                          </FileName>
                        </FileClose>
                      </div>
                    )}
                  </ImageContent>
                )}
              <CommentText>
                <pre
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                  key={`linkReplacedComment-${props.comment.id}`}
                  dangerouslySetInnerHTML={{
                    __html: replaceLinkInComment(
                      props.comment?.comment_text
                        ? props.comment?.comment_text
                        : ""
                    ),
                  }}
                />
              </CommentText>
            </IonRow>
            {(props.menu === "Task" || props.menu === "File") && (
              <>
                <Actions>
                  <Action
                    onClick={() =>
                      props.handleAction(
                        "reply",
                        props.comment.id,
                        props.comment.comment_thread,
                        "thread",
                        "",
                        props.comment?.user?.name || "unknown"
                      )
                    }
                    rightMargin={true}
                  >
                    {props.comment.id === props.replyId ? (
                      <ReplyFilled />
                    ) : (
                      <Reply />
                    )}
                  </Action>
                  <PinAction
                    color={props.pin ? "#1DA1F2" : "#969697"}
                    onClick={() =>
                      props.handleAction(
                        "pin",
                        "",
                        props.comment.id,
                        "comment_pin",
                        props.pin ? "unpin" : "pin"
                      )
                    }
                  >
                    <PinIcon
                      icon={props.pin ? pinnedIcon : pinIcon}
                      slot="start"
                    />
                    &nbsp;{props.pin ? "Pinned" : "Pin"}
                  </PinAction>
                  {props.menu === "Task" && !props.hideMarkAsResolved && (
                    <PinAction
                      onClick={() =>
                        props.handleAction(
                          "resolved",
                          "",
                          props.comment.comment_thread,
                          props.status === "UNRESOLVED"
                            ? "RESOLVED"
                            : "UNRESOLVED"
                        )
                      }
                      color={
                        props.status === "UNRESOLVED" ? "#969697" : "#69B79A"
                      }
                    >
                      {props.status === "UNRESOLVED" ? (
                        <>
                          <CheckCircleOutlined style={{ fontSize: 14 }} />
                          &nbsp; Mark as Resolved
                        </>
                      ) : (
                        <>
                          <Icon icon={ResolvedIcon} />
                          &nbsp;Resolved
                        </>
                      )}
                    </PinAction>
                  )}
                </Actions>
              </>
            )}
          </Comment>
        </IonRow>
        {props.count > 0 && (
          <RepliesRow>
            <Comment>
              <IonRow>
                {props.count > 0 && (
                  <ViewHideText
                    status={
                      props.threadId === props.comment.comment_thread
                        ? "Hide"
                        : "View"
                    }
                  >
                    <u
                      onClick={() =>
                        handleSwitchComments(
                          props.threadId === props.comment.comment_thread
                            ? "Hide"
                            : "View",
                          props.comment.comment_thread || "",
                          props.count
                        )
                      }
                    >
                      {props.threadId === props.comment.comment_thread ? (
                        <span>
                          <MinusOutlined style={{ fontSize: 12 }} /> {"Hide "}
                        </span>
                      ) : (
                        "View "
                      )}
                      Replies ({props.count})
                    </u>
                  </ViewHideText>
                )}
              </IonRow>
              {props.threadId === props.comment.comment_thread && (
                <ViewMoreText
                  display={comment_thread && props.repliesLessOffset > 0}
                  margin="top"
                  onClick={() => props.handleRepliesViewMore("less")}
                >
                  <u>View recent replies</u>
                </ViewMoreText>
              )}
              {props.relatedComments?.map((comment, id) => (
                <IonRow>
                  {props.threadId === props.comment.comment_thread && (
                    <ReplyContent
                      id={comment.id}
                      key={comment.id || id}
                      display={
                        props.comment.id === comment.id ? "none" : "block"
                      }
                    >
                      {comment_thread === comment.id &&
                        props.handleReplyScroll(comment.id)}
                      <Content
                        background={
                          props.highlightCommentId === comment.id
                            ? "#d2eeff"
                            : false
                        }
                      >
                        <CommentContent padding="16px 0">
                          <ProfileIconContent>
                            <ProfileIcon>
                              <ProfileAvatar
                                src={comment?.user?.avatar}
                                background={stringToHslColor(
                                  comment.user?.name || "A"
                                )}
                              >
                                <ProfileText replyText={true}>
                                  {(comment.user?.name &&
                                    comment.user.name.charAt(0)) ||
                                    "A"}
                                </ProfileText>
                              </ProfileAvatar>
                            </ProfileIcon>
                          </ProfileIconContent>
                          <Comment>
                            <Header>
                              <ProfileName>
                                {comment.user?.name || "Some Person"}
                                &nbsp;replied&nbsp;&nbsp;
                                {props.path !== "review" && width > 768 && (
                                  <DateTime>
                                    {handleDisplayDateTime(
                                      comment.created_at || ""
                                    )}
                                  </DateTime>
                                )}
                              </ProfileName>
                              {!comment.user_activity_trackings?.length &&
                                comment.user?.id !== userId && (
                                  <Badge
                                    style={{
                                      backgroundColor: "#69B79A",
                                      fontWeight: 700,
                                    }}
                                    count="NEW"
                                  />
                                )}
                            </Header>
                            {(width <= 768 || props.path === "review") && (
                              <DateTime>
                                {handleDisplayDateTime(
                                  props.comment.created_at || ""
                                )}
                              </DateTime>
                            )}
                            <IonRow style={{ display: "contents" }}>
                              {comment.resources &&
                                comment.resources.length !== 0 && (
                                  <ImageContent
                                    path={
                                      props.path === "review" ? true : false
                                    }
                                  >
                                    <DownloadIcon
                                      title="Download"
                                      onClick={() =>
                                        onDownload(
                                          comment.resources[0]?.url,
                                          comment.resources[0]?.name
                                        )
                                      }
                                    >
                                      <AntDownloadIcon />
                                    </DownloadIcon>
                                    {handleUrl(comment.resources[0]?.name) ? (
                                      <Image
                                        onClick={() =>
                                          handleClickOnImage(
                                            comment.resources[0]?.url,
                                            comment.resources[0]?.name,
                                            comment.comment_text
                                              ? comment.comment_text
                                              : "",
                                            comment.id,
                                            comment.comment_thread,
                                            comment?.user?.name || "unknown",
                                            "image"
                                          )
                                        }
                                        src={
                                          comment.resources &&
                                          comment?.resources[0]?.url
                                        }
                                        path={
                                          props.path === "review" ? true : false
                                        }
                                        alt="resourceImg"
                                      />
                                    ) : (
                                      <div
                                        onClick={() =>
                                          handleClickOnImage(
                                            comment.resources[0]?.url,
                                            comment.resources[0]?.name,
                                            comment.comment_text
                                              ? comment.comment_text
                                              : "",
                                            comment.id,
                                            comment.comment_thread,
                                            comment?.user?.name || "unknown",
                                            "document"
                                          )
                                        }
                                      >
                                        <FileClose>
                                          <FileTextOutlined
                                            style={{
                                              fontSize: 24,
                                              color: "#7C9EC9",
                                            }}
                                          />
                                          <FileName
                                            title={comment.resources[0]?.name}
                                          >
                                            {comment.resources[0]?.name?.toString()
                                              .length >= 40
                                              ? comment.resources[0]?.name
                                                  ?.toString()
                                                  .substr(0, 40) + "..."
                                              : comment.resources[0]?.name?.toString()}
                                          </FileName>
                                        </FileClose>
                                      </div>
                                    )}
                                  </ImageContent>
                                )}
                              <CommentText>
                                <pre
                                  style={{
                                    fontFamily: "Montserrat, sans-serif",
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-word",
                                  }}
                                  key={`linkReplacedComment-${comment.id}`}
                                  dangerouslySetInnerHTML={{
                                    __html: replaceLinkInComment(
                                      comment.comment_text
                                        ? comment.comment_text
                                        : ""
                                    ),
                                  }}
                                />
                              </CommentText>
                            </IonRow>
                            {(props.menu === "Task" ||
                              props.menu === "File") && (
                              <Actions>
                                <Action
                                  onClick={() =>
                                    props.handleAction(
                                      "reply",
                                      comment.id,
                                      comment.comment_thread,
                                      "comment",
                                      "",
                                      comment.user?.name || "unknown"
                                    )
                                  }
                                  rightMargin={true}
                                >
                                  {comment.id === props.replyId ? (
                                    <ReplyFilled />
                                  ) : (
                                    <Reply />
                                  )}
                                </Action>
                                <PinAction
                                  color={
                                    props.pinnedCommentIds?.length > 0 &&
                                    props.pinnedCommentIds.includes(comment.id)
                                      ? "#1DA1F2"
                                      : "#969697" || "#1DA1F2"
                                  }
                                  onClick={() =>
                                    props.handleAction(
                                      "pin",
                                      props.comment.id,
                                      comment.id,
                                      "reply_pin",
                                      props.pinnedCommentIds?.length > 0 &&
                                        props.pinnedCommentIds.includes(
                                          comment.id
                                        )
                                        ? "unpin"
                                        : "pin"
                                    )
                                  }
                                >
                                  <PinIcon
                                    icon={
                                      props.pinnedCommentIds?.length > 0 &&
                                      props.pinnedCommentIds.includes(
                                        comment.id
                                      )
                                        ? pinnedIcon
                                        : pinIcon
                                    }
                                    slot="start"
                                  />
                                  &nbsp;
                                  {props.pinnedCommentIds?.length > 0 &&
                                  props.pinnedCommentIds.includes(comment.id)
                                    ? "Pinned"
                                    : "Pin" || "Pin"}
                                </PinAction>
                              </Actions>
                            )}
                          </Comment>
                        </CommentContent>
                      </Content>
                    </ReplyContent>
                  )}
                </IonRow>
              ))}
              {props.threadId === props.comment.comment_thread && (
                <ViewMoreText
                  display={
                    props.repliesCount - 1 >
                    props.repliesOffset + props.repliesLimit
                      ? true
                      : false
                  }
                  onClick={() => props.handleRepliesViewMore("more")}
                >
                  <u>View more</u>
                </ViewMoreText>
              )}
            </Comment>
          </RepliesRow>
        )}
      </IonCol>
      {isModalVisible && !props.signedUrl && (
        <PreviewAttachment
          isModalVisible={isModalVisible}
          handleModal={handleModal}
          {...props}
          imageUrl={imageUrl}
          commentText={commentText}
          fileName={fileName}
          docUrl={docUrl}
        />
      )}
      {width <= 768 && props.signedUrl && (
        <div style={{ display: isModalVisible ? "block" : "none" }}>
          <IonModal isOpen={isModalVisible}>
            <MobPreviewAttachment
              image={props.displayUploadedImage}
              isAttchmentUpload={true}
              {...props}
              handleModal={handleModal}
            />
          </IonModal>
        </div>
      )}
    </Content>
  );
};

export default TaskCommentItem;
