import React from "react";
import { List, Avatar } from "antd";
import styled from "styled-components";
import { stringToHslColor } from "../../lib/stringToHslColor";

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 15px;
  letter-spacing: 0.2px;
  color: #575757;
`;

const SubTitle = styled(Title)`
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 17px;
  color: var(--text-primary);
`;

const Text = styled.span`
  text-transform: uppercase;
`;

const Wrapper = styled(List.Item.Meta)`
  padding: ${({ padding }) => padding || "unset"};
`;

const ProfileWithComment = ({
  name = "",
  commentText = "",
  avatar = undefined,
  padding = "",
}) => {
  return (
    <Wrapper
      padding={padding}
      avatar={
        <Avatar
          src={avatar}
          size={40}
          style={{
            backgroundColor: stringToHslColor(name || "A"),
          }}
        >
          <Text>{(name && name?.charAt(0)) || "A"}</Text>
        </Avatar>
      }
      title={<Title>{name || "--"}</Title>}
      description={<SubTitle>{commentText || "--"}</SubTitle>}
    />
  );
};

export default ProfileWithComment;
