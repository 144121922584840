import React, { useState } from "react";
import styled from "styled-components";
import { IonIcon, IonSpinner } from "@ionic/react";
import { Button } from "antd";
import { Capacitor } from "@capacitor/core";
import { ShareAltOutlined } from "@ant-design/icons";
import { FileSharer } from "capacitor-plugin-filesharer";

import WhatsAppIcon from "../../assests/icons/svg/whatsapp.svg";
import GmailIcon from "../../assests/icons/svg/gmail.svg";

import Flex from "../flex";

const SocialShareWrapper = styled.div`
  margin: ${({ margin }) => margin || "unset"};
`;
const ShareText = styled.div`
  font-family: Montserrat;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.2px;
  color: var(--text-primary);
`;

const SocialShareItem = styled(Flex)`
  background: ${({ backgroundColor }) => backgroundColor || "#ffffff"};
  box-shadow: 0px 0px 10px 5px rgba(208, 203, 203, 0.15);
  border-radius: 10px;
  height: 4.063rem;
  width: 100%;
  margin: 22px 0;
  padding: 0 12%;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  transition: 0.25s all;
  &:hover {
    box-shadow: 1px 1px 11px 2px #d1cbcb;
  }
`;
const SocialShareItemWrapper = styled.div`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const Icon = styled(IonIcon)`
  font-size: 2.5rem;
  margin-right: 18px;
`;

const OverlayWrapper = styled(Flex)`
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`;

const SocialShareList = ({
  options = [],
  selectedAssets = [],
  margin = "0px 10%",
  backgroundColor = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const testURL =
    "https://images.unsplash.com/photo-1637497520435-cb436ebddc8c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDN8TThqVmJMYlRSd3N8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60";
  const socialShareOptions =
    options?.length > 0
      ? [...options]
      : [
          { title: "WhatsApp", value: "whatsapp", icon: WhatsAppIcon },
          { title: "Gmail", value: "gmail", icon: GmailIcon },
        ];

  const getBase64FromUrl = async (url) => {
    try {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    } catch (err) {
      console.log("error", err);
    }
  };

  const getMultipleBase64Formatted = async () => {
    let filesList = [];
    filesList = await Promise.all(
      selectedAssets.map(async (eachSelected, eachSelectedIndex) => {
        if (eachSelected.url) {
          const formattedFileName = eachSelected.url?.split("/").pop() || "";
          const base64 = await getBase64FromUrl(
            `${process.env.REACT_APP_NODE_API_URL}/file/${formattedFileName}`
          );
          const base64Data = base64.replace(/^data:image\/[a-z]+;base64,/, "");
          const contentType = base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
          return {
            filename: eachSelected.name || "",
            base64Data,
            contentType,
          };
        }
      })
    );
    return filesList;
  };

  const onShareAssetVersion = async (value) => {
    let linkElement = document.createElement("a");
    let formattedTextMessage = "";
    selectedAssets.forEach((eachSelected, eachSelectedIndex) => {
      formattedTextMessage = formattedTextMessage.concat(
        `${eachSelectedIndex + 1}. ${eachSelected.name}\r\n ${
          eachSelected.url
        }\r\n\r\n`
      );
    });
    if (value === "whatsapp") {
      linkElement.href = `https://web.whatsapp.com/send?text=${encodeURIComponent(
        formattedTextMessage
      )}`;
      linkElement.rel = "nofollow noopener";
      linkElement.target = "_blank";
      linkElement.click();
    } else {
      linkElement.setAttribute(
        "href",
        `mailto:?subject=Vizdom&body=${encodeURIComponent(
          formattedTextMessage
        )}`
      );
      linkElement.click();
    }
  };

  const onTestShare = async () => {
    const base64Data = await getBase64FromUrl(testURL);
    FileSharer.share({
      filename: "testArun.jpeg",
      base64Data: base64Data.split(",")[1],
      contentType: "image/jpeg",
    })
      .then(() => {
        alert("file shared");
      })
      .catch((error) => {
        console.error("File sharing failed", error.message);
        alert(error);
      });
  };

  const onMultipleShare = async () => {
    setIsLoading(true);
    const fileList = await getMultipleBase64Formatted();
    FileSharer.shareMultiple({
      header: "Vizdom",
      files: [...fileList],
    })
      .catch((error) => {
        console.error("File sharing failed", error.message);
        alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <SocialShareWrapper margin={margin}>
      {isLoading && (
        <OverlayWrapper center alignCenter>
          <IonSpinner name="bubbles" />
        </OverlayWrapper>
      )}
      {Capacitor.getPlatform() === "web" ? (
        socialShareOptions?.map((each, socialShareIndex) => (
          <SocialShareItemWrapper disabled={selectedAssets?.length <= 0}>
            <SocialShareItem
              disabled={selectedAssets?.length <= 0}
              alignCenter
              key={`share-asset-version-${socialShareIndex}`}
              onClick={() =>
                onShareAssetVersion && onShareAssetVersion(each?.value)
              }
              backgroundColor={backgroundColor}
            >
              <Icon icon={each?.icon} />
              <ShareText>{`Share using ${each?.title}`}</ShareText>
            </SocialShareItem>
          </SocialShareItemWrapper>
        ))
      ) : (
        <Flex center>
          <Button
            disabled={isLoading}
            type="primary"
            shape="circle"
            icon={<ShareAltOutlined />}
            size="large"
            onClick={onMultipleShare}
          />
        </Flex>
      )}

      {/* Fast file sharing images */}
      {/* <IonButton onClick={onTestShare}>Test share 20020</IonButton> */}
    </SocialShareWrapper>
  );
};

export default SocialShareList;
