import React, { useState, useEffect } from "react";
import { Modal as AntdModal, Image as AntdImage } from "antd";
import styled from "styled-components";
import Close from "../../assests/icons/svg/close.svg";
import { IonContent, IonIcon as ReactIonIcon } from "@ionic/react";
import Flex from "../flex";
import SocialShareList from "../review/socialShareList";
import CheckFilled from "../../assests/icons/svg/checkFilled.svg";
import { getDate } from "../../lib/getDate";

const Modal = styled(AntdModal)`
  font-family: Montserrat;
  min-width: 297px;
  max-width: 521px;
  width: 100% !important;
  .ant-modal-close {
    right: 0px;
  }
  .ant-modal-body {
    padding: 24px 0 24px 24px !important;
    overflow: hidden;
  }
  @media screen and (max-width: 768px) {
    max-width: calc(100vw - 100px);
  }
`;

const ModelContentWrapper = styled.div`
  padding-right: 24px;
  overflow: auto;
  max-height: 67vh;
`;

const CloseIcon = styled(ReactIonIcon)`
  font-size: 11px;
`;

const Title = styled.h1`
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: var(--text-primary);
`;

const SubTitle = styled(Flex)`
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  color: var(--text-primary);
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 0.875rem;
  color: var(--text-primary);
  margin-left: 10px;
`;

const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  margin: 20px 0;

  .ant-image {
    width: 100%;
  }
`;
const Image = styled(AntdImage)`
  width: 100%;
  border-radius: 5px;
  height: 18vw;
  min-width: 147px;
  min-height: 137px;
  max-height: 200px;
  transition: 0.35s all;
  @media screen and (min-width: 768px) {
    // &:hover {
    //   box-shadow: 1px 2px 12px #747474;
    // }
  }
`;

const RadioButtonWrapper = styled.div`
  display: inline-flex;
  cursor: pointer;
`;

const RadioButton = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 2px solid #130f26;
  ${({ inverse }) =>
    !inverse &&
    `  
  border: 2px solid #ccc;
  bottom: 20px;
  right: 20px;
  position: absolute;
  z-index: 2;
  `}
`;

const Icon = styled(ReactIonIcon)`
  font-size: 1.5rem;
  color: var(--text-primary);
  ${({ inverse }) =>
    !inverse &&
    ` 
  color: rgba(255, 255, 255, 0.8);
  bottom: 20px;
  right: 20px;
  position: absolute;
  z-index: 2;
  `}
`;

const TruncatedText = styled.span`
  white-space: nowrap;
  max-width: 43%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

const ImageContentWrapper = styled.div`
  padding: 0 15px 0 20px;
`;

const NoDataMessage = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  color: #575757;
  padding: 30px 0;
`;

const ModelTitle = styled(Flex)`
  position: sticky;
  background: #fff;
  z-index: 8;
  top: 0;
`;

const ShareDrawer = ({
  isModalVisible = false,
  onCancel = undefined,
  imageURL = [],
}) => {
  const [selectedAssets, setSelectedAssets] = useState([]);

  useEffect(() => {
    setSelectedAssets([]);
  }, [imageURL]);

  const onSelectAssets = (asset) => {
    const selectedAssetsCopy = [...selectedAssets];
    if (selectedAssets.some(({ id }) => id === asset?.id)) {
      setSelectedAssets(
        selectedAssetsCopy.filter((each) => each?.id !== asset.id)
      );
    } else {
      selectedAssetsCopy.push(asset);
      setSelectedAssets(selectedAssetsCopy);
    }
  };

  const onSelectAll = () => {
    if (selectedAssets.length === imageURL.length) {
      setSelectedAssets([]);
    } else {
      setSelectedAssets([...imageURL]);
    }
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={onCancel}
      closeIcon={<CloseIcon icon={Close}></CloseIcon>}
      maskClosable={false}
      footer={false}
      centered
    >
      <ModelContentWrapper>
        <ModelTitle spaceBetween alignCenter padding="0 25px 0 15px">
          <Title>Share</Title>
          <Flex alignCenter>
            <RadioButtonWrapper onClick={onSelectAll}>
              {selectedAssets.length > 0 &&
              selectedAssets.length === imageURL.length ? (
                <Icon icon={CheckFilled} inverse />
              ) : (
                <RadioButton inverse />
              )}
              <Text>Select All</Text>
            </RadioButtonWrapper>
          </Flex>
        </ModelTitle>
        <ImageContentWrapper>
          {imageURL?.length > 0 ? (
            imageURL?.map((eachImage, imageIndex) => (
              <ImageWrapper
                key={`share-image-drawer-${imageIndex}`}
                onClick={() => onSelectAssets(eachImage)}
              >
                <SubTitle wrap alignCenter>
                  <TruncatedText>{eachImage.name || "--"}</TruncatedText>
                  &nbsp;|&nbsp;
                  {getDate(eachImage.created_at || "")}
                </SubTitle>
                <Image src={eachImage?.url} preview={false} />

                {selectedAssets.some(({ id }) => id === eachImage?.id) ? (
                  <Icon icon={CheckFilled} />
                ) : (
                  <RadioButton />
                )}
              </ImageWrapper>
            ))
          ) : (
            <NoDataMessage>No versions available to share!</NoDataMessage>
          )}
          <SocialShareList
            margin="0"
            backgroundColor=" #F5F5F5"
            selectedAssets={selectedAssets}
          />
        </ImageContentWrapper>
      </ModelContentWrapper>
    </Modal>
  );
};

export default ShareDrawer;
