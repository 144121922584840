// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import {
  // PushNotificationSchema,
  PushNotifications,
  // Token,
  // ActionPerformed,
} from "@capacitor/push-notifications";
import { Device } from "@capacitor/device";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const {
  REACT_APP_VAPIDKEY,
  REACT_APP_FB_API_KEY,
  REACT_APP_FB_AUTH_DOMAIN,
  REACT_APP_FB_PROJECT_ID,
  REACT_APP_FB_STORAGE_BUCKET,
  REACT_APP_FB_MSG_SENDER_ID,
  REACT_APP_FB_APP_ID,
  REACT_APP_FB_MEASUREMENT_ID,
} = process.env;
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: REACT_APP_FB_API_KEY,
  authDomain: REACT_APP_FB_AUTH_DOMAIN,
  projectId: REACT_APP_FB_PROJECT_ID,
  storageBucket: REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FB_MSG_SENDER_ID,
  appId: REACT_APP_FB_APP_ID,
  measurementId: REACT_APP_FB_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();

export const deviceInformation = async () => {
  return await Device.getInfo();
};

export const firebaseClient = async () => {
  const deviceInfo = await deviceInformation();
  if (deviceInfo?.platform === "web") {
    return new Promise((resolve, reject) => {
      getToken(messaging, { vapidKey: REACT_APP_VAPIDKEY })
        .then((currentToken) => {
          if (currentToken) {
            resolve({ token: currentToken, deviceInfo });
          } else {
            messaging
              .requestPermission()
              .then(() => {
                return resolve({ token: messaging.getToken(), deviceInfo });
              })
              .then((data) => {
                resolve({ token: data, deviceInfo });
              });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      const register = () => {
        console.log("Initializing HomePage");

        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener("registration", (token) => {
          resolve({ token: token.value, deviceInfo });
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener("registrationError", (error) => {
          reject(error);
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification) => {
            // setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
            console.log(
              "pushNotificationReceived",
              JSON.stringify(notification)
            );
          }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification) => {
            // setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
            console.log(
              "pushNotificationActionPerformed",
              JSON.stringify(notification)
            );
            window.open(`${process.env.REACT_APP_UI_URL}/home`);
          }
        );
      };
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== "granted") {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === "denied") {
              reject("Push Notification permission denied");
            } else {
              register();
            }
          });
        } else {
          register();
        }
      });
    });
  }
};
