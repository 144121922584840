import styled from "styled-components";
import { Image as AntdImage } from "antd";

import Dot from "components/dot";
import PlaceholderImage from "assests/images/placeholder.jpg";

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const StatusDot = styled(Dot)`
  position: absolute;
  z-index: 4;
  top: 4px;
  left: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const Image = styled(AntdImage)`
  object-fit: cover;
  border-radius: 4px;
  border: 3px solid #fff;
  cursor: pointer;
  transition: all 0.1s ease !important;
  width: ${({ isSelected }) => (isSelected ? "139.27px" : "100px")};
  height: ${({ isSelected }) => (isSelected ? "66px" : "56px")};
  @media screen and (max-width: 768px) {
    margin-right: 2px;
    width: ${({ isSelected }) => (isSelected ? "96px" : "85px")};
    height: ${({ isSelected }) => (isSelected ? "53.44px" : "47px")};
    transform: unset;
    border: ${({ isSelected }) =>
      isSelected ? "2px solid #fff" : "2px solid transparent"};
  }
`;

export const thumbnail = ({
  images = [],
  selected = "",
  onCarouselChange = undefined,
}) => {
  const onSelect = (index, selectedImage) => {
    onCarouselChange(index, {
      props: { id: selectedImage.currentVersionId, name: selectedImage.name },
    });
  };
  return images.map((eachImage, imageIndex) => (
    <ImageWrapper
      key={imageIndex}
      onClick={() => onSelect(imageIndex, eachImage)}
    >
      <Image
        isSelected={selected === eachImage?.currentVersionId}
        preview={false}
        src={eachImage?.url || "error"}
        fallback={PlaceholderImage}
      />
      <StatusDot
        status={eachImage.clientStatus?.toLowerCase()}
        size="0.625rem"
      />
    </ImageWrapper>
  ));
};
