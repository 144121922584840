import React, { useState } from "react";
import styled from "styled-components";
import { IonContent, IonIcon as ReactIonIcon } from "@ionic/react";
import { Modal as AntdModal } from "antd";

import { fileDownloader } from "../../lib/fileDownloader";
import { useRouterQuery } from "../../lib/useRouterQuery";

import Close from "../../assests/icons/svg/close.svg";

import SideBarActions from "./sideBarActions";
import Flex from "../flex";
import AssetsComponents from "../assetsComponents";
import AttachmentsComponents from "../attachmentsComponents";
import Compare from "./compare";
import CompareAssets from "./compare/compareAssets";
import ShareAssets from "./shareAssets";

const Modal = styled(AntdModal)`
  top: 20px !important;
  padding-bottom: 0px !important;
  .ant-modal-close {
    right: 14px;
    top: 30px;
  }
  .ant-modal-body {
    overflow: auto;
  }
`;

const CloseIcon = styled(ReactIonIcon)`
  font-size: 11px;
`;

const SideBar = ({
  isVisible = false,
  onClick = undefined,
  onCloseSideBar = undefined,
  sidebarStatus = "",
  currentVersionId = "",
  assetsList = [],
  fileCommentList = [],
  getFileOffset = undefined,
  fillCommentTotalCount = 0,
}) => {
  const [sideBarStatus, setSideBarStatus] = useState("comment");
  const [isCompareVisible, setCompareVisible] = useState(false);
  const [compareAsset, setCompareAsset] = useState({});
  const query = useRouterQuery();
  const status = query.get("status");

  const onSelectCompare = (asset) => {
    setCompareAsset(asset);
    setCompareVisible(true);
  };
  const onDownload = () => {
    const imagesList = assetsList?.imageURL?.filter((each) => each.url);
    if (imagesList?.length > 0) {
      imagesList.forEach((eachImage) => {
        fileDownloader({
          url: eachImage?.url,
          fileName: eachImage?.name,
        });
      });
    }
  };

  return (
    <Flex fullHeight>
      <SideBarActions
        sideBarStatus={sideBarStatus}
        selectSideBar={(value) => setSideBarStatus(value)}
        onClick={onClick}
        onCloseSideBar={onCloseSideBar}
        isVisible={isVisible}
        onDownload={onDownload}
      />
      <IonContent
        style={{
          "--background": "#f5f5f5",
          flex: 1,
          padding: "40px 0",
          height: "auto",
        }}
      >
        {status === "attachment" && (
          <div style={{ padding: " 0% 10%" }}>
            <AttachmentsComponents />
          </div>
        )}
        {status === "comment" && (
          <div style={{ padding: " 0% 10%" }}>
            <AssetsComponents
              height="41vh"
              path="review"
              fileCommentList={fileCommentList}
              fillCommentTotalCount={fillCommentTotalCount}
              getFileOffset={getFileOffset}
            />
          </div>
        )}
        {status === "pinned_comments" && (
          <div style={{ padding: " 0% 10%" }}>
            <AssetsComponents
              height="41vh"
              path="review"
              fileCommentList={fileCommentList}
              fillCommentTotalCount={fillCommentTotalCount}
              getFileOffset={getFileOffset}
            />
          </div>
        )}
        {status === "compare" && (
          <Compare
            onCompare={onSelectCompare}
            currentVersionId={currentVersionId}
          />
        )}
        {status === "share" && (
          <ShareAssets
            onCompare={onSelectCompare}
            currentVersionId={currentVersionId}
          />
        )}
      </IonContent>
      <Modal
        visible={isCompareVisible}
        onCancel={() => setCompareVisible(false)}
        closeIcon={<CloseIcon icon={Close}></CloseIcon>}
        width="100%"
        maskClosable={false}
        footer={false}
      >
        <div style={{ height: "calc(100vh - 85px)", padding: "30px 10px" }}>
          <CompareAssets
            selectedAssetVersion={compareAsset}
            assetsList={assetsList}
          />
        </div>
      </Modal>
    </Flex>
  );
};

export default SideBar;
