import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PageLayout from "../../components/pageLayout";
import {
  IonLoading,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import AssetsCard from "../../components/assets/assetsCard";
import {
  GET_ASSETS_LIST,
  GET_SEARCH_ASSETS_LIST,
  GET_ASSETS_FILTER,
} from "../../graphQL/queries";
import { READ_ASSETS } from "graphQL/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Empty } from "antd";
import AssetsHeader from "../../components/assets/assetsHeader";
import styled from "styled-components";

const AssetsHeaderWrapper = styled.div`
  max-width: 768px;
  padding-top: 20px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 20;
  background: #fff;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    position: relative;
  }
`;

const Assets = () => {
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [assetsList, setAssetsList] = useState([]);
  const [selectedReadAssets, setSelectedReadAssets] = useState(null);
  const [readAssets] = useMutation(READ_ASSETS);
  const projectId =
    localStorage.getItem("projectId") || "a2933aec-6a18-4794-b7c4-5bf988f95a5d";
  const [offset, setOffset] = useState(0);
  const limit = 50;

  const [getTask, { data, loading, fetchMore }] = useLazyQuery(
    GET_ASSETS_LIST,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      onError: (err) => {
        console.log(err.message);
      },
    }
  );
  const [getSearchTask, { data: searchResults, loading: searchLoading }] =
    useLazyQuery(GET_SEARCH_ASSETS_LIST);
  const [getFilteredAssets, { data: filteredResults }] =
    useLazyQuery(GET_ASSETS_FILTER);

  useEffect(() => {
    getTask({
      query: GET_ASSETS_LIST,
      variables: {
        projectId,
        offset: 0,
        limit,
      },
    });
  }, []);

  const saveReadAsset = async (taskId) => {
    try {
      await readAssets({ variables: { taskId } });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const formattedResponse = formatResponse(data);
    if (formattedResponse.length > 0) {
      if (offset === 0) {
        if (!selectedReadAssets) {
          setSelectedReadAssets(data?.task[0]?.id);
          saveReadAsset(data?.task[0]?.id);
        }

        setAssetsList(formattedResponse);
      } else {
        setAssetsList([...assetsList, ...formattedResponse]);
      }
    } else if (offset === 0) {
      setAssetsList([]);
    }
  }, [data]);

  const formatResponse = (responseData = {}) => {
    let lists = [];
    const listData =
      responseData?.task?.length > 0 ? [...responseData?.task] : [];
    if (listData.length > 0) {
      lists = listData.map((eachList) => {
        let imageList = eachList?.deliverables.map((eachRefrences) => ({
          clientStatus:
            (eachRefrences?.versions?.length > 0 &&
              eachRefrences?.versions[0]?.client_status?.toLowerCase()) ||
            "",
          currentVersionId: eachRefrences?.current_version_id,
          ...((eachRefrences?.versions?.length > 0 &&
            eachRefrences?.versions[0]?.resource) ||
            {}),
        }));
        return {
          ...eachList,
          delhiveryStatus: `Deliverable ${eachList.deliverables.length}`,
          status: eachList?.client_status?.toLowerCase() || "pending",
          imageURL: imageList?.sort((a, b) =>
            a.clientStatus === "pending"
              ? -1
              : b.clientStatus === "pending"
              ? 1
              : a.clientStatus < b.clientStatus
              ? -1
              : 1
          ),
        };
      });
      return lists?.sort((a, b) =>
        a.client_status?.toLowerCase() === "pending"
          ? -1
          : b.client_status?.toLowerCase() === "pending"
          ? 1
          : a.client_status?.toLowerCase() < b.client_status?.toLowerCase()
          ? -1
          : 1
      );
    } else {
      return lists;
    }
  };

  useEffect(() => {
    const formattedResponse = formatResponse(searchResults);
    setAssetsList(formattedResponse);
  }, [searchResults]);

  const searchAssets = (event) => {
    getSearchTask({
      variables: {
        projectId,
        search: "%" + event.target.value + "%",
      },
    });
  };

  const filterAssets = (filterValue) => {
    const queryData = {
      _or: [
        { client_status: { _eq: "PENDING" } },
        { client_status: { _eq: "APPROVED" } },
        { client_status: { _eq: "REJECTED" } },
      ],
    };

    const { type, state, space } = filterValue;
    if (type != "all") {
      queryData.type = { _eq: type };
    }
    if (space != "all") {
      queryData.subtype = { _eq: space };
    }
    if (state != "all") {
      if (queryData._or) delete queryData._or;
      queryData.client_status = { _eq: state };
    }
    getFilteredAssets({
      variables: {
        where: { project_id: { _eq: projectId }, ...queryData },
      },
    });
  };

  const viewAll = () => {
    const list = formatResponse(filteredResults) || [];
    if (list) setAssetsList(list);
  };

  const loadMoreAssets = async ($event) => {
    const totalCount = data?.task_aggregate?.aggregate?.count;
    if (assetsList.length < totalCount) {
      fetchMore({
        query: GET_ASSETS_LIST,
        variables: {
          projectId,
          offset: offset + limit,
          limit,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult;
        },
      });
      setOffset((value) => value + limit);
      $event.target.complete();
    } else {
      setDisableInfiniteScroll(true);
    }
  };

  const clearAllFilter = () => {
    fetchMore({
      query: GET_ASSETS_LIST,
      variables: {
        projectId,
        offset: 0,
        limit,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult;
      },
    });
    setOffset(0);
  };

  return (
    <PageLayout title="Assets" disablePaddingMd>
      <div style={{ marginBottom: "80px", width: "100%" }}>
        <AssetsHeaderWrapper>
          <AssetsHeader
            searchAssets={searchAssets}
            filterAssets={filterAssets}
            filteredResults={filteredResults}
            viewAll={viewAll}
            clearAllFilter={clearAllFilter}
          />
        </AssetsHeaderWrapper>

        <IonLoading
          isOpen={loading}
          message={"Please wait..."}
          spinner="bubbles"
          duration={5000}
        />
        {assetsList?.length > 0 ? (
          assetsList?.map((eachAssets, assetsIndex) => (
            <AssetsCard
              projectDetails={eachAssets}
              key={eachAssets.id || `project-assets-${assetsIndex}`}
            />
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        <IonInfiniteScroll
          threshold="100px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e) => loadMoreAssets(e)}
        >
          <IonInfiniteScrollContent loadingText="Loading assets..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </div>
    </PageLayout>
  );
};
export default withRouter(Assets);
