import styled from "styled-components";

import RightArrow from "../assests/icons/rightArrow";
import BackgroundImage from "../assests/images/welcomeBackground1.png";
import Flex from "components/flex";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
`;

const WelcomeContainer = styled(Flex)`
  color: var(--text-primary);
  position: absolute;
  width: 100%;
  top: 18%;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
`;

const Title = styled.div`
  font-size: 0.813rem;
  font-weight: 600;
  letter-spacing: 0.2px;
`;

const CompanyName = styled.div`
  font-size: 1.75rem;
  line-height: 50px;
  font-weight: 700;
  letter-spacing: 0.2px;
  margin-bottom: 80px;
`;

const LinkContainer = styled.div`
  font-size: 0.813rem;
  font-weight: 600;
  letter-spacing: 0.2px;
  opacity: 0.8;
  cursor: pointer;
`;

const WelcomePageComponent = ({
  onClick = undefined,
  hideLoginText = false,
}) => {
  return (
    <Wrapper>
      <WelcomeContainer column alignCenter>
        <Title>Welcome to</Title>
        <CompanyName>VIZDOM</CompanyName>
        {!hideLoginText && (
          <LinkContainer onClick={onClick}>
            <span style={{ marginRight: 8 }}> Login to access your design</span>
            <span>
              <RightArrow />
            </span>
          </LinkContainer>
        )}
      </WelcomeContainer>
      <ImageContainer />
    </Wrapper>
  );
};
export default WelcomePageComponent;
