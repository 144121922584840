import React, { useState } from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import { IonButton, IonContent, IonSpinner } from "@ionic/react";
import { useHistory, useParams } from "react-router-dom";
import { useRouterQuery } from "../../../lib/useRouterQuery";
import CompareAssets from "./compareAssets";
import { GET_VERSIONS } from "../../../graphQL/queries";

import CompareList from "./compareList";
import Flex from "../../flex";
import CompareItems from "./CompareItems";
import { useEffect } from "react";

const Title = styled.h2`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  margin: 50px 0 52px 0;
  color: var(--text-primary);
  padding: 0% 10%;
`;

const Button = styled(IonButton)`
  height: 41px;
  --background: #e6e6e6;
  --box-shadow: none;
  width: 153px;
  color: var(--text-primary);
  text-transform: capitalize;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 11.5px;
`;

const CompareButtonWrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
  height: 90px;
  background: #f5f5f5;
  padding-bottom: 20px;
`;
const DesktopWrapper = styled.div`
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const CompareTitle = styled.h2`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 13px;
  color: var(--text-primary);
`;

const SubTitle = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  color: var(--text-primary);
`;

const CompareListWrapper = styled.div`
  padding: 26px 10%;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 26px 0;
  }
`;

const CurrentVersionWrapper = styled.div`
  margin-top: ${({ top }) => top || "70px"};
`;

const Text = styled(SubTitle)`
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 18px;
`;

const Compare = ({
  onCompare = undefined,
  currentVersionId = "b7c43cde-4931-11ec-9df2-b756eed1564d",
  assetsList = {},
  selectedAsset = {},
}) => {
  const [compareVersions, setCompareVersions] = useState([]);
  const [compareAsset, setCompareAsset] = useState({});
  const [selectedVersion, setSelectedVersion] = useState("");
  const history = useHistory();
  const query = useRouterQuery();
  const { task_id } = useParams();
  const [getVersions, { data, loading }] = useLazyQuery(GET_VERSIONS);

  useEffect(() => {
    if (data?.task_deliverable && data?.task_deliverable.length > 0) {
      const deliverableVersion = data?.task_deliverable[0]?.versions.slice(1);
      const versions = deliverableVersion.map((each, index) => ({
        currentVersionId: data?.task_deliverable[0]?.current_version_id,
        index,
        id: each.id,
        version: each?.version,
        status: each?.status,
        ...each.resource,
      }));
      setCompareVersions(versions);
    }
  }, [data]);

  useEffect(() => {
    getVersions({
      variables: {
        currentVersionId: currentVersionId,
      },
    });
    setSelectedVersion("");
  }, [currentVersionId]);

  const onSelectAssetVersion = (version) => {
    setSelectedVersion(version);
  };

  const onCompareVersion = (value) => {
    history.push("?isCompare=true");
    setCompareAsset(value);
  };

  return (
    <>
      <DesktopWrapper>
        <Title>Compare With</Title>
        <IonContent
          style={{
            height: "58vh",
            "--background": "transparent",
          }}
        >
          <CompareListWrapper>
            {loading ? (
              <Flex center>
                <IonSpinner name="crescent" />
              </Flex>
            ) : (
              <CompareList
                width="301px"
                compareOptions={compareVersions}
                onSelect={onSelectAssetVersion}
                currentVersionId={currentVersionId}
              />
            )}
          </CompareListWrapper>
        </IonContent>
        {selectedVersion?.name && (
          <CompareButtonWrapper fullWidth center alignCenter>
            <Button shape="round" onClick={() => onCompare(selectedVersion)}>
              Compare Now
            </Button>
          </CompareButtonWrapper>
        )}
      </DesktopWrapper>
      <MobileWrapper>
        <CompareTitle>Compare Versions</CompareTitle>
        <SubTitle>{assetsList?.name || "--"}</SubTitle>
        {query.get("isCompare") ? (
          <CurrentVersionWrapper top="40px">
            <CompareAssets
              selectedAssetVersion={compareAsset}
              assetsList={assetsList}
            />
          </CurrentVersionWrapper>
        ) : (
          <>
            <CurrentVersionWrapper>
              <CompareItems
                isSelected
                name={selectedAsset?.name}
                filePath={selectedAsset?.url || ""}
                version={selectedAsset?.version}
                status={selectedAsset?.status}
                createdDate={selectedAsset?.created_at}
                backgroundColor="#F5F5F5"
              />
            </CurrentVersionWrapper>
            <Text>Compare with Earlier Options</Text>
            <CompareListWrapper>
              <CompareList
                compareOptions={compareVersions}
                onSelect={onSelectAssetVersion}
                backgroundColor="#F5F5F5"
              />
            </CompareListWrapper>
            {selectedVersion?.name && (
              <Flex fullWidth center alignCenter>
                <Button
                  shape="round"
                  onClick={() => onCompareVersion(selectedVersion)}
                >
                  Compare Now
                </Button>
              </Flex>
            )}
          </>
        )}
      </MobileWrapper>
    </>
  );
};

export default Compare;
