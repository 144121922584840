import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  IonButton,
  IonCard,
  IonCol,
  IonRow,
  useIonToast,
  IonSpinner,
} from "@ionic/react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Checkbox, Typography } from "antd";
import { useMutation, useLazyQuery } from "@apollo/client";

import {
  USER_PASSWORD_LOGIN,
  SAVE_USER_PUSH_NOTIFICATION_TOKEN,
} from "graphQL/mutations";
import {
  GET_USER_ID,
  GET_USER_CLIENT_ID,
  GET_PROJECTS_LIST,
} from "graphQL/queries";
import { firebaseClient } from "lib/firebaseClient";

import Input from "./input";

const Text = styled.h2`
  color: var(--text-primary);
  font-weight: ${({ fw }) => fw || "bold"};
  font-size: ${({ size }) => size || "2.25rem"};
  margin-bottom: ${({ marginBottom }) => marginBottom || " 15px"};
`;

const SubText = styled(Typography.Text)`
  color: ${({ color }) => color || "#1DA1F2"};
  font-size: 0.76rem;
  cursor: pointer;
  font-weight: 600;
`;

const Column = styled(IonCol)`
  text-align: end;
`;

const SubContent = styled(IonRow)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Button = styled(IonButton)`
  margin: 5vh 0;
  text-transform: capitalize;
  width: 148px;
  height: 41px;
  font-size: 0.688rem;
  font-weight: bold;
  font-family: Montserrat;
  --background: var(--button-primary);
  --box-shadow: none;
`;

const LoginText = styled.h5`
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 500;
`;

const StyledLink = styled(Link)`
  color: ${({ linkColor }) => linkColor || "#130f26"};
  font-weight: 600;
  font-size: 1.25rem;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

const LoginFooter = styled.div``;

const Card = styled(IonCard)`
  font-family: Montserrat, sans-serif;
  box-shadow: none;
  flex: 1.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10% auto 0 auto;
  justify-content: space-around;
  max-width: 530px;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin: 2vh auto 4.4rem auto;
    ${LoginText} {
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 0.938rem;
    }
    ${StyledLink} {
      font-size: 0.938rem;
    }
    ${LoginFooter} {
      background: #f5f5f5;
      width: 100%;
      bottom: 0px;
      left: 0px;
      padding: 0.3rem 0.75rem;
      position: fixed;
      height: 10vh;
      max-height: 140px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

const LoginComponent = () => {
  const [userName, setUserName] = useState({ value: null, error: null });
  const [password, setPassword] = useState({ value: null, error: null });
  const [isRememberMe, setRememberMe] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [present] = useIonToast();
  const history = useHistory();

  const [user] = useMutation(USER_PASSWORD_LOGIN);
  const [upsertUserPushNotificationToken] = useMutation(
    SAVE_USER_PUSH_NOTIFICATION_TOKEN
  );
  const [getProject, { data: projectData, error: projectError }] =
    useLazyQuery(GET_PROJECTS_LIST);

  const [getUserId, { data: userIdData, error: userIdError }] =
    useLazyQuery(GET_USER_ID);

  const [getUserClientId, { data: clientIdData, error: clientIdError }] =
    useLazyQuery(GET_USER_CLIENT_ID);

  const updateInputFields = (value, setValue) => {
    setValue({ value, error: null });
  };

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  const saveLoginCredentials = async () => {
    try {
      setLoading(true);
      const { data } = await user({
        variables: {
          email: userName.value,
          password: password.value,
          rememberMe: isRememberMe,
        },
      });
      if (data?.login?.token) {
        localStorage.setItem("token", data?.login?.token);
        await getUserId({
          variables: {
            gte: new Date().toISOString(),
            token: data.login.token,
          },
        });
        showToastMessage({
          message: data?.login?.message || "Logged in successfully!",
        });
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    var emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    let isValidEmail = validateEmail(userName.value);
    if (!isValidEmail) {
      setUserName({
        ...userName,
        error: !userName.value
          ? "Please enter the user name"
          : "Please enter the valid user name",
      });
    }
    if (!password.value) {
      setPassword({ ...password, error: "Please enter the password" });
    }
    if (userName.value && password.value && isValidEmail) {
      saveLoginCredentials();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const saveFirebaseToken = async (user) => {
    try {
      let information = await firebaseClient();
      await upsertUserPushNotificationToken({
        variables: {
          platform: information?.deviceInfo?.platform || "",
          token: information?.token || "",
          userId: user.id,
        },
      });
    } catch (err) {
      console.log("saveFirebaseToken Error: ", err);
    }
  };

  useEffect(async () => {
    if (userIdData?.client_user_session[0]?.user_id) {
      const user = userIdData.client_user_session[0].user
        ? JSON.stringify(userIdData.client_user_session[0].user)
        : "";

      localStorage.setItem("userId", userIdData.client_user_session[0].user_id);
      localStorage.setItem(
        "userName",
        userIdData.client_user_session[0].user?.name || ""
      );
      localStorage.setItem("user", user);
      saveFirebaseToken(userIdData?.client_user_session[0]?.user || "");
      await getUserClientId({
        variables: {
          user_id: userIdData.client_user_session[0].user_id,
        },
      });
    }
  }, [userIdData, userIdError]);

  useEffect(async () => {
    if (clientIdData?.client_user_map[0]?.client_id) {
      localStorage.setItem(
        "clientId",
        clientIdData.client_user_map[0].client_id
      );
      await getProject({
        variables: {
          client_id: clientIdData.client_user_map[0].client_id,
          delete_status: "DELETED",
        },
      });
    }
  }, [clientIdData, clientIdError]);

  useEffect(() => {
    if (projectData?.project.length) {
      localStorage.setItem("project", projectData?.project[0].name);
      localStorage.setItem("projectId", projectData?.project[0].id);
      history.push("/home");
    }
  }, [projectData, projectError]);

  return (
    <Card>
      <div>
        <LoginText as="h5" fw="500" size="1.5rem" marginBottom="20px">
          Login
        </LoginText>
        <Input
          placeholder="Enter Email"
          margin="0 0 0.5rem 0"
          onChange={(event) =>
            updateInputFields(event.target.value, setUserName)
          }
          error={userName.error}
          showError
          onKeyPress={handleKeyPress}
        />
        <Input
          placeholder="Enter Password"
          type="password"
          onChange={(event) =>
            updateInputFields(event.target.value, setPassword)
          }
          error={password.error}
          showError
          onKeyPress={handleKeyPress}
        />
        <IonRow>
          <IonCol size="7">
            <Checkbox
              checked={isRememberMe}
              onClick={(event) => setRememberMe(event.target.checked)}
            >
              <SubText>Keep me Logged in</SubText>
            </Checkbox>
          </IonCol>
          <Column>
            <SubText
              onClick={() => history.push("/forgot-password")}
              underline
              color="#130F26"
            >
              Forgot Password
            </SubText>
          </Column>
        </IonRow>
      </div>
      <SubContent>
        <Button shape="round" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? <IonSpinner name="crescent" /> : " Confirm "}
        </Button>
        <LoginFooter>
          <Text as="div" size="0.938rem" fw="600" marginBottom="2px">
            -OR-
          </Text>
          <StyledLink to="/login/phone-number">Login using OTP</StyledLink>
        </LoginFooter>
      </SubContent>
    </Card>
  );
};

export default LoginComponent;
