import React from "react";
import styled from "styled-components";
import { IonTextarea, IonContent as Content } from "@ionic/react";
import Flex from "../flex";
import IconWithTitle from "../iconWithTitle";

import thumbsUp from "../../assests/icons/svg/thumbsUp.svg";
import heartOutline from "../../assests/icons/svg/heartOutlined.svg";
import checkCircle from "../../assests/icons/svg/checkCircle.svg";
import heart from "../../assests/icons/svg/heart.svg";
import CheckFilled from "../../assests/icons/svg/approve/checkFilled.svg";
import HeartFilled from "../../assests/icons/svg/approve/heartFilled.svg";
import LikeFilled from "../../assests/icons/svg/approve/likeFilled.svg";

const Wrapper = styled(Flex)`
  width: 522px;
  height: 100%;
  backdrop-filter: blur(4px);
  background: #f5f5f5;
  padding: 12% 6.5% 1.5%;
  @media screen and (max-width: 768px) {
    background: #fff;
    padding: 20px 6%;
    width: 100%;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.938rem;
  color: var(--text-primary);
  margin-bottom: 17px;
`;

const InputWrapper = styled.div`
  font-family: Montserrat;
  background: #ffffff;
  border: ${({ isError }) =>
    isError ? "1px solid #FF6F6F" : "1px solid #aaaaaa"};
  box-sizing: border-box;
  border-radius: 10px;
`;

const InputTextArea = styled(IonTextarea)`
  font-family: Montserrat;
  font-size: 11px;
  color: var(--text-primary);

  .native-textarea.sc-ion-textarea-md {
    height: calc(100vh - 40vh) !important;
    max-height: 618px;
    padding: 10px 15px;
  }
`;
const FeedBackWrapper = styled.div`
  margin-top: 6%;
`;

const ErrorText = styled.div`
  font-family: Montserrat;
  color: #ff6f6f;
  visibility: ${({ isError }) => (isError ? "visible" : "hidden")};
  font-size: 0.862rem;
`;

const ApproveOrReject = ({
  approveOrRejectStatus = "reject",
  onSelectApproveFeedback = "",
  selectedApproveOption = "",
  onTextInput = undefined,
  errorMessage = "",
}) => {
  const approveFeedbackOptions = [
    {
      icon: checkCircle,
      label: "Satisfied",
      value: "satisfied",
      fontSize: "3.5rem",
      background: "#1DA1F2",
      activeIcon: CheckFilled,
    },
    {
      icon: thumbsUp,
      label: "Like it",
      value: "like_it",
      fontSize: "3.5rem",
      background: "#FFC542",
      activeIcon: LikeFilled,
    },
    {
      icon: heartOutline,
      label: "Love it",
      value: "love_it",
      filledIcon: heart,
      background: "#FF6F6F",
      activeIcon: HeartFilled,
    },
  ];
  return (
    <Wrapper column>
      <Title>
        {approveOrRejectStatus === "approve"
          ? "Please share your feedback of the Design"
          : "Please state the reason for rejecting the Design"}
      </Title>
      {approveOrRejectStatus === "approve" ? (
        <FeedBackWrapper>
          {approveFeedbackOptions?.map(
            (
              {
                icon,
                label,
                value,
                filledIcon = "",
                fontSize = "",
                background = "",
                activeIcon = "",
              },
              feedbackIndex
            ) => (
              <IconWithTitle
                icon={icon}
                activeIcon={activeIcon}
                label={label}
                value={value}
                key={`approve-feedback-${feedbackIndex}`}
                background={background}
                onselectItem={() =>
                  onSelectApproveFeedback({
                    label,
                    value,
                    icon,
                    filledIcon,
                    fontSize,
                  })
                }
                selectedItem={selectedApproveOption?.value}
              />
            )
          )}
          <ErrorText isError={errorMessage}>{errorMessage}</ErrorText>
        </FeedBackWrapper>
      ) : (
        <div>
          <InputWrapper isError={errorMessage}>
            <InputTextArea
              rows="auto"
              autofocus
              cols={6}
              placeholder="Type Here ..."
              onIonChange={(e) => onTextInput(e.detail.value)}
            ></InputTextArea>
          </InputWrapper>
          <ErrorText isError={errorMessage}>{errorMessage}</ErrorText>
        </div>
      )}
    </Wrapper>
  );
};

export default ApproveOrReject;
