import React, { useState } from "react";
import styled from "styled-components";
import { Modal, Button, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { LabelText, Title, MediumText } from "components/text";
import Flex from "components/flex";
import Input from "components/input";
import EditPicture from "./editPicture";
import { validator } from "lib/validator";
import { nameRules, emailRules } from "constants/rules";
import PhoneInput from "components/phoneInput";
import Logout from "components/logout";
import { useHistory } from "react-router-dom";

const { confirm } = Modal;

const MainWrapper = styled.div`
  width: 100%;
  max-width: 768px;
  margin: 0 auto 20% auto;
`;

const InputFieldWrapper = styled.div`
  flex: 1;
  margin-left: 10%;
`;

const SubWrapper = styled(Flex)`
  @media screen and (max-width: 768px) {
    flex-direction: column;
    .edit-picture-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ${InputFieldWrapper} {
      margin-top: 3rem;
      margin-left: 0px;
    }
  }
`;

const LogoutWrapper = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    margin: 10px 0;
    display: block;
  }
`;

const UserProfile = ({
  saveUserProfile,
  loading = false,
  onUploadProfilePic,
  deleteUserAccount = undefined,
}) => {
  const userName = localStorage.getItem("userName");
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {};

  const [name, setName] = useState({ value: user?.name || "", error: "" });
  const [email, setEmail] = useState({ value: user?.email || "", error: "" });
  const [phone, setPhone] = useState({ formattedNumber: user?.phone || "" });

  const [avatar, setAvatar] = useState(user?.avatar);

  const history = useHistory();

  const uploadProfile = (file) => {
    onUploadProfilePic(
      file,
      {
        userId: user.id,
        name: name.value,
        email: email.value,
        phone: phone.value,
        avatar,
      },
      setAvatar
    );
  };

  const updateInputFields = (value, setValue) => {
    setValue({ value, error: "" });
  };

  const saveUserInfo = ({ inputRules, inputDetails, field, inputSetState }) => {
    const inputValidation = validator({
      rules: inputRules,
      inputValue: inputDetails.value,
    });
    if (saveUserProfile && inputValidation.status) {
      saveUserProfile({
        [field]: inputDetails.value?.trim(),
      });
    } else {
      inputSetState({ ...inputDetails, error: inputValidation.errorMessage });
    }
  };

  const onUpdatePhone = (phoneObject) => {
    setPhone({ ...phoneObject });
    saveUserProfile({
      phone: phoneObject?.formattedNumber?.trim(),
    });
  };

  // const onDeleteAccount = () => {
  //   confirm({
  //     title: "Are you sure delete this account?",
  //     icon: <ExclamationCircleOutlined />,
  //     okText: "Yes, Delete",
  //     okType: "danger",
  //     cancelText: "No",
  //     onOk() {
  //       if (deleteUserAccount) {
  //         deleteUserAccount();
  //       }
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // };

  const onChangePassword = () => {
    history.push("/user/profile/change-password");
  };

  return (
    <MainWrapper>
      <Title as="div" margin="2rem 0 3.5rem 0">
        User Profile
      </Title>
      <SubWrapper>
        <div className="edit-picture-wrapper">
          <EditPicture
            onChangeProfile={uploadProfile}
            name={userName}
            avatar={avatar}
          />
        </div>

        <InputFieldWrapper>
          <Input
            label="Name"
            placeholder="Enter Name"
            margin="0 0 0.5rem 0"
            onChange={(event) => updateInputFields(event.target.value, setName)}
            error={name.error}
            value={name.value}
            onBlur={() =>
              saveUserInfo({
                inputRules: nameRules,
                inputDetails: name,
                field: "name",
                inputSetState: setName,
              })
            }
            showError
          />

          <PhoneInput onBlur={onUpdatePhone} defaultValue={user?.phone} />
          <Input
            label="Email"
            placeholder="Enter Email"
            margin="0 0 1.8rem 0"
            onChange={(event) =>
              updateInputFields(event.target.value, setEmail)
            }
            error={email.error}
            value={email.value}
            onBlur={() =>
              saveUserInfo({
                inputRules: emailRules,
                inputDetails: email,
                field: "email",
                inputSetState: setEmail,
              })
            }
            showError
          />
          <div>
            <LabelText as="div" margin="0 0 1rem 0">
              Account Settings
            </LabelText>
            <LogoutWrapper>
              <Logout primary profilePage />
            </LogoutWrapper>
            {/* <MediumText>
              Do you wish to&nbsp;
              <MediumText
                as="u"
                color="#1DA1F2"
                fontWeight="600"
                pointer
                onClick={onDeleteAccount}
              >
                Delete your Account&nbsp;
              </MediumText>
              ?
            </MediumText> */}
            <MediumText>
              Do you wish to&nbsp;
              <MediumText
                as="u"
                color="#1DA1F2"
                fontWeight="600"
                pointer
                onClick={onChangePassword}
              >
                Change your Password&nbsp;
              </MediumText>
              ?
            </MediumText>
          </div>
        </InputFieldWrapper>
      </SubWrapper>
    </MainWrapper>
  );
};

export default UserProfile;
