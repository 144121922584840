import React from "react";
import Arrow from "../assests/icons/arrow";
import styled from "styled-components";

const NavigationButton = styled.button`
  position: absolute;
  ${({ inverse, isVisible }) =>
    inverse ? `  left: 1rem;` : isVisible ? `  right: 1rem;` : "right:9rem;"}
  top: 45%;
  background: #0000008c;
  color: #fff;
  font-weight: bold;
  font-size: 0.6rem;
  z-index: 10;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  min-height: 2.3rem;
  min-width: 2.3rem;
  display: ${({ isEdit }) => (isEdit ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    ${({ inverse }) => (inverse ? `  left: 1rem;` : `  right: 1rem;`)}
    top: 47%;
  }
`;

const CarouselArrow = ({
  onClick = undefined,
  isVisible = false,
  isEdit = false,
  inverse = false,
  rightArrow = false,
}) => {
  return (
    <NavigationButton
      onClick={onClick}
      isVisible={isVisible}
      isEdit={isEdit}
      inverse={inverse}
    >
      <Arrow rightArrow={rightArrow} />
    </NavigationButton>
  );
};

export default CarouselArrow;
