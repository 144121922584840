import React from "react";

const ResetIcon = () => {
  return (
    <svg
      width="1.188em"
      height="1.313em"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.91828 11.1932C1.08572 8.8825 2.14266 6.77471 3.8944 5.25815C4.95752 4.33924 6.22612 3.69307 7.59274 3.37654L7.37375 1.31644C7.35192 1.11259 7.44193 0.89687 7.65005 0.78837C7.84269 0.683422 8.07624 0.704307 8.24586 0.84161L11.1928 3.22042C11.4221 3.40608 11.459 3.74481 11.2728 3.97722L8.8942 6.92323C8.75886 7.0887 8.5276 7.16109 8.32419 7.10082C8.11408 7.03944 7.96363 6.8586 7.94095 6.64197L7.72876 4.64757C6.6212 4.92965 5.59258 5.46678 4.72499 6.21869C3.2288 7.51261 2.32705 9.31158 2.1846 11.2855C2.04284 13.2542 2.67787 15.1632 3.97339 16.6607C5.26872 18.1579 7.06732 19.0609 9.03645 19.2033C11.0036 19.3455 12.9218 18.709 14.4147 17.4169C15.9109 16.1231 16.8127 14.3241 16.9547 12.3506C17.0688 10.7784 16.6898 9.2429 15.8595 7.90956C15.6766 7.616 15.7652 7.22596 16.0627 7.0353C16.3602 6.85041 16.7521 6.9417 16.9373 7.2388C17.9101 8.80112 18.3537 10.6007 18.2212 12.4431C18.0542 14.7532 16.9979 16.8608 15.2461 18.3775C14.9262 18.6537 14.5868 18.9075 14.2367 19.1319C12.6588 20.1432 10.8289 20.6055 8.94535 20.4692C6.63414 20.3021 4.52652 19.2455 3.01115 17.4932C1.49485 15.7418 0.751872 13.5039 0.91828 11.1932Z"
        fill="white"
      />
    </svg>
  );
};

export default ResetIcon;
