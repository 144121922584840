import React from "react";
import { IonPage, IonRow, IonCol } from "@ionic/react";
import styled from "styled-components";
import { Button as AntdButton } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

import WelcomePage from "../welcomePageComponent";

const ContentWrapper = styled(IonRow)`
  height: 100vh;
`;

const ImageContainer = styled(IonCol)`
  padding: 0;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const TitleDesktop = styled.div``;

const TitleMobile = styled.div`
  display: none;
`;

const Text = styled.h2`
  color: var(--text-primary);
  font-weight: ${({ fw }) => fw || "bold"};
  font-size: ${({ size }) => size || "2.25rem"};
  margin-bottom: ${({ marginBottom }) => marginBottom || " 15px"};
`;

const SubWrapper = styled.div`
  flex: 0.3;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: Montserrat;
  height: 100%;
  padding: 1rem 5vw;
  @media screen and (max-width: 768px) {
    ${TitleDesktop} {
      display: none;
    }
    ${TitleMobile} {
      display: flex;
      justify-content: center;
      margin-top: 10vh;
    }
  }
`;
const Button = styled(AntdButton)`
  position: fixed;
  display: none;
  top: 18px;
  left: 20px;
  width: 24px;
  min-width: 24px !important;
  background: #373738 !important;
  border-color: #373738 !important;
  height: 24px;
  span {
    font-size: 12px;
  }
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const ChangePasswordLayout = ({ children }) => {
  const history = useHistory();
  return (
    <IonPage>
      <ContentWrapper>
        <IonCol>
          <Button
            type="primary"
            shape="circle"
            icon={<LeftOutlined />}
            onClick={() => history.goBack()}
          />
          <LoginWrapper>
            <SubWrapper>
              <TitleDesktop>
                <Text>VIZDOM</Text>
                <Text as="h3" fw="600" size="1.25rem">
                  Welcome to VIZDOM!
                </Text>
              </TitleDesktop>
              <TitleMobile>
                <Text size="0.929rem">Change Password</Text>
              </TitleMobile>
            </SubWrapper>
            {children}
          </LoginWrapper>
        </IonCol>
        <ImageContainer>
          <WelcomePage />
        </ImageContainer>
      </ContentWrapper>
    </IonPage>
  );
};
export default ChangePasswordLayout;
