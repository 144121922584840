import React, { useState } from "react";
import { Select as AntdSelect, Drawer as AntdDrawer, Empty } from "antd";
import styled from "styled-components";
import country from "../constants/country.json";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonRadioGroup,
  IonRadio,
  IonSearchbar as IonSearch,
} from "@ionic/react";
import useWindowDimensions from "./useWindowDimensions";

const { Option } = AntdSelect;

const PhoneCodeWrapper = styled.div`
  margin-right: 10px;
`;

const Select = styled(AntdSelect)`
  width: 83px;
  font-size: 14px;
  .ant-select-selector {
    border: 1px solid #e2e2e2;
    border-radius: 10px !important;
    height: 51px !important;
    input {
      margin-top: 15% !important;
    }
    .ant-select-selection-item {
      padding-top: 12% !important;
    }
  }
`;

const FlagWrapper = styled.span`
  margin-right: 4px;
`;

const Wrapper = styled.div`
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5715;
  height: 100%;
`;
const Drawer = styled(AntdDrawer)`
  .ant-drawer-body {
    padding: 24px 0 !important;
  }
`;

const IonSearchbar = styled(IonSearch)`
  margin-bottom: 10px;
`;

const PhoneSelector = ({
  onPhoneCodeSelect = undefined,
  phoneCodeValue = "",
}) => {
  const { width } = useWindowDimensions();
  const [phoneCode, setPhoneCode] = useState("");
  const [isVisible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [countryCodes, setCountryCodes] = useState([...country]);

  const searchPhoneCode = (input, option) => {
    let regex = /^[a-zA-Z_ ]*$/;

    if (input.match(regex)) {
      if (input.length === 2) {
        return option.label.toLowerCase() === input.toLowerCase();
      }
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    } else {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  };

  const sortPhoneCode = (optionA, optionB) => {
    let regex = /^[a-zA-Z_ ]*$/;
    if (phoneCode.match(regex)) {
      return optionA.name
        .toLowerCase()
        .localeCompare(optionB.name.toLowerCase());
    } else {
      return optionA.value
        .toLowerCase()
        .localeCompare(optionB.value.toLowerCase());
    }
  };

  const showDrawer = () => {
    if (width <= 768) {
      setVisible(true);
    }
  };

  const onDialcodeSearch = (e) => {
    const searchValue = e.detail.value;
    const codesList = [...country];
    let field = "dial_code";
    let regex = /^[a-zA-Z_ ]*$/;

    if (searchValue.match(regex)) {
      if (searchValue.length === 2) {
        field = "code";
      } else {
        field = "name";
      }
    }

    const filteredList = codesList.filter((eachCode) =>
      eachCode[field].toLowerCase().includes(searchValue.toLowerCase())
    );
    setSearchText(searchValue);
    setCountryCodes(filteredList);
  };

  const selectPhoneCode = (value) => {
    if (onPhoneCodeSelect) onPhoneCodeSelect(value);
    if (width <= 768) {
      setVisible(false);
    }
  };

  return (
    <PhoneCodeWrapper>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={searchPhoneCode}
        optionLabelProp="value"
        filterSort={sortPhoneCode}
        onSearch={(value) => setPhoneCode(value)}
        value={phoneCodeValue || "--"}
        open={width <= 768 ? false : undefined}
        onClick={showDrawer}
        listHeight={200}
        onSelect={selectPhoneCode}
      >
        {country.map((eachCountry, countryIndex) => (
          <Option
            value={eachCountry.dial_code}
            label={eachCountry.code}
            key={`dial-code-${countryIndex}`}
            name={eachCountry.name}
          >
            <div>
              <FlagWrapper role="img" aria-label={eachCountry.name}>
                {eachCountry.flag}
              </FlagWrapper>
              {eachCountry.dial_code}
            </div>
          </Option>
        ))}
      </Select>
      <Drawer
        placement="bottom"
        closable={false}
        onClose={() => setVisible(false)}
        visible={width > 768 ? false : isVisible}
        key="bottom"
        height="290"
      >
        <Wrapper>
          <IonSearchbar
            value={searchText}
            color="light"
            type="tel"
            onIonChange={onDialcodeSearch}
          ></IonSearchbar>
          <IonContent>
            <IonList>
              <IonRadioGroup
                onIonChange={(event) => selectPhoneCode(event.detail.value)}
              >
                {countryCodes.length === 0 && (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                {countryCodes?.map((eachCountry, countryIndex) => (
                  <IonItem lines="full" key={`country-code-${countryIndex}`}>
                    <IonLabel>
                      <FlagWrapper role="img" aria-label={eachCountry.name}>
                        {eachCountry.flag}
                      </FlagWrapper>
                      {eachCountry.dial_code}
                    </IonLabel>
                    <IonRadio value={eachCountry.dial_code} color="dark" />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>
          </IonContent>
        </Wrapper>
      </Drawer>
    </PhoneCodeWrapper>
  );
};

export default PhoneSelector;
