import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CloseIcon from "../../assests/icons/close";
import { IonCol, IonRow, IonText, useIonToast } from "@ionic/react";
import { useLazyQuery } from "@apollo/client";
import { GET_COMMENT_POSITION } from "../../graphQL/queries";
import { useHistory } from "react-router-dom";
import { useRouterQuery } from "../../lib/useRouterQuery";
import InfiniteScroll from "react-infinite-scroller";

const TitleContent = styled.div`
  justify-content: space-between;
  padding: 0 50px 10px 30px;
  margin-bottom: 20px;
  display: flex;
`;

const Title = styled(IonText)`
  color: var(--text-primary);
  font-weight: 300;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
`;

const Close = styled.span`
  cursor: pointer;
`;

const Item = styled(IonRow)`
  border-bottom: 1px solid #f5f5f5;
  //   padding: 10px 15px;
  min-height: 86px;
  padding: 10px 40px;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const ItemLabel = styled(IonText)``;

const LabelHighlight = styled(IonText)`
  font-weight: 700;
  text-transform: uppercase;
`;

const NewNotDot = styled(IonCol)`
  max-width: 26px;
`;

const Dot = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  background: rgb(255, 79, 79);
  border-radius: 50%;
`;

const NoDataText = styled(IonText)`
  font-weight: 600;
  font-size: 0.8rem;
  color: var(--text-primary);
  padding-left: 40px;
  font-style: italic;
`;
const InfiniteScrollContent = styled.div`
  overflow-y: auto;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  letter-spacing: 0.2px;
  padding: 0px;
  @media screen and (max-width: 768px) {
    ${Title} {
      font-weight: 700;
    }
  }
`;

const NotificationDrawer = ({
  notifications,
  handleNotificationDrawer,
  disableInfiniteScroll,
  loadMoreNotifications,
  notificationLoading,
}) => {
  const [id, setId] = useState("");
  const [commentThread, setCommentThread] = useState("");
  const [taskId, setTaskId] = useState("");
  const [deliverableId, setDeliverableId] = useState("");
  const [tab, setTab] = useState("");

  const [present] = useIonToast();
  const history = useHistory();
  const query = useRouterQuery();
  const status = query.get("status");

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  const [getCommentPosition, { data: commentPosition }] = useLazyQuery(
    GET_COMMENT_POSITION,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        commentId: "",
        limit: 10,
        nestedLimit: 10,
        taskId: "",
        isFileComment: false,
      },
      onError: (err) => {
        showToastMessage({
          color: "danger",
          message: err.message || "Something went wrong please try again!",
        });
      },
    }
  );

  useEffect(() => {
    if (commentPosition?.getCommentPosition) {
      if (commentPosition.getCommentPosition?.nestedOffset === null) {
        if (taskId) {
          history.push(
            `/assets/${taskId}/individual-asset?status=comment&tab=${tab}&id=${id}&commentOffset=${commentPosition.getCommentPosition?.offset}&deliverableId=${deliverableId}`
          );
        }
      } else {
        if (taskId) {
          history.push(
            `/assets/${taskId}/individual-asset?status=comment&tab=${tab}&id=${id}&commentReplyId=${commentThread}&commentOffset=${commentPosition.getCommentPosition?.offset}&replyOffset=${commentPosition.getCommentPosition?.nestedOffset}&deliverableId=${deliverableId}`
          );
        }
      }
      if (status) {
        window.location.reload();
      }
      handleNotificationDrawer(false);
    }
  }, [commentPosition]);

  const handleGetCommentPosition = (id, taskId, isFileComment, limit = 10) => {
    getCommentPosition({
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        commentId: id,
        limit: limit,
        nestedLimit: 10,
        taskId: taskId,
        isFileComment,
      },
    });
  };

  const handleOnClickComment = (
    taskId,
    id,
    comment_thread,
    isFileComment,
    deliverableId
  ) => {
    setTaskId(taskId);
    setId(id);
    setCommentThread(comment_thread);
    if (deliverableId) {
      setDeliverableId(deliverableId);
      setTab("File");
    } else {
      setDeliverableId("");
      setTab("Task");
    }
    handleGetCommentPosition(
      id,
      taskId,
      isFileComment,
      deliverableId ? 100 : 10
    );
  };

  const handleClickNotification = (clientUrlObj) => {
    if (
      (clientUrlObj?.notificationFor === "reply" ||
        clientUrlObj?.notificationFor === "comment") &&
      !clientUrlObj.deliverableId
    ) {
      handleOnClickComment(
        clientUrlObj?.taskId || "",
        clientUrlObj?.replyId
          ? clientUrlObj?.replyId
          : clientUrlObj?.commentId || "",
        clientUrlObj?.thread_id || "",
        false,
        ""
      );
    } else if (
      clientUrlObj.deliverableId &&
      (clientUrlObj?.notificationFor === "reply" ||
        clientUrlObj?.notificationFor === "comment")
    ) {
      handleOnClickComment(
        clientUrlObj?.taskId || "",
        clientUrlObj?.replyId
          ? clientUrlObj?.replyId
          : clientUrlObj?.commentId || "",
        clientUrlObj?.thread_id || "",
        true,
        clientUrlObj?.deliverableId || ""
      );
    } else if (clientUrlObj?.notificationFor === "task") {
      if (clientUrlObj?.taskId) {
        history.push(`/assets/${clientUrlObj.taskId}/individual-asset`);
        handleNotificationDrawer(false);
      }
    }
  };

  return (
    <Content>
      <TitleContent>
        <Title>Notifications</Title>
        <Close onClick={() => handleNotificationDrawer(false)}>
          <CloseIcon stroke="#130F26" height="10" width="10" />
        </Close>
      </TitleContent>
      {notifications?.length > 0 ? (
        <InfiniteScrollContent>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={() => loadMoreNotifications()}
            hasMore={!notificationLoading && !disableInfiniteScroll}
            useWindow={false}
          >
            {notifications?.map((element, index) => (
              <Item
                key={element.notification?.id || index}
                onClick={() =>
                  handleClickNotification(element.notification?.client_url_obj)
                }
              >
                <IonCol>
                  <ItemLabel>{element.notification?.text || ""}</ItemLabel>
                  &nbsp;to&nbsp;
                  <LabelHighlight>
                    {element.notification?.title || ""}
                  </LabelHighlight>
                </IonCol>
                <NewNotDot>
                  {element.read_status === "PENDING" && <Dot />}
                </NewNotDot>
              </Item>
            ))}
          </InfiniteScroll>
        </InfiniteScrollContent>
      ) : (
        <NoDataText>You have no new notifications!</NoDataText>
      )}
    </Content>
  );
};

export default NotificationDrawer;
