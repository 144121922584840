import React from "react";
import { Drawer as AntdDrawer, Collapse } from "antd";
import styled from "styled-components";
import { IonButton, IonItem, IonSpinner } from "@ionic/react";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import FiterListItem from "./fiterListItem";
import Flex from "../flex";
import useWindowDimensions from "../useWindowDimensions";

const Panel = styled(Collapse.Panel)`
  border-bottom: 1px solid #dedddd !important;
  background: #fff;
  &:last-child {
    border-bottom: none !important;
  }
  .ant-collapse-header {
    background: #fff;
    padding: 28px 7px !important;
  }
  .ant-collapse-content-box {
    padding: 0px !important;
  }
  .ant-collapse-arrow {
    right: 15px !important;
  }
`;

const Item = styled(IonItem)`
  --padding-start: 10px !important;
  --inner-padding-end: 10px !important;
  --ripple-color: transparent;
`;

const Drawer = styled(AntdDrawer)`
  font-family: Montserrat;
  color: var(--text-primary);
  .ant-drawer-body {
    padding: 50px 50px 24px 50px !important;
  }
  .ant-drawer-footer {
    padding: 10px 16px 36px;
  }
`;

const Header = styled.h2`
  padding: 0px 10px;
  font-weight: 300;
  font-size: 1.75rem;
  color: var(--text-primary);
  margin-bottom: 36px;
`;

const PanelTitle = styled.h3`
  color: var(--text-primary);
  font-weight: bold;
  font-size: 0.82rem;
  margin-bottom: 0px;
`;

const Button = styled(IonButton)`
  text-transform: capitalize;
  font-size: 0.85rem;
  width: 122px;
  font-family: "Montserrat";
  --background: ${({ inverse }) => (inverse ? "#E6E6E6" : "#130f26")};
  height: 41px;
  color: ${({ inverse }) => (inverse ? "#130F26" : "#ffffff")};
  font-weight: bold;
  --box-shadow: none;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const FilterDrawer = ({
  visible = false,
  onClose,
  isLoading = false,
  filterAssets = undefined,
  filteredResults = "",
  viewAll = undefined,
  selectedItem = undefined,
  selectedFilter = {},
  resetFilter = undefined,
}) => {
  const { width } = useWindowDimensions();

  const fiterLists = [
    {
      panelTitle: "Filter By State",
      panelValue: "state",
      items: [
        { label: "All", value: "all" },
        { label: "Pending", value: "PENDING" },
        { label: "Approved", value: "APPROVED" },
        { label: "Rejected", value: "REJECTED" },
      ],
    },
    {
      panelTitle: "Filter By Space",
      panelValue: "space",
      items: [
        { label: "All", value: "all" },
        { label: "Meeting Room", value: "Meeting Room" },
        { label: "Conference Room", value: "Conference" },
        { label: "Reception Area", value: "Reception Area" },
      ],
    },
    {
      panelTitle: "Filter By Type",
      panelValue: "type",
      items: [
        { label: "All", value: "all" },
        { label: "3Ds", value: "3D" },
        { label: "TDs", value: "TD" },
        { label: "WDs", value: "WD" },
        { label: "Vizwalk", value: "Vizwalk" },
      ],
    },
  ];

  const formatAssetsCount = (count) => {
    if (count < 1e3) return count;
    if (count >= 1e3 && count < 1e6) return +(count / 1e3).toFixed(1) + "K";
    if (count >= 1e6 && count < 1e9) return +(count / 1e6).toFixed(1) + "M";
    if (count >= 1e9 && count < 1e12) return +(count / 1e9).toFixed(1) + "B";
    if (count >= 1e12) return +(count / 1e12).toFixed(1) + "T";
  };

  return (
    <Drawer
      placement="right"
      width={width <= 768 ? 300 : 483}
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <Flex center alignCenter>
          <Button
            shape="round"
            style={{ marginRight: "10px" }}
            inverse
            onClick={resetFilter}
          >
            Clear All
          </Button>
          <Button shape="round" onClick={viewAll}>
            {isLoading ? (
              <IonSpinner name="crescent" />
            ) : filteredResults?.task_aggregate?.aggregate ? (
              "View " +
              formatAssetsCount(
                filteredResults.task_aggregate.aggregate?.count || 0
              )
            ) : (
              "View"
            )}
          </Button>
        </Flex>
      }
    >
      <div>
        <Header>Filter</Header>
        <Collapse
          bordered={false}
          expandIconPosition="right"
          expandIcon={(panelValue) =>
            panelValue?.isActive ? <CloseOutlined /> : <PlusOutlined />
          }
        >
          {fiterLists?.map(({ panelTitle, panelValue, items }) => (
            <Panel
              header={<PanelTitle>{panelTitle}</PanelTitle>}
              key={panelValue}
            >
              <FiterListItem
                items={items}
                panelValue={panelValue}
                value={selectedFilter[panelValue]}
                selectedItem={selectedItem}
              />
            </Panel>
          ))}
        </Collapse>
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
