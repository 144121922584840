import styled, { css } from "styled-components";

const defaultStyles = ({
  center,
  flexEnd,
  spaceBetween,
  spaceAround,
  spaceEvenly,
  section,
  column,
  cursor,
  wrap,
  alignCenter,
  alignStart,
  fullHeight,
  alignEnd,
  fullWidth,
  padding,
  columnReverse,
  flexCount,
}) =>
  css`
    display: flex;
    ${center && "justify-content: center;"}
    ${spaceBetween && "justify-content: space-between;"}
    ${spaceAround && "justify-content: space-around;"}
    ${spaceEvenly && "justify-content: space-evenly;"}
    ${flexEnd && "justify-content: flex-end;"}
    ${wrap && "flex-wrap: wrap;"}
    ${alignCenter && "align-items: center;"}
    ${alignStart && "align-items: flex-start;"}
    ${section && `margin: ${section || "20px"};`}
    ${column && "flex-direction: column;"}
    ${columnReverse && "flex-direction: column-reverse;"}
    ${cursor && "cursor: pointer;"}
    ${fullHeight && "height:100%"}
    ${alignEnd && "align-items: end;"}
    ${fullWidth && "width:100%"}
    ${padding && `padding:${padding}`}
    ${flexCount && `flex:1`}
  `;
const Flex = styled.div`
  ${defaultStyles}
  ${(props) => props.styles}
`;

export default Flex;
