import React from "react";
import styled from "styled-components";

import CloseIcon from "../../assests/icons/close";

import {
  IonCol,
  IonRow,
  IonText,
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonButton,
} from "@ionic/react";

const TitleContent = styled(IonRow)`
  justify-content: space-between;
  padding: 0 24px 10px 8px;
  margin-bottom: 20px;
`;

const Title = styled(IonText)`
  color: var(--text-primary);
  font-weight: 300;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
`;

const Close = styled.span`
  cursor: pointer;
`;

const SelectorContent = styled(IonCol)`
  flex: 1 1 0%;
  overflow-y: auto;
`;

const SelectorLabel = styled(IonLabel)`
  margin: 26px 0px;
  font-family: Montserrat, sans-serif;
`;

const ButtonContent = styled(IonRow)`
  align-items: center;
  justify-content: center;
`;

const Button = styled(IonButton)`
  text-transform: capitalize;
  width: 140px;
  height: 41px;
  font-size: 0.688rem;
  font-weight: bold;
  font-family: Montserrat;
  --background: var(--button-primary);
  --box-shadow: none;
`;

const Content = styled(IonCol)`
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  letter-spacing: 0.2px;
  @media screen and (max-width: 768px) {
    ${Title} {
      font-weight: 700;
    }
    ${ButtonContent} {
      flex: 0.2;
    }
  }
`;

const ProjectDrawer = ({
  projects,
  selectedProject,
  handlerProjectDrawer,
  handleSelector,
  handleSwitchProject,
}) => {
  return (
    <Content>
      <TitleContent>
        <Title>Switch Project</Title>
        <Close onClick={() => handlerProjectDrawer(false)}>
          <CloseIcon stroke="#130F26" height="10" width="10" />
        </Close>
      </TitleContent>
      <SelectorContent>
        <IonList>
          <IonRadioGroup
            value={selectedProject}
            onIonChange={(e) => handleSelector(e.detail.value)}
          >
            {projects.map((element, index) => (
              <IonItem key={element.id || index}>
                <SelectorLabel
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 600,
                    color: "#130F26",
                    fontSize: "0.75rem",
                  }}
                >
                  {element.name}
                </SelectorLabel>
                <IonRadio
                  mode="md"
                  slot="end"
                  value={element.name}
                  color="dark"
                />
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>
      </SelectorContent>
      <ButtonContent>
        <div>
          <Button
            onClick={() => handleSwitchProject(selectedProject)}
            shape="round"
          >
            Switch
          </Button>
        </div>
      </ButtonContent>
    </Content>
  );
};

export default ProjectDrawer;
