import React, { useState, useEffect } from "react";
import { validator } from "lib/validator";
import { phoneNumberRules } from "constants/rules";
import Input from "components/input";

const PhoneInput = ({
  defaultValue = "",
  label = "",
  placeholder = "",
  margin = "",
  onBlur = "",
}) => {
  const formattedNumber = defaultValue?.split(" ");
  const phoneCode = formattedNumber?.length > 1 ? formattedNumber[0] : "";
  const number =
    formattedNumber?.length > 1
      ? formattedNumber[1]
      : (formattedNumber?.length > 0 && formattedNumber[0]) || "";

  const [phone, setPhone] = useState({
    code: phoneCode,
    value: number,
    error: "",
    formattedNumber: "",
  });

  const onPhoneChange = ({ target }) => {
    const value = target.value;
    setPhone({
      ...phone,
      value,
      error: "",
      formattedNumber: (phone.code + " " + value).trim(),
    });
  };
  const onPhoneCodeSelect = (code) => {
    setPhone({ ...phone, code: code.replace(/\+/g, "") });
    if (onBlur) {
      onBlur({
        ...phone,
        code,
        formattedNumber: (code.replace(/\+/g, "") + " " + phone.value).trim(),
      });
    }
  };

  const validatePhone = () => {
    const phoneValid = validator({
      rules: phoneNumberRules,
      inputValue: phone.value || "",
    });
    setPhone({ ...phone, error: phoneValid.errorMessage });
    if (phoneValid.status) {
      if (onBlur)
        onBlur({
          ...phone,
          error: phoneValid.errorMessage,
          formattedNumber: (phone.code + " " + phone.value).trim(),
        });
    }
  };

  return (
    <Input
      label={label || "Phone Number"}
      placeholder={placeholder || "Enter Phone Number"}
      margin={margin || "0 0 0.5rem 0"}
      phoneCodeValue={phone.code ? `+${phone.code}` : ""}
      showPhoneCode
      onChange={onPhoneChange}
      error={phone.error}
      value={phone.value}
      onPhoneCodeSelect={onPhoneCodeSelect}
      onBlur={validatePhone}
      showError
    />
  );
};

export default PhoneInput;
