import React, { useEffect, useState, useRef, Fragment } from "react";

import { useParams } from "react-router-dom";
import { Image as AntdImage } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  IonSpinner,
  IonText,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { useQuery } from "@apollo/client";
import PlaceholderImage from "assests/images/placeholder.jpg";

import Flex from "../flex";
import { getDate } from "lib/getDate";
import { fileDownloader } from "lib/fileDownloader";
import { SmallText } from "components/text";
import { GET_ATTACHMENT_MEDIA } from "graphQL/queries";
import MediaDetailedView from "./mediaDetailedView";

const Image = styled(AntdImage)`
  width: 290px;
  height: 167px;
  @media (max-width: 768px) {
    width: 146px;
    height: 84px;
  }
`;

const DateLabel = styled.label`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.2px;
  color: #969697;
  align-self: start;
  margin: 10px 8px 0;
`;

const DownloadIcon = styled.div`
  position: absolute;
  right: 6%;
  top: 10%;
  background: white;
  padding: 2px 8px;
  display: flex;
  border-radius: 4px;
  display: none;
  z-index: 10;
  cursor: pointer;
  border: 1px solid gray;
`;

const ImageContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  &:hover {
    ${DownloadIcon} {
      display: block;
    }
  }
`;

const Text = styled(IonText)`
  font-family: Montserrat, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  color: #575757;
`;

const AntDownloadIcon = styled(DownloadOutlined)`
  font-size: 16px;
`;
const MediaWrapper = styled(Flex)`
  .ant-image {
    margin: 8px;
  }
  @media screen and (max-width: 768px) {
    ${DownloadIcon} {
      display: block;
      padding: 0px 8px;
    }
    ${AntDownloadIcon} {
      font-size: 12px;
    }
  }
`;

const Wrapper = styled.div`
  margin-top: 20px;
  ${SmallText} {
    margin-left: 8px;
  }
  @media screen and (max-width: 768px) {
  }
`;

const DateWrapper = styled(Flex)`
  &:first-child {
    margin: 0px !important;
  }
`;

const Media = () => {
  const { task_id } = useParams();
  const mediaElement = useRef();
  const [mediaLoading, setMediaLoading] = useState(true);
  const [imageDetailsList, setImageList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState("");

  const limit = 10;

  const { data, loading, refetch } = useQuery(GET_ATTACHMENT_MEDIA, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      offset,
      limit,
      taskId: task_id,
    },
  });
  const totalImages = data?.resource_aggregate?.aggregate?.count || 0;

  useEffect(() => {
    const list = data?.resource || [];
    if (imageDetailsList?.length > 0) {
      setImageList([...imageDetailsList, ...list]);
    } else {
      setImageList([...list]);
    }
    setMediaLoading(false);
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (mediaElement?.current) {
        mediaElement?.current.scrollIntoView({
          top: 0,
          behavior: "smooth",
        });
      }
    }, 10);
  }, []);

  const loadMoreMedia = ($event) => {
    if (imageDetailsList.length < data?.resource_aggregate?.aggregate?.count) {
      setOffset(offset + limit);
      refetch();
      $event.target.complete();
    } else {
      setDisableInfiniteScroll(true);
    }
  };

  const onDownload = (url, fileName) => {
    if (url) {
      fileDownloader({
        url: url,
        fileName: fileName,
      });
    }
  };

  const showModal = (mediaIndex) => {
    setSelectedMedia(mediaIndex);
    setIsModalVisible(true);
  };

  return (
    <Wrapper>
      {totalImages > 0 && (
        <SmallText fontWeight={600}>
          {totalImages} {totalImages > 1 ? "Images" : "Image"}
        </SmallText>
      )}
      <MediaWrapper wrap alignCenter ref={mediaElement} id="media_container">
        <IonContent
          style={{
            width: "100%",
            overflowY: "auto",
            height: window.innerHeight - 250,
            "--background": "transparent",
            "--padding-bottom": "30px",
          }}
          scrollEvents
        >
          {(imageDetailsList.length > 0 && (
            <Flex wrap alignEnd>
              {imageDetailsList?.map(
                ({ url, created_at = "", name = "" }, imageListIndex) => (
                  <Fragment key={`media-${imageListIndex}`}>
                    {imageDetailsList[imageListIndex - 1] &&
                    getDate(created_at) ===
                      getDate(
                        imageDetailsList[imageListIndex - 1].created_at
                      ) ? null : (
                      <DateWrapper
                        fullWidth
                        section="15px 0 0"
                        key={`media-date-${imageListIndex}`}
                      >
                        <DateLabel>
                          {getDate(new Date()) === getDate(created_at)
                            ? "Today"
                            : getDate(created_at)}
                        </DateLabel>
                      </DateWrapper>
                    )}
                    <Flex column key={`media-list-image-${imageListIndex}`}>
                      <ImageContent>
                        <DownloadIcon
                          title="Download"
                          onClick={() => onDownload(url, name)}
                        >
                          <AntDownloadIcon />
                        </DownloadIcon>
                        <Image
                          preview={false}
                          src={url || "error"}
                          fallback={PlaceholderImage}
                          onClick={() => showModal(imageListIndex)}
                        />
                      </ImageContent>
                    </Flex>
                  </Fragment>
                )
              )}
            </Flex>
          )) || <Text>No Media Found</Text>}
          <IonInfiniteScroll
            threshold="100px"
            disabled={disableInfiniteScroll}
            onIonInfinite={(e) => loadMoreMedia(e)}
          >
            <IonInfiniteScrollContent loadingText="Loading media..."></IonInfiniteScrollContent>
          </IonInfiniteScroll>
          {(mediaLoading || loading) && (
            <Flex center alignCenter fullWidth section="20px 0">
              <IonSpinner name="crescent" />
            </Flex>
          )}
        </IonContent>
      </MediaWrapper>
      <MediaDetailedView
        imageDetailsList={imageDetailsList}
        isVisible={isModalVisible}
        defaultSelected={selectedMedia}
        onClose={() => setIsModalVisible(false)}
      />
    </Wrapper>
  );
};

export default Media;
