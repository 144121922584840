import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useIonToast } from "@ionic/react";

import { VERIFY_USER_OTP, USER_PHONE_LOGIN } from "../graphQL/mutations";
import LoginLayout from "./loginLayout";
import LoginWithPhoneComponent from "./loginWithPhoneComponent";
import {
  GET_USER_ID,
  GET_USER_CLIENT_ID,
  GET_PROJECTS_LIST,
} from "../graphQL/queries";

const OTPInput = ({
  title = "",
  redirect = "/login/phone-number",
  layoutTitle = "",
  type = "login",
  phoneCodeValue = "",
  number = "",
}) => {
  const [verifyOtp, { loading }] = useMutation(VERIFY_USER_OTP);
  const [sendOtp] = useMutation(USER_PHONE_LOGIN);
  const [getProject, { data: projectData, error: projectError }] =
    useLazyQuery(GET_PROJECTS_LIST);

  const [getUserId, { data: userIdData, error: userIdError }] =
    useLazyQuery(GET_USER_ID);

  const [getUserClientId, { data: clientIdData, error: clientIdError }] =
    useLazyQuery(GET_USER_CLIENT_ID);

  const phoneNumber = number || localStorage.getItem("phoneNumber") || "";
  const phoneCode =
    phoneCodeValue || localStorage.getItem("phoneCode") || "+91";
  const [seconds, setSeconds] = useState(60);
  const [interval, setMyInterval] = useState(null);

  const history = useHistory();
  const [present] = useIonToast();
  const [otpInputs, setOtpInputs] = useState([
    { code: "" },
    { code: "" },
    { code: "" },
    { code: "" },
  ]);
  const [otpError, setOtpError] = useState("");

  const onPhoneInputClick = () => {
    history.push(redirect);
  };

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  const getOTPValues = (otps) => {
    const otpValues = [...otps];
    return otpValues
      ?.map((each) => each.code)
      .join()
      .replaceAll(",", "");
  };

  const submitOTP = async () => {
    try {
      const concatOTPValue = getOTPValues(otpInputs);
      if (concatOTPValue.length < 4) {
        setOtpError("Please enter the received OTP");
        return;
      }
      const userPhoneNumber = (phoneCode + " " + phoneNumber).replace(
        /\+/g,
        ""
      );
      const { data } = await verifyOtp({
        variables: {
          otp: concatOTPValue,
          phoneNumber: userPhoneNumber,
        },
      });
      if (data?.verifyOtp?.token) {
        if (type === "forgot-password") {
          history.push(`/change-password?token=${data.verifyOtp.token}`);
        } else {
          localStorage.setItem("token", data.verifyOtp.token);
          await getUserId({
            variables: {
              gte: new Date().toISOString(),
              token: data.verifyOtp.token,
            },
          });
          showToastMessage({ message: data?.login?.message });
        }
      }
    } catch (err) {
      showToastMessage({
        color: "danger",
        message: err.message || "Something went wrong please try again!",
      });
    }
  };

  const changeOtpValue = (value, index) => {
    const phoneRegex = /^(?:[1-9]\d*|\d)$/;
    if (!value.match(phoneRegex)) {
      return;
    }
    setOtpError("");
    const otpValue = [...otpInputs];
    setOtpInputs(
      otpValue.map((each, otpIndex) =>
        otpIndex === index
          ? { code: value.charAt(value.length - 1) || "" }
          : each
      )
    );
    if (index < otpInputs.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const resendOTP = async () => {
    try {
      const userPhoneNumber = (phoneCode + " " + phoneNumber).replace(
        /\+/g,
        ""
      );
      const { data } = await sendOtp({
        variables: {
          phoneNumber: userPhoneNumber,
        },
      });
      if (data) {
        setSeconds(60);
        showToastMessage({
          message: "OTP sent to the phone number",
        });
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    }
  };

  useEffect(() => {
    let count = seconds;
    const newInterval = setInterval(() => {
      count--;
      if (count <= 0) {
        setSeconds(0);
        clearInterval(newInterval);
      } else {
        setSeconds(count);
      }
    }, 1000);
    setMyInterval(newInterval);
    return () => clearInterval(newInterval);
  }, [seconds]);

  useEffect(async () => {
    if (userIdData?.client_user_session[0]?.user_id) {
      localStorage.setItem("userId", userIdData.client_user_session[0].user_id);
      localStorage.setItem(
        "userName",
        userIdData.client_user_session[0].user?.name || ""
      );
      await getUserClientId({
        variables: {
          user_id: userIdData.client_user_session[0].user_id,
        },
      });
    }
  }, [userIdData, userIdError]);

  useEffect(async () => {
    if (clientIdData?.client_user_map[0]?.client_id) {
      localStorage.setItem(
        "clientId",
        clientIdData.client_user_map[0].client_id
      );
      await getProject({
        variables: {
          client_id: clientIdData.client_user_map[0].client_id,
          delete_status: "DELETED",
        },
      });
    }
  }, [clientIdData, clientIdError]);

  useEffect(() => {
    if (projectData?.project.length) {
      localStorage.setItem("project", projectData?.project[0].name);
      localStorage.setItem("projectId", projectData?.project[0].id);
      history.push("/home");
    }
  }, [projectData, projectError]);

  return (
    <LoginLayout showBackButton title={layoutTitle}>
      <LoginWithPhoneComponent
        title={title}
        inputLabel="An OTP has been shared on your Number "
        value={phoneNumber}
        phoneCodeValue={phoneCode}
        onInputClick={onPhoneInputClick}
        showOTP
        seconds={seconds}
        onSubmit={submitOTP}
        otpInputs={otpInputs}
        onOtpEnter={changeOtpValue}
        loading={loading}
        otpError={otpError}
        resendOTP={resendOTP}
      ></LoginWithPhoneComponent>
    </LoginLayout>
  );
};

export default OTPInput;
