import React, { useEffect } from "react";
import ChangePasswordComponent from "../../../components/changePassword/changePassword";
import { withRouter } from "react-router";
import ChanePasswordLayout from "../../../components/changePassword/layout";
import { useRouterQuery } from "../../../lib/useRouterQuery";

const ChangePassword = () => {
  const query = useRouterQuery();
  const resetToken = query.get("token");

  return (
    <ChanePasswordLayout>
      <ChangePasswordComponent resetToken={resetToken} />
    </ChanePasswordLayout>
  );
};
export default withRouter(ChangePassword);
