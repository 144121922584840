import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { MessageOutlined, MessageFilled } from "@ant-design/icons";
import { IonSpinner } from "@ionic/react";
import { Badge } from "antd";
import { useRouterQuery } from "../../lib/useRouterQuery";
import { fileDownloader } from "../../lib/fileDownloader";
import { Tooltip as AntdTooltip } from "antd";

import Send from "../../assests/icons/send";
import Clip from "../../assests/icons/clip";
import Download from "../../assests/icons/download";
import CompareIcon from "../../assests/icons/compareIconFilled";

import Flex from "../flex";
import AssetsComponents from "../assetsComponents";
import AttachmentsComponents from "../attachmentsComponents";
import ShareAssets from "../review/shareAssets";

const IconWrapper = styled.div`
  font-size: 1rem;
  margin-right: 18px;
  &:last-child {
    margin-right: 0px;
  }
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;

const Text = styled.span`
  font-size: 0.8rem;
  text-decoration: underline;
  margin-left: 7px;
  font-weight: 600;
  font-family: "Montserrat";
  margin-top: 2.5px;
`;

const DownloadWrapper = styled(IconWrapper)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AssetsActionsWrapper = styled(Flex)`
  padding: 16.5px 0;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    padding: ${({ padding }) => padding || "16.5px 5%"};
    ${DownloadWrapper} {
      justify-content: flex-start;
    }

    ${Text} {
      display: none;
    }
  }
`;
const IconLink = styled(Link)`
  font-size: 1rem;
  margin-right: 18px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: 0.3s;
  position: relative;
`;

const CompareText = styled.div`
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  margin-left: 4px;
  color: var(--text-primary);
`;

const TooltipText = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 0.796rem;
  color: #ffffff;
`;
const Tooltip = styled(AntdTooltip)`
  .ant-tooltip-inner {
    min-height: 22px !important;
    padding: 1px 8px !important;
  }
  @media screen and (max-width: 768px) {
    .ant-tooltip-placement-bottom {
      visibility: hidden !important;
    }
  }
`;

const AssetsActions = ({
  taskId,
  path = "individual-asset",
  padding = "",
  compareOption = false,
  fileCommentList = [],
  totalFileComment = 0,
  projectDetails = "",
  getFileOffset = undefined,
  currentVersionId = "",
}) => {
  const query = useRouterQuery();
  const status = query.get("status");
  const [isShareVisible, setIsShareVisible] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const onDownload = async () => {
    setIsDownloadLoading(true);
    const imagesList = projectDetails?.imageURL?.filter((each) => each.url);
    if (imagesList?.length > 0) {
      await imagesList.forEach(async (eachImage) => {
        await fileDownloader({
          url: eachImage?.url,
          fileName: eachImage?.name,
        });
      });
    }
    setIsDownloadLoading(false);
  };

  const onShareAssets = () => {
    setIsShareVisible(true);
  };

  return (
    <div>
      <ShareAssets
        isShareModelType
        isShareVisible={isShareVisible}
        imageURL={projectDetails?.imageURL || []}
        onCancel={() => setIsShareVisible(false)}
        currentVersionId={currentVersionId}
      />
      <AssetsActionsWrapper alignCenter padding={padding}>
        <IconLink to={`/assets/${taskId}/${path}?status=comment&tab=Task`}>
          <Tooltip
            placement="bottom"
            title={<TooltipText>Comments</TooltipText>}
            color="#130F26"
            getPopupContainer={(trigger) => trigger}
          >
            <Badge dot offset={[-2, 4]} style={{ boxShadow: "none" }}>
              {status === "comment" || status === "pinned_comments" ? (
                <MessageFilled style={{ fontSize: "18px", color: "#130F26" }} />
              ) : (
                <MessageOutlined
                  style={{ fontSize: "18px", color: "#130F26" }}
                />
              )}
            </Badge>
          </Tooltip>
        </IconLink>
        <Tooltip
          placement="bottom"
          title={<TooltipText>Share</TooltipText>}
          getPopupContainer={(trigger) => trigger}
          color="#130F26"
        >
          <IconWrapper onClick={onShareAssets}>
            <Send />
          </IconWrapper>
        </Tooltip>
        <Tooltip
          placement="bottom"
          title={<TooltipText>Attachments</TooltipText>}
          color="#130F26"
          getPopupContainer={(trigger) => trigger}
        >
          <IconLink to={`/assets/${taskId}/${path}?status=attachment`}>
            <Clip strokeWidth={status === "attachment" ? 0.6 : 0} />
          </IconLink>
        </Tooltip>
        {isDownloadLoading ? (
          <DownloadWrapper>
            <IonSpinner name="bubbles" />
          </DownloadWrapper>
        ) : (
          <DownloadWrapper onClick={onDownload}>
            <Download />
            <Text>Download</Text>
          </DownloadWrapper>
        )}

        {compareOption && (
          <Tooltip
            placement="bottom"
            title={<TooltipText>Compare</TooltipText>}
            color="#130F26"
            getPopupContainer={(trigger) => trigger}
          >
            <IconLink to={`/assets/${taskId}/compare`}>
              <CompareIcon />
              <CompareText>Compare</CompareText>
            </IconLink>
          </Tooltip>
        )}
      </AssetsActionsWrapper>
      {status === "comment" && (
        <AssetsComponents
          fileCommentList={fileCommentList}
          fillCommentTotalCount={totalFileComment}
          getFileOffset={getFileOffset}
          path={path}
        />
      )}
      {status === "pinned_comments" && (
        <AssetsComponents getFileOffset={getFileOffset} />
      )}
      {status === "attachment" && <AttachmentsComponents />}
    </div>
  );
};

export default AssetsActions;
