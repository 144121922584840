import React, { useEffect, useState } from "react";
import { IonCol, IonRow, IonSpinner } from "@ionic/react";
import { Input, Upload, Popover } from "antd";
import styled from "styled-components";
import Clip from "../../assests/icons/clip";
import Send from "../../assests/icons/send";
import Close from "../../assests/icons/close";
import { Drawer } from "antd";
import AttachmentSelector from "./attachmentDrawer";
import useWindowDimensions from "../useWindowDimensions";
import { CloseOutlined, FileTextOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { stringToHslColor } from "../../lib/stringToHslColor";

const { TextArea } = Input;

const CommentTextBox = styled(TextArea)`
  font-family: Montserrat, sans-serif;
  border-radius: 10px 0px 0px 10px;
  padding: ${({ review, isPreviewReply, isMobView }) =>
    isMobView
      ? "12px 40px 10px 54px"
      : review
      ? "13px 40px 10px 10px"
      : isPreviewReply
      ? "18px 40px 16px 54px"
      : "20px 40px 10px 54px"};
  border-right: none;
  height: ${({ isReply }) => (isReply ? "40px" : "60px")};
`;

const CommentInputBox = styled(IonCol)`
  flex: 1;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
`;

const SendBox = styled(IonCol)`
  flex: 0.01;
  padding: 0;
`;

const Button = styled.div`
  background: ${({ review }) => (review ? "#FFFFFF" : "#f5f5f5")};
  display: flex;
  align-items: center;
  color: var(--text-primary);
  font-size: 0.75rem;
  border-radius: 0px 10px 10px 0px;
  height: 100%;
  padding: ${({ review }) => (review ? "0 12px 0 0" : "0 16px 0 16px")};
  cursor: ${({ cursor }) => (cursor ? "pointer" : "not-allowed")};
  border: ${({ buttonShadow }) => !buttonShadow && "1px solid #e2e2e2"};
  border-bottom: ${({ buttonShadow, buttonOverShadow }) =>
    buttonOverShadow || buttonShadow ? "1px solid #40a9ff" : ""};
  border-top: ${({ buttonShadow, buttonOverShadow }) =>
    buttonOverShadow || buttonShadow ? "1px solid #40a9ff" : ""};
  box-shadow: ${({ buttonShadow, buttonOverShadow }) =>
    buttonShadow
      ? "1px 0px 2px 0px #40a9ff"
      : (buttonOverShadow || buttonShadow) && "1px 0px 0px 0px #40a9ff"};
  border-left: none;
`;

const SendText = styled.span`
  margin-right: 6px;
`;
const ProfileIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ isReply }) => (isReply ? "1.8rem" : "2.375rem")};
  width: ${({ isReply }) => (isReply ? "1.8rem" : "2.375rem")};
  border-radius: 50%;
  background-color: rgb(124, 158, 201);
  color: rgb(255, 255, 255);
  font-weight: 600;
  z-index: 11;
  left: 10px;
  font-size: ${({ isPreviewReply }) => isPreviewReply && "1rem"};
`;

const AttachmentModal = styled(IonRow)`
  font-family: Montserrat, sans-serif;
  width: 130px;
  height: inherit;
  outline: none;
  padding: 20px 0 20px 0;
  text-align: center;
  border-radius: 10px;
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
    background-color: inherit;
    border: none;
  }
`;

const HeaderTitle = styled(IonRow)`
  font-weight: 700;
  font-size: 0.938rem;
  padding: 0 20px;
`;

const MenuItem = styled(IonRow)`
  width: 100%;
  padding: 20px 10px 20px 20px;
  font-weight: 600;
  font-size: 0.75rem;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const AntPopover = styled.div`
  position: absolute;
  z-index: 11;
  right: ${({ review }) => (review ? "14px" : "20px")};
  display: flex;
`;

const AttachmentDrawer = styled(Drawer)`
  // .ant-drawer-body {
  //   padding: ${({ padding }) => padding || ""};
  // }
  .ant-drawer-body {
    padding: 50px 0px 40px 0px;
  }
`;

const PreviewImgContainer = styled.div`
  height: 82%;
  width: 100%;
  position: absolute;
  z-index: 10;
  bottom: 18%;
  background: #f5f5f5;
`;

const PreviewImgContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CloseContent = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
  cursor: pointer;
`;

const PreviewImg = styled.div`
  height: 65%;
  width: 65%;
`;

const FileClose = styled.div`
  display: flex;
  align-items: center;
  background: #f7f6f6;
  max-width: max-content;
  margin-top: 10px;
  padding: 6px 20px;
  border-radius: 8px;
`;

const FileName = styled.div`
  font-size: 1.2rem;
  color: var(--text-primary);
  padding: 10px 10px;
`;
const ProfileAvatar = styled(Avatar)`
  background-color: ${({ background }) =>
    background ? background : "#3f99a2"};
  height: 100%;
  width: 100%;
  .ant-avatar-string {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const ProfileText = styled.span`
  font-size: ${({ replyText }) => (replyText ? "0.875rem" : "1.125rem")};
`;

const Content = styled(IonRow)`
  font-family: Montserrat, sans-serif;
  padding-right: 4px;
  @media screen and (max-width: 768px) {
    // background-color: #f5f5f5;
    ${SendText} {
      display: none;
    }
    ${Button} {
      background: ${({ isReply }) => isReply && "#ffffff"};
      border-left: ${({ isReply }) => isReply && "1px solid #e2e2e2"};
    }
    ${AntPopover} {
      right: 12px;
    }
    ${PreviewImgContainer} {
      bottom: -4%;
      height: 78%;
    }
  }
`;

const CloseIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 16px;
`;

const CommentInput = ({
  message,
  handleOnchangeComment,
  handleAttachment,
  handleSendComment,
  handleCloseReply,
  isReply,
  isLoading,
  isAttchment,
  commentAttachedFile,
  onFocusInput,
  replyToPersonName,
  path,
  signedUrl,
  handleCloseFileAttachment,
  displayUploadedImage,
  fileFormat,
  fileName,
  isPreviewReply,
}) => {
  const [buttonShadow, setButtonShadow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isMobAttachment, setIsMobAttachment] = useState(false);
  const [buttonOverShadow, setButtonOverShadow] = useState(false);
  const [popVisible, setPopVisible] = useState(false);

  const { width } = useWindowDimensions();
  const inputRef = React.useRef(null);

  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {};

  useEffect(() => {
    setIsMobAttachment(width <= 768 ? true : false);
  }, [width]);

  useEffect(() => {
    if (isReply) {
      inputRef.current.focus({
        cursor: "start",
      });
    }
  }, [onFocusInput]);

  const onFocusHandler = () => {
    setButtonShadow(true);
  };

  const blurHandler = () => {
    setButtonShadow(false);
  };

  const handleAttachmentDrawer = (visible) => {
    setVisible(visible);
  };

  const handleFileAttachment = (file, menu) => {
    setVisible(false);
    handleAttachment(file, menu);
  };

  const Menu = ({ menu, handleAttachment }) => {
    return (
      <Upload
        name="logo"
        listType="picture-card"
        className="avatar-uploader"
        multiple={false}
        accept={menu === "Photo" ? "image/*" : ".pdf,.doc,.docs"}
        beforeUpload={() => false}
        onChange={(file) => {
          setPopVisible(false);
          handleAttachment(file, menu);
        }}
        showUploadList={false}
      >
        <MenuItem>{menu}</MenuItem>
      </Upload>
    );
  };

  const Tooltip = (
    <AttachmentModal>
      <HeaderTitle>Attach</HeaderTitle>
      <Menu menu="Photo" handleAttachment={handleAttachment} />
      <Menu menu="Document" handleAttachment={handleAttachment} />
    </AttachmentModal>
  );

  return (
    <Content isReply={isReply}>
      {signedUrl && width > 768 && (
        <PreviewImgContainer>
          <PreviewImgContent>
            <CloseContent onClick={handleCloseFileAttachment}>
              <CloseOutlined />
            </CloseContent>
            {fileFormat === "Photo" ? (
              <PreviewImg>
                <img
                  src={displayUploadedImage}
                  alt="image"
                  height="100%"
                  width="100%"
                />
              </PreviewImg>
            ) : (
              <div>
                <FileClose>
                  <FileTextOutlined
                    style={{ fontSize: 50, color: "#7C9EC9" }}
                  />
                  <FileName title={fileName}>
                    {fileName?.toString().length >= 40
                      ? fileName?.toString().substr(0, 20) + "..."
                      : fileName?.toString()}
                  </FileName>
                </FileClose>
              </div>
            )}
          </PreviewImgContent>
        </PreviewImgContainer>
      )}
      <CommentInputBox>
        {(path !== "review" || width <= 768 || isPreviewReply) && (
          <ProfileIcon isPreviewReply={isPreviewReply}>
            <ProfileAvatar
              src={user?.avatar}
              background={stringToHslColor(user?.name || "A")}
            >
              <ProfileText>
                {(user?.name && user.name.charAt(0)) || "A"}
              </ProfileText>
            </ProfileAvatar>
          </ProfileIcon>
        )}
        <CommentTextBox
          onFocus={onFocusHandler}
          onBlur={blurHandler}
          onMouseOver={() => setButtonOverShadow(true)}
          onMouseLeave={() => setButtonOverShadow(false)}
          autoSize={
            path === "review" || isPreviewReply
              ? { minRows: 1, maxRows: 2 }
              : { minRows: width <= 768 ? 1 : 2, maxRows: 4 }
          }
          isMobView={width <= 768 ? true : false}
          review={path === "review" ? true : false}
          isPreviewReply={isPreviewReply}
          size="medium"
          placeholder={
            isPreviewReply
              ? "Reply..."
              : replyToPersonName
              ? "Reply to " + replyToPersonName
              : "Add a comment"
          }
          onChange={handleOnchangeComment}
          value={message}
          ref={inputRef}
        />
        {isAttchment && !isMobAttachment && (
          <AntPopover
            review={path === "review" ? true : false}
            onClick={() => setPopVisible(true)}
          >
            <Popover
              content={Tooltip}
              trigger="click"
              visible={popVisible}
              onVisibleChange={() => setPopVisible(!popVisible)}
            >
              <span style={{ cursor: "pointer", display: "flex" }}>
                <Clip fill={popVisible ? "#1DA1F2" : "#A6A6A6"} />
              </span>
            </Popover>
          </AntPopover>
        )}
        {isAttchment && isMobAttachment && (
          <AntPopover onClick={() => handleAttachmentDrawer(true)}>
            <span style={{ cursor: "pointer", display: "flex" }}>
              <Clip fill={visible ? "#1DA1F2" : "#A6A6A6"} />
            </span>
          </AntPopover>
        )}
      </CommentInputBox>
      <SendBox>
        <Button
          onClick={
            (message.length > 0 || commentAttachedFile) && handleSendComment
          }
          isReply={isReply}
          buttonShadow={buttonShadow}
          buttonOverShadow={buttonOverShadow}
          cursor={message.length > 0 || commentAttachedFile ? true : false}
          review={path === "review" ? true : false}
        >
          {isLoading ? (
            <IonSpinner name="crescent" />
          ) : (
            <>
              {path === "review" ? (
                <span style={{ color: "blue" }}>
                  <u>Post</u>
                </span>
              ) : (
                <>
                  {!isReply && <SendText>Send</SendText>}
                  <Send stroke="#FFFFFF" fill="#130F26" />
                </>
              )}
            </>
          )}
        </Button>
      </SendBox>
      <AttachmentDrawer
        placement={"bottom"}
        closable={false}
        visible={visible}
        width={"100%"}
        height="60%"
      >
        <AttachmentSelector
          handleAttachmentDrawer={handleAttachmentDrawer}
          handleAttachment={handleFileAttachment}
        />
      </AttachmentDrawer>
      {isReply && (
        <CloseIcon>
          <span style={{ cursor: "pointer" }} onClick={handleCloseReply}>
            <Close stroke="#130F26" height="10" width="10" />
          </span>
        </CloseIcon>
      )}
    </Content>
  );
};

export default CommentInput;
