import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./core/routes";
import "antd/dist/antd.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "./lib/apolloClient";

const {
  REACT_APP_FB_API_KEY,
  REACT_APP_FB_AUTH_DOMAIN,
  REACT_APP_FB_PROJECT_ID,
  REACT_APP_FB_STORAGE_BUCKET,
  REACT_APP_FB_MSG_SENDER_ID,
  REACT_APP_FB_APP_ID,
  REACT_APP_FB_MEASUREMENT_ID,
} = process.env;
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = new URLSearchParams({
  apiKey: REACT_APP_FB_API_KEY,
  authDomain: REACT_APP_FB_AUTH_DOMAIN,
  projectId: REACT_APP_FB_PROJECT_ID,
  storageBucket: REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FB_MSG_SENDER_ID,
  appId: REACT_APP_FB_APP_ID,
  measurementId: REACT_APP_FB_MEASUREMENT_ID,
});

const App = () => {
  const apolloClient = useApollo();
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register(`./firebase-messaging-sw.js?${firebaseConfig}`)
      .then(function (registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function (err) {
        console.log("Service worker registration failed, error:", err);
      });
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <Routes />
      </Router>
    </ApolloProvider>
  );
};

export default App;
