import React from "react";
import { IonItem, IonLabel, IonIcon } from "@ionic/react";
import styled from "styled-components";

const Icon = styled(IonIcon)`
  font-size: 15px;
  margin-right: 18px;
  position: absolute;
  z-index: 2;
  overflow: hidden;
`;

const Label = styled(IonLabel)`
  --ion-font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  line-height: 15px;
  --color: var(--text-primary);
  margin-left: 28px;
  transition: 0.35s all;
`;
const ActiveIcon = styled(IonIcon)`
  font-size: 15px;
  margin-right: 18px;
  position: absolute;
  right: 90%;
  transition: 0.25s all;
  visibility: hidden;
`;

const Item = styled(IonItem)`
  position: relative;
  --ion-color-success: #8bddb3;
  font-size: 12px;
  border-radius: 5px;
  margin-bottom: 20px;
  --ion-color-success: ${({ color, background }) =>
    color === "success" ? background : "#fff"};

  &:hover {
    // opacity: ${({ color }) => (color === "success" ? 1 : 0.7)};
    --background: ${({ background }) => background};
    ${Icon} {
      visibility: hidden;
    }
    ${ActiveIcon} {
      right: 2%;
      font-size: 24px;
      color: #fff;
      visibility: visible;
    }
    ${Label} {
      margin-left: 18px;
      --color: #fff;
    }
  }
  ${({ color }) =>
    color === "success" &&
    `
    ${Icon} {
      visibility: hidden;
    }
    ${ActiveIcon} {
      right: 2%;
      font-size: 24px;
      color: #fff;
      visibility: visible;
    }
  ${Label} {
    margin-left: 18px;
    --color: #fff;
  }
  `}
  @media screen and (max-width: 768px) {
    --background: #f5f5f5;
  }
`;

const IconWithTitle = ({
  label = "",
  icon = "",
  onselectItem = undefined,
  selectedItem = undefined,
  value = "",
  background = "",
  activeIcon = "",
}) => {
  return (
    <Item
      button
      lines="none"
      onClick={onselectItem}
      color={(selectedItem === value && "success") || undefined}
      background={background}
    >
      <Label>{label}</Label>
      <Icon icon={icon} slot="start" />
      <ActiveIcon icon={activeIcon} slot="start" />
    </Item>
  );
};

export default IconWithTitle;
