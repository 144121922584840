import { IonButton, IonRow, IonText } from "@ionic/react";
import CloseIcon from "../../assests/icons/close";
import styled from "styled-components";

const Close = styled.span`
  cursor: pointer;
`;

const Button = styled(IonButton)`
  text-transform: capitalize;
  width: 140px;
  height: 41px;
  font-size: 0.688rem;
  font-weight: bold;
  font-family: Montserrat;
  --background: var(--button-primary);
  --box-shadow: none;
`;

const CloseContent = styled(IonRow)`
  justify-content: flex-end;
`;

const Content = styled(IonRow)`
  font-family: Montserrat, sans-serif;
  justify-content: center;
  padding: 20px 60px;
`;

const Message = styled(IonText)`
  color: var(--text-primary);
  margin-bottom: 40px;
  font-size: 0.875rem;
  font-weight: 400;
`;
const SwitchProjectConfirmation = ({
  handleModal,
  handleSwitchProjectConfirmation,
  selectedProject,
  existingProject,
}) => {
  return (
    <div>
      <CloseContent>
        <Close onClick={() => handleModal(false)}>
          <CloseIcon stroke="#130F26" height="10" width="10" />
        </Close>
      </CloseContent>
      <Content>
        <Message>
          Are you sure you want to switch from {existingProject} to{" "}
          {selectedProject} ?
        </Message>
        <div>
          <Button
            onClick={() => handleSwitchProjectConfirmation(selectedProject)}
            shape="round"
          >
            Switch
          </Button>
        </div>
      </Content>
    </div>
  );
};
export default SwitchProjectConfirmation;
