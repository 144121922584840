import styled from "styled-components";

const Dot = styled.div`
  display: inline-block;
  margin-right: 5px;
  border-radius: 50%;
  width: ${({ size }) => size || "0.75rem"};
  height: ${({ size }) => size || "0.75rem"};
  background: ${({ status, color }) =>
    status
      ? status === "rejected"
        ? "#FF6F6F"
        : status === "approved"
        ? "#8BDDB3"
        : "#FFDC3E"
      : color || "#FFDC3E"};
`;

export default Dot;
