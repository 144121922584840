import React from "react";

const MobCommentsIcon = ({
  width = "16",
  height = "16",
  viewBox = "0 0 16 16",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6262 8.27535H10.6322"
        stroke="#929292"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.95338 8.27535H7.95938"
        stroke="#929292"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.28053 8.27535H5.28653"
        stroke="#929292"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7137 12.7132C10.677 14.7509 7.6594 15.1911 5.19055 14.0494C4.82608 13.9026 2.4671 14.5559 1.95524 14.0447C1.44337 13.5327 2.09727 11.1734 1.95055 10.8089C0.808238 8.34039 1.24908 5.32174 3.28648 3.28474C5.88732 0.682877 10.1129 0.682877 12.7137 3.28474C15.3199 5.89063 15.3145 10.112 12.7137 12.7132Z"
        stroke="#929292"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default MobCommentsIcon;
