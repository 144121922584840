import React from "react";
import styled from "styled-components";
import { IonIcon } from "@ionic/react";
import EditProfileIcon from "assests/icons/svg/editProfile.svg";

import UserAvatar from "components/userAvatar";
import Flex from "components/flex";

const EditWrapper = styled.label`
  display: block;
  width: fit-content;
  height: fit-content;
  position: relative;
  margin-top: 0.5rem;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s all;
  &:hover {
    box-shadow: 1px 3px 10px #a6a0a0;
  }
`;

const Input = styled.input`
  display: none;
`;

const Icon = styled(IonIcon)`
  font-size: 15px;
`;

const IconWrapper = styled(Flex)`
  position: absolute;
  width: 28px;
  height: 28px;
  right: 5%;
  border-radius: 50%;
  z-index: 2;
  background: #130f26;
  bottom: 0;
`;

const EditPicture = ({
  onChangeProfile = undefined,
  name = "",
  avatar = "",
}) => {
  const handledUploadProfile = (event) => {
    if (onChangeProfile) {
      onChangeProfile(event.target.files[0]);
    }
    event.target.value = "";
  };

  return (
    <EditWrapper>
      <UserAvatar size={80} textSize="1.5rem" name={name} avatar={avatar} />
      <Input
        type="file"
        id="upload"
        accept="image/*"
        onChange={handledUploadProfile}
      />
      <IconWrapper center alignCenter>
        <Icon icon={EditProfileIcon} />
      </IconWrapper>
    </EditWrapper>
  );
};

export default EditPicture;
