import React, { useState, useEffect, useRef } from "react";
import Flex from "../flex";
import styled from "styled-components";
import { List } from "antd";
import CommentTextArea from "./commentTextArea";
import { REPLY_TO_COMMENT } from "../../graphQL/mutations";
import { GET_REPLIES_LIST } from "../../graphQL/queries";
import { useIonToast, IonSpinner } from "@ionic/react";

import { useMutation, useLazyQuery } from "@apollo/client";
import ProfileWithComment from "./profileWithComment";

const CommentWrapper = styled.div`
  background: #ffffff;
  border-radius: 5px;
  width: 20vw;
  max-width: 358px;
  max-height: 308px;
  min-width: 200px;
  .ant-list-items {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .ant-list-empty-text {
    display: none !important;
  }
`;

const CommentCount = styled(Flex)`
  border-right: 1px solid #e6e6e6;
  min-width: 22px;
  padding: 1.2rem 0 1rem 0rem;
`;

const CommentItem = styled.div`
  flex: 1;
  overflow: auto;
  margin-right: 3%;
  max-height: 225px;
  padding: 0.45rem 0 0.45rem 3%;
`;

const Item = styled(List.Item)`
  border: none !important;
`;

const ScrollDivContainer = styled.div`
  visibility: hidden;
  height: 0;
`;
const ViewMoreText = styled.div`
  color: #575757;
  font-size: 0.8rem;
  cursor: pointer;
  text-decoration: underline;
  margin: 14px 0 14px 18%;
`;

const CommentList = ({
  comments = [],
  fileCommentIndex = 0,
  commentInfo = {},
  totalFileComment = 0,
  onPostReplyComment = undefined,
}) => {
  const [allComments, setAllComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [replyToComment, { loading }] = useMutation(REPLY_TO_COMMENT);
  const [present] = useIonToast();
  const divElement = useRef(null);
  const [repliesOffset, setRepliesOffset] = useState(0);
  const [totalRepliesCount, setTotalRepliesCount] = useState(
    commentInfo?.comments_aggregate?.aggregate?.count || 0
  );
  const postedComment = comments[0] || "";
  const [getReplies, { data: repliesData, loading: repliesLoading }] =
    useLazyQuery(GET_REPLIES_LIST, {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        comment_thread: commentInfo.id,
        offset: repliesOffset,
        limit: 6,
        userId: localStorage.getItem("userId"),
        createdAt: "desc",
      },
      onError: (err) => {
        console.log(err.message);
      },
    });

  useEffect(() => {
    const repliesComment = repliesData?.comment || [];
    if (repliesOffset + 6 < totalRepliesCount) {
      setAllComments([...allComments, ...repliesComment]);
    } else {
      if (repliesComment.length > 0) {
        const formattedData = [...repliesComment];
        formattedData.splice(-1);
        setAllComments([...allComments, ...formattedData]);
      }
    }
  }, [repliesData]);

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  useEffect(() => {
    getReplies();
  }, []);

  const postReplyComment = async () => {
    try {
      let userId = localStorage.getItem("userId");
      const { data } = await replyToComment({
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
        variables: {
          comment_text: commentText,
          comment_user: userId,
          comment_thread: commentInfo.id,
        },
      });
      if (data?.insert_comment_one) {
        const allCommentsCopy = [...allComments];
        allCommentsCopy.unshift(data?.insert_comment_one);
        setAllComments([...allCommentsCopy]);
        if (onPostReplyComment) {
          onPostReplyComment(commentInfo.id, data?.insert_comment_one);
        }

        setTimeout(() => {
          divElement.current.scrollIntoView();
        }, 30);
        setCommentText("");
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    }
  };

  const onComment = ({ target: { value } }) => {
    setCommentText(value);
  };

  const loadMoreReplies = () => {
    getReplies();
    setRepliesOffset((state) => state + 6);
  };

  return (
    <CommentWrapper>
      <Flex>
        <CommentCount center>
          {totalFileComment - fileCommentIndex}.
        </CommentCount>
        <CommentItem>
          <ProfileWithComment
            avatar={postedComment?.user?.avatar}
            name={postedComment?.user?.name}
            commentText={postedComment?.comment_text}
            padding="12px 0"
          />
          {repliesLoading ? (
            <ViewMoreText>
              <IonSpinner name="dots"></IonSpinner>
            </ViewMoreText>
          ) : (
            allComments.length < totalRepliesCount - 1 && (
              <ViewMoreText onClick={loadMoreReplies}>
                View Older Replies ({totalRepliesCount - 1 - allComments.length}
                )
              </ViewMoreText>
            )
          )}
          <Flex column>
            <>
              <List
                dataSource={allComments}
                renderItem={(item, index) => (
                  <Item key={item.id || `file-image-comment-${index}`}>
                    <ProfileWithComment
                      name={item?.user?.name}
                      commentText={item.comment_text}
                      avatar={item?.user?.avatar}
                    />
                  </Item>
                )}
              />
            </>
          </Flex>
          <ScrollDivContainer ref={divElement}></ScrollDivContainer>
        </CommentItem>
      </Flex>
      <div>
        <CommentTextArea
          borderBottom
          autoSizeOptions={{ minRows: 2, maxRows: 2 }}
          commentPlaceholder={comments?.length > 0 ? "Reply..." : undefined}
          postComment={postReplyComment}
          onComment={onComment}
          commentText={commentText}
          isLoading={loading}
        />
      </div>
    </CommentWrapper>
  );
};

export default CommentList;
