import CommentsItem from "./commentsItem";
import { useState, useEffect } from "react";

import dateTimeToTimestamp from "../../lib/DateTimeToTimeStamp";
import timeStampToTimeElapsed from "../../lib/TimeStampToTimeElapsed";
import styled from "styled-components";
import {
  IonRow,
  IonText,
  IonInfiniteScroll,
  useIonToast,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  GET_NEW_COMMENTS,
  GET_OLD_COMMENTS,
  GET_COMMENT_POSITION,
} from "../../graphQL/queries";
import { useLazyQuery } from "@apollo/client";

const CommentsContainer = styled(IonRow)`
  border-bottom: ${({ border }) => (border ? border : "")};
`;

const TimeStampHolder = styled(IonText)`
  display: ${({ display }) => display};
  border-top: ${({ border_top }) => border_top};
  width: 100%;
  padding-top: 12px;
  font-weight: 700;
  font-size: 0.938rem;
  color: rgb(19, 15, 38);
  // margin-left: 16px;
`;

const Title = styled.div`
  font-weight: 300;
  color: var(--text-primary);
  margin: 40px 0 10px;
`;

const CommentsComponent = () => {
  const history = useHistory();
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [newCommentsData, setNewCommentsData] = useState([]);
  const [newCommentsCount, setNewCommentsCount] = useState(0);
  const [getMoreComments, setGetMoreComments] = useState(false);
  const [newCommentsOffset, setNewCommentsOffset] = useState(0);
  const [newCommentslimit] = useState(10);
  const [oldCommentsOffset, setOldCommentsOffset] = useState(0);
  const [oldCommentslimit] = useState(10);
  const [oldCommentsCount, setOldCommentsCount] = useState(0);
  const [loadOldComments, setLoadOldComments] = useState(false);
  const [id, setId] = useState("");
  const [commentThread, setCommentThread] = useState("");
  const [taskId, setTaskId] = useState("");

  const [present] = useIonToast();

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  const [getNewComments, { data: newComments, loading: loadingNewComments }] =
    useLazyQuery(GET_NEW_COMMENTS, {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        limit: newCommentslimit,
        offset: newCommentsOffset,
        projectId: localStorage.getItem("projectId"),
        userId: localStorage.getItem("userId"),
      },
      onError: (err) => {
        showToastMessage({
          color: "danger",
          message: err.message || "Something went wrong please try again!",
        });
      },
    });

  useEffect(() => {
    loadComments("new", "");
  }, []);

  const [getOldComments, { data: oldComments, loading: loadingOldComments }] =
    useLazyQuery(GET_OLD_COMMENTS, {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        limit: oldCommentslimit,
        offset: oldCommentsOffset,
        projectId: localStorage.getItem("projectId"),
        userId: localStorage.getItem("userId"),
      },
      onError: (err) => {
        showToastMessage({
          color: "danger",
          message: err.message || "Something went wrong please try again!",
        });
      },
    });
  const [getInitialOldComments, { data: initialOldComments }] = useLazyQuery(
    GET_OLD_COMMENTS,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        limit: oldCommentslimit,
        offset: oldCommentsOffset,
        projectId: localStorage.getItem("projectId"),
        userId: localStorage.getItem("userId"),
      },
      onError: (err) => {
        showToastMessage({
          color: "danger",
          message: err.message || "Something went wrong please try again!",
        });
      },
    }
  );

  useEffect(() => {
    if (newComments?.comment?.length > 0) {
      if (getMoreComments) {
        setCommentsData([...commentsData, ...newComments?.comment]);
        setNewCommentsData([...commentsData, ...newComments?.comment]);
      } else {
        setCommentsData(newComments?.comment);
        setNewCommentsData(newComments?.comment);
        if (newComments?.comment_aggregate?.aggregate?.count <= 10) {
          loadComments("old", "", "initialLoadOldComments");
        }
      }
      if (newCommentsOffset === 0) {
        setNewCommentsCount(newComments?.comment_aggregate?.aggregate?.count);
      }
      setGetMoreComments(false);
    } else {
      if (newComments?.comment_aggregate?.aggregate?.count === 0) {
        loadComments("old", "");
      }
    }
  }, [newComments]);

  useEffect(() => {
    if (initialOldComments?.comment?.length > 0) {
      setCommentsData([...commentsData, ...initialOldComments?.comment]);
      setOldCommentsCount(
        initialOldComments?.comment_aggregate?.aggregate?.count
      );
      setGetMoreComments(false);
    }
  }, [initialOldComments]);

  useEffect(() => {
    if (oldComments?.comment?.length > 0) {
      if (getMoreComments) {
        setCommentsData([...commentsData, ...oldComments?.comment]);
      } else {
        setCommentsData(oldComments?.comment);
      }
      setOldCommentsCount(oldComments?.comment_aggregate?.aggregate?.count);
      setGetMoreComments(false);
    }
  }, [oldComments]);

  const loadComments = (type, status, initEvent) => {
    if (initEvent === "initialLoadOldComments") {
      getInitialOldComments({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {
          limit: oldCommentslimit,
          offset:
            status === "increment"
              ? oldCommentsOffset + oldCommentslimit
              : oldCommentsOffset,
          projectId: localStorage.getItem("projectId"),
          userId: localStorage.getItem("userId"),
        },
      });
    } else {
      if (type === "new") {
        getNewComments({
          fetchPolicy: "network-only",
          nextFetchPolicy: "cache-first",
          variables: {
            limit: newCommentslimit,
            offset:
              status === "increment"
                ? newCommentsOffset + newCommentslimit
                : newCommentsOffset,
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
          },
        });
      } else {
        getOldComments({
          fetchPolicy: "network-only",
          nextFetchPolicy: "cache-first",
          variables: {
            limit: oldCommentslimit,
            offset:
              status === "increment"
                ? oldCommentsOffset + oldCommentslimit
                : oldCommentsOffset,
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
          },
        });
      }
    }
  };

  const getTimeElapsedFormat = (dateTime, prevDateTime) => {
    let dataDateTime = new Date(dateTime).toLocaleString();
    let prevDataDateTime = new Date(prevDateTime).toLocaleString();
    let previousTimeStamp = dateTimeToTimestamp({
      date: dataDateTime.substring(0, 10),
      time: dataDateTime.substring(12),
    });
    let currentDateTime = new Date().toLocaleString();
    let currentDate = currentDateTime.substring(0, 10);
    let currentTime = currentDateTime.substring(12);
    let currentTimeStamp = dateTimeToTimestamp({
      date: currentDate,
      time: currentTime,
    });
    let timeElapse = timeStampToTimeElapsed(
      currentTimeStamp,
      previousTimeStamp
    );
    if (timeElapse) {
      if (
        timeElapse.includes("min") ||
        timeElapse.includes("sec") ||
        timeElapse.substring(0, 3).includes("h")
      ) {
        if (dataDateTime.substring(0, 2) === prevDataDateTime.substring(0, 2)) {
          return "Today";
        } else {
          return dataDateTime.substring(0, 10);
        }
      } else {
        return dataDateTime.substring(0, 10);
      }
    } else return "";
  };

  const [getCommentPosition, { data: commentPosition }] = useLazyQuery(
    GET_COMMENT_POSITION,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        commentId: "",
        limit: 10,
        nestedLimit: 10,
        taskId: "",
      },
      onError: (err) => {
        showToastMessage({
          color: "danger",
          message: err.message || "Something went wrong please try again!",
        });
      },
    }
  );

  useEffect(() => {
    if (commentPosition?.getCommentPosition) {
      if (commentPosition.getCommentPosition?.nestedOffset === null) {
        if (taskId) {
          history.push(
            `/assets/${taskId}/individual-asset?status=comment&tab=Task&id=${id}&commentOffset=${commentPosition.getCommentPosition?.offset}`
          );
        }
      } else {
        if (taskId) {
          history.push(
            `/assets/${taskId}/individual-asset?status=comment&tab=Task&id=${id}&commentReplyId=${commentThread}&commentOffset=${commentPosition.getCommentPosition?.offset}&replyOffset=${commentPosition.getCommentPosition?.nestedOffset}`
          );
        }
      }
    }
  }, [commentPosition]);

  const handleOnClickComment = (taskId, id, comment_thread) => {
    setTaskId(taskId);
    setId(id);
    setCommentThread(comment_thread);
    getCommentPosition({
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        commentId: id,
        limit: 10,
        nestedLimit: 10,
        taskId: taskId,
      },
    });
  };

  const loadMoreComments = ($event) => {
    if (commentsData.length < newCommentsCount) {
      if (commentsData.length < newCommentsCount && !getMoreComments) {
        setGetMoreComments(true);
        loadComments("new", "increment");
        setNewCommentsOffset(newCommentsOffset + newCommentslimit);
        $event.target.complete();
      }
    } else {
      setDisableInfiniteScroll(false);
      if (newCommentsCount === 0) {
        if (
          commentsData.length < newCommentsCount + oldCommentsCount &&
          !getMoreComments
        ) {
          setLoadOldComments(true);
          setGetMoreComments(true);
          loadComments("old", "increment");
          setOldCommentsOffset(oldCommentsOffset + oldCommentslimit);
          $event.target.complete();
        } else {
          setDisableInfiniteScroll(true);
        }
      } else {
        if (!loadOldComments && !getMoreComments) {
          setLoadOldComments(true);
          setGetMoreComments(true);
          loadComments("old", "");
          setOldCommentsOffset(oldCommentsOffset + oldCommentslimit);
          $event.target.complete();
        } else if (
          commentsData.length < newCommentsCount + oldCommentsCount &&
          !getMoreComments
        ) {
          setGetMoreComments(true);
          loadComments("old", "");
          setOldCommentsOffset(oldCommentsOffset + oldCommentslimit);
          $event.target.complete();
        } else {
          setDisableInfiniteScroll(true);
        }
      }
    }
  };

  const handleDisply = (id) => {
    if (id === 0) {
      return "block";
    } else {
      return newCommentsCount >= id + 1
        ? "none"
        : newCommentsCount == id
        ? "block"
        : new Date(commentsData[id - 1]?.created_at)
            .toLocaleString()
            .substr(6, 4) > // checking year difference
          new Date(commentsData[id]?.created_at).toLocaleString().substr(6, 4)
        ? "block"
        : new Date(commentsData[id - 1]?.created_at)
            .toLocaleString()
            .substr(3, 2) > // checking month difference
          new Date(commentsData[id]?.created_at).toLocaleString().substr(3, 2)
        ? "block"
        : new Date(commentsData[id - 1]?.created_at)
            .toLocaleString()
            .substring(0, 2) > // checking date difference
          new Date(commentsData[id]?.created_at)
            .toLocaleString()
            .substring(0, 2)
        ? "block"
        : "none";
    }
  };

  const color = ["#7C9EC9", "#FD9393", "#FFC542", "#8BDDB3"];

  return (
    <div>
      <Title>Comments</Title>
      {newCommentsCount === 0 && (
        <TimeStampHolder>
          <div style={{ marginBottom: 20 }}>
            <div>New</div>
            <IonText style={{ color: "#FF6F6F", fontSize: "0.75rem" }}>
              No new comments today.
            </IonText>
          </div>
        </TimeStampHolder>
      )}
      {commentsData?.length > 0 ? (
        <>
          {commentsData.map((ele, id) => (
            <CommentsContainer key={id}>
              <TimeStampHolder
                display={handleDisply(id)}
                border_top={
                  newCommentsCount === 0
                    ? "1px solid #C4C4C4"
                    : id !== 0
                    ? "1px solid #C4C4C4"
                    : ""
                }
              >
                {newCommentsCount > id
                  ? "New"
                  : getTimeElapsedFormat(
                      ele.created_at,
                      id === 0
                        ? ele.created_at
                        : commentsData[id - 1]?.created_at
                    )}
              </TimeStampHolder>
              <CommentsItem
                id={id}
                user={ele.user || {}}
                imageUrl={
                  ele.thread?.task_threads[0]?.related_task?.deliverables[0]
                    ?.versions[0]?.resource?.url || ""
                }
                comment={ele.comment_text}
                createdAt={ele.created_at}
                task_id={ele.thread?.task_threads[0]?.related_task?.id || ""}
                handleOnClickComment={(taskId) =>
                  handleOnClickComment(
                    taskId,
                    ele.id,
                    ele.id !== ele.thread?.comments[0]?.id
                      ? ele.comment_thread
                      : ""
                  )
                }
                profileBackground={color[id % color.length]}
                type={
                  ele.id === ele.thread?.comments[0]?.id
                    ? "Commented"
                    : ele.user?.id === localStorage.getItem("userId")
                    ? "Replied to your comment"
                    : "Replied"
                }
                userId={ele.user?.id}
              />
            </CommentsContainer>
          ))}
          <IonInfiniteScroll
            threshold="100px"
            disabled={disableInfiniteScroll}
            onIonInfinite={(e) => loadMoreComments(e)}
          >
            <IonInfiniteScrollContent loadingText="Loading comments..."></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CommentsComponent;
