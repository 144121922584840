import React from "react";

const MobAssetsIcon = ({
  width = "16",
  height = "16",
  viewBox = "0 0 16 16",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.87012 9.71617H11.2655"
        stroke="#929292"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.6665 5.1989C1.6665 3.57096 2.49984 2.17287 4.08117 1.84842C5.66184 1.52334 6.86317 1.63572 7.86117 2.17414C8.85984 2.71255 8.57384 3.50747 9.59984 4.09096C10.6265 4.67509 12.2778 3.79763 13.3565 4.96144C14.4858 6.17985 14.4798 8.05033 14.4798 9.24271C14.4798 13.7735 11.9418 14.1329 8.07317 14.1329C4.2045 14.1329 1.6665 13.8192 1.6665 9.24271V5.1989Z"
        stroke="#929292"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default MobAssetsIcon;
