import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import { useSession } from "../../components/sessionValidator";
import LoginComponent from "../../components/loginComponent";
import LoginLayout from "../../components/loginLayout";

const Login = ({ history }) => {
  const [session] = useSession();

  useEffect(() => {
    if (session) {
      history.push("/home");
    }
  }, [history, session]);
  return (
    <LoginLayout>
      <LoginComponent />
    </LoginLayout>
  );
};
export default withRouter(Login);
