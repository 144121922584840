import React, { useState, useEffect } from "react";
import { Image as AntdImage } from "antd";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import styled from "styled-components";
import { RightOutlined } from "@ant-design/icons";
import PlaceholderImage from "../assests/images/placeholder.jpg";
import { Link, useHistory } from "react-router-dom";
import CarouselArrow from "./carouselArrow";

const CarouselWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const Div = styled.div`
  background: #f5f5f5;
  height: 59.17px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
`;

const SubTitle = styled.div`
  color: var(--text-primary);
  font-weight: ${({ fw }) => fw || 600};
  font-family: "Montserrat";
  font-size: 0.8rem;
  ${({ pointer }) => pointer && "cursor:pointer"}
`;

const Chip = styled(SubTitle)`
  text-transform: capitalize;
  background: ${({ status }) =>
    status === "pending"
      ? "#ffdc3e"
      : status === "approved"
      ? "#8BDDB3"
      : "#FF6F6F"};
  border-radius: 100px;
  width: fit-content;
  min-width: 5.849rem;
  padding: 0 10px;
  height: clamp(21px, 7vw, 35.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  left: 5%;
`;

const Carousel = styled(ResponsiveCarousel)`
  background: #ebebeb;
  position: unset !important;
  .carousel-slider {
    position: unset !important;
  }
  .control-dots {
    bottom: 11px;
    width: max-content;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    .dot {
      background: #000 !important;
      box-shadow: unset !important;
      margin: 0 3px;
      @media (max-width: 768px) {
        width: 5px;
        height: 5px;
      }
    }
  }
  .carousel-status {
    border: 1px solid #ffffff;
    backdrop-filter: blur(4px);
    background: rgba(201, 201, 201, 0.7);
    border-radius: 100px;
    width: fit-content;
    min-width: 5.849rem;
    padding: 0 10px;
    height: clamp(21px, 7vw, 35.5px);
    top: 5%;
    right: 5%;
    color: var(--text-primary);
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 0.8rem;
    text-shadow: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const IconWrapper = styled.span`
  margin-left: 15px;
`;

const ImageWrapper = styled.div`
  color: rgb(255, 255, 255);
  text-align: center;
  background: #ebebeb;
  .ant-image-error {
    img {
      object-fit: unset !important;
    }
  }
  img {
    object-fit: contain;
    height: 410px;
    @media screen and (max-width: 600px) {
      height: 280px;
    }
    @media screen and (max-width: 420px) {
      height: 200.61px;
    }
  }
`;

const Text = styled.div`
  color: ${({ status }) => (status === "approved" ? "#69b79a" : "#FF8181")};
  font-family: "Montserrat";
  font-size: 0.938rem;
  text-transform: uppercase;
  font-weight: 700;
`;

const Image = styled(AntdImage)`
  height: 410px;
  @media screen and (max-width: 600px) {
    height: 280px;
  }
  @media screen and (max-width: 420px) {
    height: 200.61px;
  }
`;
const TruncatedText = styled(SubTitle)`
  white-space: nowrap;
  width: 38%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ImageCarousel = ({
  id = "",
  status = "pending",
  imageURL = [],
  hideReview = false,
  onChangeAssetVersion = "",
}) => {
  const history = useHistory();
  const [deliverableName, setDeliverableName] = useState("");
  const getDecimalValue = (digits = 0) => {
    return Number(digits) > 0 ? digits.toString().padStart(2, "0") : 0;
  };

  const onCarouselChange = (index, value) => {
    if (onChangeAssetVersion) {
      onChangeAssetVersion(value?.props?.id);
    }
    setDeliverableName(value?.props?.name || "");
  };

  useEffect(() => {
    if (imageURL?.length > 0) {
      setDeliverableName(imageURL[0]?.name || "");
    }
  }, [imageURL]);

  const goToReviewPage = () => {
    if (!hideReview && status.toLowerCase() === "pending") {
      history.push(`/assets/${id}/review`);
    }
  };

  return (
    <CarouselWrapper>
      <Carousel
        statusFormatter={(current, total) =>
          `${getDecimalValue(current)} / ${getDecimalValue(total)}`
        }
        emulateTouch={true}
        showArrows={false}
        showIndicatiors={false}
        showThumbs={false}
        infiniteLoop
        onChange={onCarouselChange}
        renderArrowPrev={
          imageURL.length > 1
            ? (onClick) => {
                return <CarouselArrow onClick={onClick} inverse />;
              }
            : undefined
        }
        renderArrowNext={
          imageURL.length > 1
            ? (onClick) => {
                return <CarouselArrow onClick={onClick} rightArrow isVisible />;
              }
            : undefined
        }
      >
        {(imageURL.length > 0 &&
          imageURL?.map(
            (
              { url = "", name = "", currentVersionId = "", clientStatus = "" },
              imageIndex
            ) => (
              <ImageWrapper
                key={`image-carousel-${imageIndex}`}
                name={name}
                id={currentVersionId}
                onDoubleClick={goToReviewPage}
              >
                <Image
                  width="100%"
                  preview={false}
                  src={url || "error"}
                  fallback={PlaceholderImage}
                />
                <Chip status={clientStatus}>{clientStatus}</Chip>
              </ImageWrapper>
            )
          )) || (
          <ImageWrapper>
            <Image
              width="100%"
              preview={false}
              src="error"
              fallback={PlaceholderImage}
            />
          </ImageWrapper>
        )}
      </Carousel>
      <Div>
        <TruncatedText as="div">{deliverableName}</TruncatedText>
        {status.toLowerCase() === "pending" ? (
          !hideReview && (
            <Link to={`/assets/${id}/review`}>
              <SubTitle fw="bold" pointer>
                REVIEW NOW
                <IconWrapper>
                  <RightOutlined />
                </IconWrapper>
              </SubTitle>
            </Link>
          )
        ) : (
          <Text status={status.toLowerCase()}>{status}</Text>
        )}
      </Div>
    </CarouselWrapper>
  );
};

export default ImageCarousel;
