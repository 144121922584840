import React from "react";
import { Modal as AntdModal } from "antd";
import styled from "styled-components";
import { IonIcon as ReactIonIcon } from "@ionic/react";

import Flex from "../flex";
import Close from "../../assests/icons/svg/close.svg";

const Text = styled.div`
  color: var(--text-primary);
  font-family: Montserrat;
  letter-spacing: 0.2px;
  font-size: 0.875rem;
`;

const IonIcon = styled(ReactIonIcon)`
  font-size: ${({ iconSize }) => iconSize || "5.6rem"};
  margin-bottom: 4%;
  color: #ff8577;
`;

const Modal = styled(AntdModal)`
  .ant-modal-body {
    padding: 14.5% 0%;
    background: #ffffff;
  }
  .ant-modal-close {
    top: 3% !important;
    right: 2% !important;
  }
  .ant-modal-content {
    overflow: auto;
    border-radius: 5px;
  }
`;
const CloseIcon = styled(ReactIonIcon)`
  font-size: 11px;
`;

const ApproveFeedbackModel = ({
  isModalVisible = true,
  onClose = undefined,
  selectedApproveFeedback = "",
}) => {
  return (
    <Modal
      visible={isModalVisible}
      onCancel={onClose}
      footer=""
      closeIcon={<CloseIcon icon={Close}></CloseIcon>}
      width={576}
      maskClosable={false}
    >
      <Flex center alignCenter column>
        {selectedApproveFeedback?.icon && (
          <IonIcon
            icon={
              selectedApproveFeedback?.filledIcon
                ? selectedApproveFeedback?.filledIcon
                : selectedApproveFeedback?.icon
            }
            iconSize={selectedApproveFeedback?.fontSize}
          ></IonIcon>
        )}

        <Text>Thank you for your feedback!</Text>
        <Text>We are working hard to bring you the best designs.</Text>
      </Flex>
    </Modal>
  );
};

export default ApproveFeedbackModel;
