import { IonText, IonIcon } from "@ionic/react";
import { attachOutline, send } from "ionicons/icons";
import styled from "styled-components";

const MsgDescriptionSection = styled.div`
  display: flex;
  flex: 1;
  padding: 2em;
  @media (max-width: 785px) {
    display: none;
    padding: 1em;
  }
`;

const MsgDescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const MsgRecepientInfo = styled.div`
  display: flex;
  flex: 0.15;
  border-bottom: 1px solid #aaaaaa;
  align-items: center;
`;

const MsgRecepientAvatar = styled.div`
  display: flex;
  width: 45px !important;
  height: 45px !important;
  background: #7c9ec9;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1em;
  font-weight: 600;
`;

const StyledRecepientAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
`;

const StyledIonText = styled(IonText)`
  margin-left: 1em;
  font-weight: 600;
`;

const MsgRecepientMessages = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 1em;
  overflow-x: auto;
`;

const MsgDay = styled.div`
  display: flex;
  flex: 1;
  height: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #5961f9;
  color: white;
  font-weight: bold;
  font-size: 10px;
  padding: 4px 20px;
  border-radius: 12px;
  text-transform: capitalize;
  margin: 20px 0px;
`;

const MsgReciepent = styled.div`
  display: flex;
  flex: 1;
  padding: 20px 10px 10px 20px;
  max-width: 400px;
  border-radius: 5px;
  margin: 5px;
  font-size: 14px;
  flex-direction: column;
  background: #e6e6e6;
`;

const MsgUser = styled.div`
  display: flex;
  flex: 1;
  padding: 20px 10px 10px 20px;
  max-width: 400px;
  border-radius: 5px;
  margin: 5px;
  font-size: 14px;
  flex-direction: column;
  background: #f1efef;
`;

const TextTime = styled.div`
  display: flex;
  color: #b7b7b7;
  font-size: 12px;
  flex: 1;
  justify-content: flex-end;
`;

const MsgNewText = styled.div`
  display: flex;
  flex: 0.15;
  border: 1px solid #aaaaaa;
  box-sizing: border-box;
  border-radius: 10px;
  flex-direction: row;
  @media (max-width: 785px) {
    flex: 0.1;
  }
`;

const MsgTextAreaContainer = styled.div`
  display: flex;
  flex: 1;
`;

const MsgTextBox = styled.input`
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 2em;
  border-radius: 10px;
  ::placeholder {
    font-size: 20px;
    letter-spacing: 0.2px;
    font-weight: 800;
    color: var(--text-primary);
    margin-left: 5em;
    @media (max-width: 785px) {
      font-size: 15px;
    }
  }
`;

const MsgAttachments = styled.div`
  display: flex;
  flex: 0.15;
  font-size: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const MsgSendNewAction = styled.div`
  display: flex;
  flex: 0.3;
  justify-content: center;
  align-items: center;
`;

const ActionSendMsgButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: none !important;
  box-shadow: none !important;
  width: 100%;
  background: #f5f5f5;
  text-transform: capitalize;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  background-position: center;
  transition: background 1.2s;
  :hover {
    background: #f5f5f5 radial-gradient(circle, #f5f5f5 1%, white 1%)
      center/15000%;
  }
  :active {
    background-color: #f5f5f5;
    background-size: 100%;
    transition: background 0s;
  }
`;

const SendMsgText = styled.div`
  font-size: 20px;
  letter-spacing: 0.2px;
  font-weight: 800;
  color: var(--text-primary);
  margin-right: 1em;
  @media (max-width: 1176px) {
    display: none;
  }
`;

const SendMsgIcon = styled.div`
  transform: rotate(-40deg);
  font-size: 23px;
`;

// export interface Props {
//   o?: {
//     day: string,
//   };
// }

const MsgGround = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ o }) =>
    o?.day === "day"
      ? "center"
      : o?.day === "you"
      ? "flex-start"
      : o?.day === "me"
      ? "flex-end"
      : ""};
  justify-content: center;
`;

const MessageSection = () => {
  const messages = [
    {
      day: "monday",
    },
    {
      you: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus",
      time: "1:23 PM",
    },
    {
      me: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus",
      time: "1:23 PM",
    },
    {
      you: "Lorem ipsum",

      time: "1:23 PM",
    },
    {
      me: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",

      time: "1:23 PM",
    },
    {
      day: "today",
    },
    {
      me: "?",

      time: "1:23 PM",
    },
    {
      you: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus",

      time: "1:23 PM",
    },
    {
      you: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus",

      time: "1:23 PM",
    },
  ];

  return (
    <MsgDescriptionSection>
      <MsgDescriptionContainer>
        <MsgRecepientInfo>
          <MsgRecepientAvatar>
            <StyledRecepientAvatar slot="start">A</StyledRecepientAvatar>
          </MsgRecepientAvatar>
          <StyledIonText color="#130f26">
            <h3>Alice Smith</h3>
          </StyledIonText>
        </MsgRecepientInfo>
        <MsgRecepientMessages>
          {messages?.map((eachMessage) => (
            <MsgGround
              o={{
                day: eachMessage.day
                  ? "day"
                  : eachMessage.you
                  ? "you"
                  : eachMessage.me
                  ? "me"
                  : "",
              }}
            >
              {eachMessage.day ? <MsgDay>{eachMessage.day}</MsgDay> : ""}
              {eachMessage.you ? (
                <MsgReciepent>
                  {eachMessage.you}
                  <TextTime>{eachMessage.time}</TextTime>
                </MsgReciepent>
              ) : (
                ""
              )}
              {eachMessage.me ? (
                <MsgUser>
                  {eachMessage.me}
                  <TextTime>{eachMessage.time}</TextTime>
                </MsgUser>
              ) : (
                ""
              )}
            </MsgGround>
          ))}
        </MsgRecepientMessages>
        <MsgNewText>
          <MsgTextAreaContainer>
            <MsgTextBox placeholder="Add a comment..."></MsgTextBox>
          </MsgTextAreaContainer>
          <MsgAttachments>
            <IonIcon icon={attachOutline}></IonIcon>
          </MsgAttachments>
          <MsgSendNewAction>
            <ActionSendMsgButton>
              <SendMsgText>Send</SendMsgText>
              <SendMsgIcon>
                <IonIcon icon={send}></IonIcon>
              </SendMsgIcon>
            </ActionSendMsgButton>
          </MsgSendNewAction>
        </MsgNewText>
      </MsgDescriptionContainer>
    </MsgDescriptionSection>
  );
};

export default MessageSection;
