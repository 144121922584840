import React from "react";

const Arrow = ({ rightArrow = false }) => {
  return (
    <svg
      width="1em"
      height="2em"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: rightArrow ? "rotate(180deg)" : "unset",
        marginLeft: rightArrow ? "3px" : "unset",
        marginRight: !rightArrow ? "3px" : "unset",
      }}
    >
      <path
        d="M11.6576 2L2.00006 11.6575L11.6576 21.3151"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Arrow;
