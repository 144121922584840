import { withRouter } from "react-router-dom";
import PageLayout from "../../components/pageLayout";
import ChangePasswordComponent from "../../components/changePassword/changePassword";
import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ChangePasswordPage = () => {
  return (
    <PageLayout title="Change Password">
      <Content>
        <ChangePasswordComponent isLoggedInUser={true} />
      </Content>
    </PageLayout>
  );
};
export default withRouter(ChangePasswordPage);
