import React, { useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { IonCol, IonLoading } from "@ionic/react";

import PageLayout from "components/pageLayout";
import UserProfile from "components/userProfile";
import {
  UPDATE_USER,
  UPLOAD_PROFILE_PIC,
  DELETE_ACCOUNT,
} from "graphQL/mutations";
import { useToastMessage } from "components/useToastMessage";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [updateUser, { loading }] = useMutation(UPDATE_USER);
  const [uploadProfilePic] = useMutation(UPLOAD_PROFILE_PIC);
  const [deleteAccount, { loading: deleteAccountLoading, client }] =
    useMutation(DELETE_ACCOUNT);
  const userProfileElement = useRef(null);

  const { showToastMessage } = useToastMessage();
  const history = useHistory();

  const saveUserProfile = async (userDetails) => {
    try {
      const userId = localStorage.getItem("userId");
      const { data } = await updateUser({
        variables: {
          userId,
          userSetInput: { ...userDetails },
        },
      });
      if (data?.update_user_by_pk) {
        showToastMessage({
          message: "Saved the profile data!",
        });
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    }
  };

  const onUploadProfilePic = async (file, user = {}, setAvatarState) => {
    if (!file) return;
    setIsLoading(true);
    try {
      const { data } = await uploadProfilePic({
        variables: {
          fileName: file.name,
        },
      });
      if (data?.fileUpload?.signedUrl) {
        await fetch(data.fileUpload.signedUrl, {
          method: "PUT",
          body: file,
        }).then((response) => {
          saveUserProfile({ avatar: data.fileUpload.url });
          setAvatarState(data.fileUpload.url);
        });
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Error while uploading photo!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteUserAccount = async () => {
    try {
      const { data } = await deleteAccount({
        variables: {
          userId: localStorage.getItem("userId"),
          deletedAt: new Date().toISOString(),
        },
      });
      if (data?.update_client_user_map?.affected_rows) {
        showToastMessage({
          message: "Your account deleted",
          duration: 500,
        });
        setTimeout(() => {
          localStorage.clear();
          client.resetStore().then(() => {
            history.push("/login");
          });
        }, 1200);
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Error while deleting account!",
      });
    }
  };

  return (
    <PageLayout title="profile">
      <IonCol>
        <UserProfile
          saveUserProfile={saveUserProfile}
          onUploadProfilePic={onUploadProfilePic}
          deleteUserAccount={deleteUserAccount}
          loading={loading || deleteAccountLoading || isLoading}
          ref={userProfileElement}
        />
        <IonLoading
          isOpen={loading || deleteAccountLoading || isLoading}
          message="Please wait..."
          spinner="bubbles"
        />
      </IonCol>
    </PageLayout>
  );
};

export default Profile;
