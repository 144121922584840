import { gql } from "@apollo/client";

export const GET_USER_SESSION = gql`
  query verifySessionToken($expiredAt: timestamptz, $token: String!) {
    client_user_session(
      where: { expired_at: { _gte: $expiredAt }, token: { _eq: $token } }
    ) {
      expired_at
    }
  }
`;

export const GET_USER_ID = gql`
  query get_user_id($gte: timestamptz, $token: String!) {
    client_user_session(
      where: {
        token: { _eq: $token }
        expired_at: { _gte: $gte }
        client_user_map_details: { deleted_at: { _is_null: true } }
      }
    ) {
      user_id
      user {
        avatar
        id
        email
        name
        phone
      }
    }
  }
`;

export const GET_USER_CLIENT_ID = gql`
  query get_client_id($user_id: uuid!) {
    client_user_map(where: { user_id: { _eq: $user_id } }) {
      client_id
    }
  }
`;

export const GET_PROJECTS_LIST = gql`
  query get_projects($client_id: uuid!, $delete_status: String!) {
    project(
      where: {
        client_project_maps: { client_id: { _eq: $client_id } }
        delete_status: { _neq: $delete_status }
      }
    ) {
      id
      name
      status
    }
  }
`;
export const GET_ASSET = gql`
  query getAsset($taskId: uuid = "") {
    task(where: { id: { _eq: $taskId } }) {
      id
      description
      name
      stage
      status
      client_status
      subtype
      type
      creator_id
      updated_at
      created_at
      approver_id
      last_updator_id
      deliverables {
        created_at
        id
        status
        current_version_id
        versions(order_by: { version: desc }) {
          status
          client_status
          resource_id
          last_updator_id
          id
          version
          resource {
            created_at
            url
            name
            id
            type
            user_id
          }
        }
        task_id
      }
      project_id
    }
  }
`;

export const GET_ASSETS_LIST = gql`
  query getTask($projectId: uuid!, $offset: Int, $limit: Int) {
    task(
      where: {
        project_id: { _eq: $projectId }
        _or: [
          { client_status: { _eq: "PENDING" } }
          { client_status: { _eq: "APPROVED" } }
          { client_status: { _eq: "REJECTED" } }
        ]
      }
      order_by: { status: asc }
      offset: $offset
      limit: $limit
    ) {
      id
      description
      name
      stage
      status
      client_status
      subtype
      type
      creator_id
      approver_id
      deliverables {
        created_at
        id
        status
        current_version_id
        versions(order_by: { version: desc }) {
          client_status
          status
          resource_id
          last_updator_id
          id
          version
          resource {
            url
            name
            id
            type
            user_id
          }
        }
        task_id
      }
      project_id
    }
    task_aggregate(
      where: {
        project_id: { _eq: $projectId }
        _or: [
          { client_status: { _eq: "PENDING" } }
          { client_status: { _eq: "APPROVED" } }
          { client_status: { _eq: "REJECTED" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_SEARCH_ASSETS_LIST = gql`
  query getSearchTask($projectId: uuid!, $search: String!) {
    task(
      where: {
        project_id: { _eq: $projectId }
        _and: {
          _or: [
            { client_status: { _eq: "PENDING" } }
            { client_status: { _eq: "APPROVED" } }
            { client_status: { _eq: "REJECTED" } }
          ]
        }
        _or: [
          { name: { _ilike: $search } }
          { tags: { tag: { name: { _ilike: $search } } } }
        ]
      }
      order_by: { status: asc }
    ) {
      id
      description
      name
      stage
      status
      client_status
      subtype
      type
      creator_id
      approver_id
      deliverables {
        created_at
        id
        status
        current_version_id
        versions(order_by: { version: desc }) {
          status
          client_status
          resource_id
          last_updator_id
          id
          version
          resource {
            url
            name
            id
            type
            user_id
          }
        }
        task_id
      }
      project_id
    }
  }
`;

export const GET_ASSETS_FILTER = gql`
  query getFilteredAssets($where: task_bool_exp) {
    task(where: $where, order_by: { status: asc }) {
      id
      client_status
      description
      name
      rank
      stage
      status
      subtype
      type
      updated_at
      last_updator_id
      created_at
      creator_id
      deadline
      delete_status
      approver_id
      phase
      deliverables {
        status
        id
        current_version_id
        created_at
        task_id
        versions(order_by: { version: desc }) {
          status
          resource_id
          client_status
          last_updator_id
          id
          version
          resource {
            url
            name
            id
            type
            user_id
          }
        }
        task_id
      }
      project_id
    }
    task_aggregate(where: $where, order_by: { status: asc }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_COMMENTS_LIST = gql`
  query getAllComments(
    $taskId: uuid!
    $offset: Int!
    $limit: Int!
    $order_by: order_by!
    $userId: uuid!
  ) {
    thread(
      where: {
        task_threads: { task: { _eq: $taskId } }
        visible_to_client: { _eq: true }
      }
      order_by: { created_at: $order_by }
      offset: $offset
      limit: $limit
    ) {
      created_at
      id
      status
      visibility
      comments(order_by: { created_at: asc }, offset: 0, limit: 1) {
        comment_text
        comment_thread
        comment_user
        created_at
        id
        pinned_comments(limit: 1, where: { user_id: { _eq: $userId } }) {
          id
        }
        user_activity_trackings(
          limit: 1
          where: { user_id: { _eq: $userId } }
        ) {
          id
        }
        resources(limit: 1, offset: 0, where: { type: { _eq: "FILE" } }) {
          id
          name
          type
          url
          creator {
            avatar
            id
            name
          }
        }
        user {
          avatar
          name
          id
        }
      }
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    thread_aggregate(
      where: {
        task_threads: { task: { _eq: $taskId } }
        visible_to_client: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_RESOLVED_COMMENTS = gql`
  query getResolvedComments(
    $taskId: uuid!
    $offset: Int!
    $limit: Int!
    $status: String!
    $order_by: order_by!
    $userId: uuid!
  ) {
    thread(
      where: {
        status: { _eq: $status }
        task_threads: { task: { _eq: $taskId } }
        visible_to_client: { _eq: true }
      }
      order_by: { created_at: $order_by }
      offset: $offset
      limit: $limit
    ) {
      created_at
      id
      status
      visibility
      comments(order_by: { created_at: asc }, offset: 0, limit: 1) {
        comment_text
        comment_thread
        comment_user
        created_at
        id
        pinned_comments(limit: 1, where: { user_id: { _eq: $userId } }) {
          id
        }
        user_activity_trackings(
          limit: 1
          where: { user_id: { _eq: $userId } }
        ) {
          id
        }
        resources(limit: 1, offset: 0, where: { type: { _eq: "FILE" } }) {
          id
          name
          type
          url
          creator {
            avatar
            id
            name
          }
        }
        user {
          avatar
          name
          id
        }
      }
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    thread_aggregate(
      where: {
        status: { _eq: $status }
        task_threads: { task: { _eq: $taskId } }
        visible_to_client: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_REPLIES_LIST = gql`
  query getAllReplies(
    $comment_thread: uuid!
    $offset: Int!
    $limit: Int!
    $userId: uuid!
    $createdAt: order_by = desc
  ) {
    comment(
      where: { comment_thread: { _eq: $comment_thread } }
      limit: $limit
      offset: $offset
      order_by: { created_at: $createdAt }
    ) {
      comment_text
      comment_thread
      comment_user
      created_at
      id
      pinned_comments(limit: 1, where: { user_id: { _eq: $userId } }) {
        id
      }
      user_activity_trackings(limit: 1, where: { user_id: { _eq: $userId } }) {
        id
      }
      user {
        avatar
        id
        name
      }
      resources(limit: 1, offset: 0, where: { type: { _eq: "FILE" } }) {
        id
        name
        type
        url
        creator {
          avatar
          id
          name
        }
      }
    }
    comment_aggregate(where: { comment_thread: { _eq: $comment_thread } }) {
      aggregate {
        count
      }
    }
  }
`;

export const USER_COMMENTS_ACTIVITY_TRACK = gql`
  query getUserCommentsActivity($user_id: uuid!) {
    user_activity_tracking(where: { user_id: { _eq: $user_id } }) {
      comment_id
      id
      task_id
      user_id
      comment {
        comment_text
        comment_thread
        comment_user
        created_at
        id
      }
    }
  }
`;

export const GET_ATTACHMENT_MEDIA = gql`
  query getAttachmentMedia($taskId: uuid!, $limit: Int, $offset: Int) {
    resource(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        comment: {
          thread: {
            task_threads: { task: { _eq: $taskId } }
            status: { _eq: "UNRESOLVED" }
          }
        }
        type: { _eq: "FILE" }
        name: { _similar: "%.(JPG|jpg|jpeg|JPEG|png|PNG)" }
      }
    ) {
      id
      name
      type
      url
      created_at
      comment {
        user {
          email
          id
          name
        }
      }
    }
    resource_aggregate(
      order_by: { created_at: desc }
      where: {
        comment: {
          thread: {
            task_threads: { task: { _eq: $taskId } }
            status: { _eq: "UNRESOLVED" }
          }
        }
        type: { _eq: "FILE" }
        name: { _similar: "%.(JPG|jpg|jpeg|JPEG|png|PNG)" }
      }
    ) {
      aggregate {
        count
      }
    }
  }
  # query getAttachmentMedia($taskId: uuid!) {
  #   task(where: { id: { _eq: $taskId } }, offset: 0, limit: 10) {
  #     id
  #     description
  #     name
  #     rank
  #     stage
  #     status
  #     subtype
  #     type
  #     updated_at
  #     last_updator_id
  #     created_at
  #     creator_id
  #     deadline
  #     delete_status
  #     approver_id
  #     phase
  #     project_id
  #     approver {
  #       phone
  #       name
  #       id
  #       resources {
  #         name
  #         url
  #         comment_id
  #         created_at
  #         id
  #         user_id
  #       }
  #     }
  #   }
  # }
`;

export const GET_MEDIA_URL = gql`
  query getAttachmentMedia($fileName: String!) {
    readFile(fileName: $fileName) {
      signedUrl
    }
  }
`;

export const GET_ATTACHMENT_DOCS = gql`
  query getAttachmentDocs($offset: Int!, $limit: Int!, $taskId: uuid!) {
    resource(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        comment: {
          thread: {
            task_threads: { task: { _eq: $taskId } }
            status: { _eq: "UNRESOLVED" }
          }
        }
        type: { _eq: "FILE" }
        name: { _nsimilar: "%.(JPG|jpg|jpeg|JPEG|png|PNG)" }
      }
    ) {
      id
      name
      type
      url
      created_at
      creator {
        avatar
        id
        name
      }
    }
    resource_aggregate(
      where: {
        comment: {
          thread: {
            task_threads: { task: { _eq: $taskId } }
            status: { _eq: "UNRESOLVED" }
          }
        }
        type: { _eq: "FILE" }
        name: { _nsimilar: "%.(JPG|jpg|jpeg|JPEG|png|PNG)" }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const LINKS_METADATA = gql`
  query getLinks($taskId: String!, $limit: Int!, $offset: Int!) {
    getCommentLinksWithMetaData(
      taskId: $taskId
      limit: $limit
      offset: $offset
    ) {
      count
      links
    }
  }
`;

export const GET_NEW_ASSETS = gql`
  query getUserNewTasks(
    $limit: Int!
    $offset: Int!
    $projectId: uuid!
    $clientId: uuid!
    $userId: uuid!
  ) {
    task(
      limit: $limit
      offset: $offset
      where: {
        project: {
          id: { _eq: $projectId }
          client_project_maps: {
            client_id: { _eq: $clientId }
            clients: { client_user_map: { user_id: { _eq: $userId } } }
          }
        }
        _or: [
          { status: { _ilike: "CLIENT_REVIEW" } }
          { task_feedbacks: { feedback_type: { _in: ["APPROVE", "REJECT"] } } }
        ]
        user_activity_trackings: { _not: { user_id: { _eq: $userId } } }
      }
      order_by: { created_at: desc }
    ) {
      id
      name
      deliverables(limit: 1, order_by: { created_at: desc }) {
        current_version {
          resource {
            url
          }
        }
        created_at
      }
      creator {
        name
      }
    }
    task_aggregate(
      where: {
        project: {
          id: { _eq: $projectId }
          client_project_maps: {
            client_id: { _eq: $clientId }
            clients: { client_user_map: { user_id: { _eq: $userId } } }
          }
        }
        _or: [
          { status: { _ilike: "CLIENT_REVIEW" } }
          { task_feedbacks: { feedback_type: { _in: ["APPROVE", "REJECT"] } } }
        ]
        user_activity_trackings: { _not: { user_id: { _eq: $userId } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_VERSIONS = gql`
  query getVersions($taskId: uuid, $currentVersionId: uuid!) {
    task_deliverable(
      limit: 10
      offset: 0
      order_by: { created_at: desc }
      where: {
        task_id: { _eq: $taskId }
        current_version_id: { _eq: $currentVersionId }
      }
    ) {
      id
      versions(limit: 10, offset: 0, order_by: { version: desc }) {
        id
        status
        version
        resource {
          created_at
          name
          type
          url
        }
      }
      versions_aggregate(order_by: { version: desc }) {
        aggregate {
          count
        }
      }
      current_version_id
      current_version {
        id
        resource_id
        version
      }
    }
  }
`;

export const GET_PINNED_COMMENTS = gql`
  query getPinnedComments(
    $where: pinned_comment_bool_exp
    $offset: Int!
    $limit: Int!
  ) {
    pinned_comment(
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
      where: $where
    ) {
      id
      user_id
      comment {
        comment_text
        id
        comment_thread
        user {
          avatar
          id
          name
        }
        resources(limit: 1, offset: 0, where: { type: { _eq: "FILE" } }) {
          id
          name
          type
          url
          creator {
            avatar
            id
            name
          }
        }
        comment_user
        created_at
        thread {
          status
        }
      }
    }
    pinned_comment_aggregate(order_by: { created_at: desc }, where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FILE_COMMENTS = gql`
  query getAllComments(
    $versionId: uuid
    $offset: Int
    $limit: Int = 10
    $commentOffset: Int = 0
    $commentLimit: Int = 20
    $userId: uuid
  ) {
    thread(
      where: {
        status: { _eq: "UNRESOLVED" }
        file_threads: { deliverable_version: { id: { _eq: $versionId } } }
        visibility: { _eq: "PUBLIC" }
        visible_to_client: { _eq: true }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      created_at
      id
      status
      visibility
      comments(
        order_by: { created_at: asc }
        offset: $commentOffset
        limit: $commentLimit
      ) {
        comment_text
        comment_thread
        comment_user
        created_at
        id
        pinned_comments(limit: 1, where: { user_id: { _eq: $userId } }) {
          id
        }
        user_activity_trackings(
          limit: 1
          where: { user_id: { _eq: $userId } }
        ) {
          id
        }
        thread {
          file_threads {
            coordinates
            file
            id
            thread
          }
        }
        user {
          avatar
          email
          id
          name
          phone
        }
      }
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    thread_aggregate(
      where: {
        status: { _eq: "UNRESOLVED" }
        file_threads: { deliverable_version: { id: { _eq: $versionId } } }
        visibility: { _eq: "PUBLIC" }
        visible_to_client: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_NEW_COMMENTS = gql`
  query getNewComments(
    $limit: Int!
    $offset: Int!
    $projectId: uuid!
    $userId: uuid!
  ) {
    comment(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        thread: {
          task_threads: { related_task: { project_id: { _eq: $projectId } } }
          visible_to_client: { _eq: true }
        }
        _not: { user_activity_trackings: { user_id: { _eq: $userId } } }
        comment_user: { _neq: $userId }
      }
    ) {
      comment_text
      comment_user
      id
      created_at
      comment_thread
      user {
        id
        name
        avatar
      }
      thread {
        comments(order_by: { created_at: asc }, limit: 1) {
          id
          comment_text
        }
        task_threads(limit: 1, order_by: { created_at: desc }) {
          related_task {
            id
            deliverables(limit: 1, order_by: { created_at: desc }) {
              versions(order_by: { version: desc }, limit: 1) {
                resource {
                  name
                  url
                }
              }
            }
          }
        }
      }
    }
    comment_aggregate(
      where: {
        thread: {
          task_threads: { related_task: { project_id: { _eq: $projectId } } }
          visible_to_client: { _eq: true }
        }
        _not: { user_activity_trackings: { user_id: { _eq: $userId } } }
        comment_user: { _neq: $userId }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_OLD_COMMENTS = gql`
  query getOldComments(
    $limit: Int!
    $offset: Int!
    $projectId: uuid!
    $userId: uuid!
  ) {
    comment(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        user_activity_trackings: { user_id: { _eq: $userId } }
        comment_user: { _neq: $userId }
        thread: {
          task_threads: { related_task: { project_id: { _eq: $projectId } } }
          visible_to_client: { _eq: true }
        }
      }
    ) {
      comment_text
      comment_user
      id
      created_at
      comment_thread
      user {
        id
        name
        avatar
      }

      thread {
        comments(order_by: { created_at: asc }, limit: 1) {
          id
          comment_text
        }
        task_threads(limit: 1, order_by: { created_at: desc }) {
          related_task {
            id
            deliverables(limit: 1, order_by: { created_at: desc }) {
              versions(order_by: { version: desc }, limit: 1) {
                resource {
                  name
                  url
                }
              }
            }
          }
        }
      }
    }
    comment_aggregate(
      where: {
        user_activity_trackings: { user_id: { _eq: $userId } }
        comment_user: { _neq: $userId }
        thread: {
          task_threads: { related_task: { project_id: { _eq: $projectId } } }
          visible_to_client: { _eq: true }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_COMMENT_POSITION = gql`
  query getCommentPosition(
    $commentId: String!
    $taskId: String!
    $limit: Int!
    $nestedLimit: Int!
    $isFileComment: Boolean! = false
  ) {
    getCommentPosition(
      commentId: $commentId
      taskId: $taskId
      limit: $limit
      nestedLimit: $nestedLimit
      isFileComment: $isFileComment
    ) {
      limit
      nestedLimit
      nestedOffset
      offset
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getUserNotification(
    $limit: Int!
    $offset: Int!
    $userId: uuid!
    $projectId: uuid!
  ) {
    user_notification(
      order_by: { created_at: desc }
      where: {
        user_id: { _eq: $userId }
        notification: { project_id: { _eq: $projectId } }
      }
      limit: $limit
      offset: $offset
    ) {
      notification {
        id
        image
        task_id
        text
        title
        url
        created_at
        client_url_obj
      }
      read_status
    }
    user_notification_aggregate(
      where: {
        user_id: { _eq: $userId }
        notification: { project_id: { _eq: $projectId } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PROJECT_MANAGER = gql`
  query getProjectManager($projectId: uuid!) {
    user(
      where: {
        role: { name: { _ilike: "DESIGN_MANAGER" } }
        deleted_at: { _is_null: true }
        project_users: {
          user: { role: { name: { _ilike: "DESIGN_MANAGER" } } }
          project_id: { _eq: $projectId }
        }
      }
    ) {
      phone
      name
      email
    }
  }
`;

export const GET_ANALYTICS = gql`
  query analyticsData($projectId: uuid!) {
    getAnalyticsData(projectId: $projectId) {
      countAssets
      days
      percentApproved
      feedbackLiked
      feedbackSatisfied
      feedbackLoved
      statusApproved
      statusPending
      statusDisapproved
    }
  }
`;
