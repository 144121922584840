import styled from "styled-components";
import { IonCol, IonRow, IonText } from "@ionic/react";
import dateTimeToTimestamp from "../../lib/DateTimeToTimeStamp";
import timeStampToTimeElapsed from "../../lib/TimeStampToTimeElapsed";
import { stringToHslColor } from "../../lib/stringToHslColor";

import { Avatar } from "antd";

const CommentContent = styled(IonCol)`
  padding: 16px 10px;
  flex: 1;
  display: flex;
  align-items: center;
`;
const ProfileIconContent = styled(IonCol)`
  flex: 0.01;
  font-weight: 600;
`;

const ProfileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.875rem;
  width: 1.875rem;
  border-radius: 50%;
  background-color: ${({ background }) =>
    background ? background : "#7C9EC9"};
  color: rgb(255, 255, 255);
  margin-top: 6px;
`;
const ProfileAvatar = styled(Avatar)`
  background-color: ${({ background }) =>
    background ? background : "#3f99a2"};
  height: 100%;
  width: 100%;
  .ant-avatar-string {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const ProfileText = styled.span`
  font-size: 0.875rem;
`;

const Header = styled(IonRow)`
  display: flex;
  justify-content: unset;
`;

const Comment = styled(IonCol)`
  flex: 1;
`;

const ProfileName = styled(IonText)`
  color: var(--text-primary);
  font-weight: 400;
  margin-right: 10px;
`;

const Name = styled.span`
  font-weight: 700;
`;
const TimeStamp = styled.span`
  color: #859ee6;
  font-weight: 600;
`;
const ImageContent = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 120px;
`;

const Image = styled.img`
  height: 68px;
  border-radius: 5px;
`;

const Content = styled(IonRow)`
  font-size: 0.75rem;
  width: 100%;
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #ffffff;
  }
`;

const CommentsItem = (props) => {
  const getTimeElapsedFormat = (dateTime) => {
    let dataDateTime = new Date(dateTime).toLocaleString();
    let previousTimeStamp = dateTimeToTimestamp({
      date: dataDateTime.substring(0, 10),
      time: dataDateTime.substring(12),
    });
    let currentDateTime = new Date().toLocaleString();
    let currentDate = currentDateTime.substring(0, 10);
    let currentTime = currentDateTime.substring(12);

    let currentTimeStamp = dateTimeToTimestamp({
      date: currentDate,
      time: currentTime,
    });
    let timeElapse = timeStampToTimeElapsed(
      currentTimeStamp,
      previousTimeStamp
    );
    return timeElapse || "";
  };

  return (
    <Content onClick={() => props.handleOnClickComment(props.task_id || "")}>
      <CommentContent>
        <ProfileIconContent>
          <ProfileIcon background={props.profileBackground}>
            <ProfileAvatar
              src={props.user?.avatar}
              background={stringToHslColor(props.user?.name || "A")}
            >
              <ProfileText>
                {(props.user?.name && props.user.name.charAt(0)) || "A"}
              </ProfileText>
            </ProfileAvatar>
          </ProfileIcon>
        </ProfileIconContent>
        <Comment>
          <Header>
            <ProfileName>
              <Name>
                {props.user?.name || ""}&nbsp;
                {props.type}
                &nbsp;
              </Name>
              {props.comment}
              {/* &nbsp; */}
            </ProfileName>
            <TimeStamp>{getTimeElapsedFormat(props.createdAt)}</TimeStamp>
          </Header>
        </Comment>
        <ImageContent>
          <Image src={props.imageUrl} height="50px" width="100%" />
        </ImageContent>
      </CommentContent>
    </Content>
  );
};

export default CommentsItem;
