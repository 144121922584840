import React from "react";

const AssetsIcon = ({
  width = "20",
  height = "21",
  viewBox = "0 0 20 21",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.08789 12.4463H14.0821"
        stroke="#929292"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.08301 6.7997C2.08301 4.76477 3.12467 3.01716 5.10134 2.6116C7.07717 2.20525 8.57884 2.34573 9.82634 3.01874C11.0747 3.69176 10.7172 4.68541 11.9997 5.41477C13.283 6.14493 15.3472 5.04811 16.6955 6.50287C18.1072 8.02589 18.0997 10.364 18.0997 11.8545C18.0997 17.5179 14.9272 17.9672 10.0913 17.9672C5.25551 17.9672 2.08301 17.5751 2.08301 11.8545V6.7997Z"
        stroke="#929292"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default AssetsIcon;
