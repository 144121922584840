import styled from "styled-components";

const Chip = styled.div`
  color: var(--text-primary);
  font-weight: ${({ fw }) => fw || 600};
  font-family: "Montserrat";
  font-size: 0.8rem;
  text-transform: capitalize;
  background: ${({ status }) =>
    status === "pending"
      ? "#ffdc3e"
      : status === "approved"
      ? "#8BDDB3"
      : "#FF8080"};
  border-radius: 100px;
  padding: 0 10px;
  height: clamp(21px, 7vw, 35.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 5.849rem;
  width: fit-content;
`;

export default Chip;
