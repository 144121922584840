import React, { useEffect, useState, useRef } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useParams } from "react-router-dom";
import {
  useIonToast,
  IonIcon,
  IonButton as ReactIonButton,
} from "@ionic/react";
import { Popover, Button as AntdButton, Input, Tooltip } from "antd";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import PlaceholderImage from "assests/images/placeholder.jpg";
import ApproveIcon from "assests/icons/svg/approveCheckCircle.svg";
import RejectIcon from "assests/icons/svg/rejectCircle.svg";
import Close from "assests/icons/close";

import EditIcon from "./editIcon";
import ExpandIcon from "./expandIcon";
import CloseIcon from "./closeIcon";
import ResetIcon from "./resetIcon";

import {
  ADD_FILE_COMMENT,
  APPROVE_OR_REJECT_VERSION,
  UPDATE_ASSET_STATUS,
} from "../../graphQL/mutations";
import { useRouterQuery } from "../../lib/useRouterQuery";
import { getDate } from "lib/getDate";

import CommentList from "./commentList";
import Flex from "components/flex";
import Dot from "components/dot";

const MainWrapper = styled.div`
  height: 100%;
`;

const TextArea = styled(Input.TextArea)`
  border: none !important;
  margin-top: 18px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 18px;
  color: var(--text-primary);
  &:focus {
    box-shadow: none !important;
  }
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: ${({ isReviewPage }) => (isReviewPage ? " 0 auto" : "unset")};
  background-color: #e3e3e3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 8 8'%3E%3Cg fill='%23cccccc' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
  ${({ isReview }) =>
    isReview &&
    `
       display: flex;
      flex-direction: column-reverse;
      background: #000;
      .transform-component-module_wrapper__1_Fgj {
      margin: 12% 0;
      height:unset !important;
      }
  `}

  .transform-component-module_wrapper__1_Fgj {
    width: 100%;
    height: 100%;
    background-color: #e3e3e3;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 8 8'%3E%3Cg fill='%23cccccc' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
  }
  .react-transform-component {
    width: fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
    ${({ isReviewPage }) =>
      !isReviewPage &&
      `
    margin: 0 auto !important;
    transform: unset !important;
    `}
  }
`;

const Image = styled.img`
  width: 100%;
  height: 40vw;
  object-fit: ${({ isReviewPage }) => (isReviewPage ? "contain" : "unset")};
`;

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  color: var(--text-primary);
  min-width: 22px;
  height: 22px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
`;

const IonButton = styled(ReactIonButton)`
  text-transform: capitalize;
  width: ${({ fullWidth }) => (fullWidth ? "399px" : "190px")};
  font-size: 0.738rem;
  font-family: "Montserrat";
  --background: ${({ inverse }) => (inverse ? "#FF6F6F" : "#8BDDB3")};
  height: 2.875rem;
  color: #ffffff;
  font-weight: bold;
  --box-shadow: none;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media screen and (max-width: 768px) {
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "190px")};
  }
`;

const MarkerText = styled.div`
  position: absolute;
  font-family: Montserrat;
  font-weight: bold;
  font-size: clamp(10px, 1.1vw, 15.5px);
  margin-right: 5%;
  transform: rotate(-45deg);
`;

const MarkerButton = styled.div`
  color: var(--text-primary);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: ${({ x }) => x || 0};
  top: ${({ y }) => y || 0};
  max-height: 32px;
  max-width: 32px;
  height: 3vw;
  width: 3vw;
  box-shadow: 3px 2px 7px #62626275;
  background: #fff;
  -webkit-border-radius: 63px 63px 63px 63px/ 108px px 108px 72px 72px;
  border-radius: 50% 50% 50% 50%/50% 50% 3% 50%;
  transform: rotate(45deg);
  cursor: pointer;
  &:hover {
    background: #1da1f2;
    ${MarkerText} {
      color: #fff;
    }
  }
  ${({ isActive }) =>
    isActive &&
    ` background: #1da1f2;
  ${MarkerText} {
    color: #fff;
  }`}
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  padding: 4.9px 0;
  font-size: 1.2rem;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: rgb(96, 94, 88);
  color: #fff;
  cursor: pointer;
  transition: 0.25s all;
  margin-left: 5px;
  margin-bottom: ${({ marginBottom }) => marginBottom || ""};
  ${({ isActive }) =>
    isActive &&
    ` color: var(--text-primary);
  background: #ffffffe0;
  border: 1px solid #130f26;`}
  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
  @media screen and (min-width: 768px) {
    ${({ isVisible }) =>
      isVisible &&
      `
  width: 28px;
  height: 28px;
  font-size: 12px;

`}
  }
`;

const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const PostButton = styled(AntdButton)`
  align-self: flex-end;

  span {
    text-decoration-line: underline;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    color: #467aff;
    font-size: 0.75rem;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -2px;
  right: -2px;
  background: none;
  outline: none;
  border: none;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1rem;
`;

const ResetButton = styled(EditButton)`
  @media screen and (min-width: 768px) {
    ${({ isVisible }) =>
      isVisible &&
      ` 
    width: 28px;
    height: 28px;
    font-size: 12px;

  `}
  }
`;

const ImageWrapper = styled.div`
  height: fit-content;
  margin: 0;
  padding: 0;
  transform-origin: 0% 0%;
  width: 100%;
  position: relative;
`;

const PostedCommentWrapper = styled.div`
  padding: 0.675rem;
  font-size: 0.875rem;
`;

const ActionButtonsContainer = styled(Flex)`
  position: ${({ isReview }) => (isReview ? "relative" : "absolute")};
  z-index: 2;
  width: 100%;
  padding: ${({ isReview }) =>
    isReview ? "1rem 1.2rem 1.8rem 1.2rem" : "1rem 1.2rem"};
  max-width: 905px;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

const ApproveOrRejectWrapper = styled(Flex)`
  position: ${({ isReview }) => (isReview ? "relative" : "absolute")};
  bottom: 0;
  padding: 0rem 1.2rem;
  font-size: 2.98rem;
  z-index: 2;
  width: 100%;
  max-width: 905px;
  left: 0;
  right: 0;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    font-size: 2.2rem;
    bottom: 0px;
  }
`;

const Icon = styled(IonIcon)`
  cursor: pointer;
`;

const ImageCount = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: -33px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: fit-content;
  @media screen and (max-width: 768px) {
    color: #000000;
    font-weight: 400;
    bottom: -25px;
    top: unset;
    width: 100%;
  }
`;

const TruncatedText = styled.div`
  white-space: nowrap;
  width: clamp(60px, 100%, 30vw);
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
  text-align: center;
  margin: 0 auto;
  font-weight: 800;
  font-family: "Montserrat";
  font-size: 0.938rem;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const ReviewFullScreen = styled.div`
  background: #fff;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
  height: ${({ isPending }) => (isPending ? "138px" : "80px")};
`;

const SubTitle = styled.div`
  color: #929292;
  font-weight: 600;
  font-size: 0.688rem;
`;

const TruncatedSubText = styled(SubTitle)`
  white-space: nowrap;
  width: ${({ textWidth }) => textWidth || " 38%"};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1rem;
  line-height: 15px;
  color: var(--text-primary);
  margin-right: 10px;
  white-space: nowrap;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleWrapper = styled.div`
  flex: 1;
`;

const IconWrapper = styled.span`
  margin-right: 10px;
  font-size: 0.638rem;
`;

const ImageMarker = ({
  onEdit = undefined,
  isVisible = false,
  url = "",
  currentImage = "",
  fileCommentList = [],
  resetEdit = undefined,
  isReview = false,
  totalFileComment = 0,
  setTotalFileComment = "",
  onPostReplyComment = undefined,
  onPostNewComment = undefined,
  isReviewPage = false,
  name = "",
  imageIndex = 0,
  currentVersionId,
  currentVersion = "",
  onUpdateAsset = undefined,
  assetStatus = "",
  imageURL = [],
  onApproveOrReject = undefined,
  deliverableName = "",
  asset = "",
  onExpandView = undefined,
}) => {
  const imageContainerElement = useRef(null);
  const transformElement = useRef(null);

  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [actionmaxWidth, setActionMaxWidth] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [fileComments, setFileComments] = useState([]);
  const [isFileComment, setIsFileComment] = useState(false);
  const [currentComment, setCurrentComment] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isPopOverVisible, setIsPopOverVisible] = useState(false);
  const [imageError, setImageError] = useState(false);

  const [addFileComment] = useMutation(ADD_FILE_COMMENT);
  const [approveOrRejectVersion] = useMutation(APPROVE_OR_REJECT_VERSION);
  const [updateAssetStatus] = useMutation(UPDATE_ASSET_STATUS);

  const [present] = useIonToast();
  const query = useRouterQuery();
  const tab = query.get("tab");
  const { task_id } = useParams();

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  useEffect(() => {
    setIsFileComment(false);
    setCoords({
      x: null,
      y: null,
    });
    setIsEdit(false);
    transformElement?.current?.centerView();
  }, [currentImage]);

  useEffect(() => {
    setTimeout(() => transformElement?.current?.centerView(), 190);
  }, [isVisible]);

  useEffect(() => {
    const allComments = fileCommentList?.map((each) => {
      return {
        ...each,
        ...(each?.comments[0]?.thread?.file_threads[0]?.coordinates || {
          x: null,
          y: null,
        }),
      };
    });
    setFileComments(allComments || []);
  }, [fileCommentList]);

  const getMarkImages = (e, instance) => {
    setIsFileComment(false);
    if (isEdit) {
      setCoords({
        x: e.nativeEvent.offsetX,
        y: e.nativeEvent.offsetY,
      });
      setTimeout(() => {
        setIsFileComment(true);
      }, 5);
    } else {
      setCoords({
        x: null,
        y: null,
      });
    }
  };

  const onComment = ({ target: { value } }) => {
    setCommentText(value);
  };

  useEffect(() => {
    if (!actionmaxWidth && imageContainerElement?.current?.offsetWidth > 0) {
      setActionMaxWidth(imageContainerElement?.current?.offsetWidth);
    }
  }, [imageContainerElement?.current?.offsetWidth]);
  const getClientSize = () => {
    return {
      x: imageContainerElement?.current?.offsetWidth || 0,
      y: imageContainerElement?.current?.offsetHeight || 0,
    };
  };

  const postComment = async () => {
    const newCoords = {
      x: Math.round((coords.x / getClientSize().x) * 100),
      y: Math.round((coords.y / getClientSize().y) * 100),
    };
    const userId = localStorage.getItem("userId");
    const currentVersionId = localStorage.getItem("currentVersionId");
    try {
      setLoading(true);
      const { data } = await addFileComment({
        variables: {
          commentText,
          commentUser: userId,
          coordinates: { ...newCoords },
          file: currentVersionId,
        },
      });
      if (data?.insert_file_thread_one) {
        if (setTotalFileComment) {
          setTotalFileComment(totalFileComment + 1);
        }
        onPostNewComment({
          ...data.insert_file_thread_one.file_thread,
          ...newCoords,
        });

        setIsFileComment(false);
        setCommentText("");
      }
    } catch (error) {
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    } finally {
      setLoading(false);
    }
  };

  const saveAssetStatus = async (status, userId) => {
    try {
      await updateAssetStatus({
        variables: {
          taskId: task_id,
          userId,
          status,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const checkVersionStatus = (status) => {
    if (imageURL.length === 1) {
      if (status === "APPROVED") {
        onApproveOrReject("approve");
      } else {
        onApproveOrReject("reject");
      }
      return false;
    }
    const assetVersions = [...imageURL];
    const pendingList = assetVersions.filter(
      (each) => each.clientStatus === "pending"
    );
    if (pendingList?.length === 1) {
      const approvedList = assetVersions.filter(
        (each) => each.clientStatus === "approved"
      );
      console.log("approvedList", approvedList);
      const rejectedList = assetVersions.filter(
        (each) => each.clientStatus === "rejected"
      );
      if (
        rejectedList.length > 0 &&
        rejectedList.length === imageURL.length - 1
      ) {
        onApproveOrReject("reject");
        return false;
      } else if (
        approvedList.length > 0 &&
        approvedList.length === imageURL.length - 1
      ) {
        onApproveOrReject("approve");
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const onApproveOrRejectVersion = async (clientStatus = "") => {
    const userId = localStorage.getItem("userId");
    const validateVersions = checkVersionStatus(clientStatus);
    console.log("isLastPendingVersion", validateVersions);
    if (!validateVersions) return;
    if (clientStatus === "REJECTED" && assetStatus !== "in_progress") {
      saveAssetStatus("IN_PROGRESS", userId);
    }
    try {
      const userId = localStorage.getItem("userId");
      const { data } = await approveOrRejectVersion({
        variables: {
          userId,
          clientStatus,
          deliverableVersionId: currentVersionId,
        },
      });
      if (data?.update_deliverable_version_by_pk) {
        if (onUpdateAsset) {
          onUpdateAsset({ ...data.update_deliverable_version_by_pk });
        }
        showToastMessage({
          message: clientStatus === "APPROVED" ? "Approved" : "Rejected",
        });
      } else {
        showToastMessage({
          color: "danger",
          message: "Something went wrong please try again!",
        });
      }
    } catch (error) {
      console.log(error);
      showToastMessage({
        color: "danger",
        message: error.message || "Something went wrong please try again!",
      });
    }
  };

  const content = (
    <CommentWrapper
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <CloseButton onClick={() => setIsFileComment(false)}>
        <CloseIcon />
      </CloseButton>
      <TextArea
        value={commentText}
        autoFocus
        onChange={onComment}
        placeholder="Add Comment..."
        autoSize={{ minRows: 2, maxRows: 3 }}
      />
      <PostButton
        type="link"
        onClick={postComment}
        loading={isLoading}
        disabled={!commentText}
      >
        Post
      </PostButton>
    </CommentWrapper>
  );

  const onSelectEditFileComment = () => {
    setIsEdit(!isEdit);
    if (onEdit) onEdit();
  };

  const resetAll = (resetTransform, centerView) => {
    resetTransform(0);
    centerView(undefined, 0);
    setIsEdit(false);
    if (resetEdit) resetEdit();
  };
  const handledVisibleChange = (value) => {
    setIsPopOverVisible(value);
    if (!value) {
      setCurrentComment(null);
    }
  };

  return (
    <MainWrapper isReviewPage={isReviewPage} isReview={isReview}>
      <Wrapper isReviewPage={isReviewPage} isReview={isReview}>
        <TransformWrapper
          ref={transformElement}
          limitToBounds={false}
          disabled={!isReviewPage}
          pan={{
            disabled: false,
            lockAxisX: false,
            lockAxisY: false,
            velocityEqualToMove: false,
            velocity: false,
          }}
          centerOnInit
          pinch={{ disabled: false }}
          doubleClick={{ disabled: true }}
          wheel={{
            wheelEnabled: true,
            touchPadEnabled: true,
            limitsOnWheel: false,
          }}
          reset={{
            disabled: false,
            animation: true,
            animationTime: 10,
            animationType: "easeOut",
          }}
        >
          {({
            zoomIn,
            zoomOut,
            resetTransform,
            centerView,
            scale,
            instance,
            ...rest
          }) => (
            <React.Fragment>
              {isReviewPage && !imageError && (
                <>
                  {isReview ? (
                    <ReviewFullScreen
                      isPending={currentVersion?.clientStatus === "pending"}
                    >
                      <TitleWrapper>
                        <Flex alignCenter spaceBetween section="0 0 5px 0">
                          <Title>{asset.name || ""}</Title>
                          {currentVersion.created_at && (
                            <SubTitle>
                              {getDate(currentVersion.created_at)}
                            </SubTitle>
                          )}
                        </Flex>
                        <Flex alignCenter>
                          <Dot
                            status={currentVersion?.clientStatus?.toLowerCase()}
                            size="1rem"
                          />
                          <TruncatedSubText>
                            {deliverableName || ""}
                          </TruncatedSubText>
                        </Flex>
                      </TitleWrapper>
                      {currentVersion?.clientStatus === "pending" && (
                        <Flex>
                          <IonButton
                            shape="round"
                            style={{ marginRight: "10px" }}
                            inverse
                            onClick={() => onApproveOrRejectVersion("REJECTED")}
                          >
                            <IconWrapper slot="start">
                              <Close height="10" width="10" />
                            </IconWrapper>
                            <span>Reject</span>
                          </IonButton>
                          <IonButton
                            shape="round"
                            onClick={() => onApproveOrRejectVersion("APPROVED")}
                          >
                            <IconWrapper slot="start">
                              <CheckOutlined />
                            </IconWrapper>
                            <span>Approve</span>
                          </IonButton>
                        </Flex>
                      )}
                    </ReviewFullScreen>
                  ) : (
                    !isEdit &&
                    currentVersion?.clientStatus === "pending" && (
                      <ApproveOrRejectWrapper
                        spaceBetween
                        isReview={isReview}
                        isVisible={isVisible}
                        maxWidth={imageContainerElement?.current?.offsetWidth}
                      >
                        <Tooltip
                          placement="topLeft"
                          title="Reject this asset version"
                        >
                          <Icon
                            icon={RejectIcon}
                            onClick={() => onApproveOrRejectVersion("REJECTED")}
                          />
                        </Tooltip>
                        <Tooltip
                          placement="topRight"
                          title="Approve this asset version"
                        >
                          <Icon
                            icon={ApproveIcon}
                            onClick={() => onApproveOrRejectVersion("APPROVED")}
                          />
                        </Tooltip>
                      </ApproveOrRejectWrapper>
                    )
                  )}
                  <ActionButtonsContainer
                    spaceBetween
                    isReview={isReview}
                    isVisible={isVisible}
                    maxWidth={imageContainerElement?.current?.offsetWidth}
                  >
                    <ControlsWrapper isVisible={isVisible} isReview={isReview}>
                      <Button onClick={() => zoomOut()}>-</Button>
                      <Button>
                        {Math.round(
                          (instance?.transformState?.scale - 1) * 14.285714286
                        )}
                        %
                      </Button>
                      <Button onClick={() => zoomIn()}>+</Button>
                      <ResetButton
                        onClick={() => resetAll(resetTransform, centerView)}
                      >
                        <ResetIcon />
                      </ResetButton>
                    </ControlsWrapper>
                    <Flex>
                      <EditButton
                        isActive={isEdit}
                        onClick={onSelectEditFileComment}
                      >
                        <EditIcon />
                      </EditButton>
                      {window.innerWidth < 768 &&
                        !isEdit &&
                        isReviewPage &&
                        !isReview && (
                          <EditButton marginLeft="18px" onClick={onExpandView}>
                            <ExpandIcon />
                          </EditButton>
                        )}
                    </Flex>
                  </ActionButtonsContainer>
                  {!isReview && (
                    <ImageCount>
                      {deliverableName && (
                        <TruncatedText>{deliverableName}</TruncatedText>
                      )}
                      {imageIndex + 1} of {imageURL.length}
                    </ImageCount>
                  )}
                </>
              )}
              <TransformComponent className="image-container-test">
                <ImageWrapper
                  onDoubleClick={(e) => getMarkImages(e, instance)}
                  ref={imageContainerElement}
                >
                  <Image
                    src={imageError ? PlaceholderImage : url}
                    alt="vizdom"
                    isReviewPage={isReviewPage}
                    onError={(e) => {
                      setImageError(true);
                    }}
                  />
                  {(isEdit || tab === "File") &&
                    fileComments?.map((eachFileComment, fileCommentIndex) => (
                      <Popover
                        content={
                          isPopOverVisible && (
                            <CommentList
                              comments={eachFileComment.comments}
                              fileCommentIndex={fileCommentIndex}
                              commentInfo={eachFileComment}
                              totalFileComment={totalFileComment}
                              onPostReplyComment={onPostReplyComment}
                            />
                          )
                        }
                        trigger="click"
                        style={{ position: "relative" }}
                        overlayClassName="custom-popover"
                        onVisibleChange={handledVisibleChange}
                        key={`image-marker-${eachFileComment.id}`}
                      >
                        <MarkerButton
                          x={`calc(${eachFileComment.x}% - 1.2vw)`}
                          y={`calc(${eachFileComment.y}% - 2.6vw)`}
                          onClick={() => setCurrentComment(eachFileComment)}
                          isActive={eachFileComment.id === currentComment?.id}
                        >
                          <MarkerText>
                            {totalFileComment - fileCommentIndex}
                          </MarkerText>
                        </MarkerButton>
                      </Popover>
                    ))}

                  {isFileComment && (
                    <Popover
                      content={content}
                      trigger="click"
                      visible={isFileComment}
                      style={{ position: "relative" }}
                      overlayClassName="custom-popover"
                    >
                      {isFileComment && (
                        <MarkerButton
                          x={`calc(${Math.round(
                            (coords.x / getClientSize().x) * 100
                          )}% - 1.2vw)`}
                          y={`calc(${Math.round(
                            (coords.y / getClientSize().y) * 100
                          )}% - 2.6vw)`}
                        >
                          <MarkerText>{totalFileComment + 1}</MarkerText>
                        </MarkerButton>
                      )}
                    </Popover>
                  )}
                </ImageWrapper>
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      </Wrapper>
    </MainWrapper>
  );
};

export default ImageMarker;
