import { IonRow, IonText } from "@ionic/react";
import { useState } from "react";
import styled from "styled-components";
import CommentsSelectorHeader from "./commentsSelectorHeader";
import TaskComments from "./taskComments";
import NoDataMessage from "../noDataMessage";
import FileComments from "./fileComments";
import { useHistory } from "react-router-dom";
import { useRouterQuery } from "../../lib/useRouterQuery";
import { useParams } from "react-router-dom";

const Content = styled(IonRow)`
  flex-direction: column;
  font-family: Montserrat, sans-serif;
`;
const NoDataText = styled(IonText)`
  font-size: 0.75rem;
  font-weight: 700;
  color: #575757;
  padding-top: 20px;
`;

const CommentWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const Comments = ({
  height = "",
  path = "individual-asset",
  fileCommentList = [],
  fillCommentTotalCount = 0,
  getFileOffset = undefined,
}) => {
  // const [menu, setMenu] = useState("Task");
  const history = useHistory();
  const { task_id } = useParams();
  const taskId = task_id;
  const query = useRouterQuery();
  const menu = query.get("tab");

  const handleMenuSelector = (menu) => {
    history.push(`/assets/${taskId}/${path}?status=comment&tab=${menu}`);
    // setMenu(menu);
  };

  return (
    <Content style={{ marginTop: 40 }}>
      <CommentsSelectorHeader
        menuOption1="Task"
        menuOption2="File"
        menuOption3="Resolved"
        handleMenuSelector={handleMenuSelector}
        menu={menu}
        menu1Length="0"
        menu2Length="0"
        menu3Length="0"
        newMenu1={true}
        newMenu2={false}
        newMenu3={false}
        background={path === "review" ? "#ffffff" : "#f5f5f5"}
      />
      <CommentWrapper>
        {menu === "Task" && (
          <TaskComments menu={menu} height={height} path={path} />
        )}
        {menu === "File" && (
          <TaskComments
            menu={menu}
            height={height}
            path={path}
            fileCommentList={fileCommentList}
            fillCommentTotalCount={fillCommentTotalCount}
            getFileOffset={getFileOffset}
          />
        )}
        {menu === "Resolved" && <TaskComments menu={menu} height={height} />}
      </CommentWrapper>
    </Content>
  );
};

export default Comments;
