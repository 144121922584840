import React from "react";

const WebCommentsSelectedIcon = ({
  width = "20",
  height = "20",
  viewBox = "0 0 20 20",
  color = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.60107 9.5108C6.14084 9.5108 5.76774 9.8839 5.76774 10.3441C5.76774 10.8044 6.14084 11.1775 6.60107 11.1775H6.60857C7.06881 11.1775 7.44191 10.8044 7.44191 10.3441C7.44191 9.8839 7.06881 9.5108 6.60857 9.5108H6.60107ZM9.10864 10.3441C9.10864 9.8839 9.48174 9.5108 9.94198 9.5108H9.94948C10.4097 9.5108 10.7828 9.8839 10.7828 10.3441C10.7828 10.8044 10.4097 11.1775 9.94948 11.1775H9.94198C9.48174 11.1775 9.10864 10.8044 9.10864 10.3441ZM12.4494 10.3441C12.4494 9.8839 12.8225 9.5108 13.2827 9.5108H13.2902C13.7505 9.5108 14.1235 9.8839 14.1235 10.3441C14.1235 10.8044 13.7505 11.1775 13.2902 11.1775H13.2827C12.8225 11.1775 12.4494 10.8044 12.4494 10.3441ZM3.72535 3.72296C7.18792 0.259022 12.8128 0.258989 16.2755 3.72286L16.2756 3.72296C19.7451 7.19217 19.7378 12.8118 16.2756 16.2744C13.5776 18.9737 9.58797 19.5662 6.31283 18.077C6.29757 18.0761 6.2696 18.0752 6.22541 18.0764C6.12426 18.0792 5.98816 18.0914 5.817 18.1113C5.65727 18.1298 5.47929 18.1537 5.28509 18.1798L5.24942 18.1846C5.04336 18.2122 4.82185 18.2417 4.59793 18.2677C4.15526 18.319 3.67718 18.3598 3.25871 18.3417C3.04901 18.3326 2.83566 18.3081 2.63977 18.2543C2.44794 18.2017 2.23289 18.1101 2.06159 17.939L2.06134 17.9388C1.89009 17.7675 1.79841 17.5524 1.74566 17.3605C1.6918 17.1646 1.66728 16.9512 1.65818 16.7414C1.64001 16.3228 1.68087 15.8447 1.73224 15.4019C1.75823 15.178 1.78776 14.9564 1.81542 14.7503L1.8203 14.7139L1.82031 14.7139C1.84636 14.5199 1.87022 14.3422 1.88881 14.1826C1.90875 14.0115 1.92088 13.8753 1.92374 13.7742C1.92499 13.73 1.92402 13.7021 1.92318 13.6868C0.433244 10.4119 1.02658 6.42119 3.72535 3.72296Z"
        fill="#130F26"
      />
    </svg>
  );
};
export default WebCommentsSelectedIcon;
