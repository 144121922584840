import React, { useState } from "react";
import styled from "styled-components";
import HomeIcon from "../assests/icons/mobHomeIcon";
import CommentsIcon from "../assests/icons/mobCommentsIcon";
import AssetsIcon from "../assests/icons/mobAssetsIcon";
// import MessageIcon from "../assests/icons/mobMessageIcon";
import MobHomeSelectedIcon from "../assests/icons/mobHomeSelectedIcon";
import MobCommentsSelectedIcon from "../assests/icons/mobCommentsSelectedIcon";
import MobAssetsSelectedIcon from "../assests/icons/mobAssetsSelectedIcon";
// import MobMessageSelectedIcon from "../assests/icons/mobMessageSelectedIcon";
import { useHistory } from "react-router-dom";
import { personOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import ChatIcon from "assests/icons/chatIcon";
import { Popover as AntdPopover } from "antd";
import UserChat from "./userChat";

const SideBarWrapper = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  position: relative;
  bottom: 0;
  background: #f5f5f5;
  user-select: none;
  padding: 0px 4% 8px 4%;
  z-index: 10;
  .chat-popover {
    width: 100% !important;
  }
`;
const MenuItemList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 520px;
  margin: 0 auto;
`;
const Item = styled.div`
  //   margin-bottom: ${({ itemKey }) => itemKey !== 4 && "60px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.div`
  margin-top: 5px;
  font-size: 0.688rem;
  color: ${({ selected }) => (selected ? "#130F26" : "#929292")};
  font-weight: ${({ selected }) => (selected ? 700 : 500)};
  letter-spacing: 0.2px;
`;

const Icon = styled(IonIcon)`
  color: ${({ iconColor }) => (iconColor ? "#130F26" : "#929292")};
  --ionicon-stroke-width: 47px;
`;

const Popover = styled(AntdPopover)`
  width: 100% !important;
`;

const MenuItem = ({ title, value, Icon, itemKey, onClick }) => {
  return (
    <Item key={itemKey} itemKey={itemKey} onClick={() => onClick(value)}>
      {Icon}
      <Title selected={value.toLowerCase() === title.toLowerCase()}>
        {value}
      </Title>
    </Item>
  );
};

const MobBottomMenu = ({ title = "Home" }) => {
  const history = useHistory();
  const [selectedMenu, setSelectedMenu] = useState(title);

  const handdleClick = (itemValue) => {
    setSelectedMenu(itemValue);
    history.push("/" + itemValue);
  };

  const onChangePopoverState = (value) => {
    if (!value) {
      setSelectedMenu(title);
    }
  };

  return (
    <SideBarWrapper>
      <MenuItemList>
        <MenuItem
          title={selectedMenu}
          value="Home"
          itemKey={1}
          Icon={
            selectedMenu.toLowerCase() === "home" ? (
              <MobHomeSelectedIcon />
            ) : (
              <HomeIcon />
            )
          }
          onClick={() => handdleClick("home")}
        />
        <MenuItem
          title={selectedMenu}
          value="Comments"
          itemKey={2}
          Icon={
            selectedMenu.toLowerCase() === "comments" ? (
              <MobCommentsSelectedIcon />
            ) : (
              <CommentsIcon />
            )
          }
          onClick={() => handdleClick("comments")}
        />
        <Popover
          content={<UserChat inverse />}
          trigger="click"
          overlayClassName="chat-popover"
          itemKey={3}
          onVisibleChange={onChangePopoverState}
        >
          <MenuItem
            title={selectedMenu}
            value="Chat"
            onClick={() => setSelectedMenu("chat")}
            Icon={
              <ChatIcon
                filled={selectedMenu.toLowerCase() === "chat"}
                itemKey={5}
              />
            }
          />
        </Popover>
        <MenuItem
          title={selectedMenu}
          value="Assets"
          itemKey={4}
          Icon={
            selectedMenu.toLowerCase() === "assets" ? (
              <MobAssetsSelectedIcon />
            ) : (
              <AssetsIcon />
            )
          }
          onClick={() => handdleClick("assets")}
        />
        <MenuItem
          title={selectedMenu}
          value="Profile"
          itemKey={5}
          Icon={
            <Icon
              icon={personOutline}
              iconColor={selectedMenu.toLowerCase() === "profile"}
            />
          }
          onClick={() => handdleClick("user/profile")}
        />

        {/* <MenuItem
          title={title}
          value="Messages"
          itemKey={4}
          Icon={
           selectedMenu.toLowerCase() === "messages" ? (
              <MobMessageSelectedIcon />
            ) : (
              <MessageIcon />
            )
          }
          onClick={() => handdleClick("messages")}
        /> */}
      </MenuItemList>
    </SideBarWrapper>
  );
};
export default MobBottomMenu;
