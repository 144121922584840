import React from "react";
import PageLayout from "../../../components/pageLayout";

import useWindowDimensions from "../../../components/useWindowDimensions";
import IndividualAssetComponent from "../../../components/individualAsset";

const IndividualAsset = () => {
  const { width } = useWindowDimensions();
  return (
    <PageLayout title="Assets" arrowCircle={true}>
      <IndividualAssetComponent />
    </PageLayout>
  );
};

export default IndividualAsset;
