import {
  IonRow,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonToast,
  IonLoading,
  IonText,
} from "@ionic/react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import LinksItem from "./linksItem";
import { useParams } from "react-router-dom";
import NoDataMessage from "../noDataMessage";
import { LINKS_METADATA } from "../../graphQL/queries";
import { useQuery } from "@apollo/client";

const NoDataText = styled(IonText)`
  font-family: Montserrat, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  color: #575757;
  padding-top: 20px;
`;
const NoDataContent = styled(IonRow)``;
const TotalCount = styled(IonText)`
  font-family: var(--font-family);
  display: none;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 16px 0px 16px 0px;
`;
const Content = styled(IonRow)`
  font-family: var(--font-family);
  @media screen and (max-width: 768px) {
    ${TotalCount} {
      display: block;
    }
  }
`;

const LinksComponent = ({ menu }) => {
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [count, setCount] = useState(0);
  const [getMoreLinks, setGetMoreLinks] = useState(false);
  const { task_id } = useParams();
  const [present] = useIonToast();

  const [linksData, setLinksData] = useState([]);

  const { data: linksMetaData, loading: linksDataLoading } = useQuery(
    LINKS_METADATA,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: {
        taskId: task_id,
        limit: limit,
        offset: offset,
      },
      onError: (err) => {
        showToastMessage({
          color: "danger",
          message: err.message || "Something went wrong please try again!",
        });
      },
    }
  );

  const showToastMessage = ({ color = "success", message = "" }) => {
    present({
      buttons: [{ icon: "close", role: "cancel" }],
      message,
      position: "top",
      color,
      duration: 3000,
    });
  };

  useEffect(() => {
    if (linksMetaData?.getCommentLinksWithMetaData?.links.length > 0) {
      if (getMoreLinks) {
        setLinksData([
          ...linksData,
          ...linksMetaData.getCommentLinksWithMetaData.links,
        ]);
        setCount(linksMetaData?.getCommentLinksWithMetaData?.count);
        setGetMoreLinks(false);
      } else {
        setLinksData(linksMetaData.getCommentLinksWithMetaData.links);
        setCount(linksMetaData?.getCommentLinksWithMetaData?.count);
      }
      setGetMoreLinks(false);
    }
  }, [linksMetaData]);

  const loadMoreLinks = ($event) => {
    if (linksData.length < count) {
      setGetMoreLinks(true);
      setOffset(offset + limit);
      $event.target.complete();
    } else {
      setDisableInfiniteScroll(true);
    }
  };
  return (
    <Content>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={linksDataLoading}
        message={"Please wait..."}
      />
      <IonContent
        style={{
          width: "100%",
          overflowY: "auto",
          height: window.innerHeight - 200,
        }}
        scrollEvents
      >
        {linksData?.length > 0 ? (
          <>
            <TotalCount>
              {count.toString().length > 1
                ? count + " " + "Links"
                : count + " " + "Link"}
            </TotalCount>
            {linksData?.map((ele, id) => (
              <LinksItem
                key={id}
                id={id}
                sentByPersonName={ele.creator?.name || ""}
                url={ele.url ? ele.url : ""}
                hostName={ele["og:site_name"] ? ele["og:site_name"] : ""}
                image={ele["og:image"] || ""}
              />
            ))}
          </>
        ) : (
          <NoDataContent>
            <NoDataText>{`No ${menu} Found`}</NoDataText>
          </NoDataContent>
        )}
        <IonInfiniteScroll
          threshold="100px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e) => loadMoreLinks(e)}
        >
          <IonInfiniteScrollContent loadingText="Loading links..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </Content>
  );
};

export default LinksComponent;
