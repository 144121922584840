import React from "react";
import { Carousel as ResposiveCarousel } from "react-responsive-carousel";
import { Modal as AntdModal } from "antd";
import styled from "styled-components";
import { getDate } from "lib/getDate";
import Flex from "components/flex";
import { LabelText } from "components/text";
import Header from "components/header";
import CarouselNavigationArrow from "components/carouselNavigationArrow";
import MediaCloseIcon from "assests/icons/svg/mediaCloseIcon.svg";
import { IonText, IonIcon } from "@ionic/react";

const HeaderContent = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const Carousel = styled(ResposiveCarousel)`
  .thumbs-wrapper {
    margin: 20px 20px 0;
  }
  .thumbs {
    padding-left: 0;
    margin-bottom: 0;
  }
  .thumb.selected {
    transform: scale(1.19);
    border: 0px !important;
  }
  .thumb {
    transition: 0.15s all ease-in;
    border: 1px solid white;
    &:hover {
      border: 1px solid grey;
    }
  }
  .control-next.control-arrow {
    padding: 0;
    &:before {
      border-left: 8px solid #000;
    }
  }
  .carousel.slider {
    user-select: none;
  }
  .control-prev.control-arrow {
    padding: 0;
    &:before {
      border-right: 8px solid #000;
    }
  }
  .carousel.slide.legend {
    margin-left: -50% !important;
    width: 100% !important;
  }
  .slide img {
    height: 86vh;
    object-fit: contain;
  }
  .carousel {
    &:nth-child(2) {
      position: absolute !important;
      bottom: 10px !important;
    }
  }
`;

const SentByWrapper = styled(Flex)`
  transition: all 0.5s ease-in-out;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 600;
  position: absolute;
  bottom: 0px;
  background: rgb(0 0 0 / 35%);
  width: 100%;
  left: 0%;
  height: clamp(20px, 26vh, 86px);
  padding-left: 4%;
  text-align: initial;
`;

const ImageWrapper = styled.div``;

const Modal = styled(AntdModal)`
  width: 100% !important;
  max-width: unset !important;
  height: 100%;
  margin: unset !important;
  .ant-modal-body {
    padding: 0px !important;
    height: 100vh;
  }
  .ant-modal-close {
    top: 18px !important;
    right: 12px !important;
  }

  @media screen and (max-width: 768px) {
    .ant-modal-close {
      display: none;
    }

    ${Carousel} {
      .slide img {
        height: clamp(254px, 61vh, 310px);
      }
      .thumbs-wrapper {
        margin: 0 20px;
      }

      .carousel {
        &:nth-child(2) {
          position: absolute !important;
          bottom: 0 !important;
        }
      }
    }

    ${ImageWrapper} {
      display: flex;
      flex-direction: column-reverse;
    }
    ${SentByWrapper} {
      position: unset;
      padding: 6% 4% 10%;
      height: unset;
      background: unset;
      color: #000;
      .createdTitle {
        color: #969697;
      }
    }
  }
`;

const Icon = styled(IonIcon)`
  font-size: 2.82rem;
  transition: 0.15s all;
  &:hover {
    transform: scale(1.2);
  }
`;

const MediaDetailedView = ({
  imageDetailsList = [],
  isVisible = false,
  onClose = undefined,
  defaultSelected = 0,
}) => {
  return (
    <Modal
      centered
      visible={isVisible}
      footer={null}
      onCancel={onClose}
      closeIcon={<Icon icon={MediaCloseIcon} />}
    >
      <HeaderContent>
        <Header arrowCircle onClickBack={onClose} />
      </HeaderContent>

      <Carousel
        showIndicators={false}
        showStatus={false}
        selectedItem={defaultSelected}
        renderArrowNext={(onClick, isVisible) =>
          isVisible && (
            <CarouselNavigationArrow
              onClick={onClick}
              rightArrow
              isVisible
              smTop="60%"
            />
          )
        }
        renderArrowPrev={(onClick, isVisible) =>
          isVisible && (
            <CarouselNavigationArrow onClick={onClick} inverse smTop="60%" />
          )
        }
      >
        {imageDetailsList?.map(({ url, comment, created_at, name }, index) => (
          <ImageWrapper key={`media-detailed-view=${index}`}>
            <img src={url} alt={name || "media_image"} />
            <SentByWrapper center column>
              {comment?.user?.name && (
                <LabelText color="inherit" margin="0 0 4px 0">
                  Sent By {comment?.user?.name}
                </LabelText>
              )}
              {created_at && (
                <div className="createdTitle">
                  Created On :{getDate(created_at)}
                </div>
              )}
            </SentByWrapper>
          </ImageWrapper>
        ))}
      </Carousel>
    </Modal>
  );
};

export default MediaDetailedView;
